@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/fonts';
@import '../../../../../../../assets/fontello/css/fontello.css';

.order-detail-description-panel-container{

    .order-detail-description-panel-title{
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #676F8F;
        letter-spacing: 0.4px;
        margin-bottom: 24px;

        .order-detail-description-panel-chevron {
            font-size: 8px;
            transform: rotate(180deg);
            color: #3c7af5;
            position: absolute;
            top: 22px;
            right: 13px;

            &.open {
              transform: unset;
              border-bottom: none;
            }
          }
    }
    .order-detail-description-panel-section{
        margin-bottom: 30px;
        .order-detail-description-panel-section-title{
        text-transform: uppercase;

        font-size: 14px;
        font-family: Ubuntu-Light;
        color: #2F2E3C;
        letter-spacing: 0.28px;
        }
        .order-detail-description-panel-section-content{
            font-size: 14px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        letter-spacing: 0.28px;
        }
    }
}

@media (orientation: portrait) {


    .order-detail-description-panel-container{

        .order-detail-description-panel-title{
            font-size: 12px;
            font-family: Ubuntu-Medium;
            color: #3C7AF5;
            letter-spacing: 0.24px;
            margin-bottom: 16px;

            position: relative;
            .order-detail-description-panel-chevron {
                font-size: 8px;
                transform: rotate(180deg);
                color: #3c7af5;
                position: absolute;
                top: 2px;
                right: 13px;

                &.open {
                  transform: unset;
                  border-bottom: none;
                }
              }
        }
        .order-detail-description-panel-section{

            .order-detail-description-panel-section-title{
            text-transform: uppercase;
            font-size: 12px;
            font-family: Ubuntu-Light;
            color: #2F2E3C;
            letter-spacing: 0.24px;

            }
            .order-detail-description-panel-section-content{
                font-size: 12px;
                font-family: Ubuntu-Medium;
                color: #494F66;
                letter-spacing: 0.24px;
            }
        }
    }
}