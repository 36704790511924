@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';
.add-application-container{

    height: 100%;
    .add-application-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 43px;
        height: 100%;
        overflow: auto;

        .button-submit{
            background-color: $btn-color-disabled;
            border: none;
            border-radius: $btn-border-radius;
            width: $btn-width;
            height: $btn-height;
            color: white;
            font-family: $btn-font;
            font-size: $btn-font-size;
            margin-top: 27px;

            &.enabled{
                background-color: $btn-color-bg-primary;
                cursor: pointer;
            }
        }
    }

    .add-success-application-wrapper{
        margin: 30px;
        background: white;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px #0022AB1A;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-bottom: 75px;
        border-radius: 10px;

        .load-finished-general-container{
            width: auto;
        }
    }
}

@media (orientation: portrait) {

    .add-application-container{

        .add-application-wrapper{
           padding: 21px 16px;
           margin-bottom: 71px;

           .app-add-container{
               width: 100%;
           }

           .button-submit-container{
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               background-color: white;
               display: flex;
               justify-content: center;
               align-items: center;
               height: 71px;
               box-shadow: 0px 0px 6px #00000029;

           }
            .button-submit{
                margin: 0;
                width: $btn-width-short;
                border-radius: $btn-border-radius;
                font-family: $btn-font;
                font-size: $btn-font-size;
                &.enabled{
                }
            }
        }

        .add-success-application-wrapper{

            .load-finished-general-container{
                width: 100%;
                box-sizing: border-box;
                .load-finished-general-subtitle{
                    width: 85%;

                    .credentials-title{
                        display: none;
                    }
                 }
            }
        }
    }
}