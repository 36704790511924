@import "../../../../assets/styles/variables";

.content-shelving{
  margin-top: 40px;
}

.grid-item-shelving{
  margin-top: 40px;
  height: 68vh;
  overflow-y: auto;
}

.header-shelving{
  display: inline;
  width: 100%;
}

.count-shelving{
  font-size: 25px !important;
  margin: 10px !important;
  left: 40vw !important;
  position: absolute;
}

.update-shelving{
  margin-left: 50px !important;

}

.queue-shelving{

  margin-left: 20px !important;
}

.textupdate-shelving{
  margin-top: 20px !important;
  color: #676f8f;
  display: flex;
  font-family: Ubuntu-Light !important;
  font-size: 12px;
}

.btnrefresh-shelving{
  margin-top: 10px !important;
  align-items: end !important;
  width: 140px;
  background: #676f8f !important;
  border-color: transparent;
  border-radius: $btn-border-radius !important;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  font-family: $btn-font !important;
  font-size: $btn-font-size;
  height: $btn-height;
  justify-content: center;
  padding: 0 35px;
}

.txtcantidad-shelving{
  color: #62d862;
  font-family: Ubuntu-Medium !important;
  font-size: 70px;
  margin-left: 10px;
  margin-top: -5px !important;
}

.textTotal-shelving{
  color: #494f66 !important;
  font-family: Ubuntu-Medium !important;
  font-size: 30px !important;
  margin-top: 10px !important;
  margin-left: 150px !important;

}

.iamgeestanteria-shelving{
  height: 630px;
  margin-left: 20px;
}

.image-est-shelving{
  background-image: url("../../../../assets/images/Estanteria4x.png");
  background-size: 330px !important;
  background-repeat: no-repeat;
  height: 780px;
  width: 400px;
  margin-left: 140px;
}

.grid-shelving{
  padding-top: 120px !important;
  padding-left: 16px !important;
}

.rigth-box-shelving{
  padding-right: 0px;
}

.icon-close-shelving{
  transform: scale(1.8);
}

.tittle-dialog-shelving{
  color: #494f66;
  font-family: Ubuntu-Medium !important;
  font-size: 24px !important;
}

.grid-item-shelving{
  margin-top: 15px;
  padding-top: 20px !important;
  margin-left: 30px;
  margin-bottom: 20px;
  background-color: #fafafc !important;
}

.alert-icon-shelving{
  position: absolute;
  height: 45px;
  padding-top: 10px;
  padding-left: 240px;
}

.image-shelf-shelving{
  padding: 15px;
  margin-top: 3px;
  height: 80px;
}


@media (orientation: portrait){
  .content-shelving{
    margin-top: 40px;
  }

  .grid-item-shelving{
    margin-top: 40px;
    height: 68vh;
    overflow-y: auto;
  }

  .header-shelving{
    display: inline;
    width: 100%;
  }

  .count-shelving{
    font-size: 25px !important;
    margin: 10px !important;
    left: 40vw !important;
    position: absolute;
  }

  .update-shelving{
    margin-left: 50px !important;

  }

  .queue-shelving{

    margin-left: 20px !important;
  }

  .textupdate-shelving{
    margin-top: 20px !important;
    color: #676f8f;
    display: flex;
    font-family: Ubuntu-Light !important;
    font-size: 12px;
  }

  .btnrefresh-shelving{
    margin-top: 20px !important;
    align-items: end !important;
    width: 170px;
    background: #676f8f !important;
    border-color: transparent;
    color: #fff !important;
    cursor: pointer;
    display: flex;
    height: $btn-height;
    justify-content: center;
    padding: 0 35px;
    line-height: 1.1 !important;
  }

  .txtcantidad-shelving{
    color: #62d862;
    font-family: Ubuntu-Medium !important;
    font-size: 70px;
    margin-left: 10px;
    margin-top: -5px !important;
  }

  .textTotal-shelving{
    color: #494f66 !important;
    font-family: Ubuntu-Medium !important;
    font-size: 30px !important;
    margin-top: 10px !important;
    margin-left: 150px !important;

  }

  .iamgeestanteria-shelving{
    height: 630px;
    margin-left: 20px;
  }

  .image-est-shelving{
    background-image: url("../../../../assets/images/Estanteria4x.png");
    background-size: 330px !important;
    background-repeat: no-repeat;
    height: 780px;
    width: 400px;
    margin-left: 140px;
  }

  .grid-shelving{
    padding-top: 120px !important;
    padding-left: 16px !important;
  }

  .rigth-box-shelving{
    padding-right: 0px;
  }

  .icon-close-shelving{
    transform: scale(1.8);
  }

  .tittle-dialog-shelving{
    color: #494f66;
    font-family: Ubuntu-Medium !important;
    font-size: 24px !important;
  }

  .grid-item-shelving{
    margin-top: 15px;
    padding-top: 20px !important;
    margin-left: 30px;
    margin-bottom: 20px;
    background-color: #fafafc !important;
  }

  .alert-icon-shelving{
    position: absolute;
    height: 45px;
    padding-top: 10px;
    padding-left: 240px;
  }

  .image-shelf-shelving{
    padding: 15px;
    margin-top: 3px;
    height: 80px;
  }
}