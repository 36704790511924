@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/fonts";

@import "../../../../../../../assets/fontello/css/fontello.css";

.reception-order-step-three-container {
  width: 520px;
  .reception-order-step-three-location {
    margin-bottom: 20px;
  }
  .step-container {
    width: 100% !important;
    .step-title-wrapper .step-title {
      font-size: 16px;
    }
  }
}
.reception-order-step-three-content {
  .check-all {
    display: flex;
    align-items: center;
    color: #494f66;
    font-size: 14px;
    font-family: Ubuntu-Light;
    border-bottom: 0.5px solid #d4d5e8;
    padding: 10px 0;
    margin-bottom: 20px;
  }
  .step-title-wrapper .step-title {
    font-size: 16px;
    text-align: left;
  }
  .reception-order-step-title {
    display: flex;
    justify-content: space-between;
    .reception-order-step-title-wrapper {
      font-family: Ubuntu-Light;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #676f8f;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .reception-order-step-three-icon-wrapper {
      cursor: pointer;
    }
  }

  .reception-order-step-dest {
    font-family: Ubuntu-Medium;
    font-size: 18px;
    color: #494f66;

    margin-bottom: 41px;
  }
}

@media (orientation: portrait) {

  .reception-order-step-three-container {
    width: 330px;

    .step-container {
      width: 100% !important;
      .step-title-wrapper .step-title {
        font-size: 14px;
      }
    }
  }
  .reception-order-step-three-content {
    .reception-order-step-title {
      display: flex;
      justify-content: space-between;
      .reception-order-step-title-wrapper {
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #676f8f;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      .reception-order-step-edit-icon {
      }
    }

    .reception-order-step-dest {
      font-family: Ubuntu-Medium;
      font-size: 12px;
      color: #494f66;

      margin-bottom: 12px;
    }
  }
}
