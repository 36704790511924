@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.add-business-container{
    height: 100%;
    overflow: auto;
    .add-business-content{
        overflow: auto;
        width: 100% ;
        height: 100%;
        padding: 70px 15px 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .add-business-wrapper{
            width: 350px;
            .add-business-step-wrapper{
                margin-top: 15px;

            }
        }
    }
    .add-business-success{
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin: 22px;
        height: 100%;

        .load-finished-general-container{
            width: 400px;
        }
    }
}
@media (orientation: portrait) {

    .add-business-container{
        .add-business-content{
            overflow: auto;

            width: 100% ;
            height: 100%;
            padding: 22px 22px;
            display: block;

            box-sizing: border-box;
            margin-bottom: 71px;
            .add-business-wrapper{
                width: 100%;
                .add-business-step-wrapper{
                    margin-top: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .add-business-success{
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            margin: 22px;
            height: 100%;

            .load-finished-general-container{
                width: 260px;
            }
        }
    }
}
