@import '../../../assets/styles/variables';
@import '../../../assets/styles/fonts';

.applications-container{
    height: 100%;
    overflow: auto;

    .application-table-wrapper{
        height: 100%;
        width: 93vw;
        
        overflow: auto;
        box-sizing: border-box;
       
      
        max-height: calc(100% - 116px);
      
        padding: 15px 56px;
    

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .app-table-container{
            width: 100%;
        }
        .application-table-pagination{
            margin-top: 30px;
        }
    }
}

@media (orientation: portrait) {

    .applications-container{


        .application-table-wrapper{
            padding: 21px 16px;
            width: 100vw;
            
            .app-table-container{
            }
            .application-table-pagination{
            }
        }
    }
}