@import "../../../assets/styles/fonts";
@import "../../../assets/styles/variables";

.extra-menu-container{
    height: 100%;
    overflow: auto;
    .extra-menu-content-stock{
        display: flex;
        justify-content: center;
        overflow: auto;
        .extra-menu-list{
            width: 85%;
            flex-direction: column;
            display: flex;
            gap: 15px;
            margin-top: 25px;
            cursor: pointer;
            a{
                text-decoration: none;
            }
        }
    }
}

@media (orientation: portrait) {

    .extra-menu-container{
        height: 100%;
        overflow: auto;
        .extra-menu-content{
            .extra-menu-list{
                width: 85%;
            }
        }
    }
}