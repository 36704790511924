@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/fonts';

.product-result-search-item-container{
    display: flex;
    flex-direction: column;
    min-width: 0;
    .product-result-search-item-wrapper{
        display: flex;
        justify-content: space-between;

    }
    .product-summary-container {
        flex: 1;
        .product-summary-units, .product-summary-divider{
            display: none;
        }
    }
    .product-result-search-item-right-col{
        .product-result-search-item-stock{
            color: #62D862;
            font-size: 16px;
            font-family: Ubuntu-Medium;
            text-align: right;
            margin-bottom: 16px;
        }
        .product-result-search-item-number-input{

        }
    }

    .product-result-search-item-divider{
        width: 100%;
        height: 0.5px;
        background-color: #D4D5E8;
        margin-bottom: 20px;
    }

    &:last-child{
        .product-result-search-item-divider{
            display: none;
        }
    }
}

@media (orientation: portrait) {


    .product-result-search-item-container{
        display: flex;
        justify-content: space-between;
        .product-result-search-item{
            flex: 1;
            overflow: hidden;
        }
        .product-summary-container {

            .product-summary-units, .product-summary-divider{
                display: none;
            }
            .product-summary-name{
                font-size: 15px;
            }
            .product-summary-brand, .product-summary-code{
                font-size: 12px;

            }

        }
        .product-result-search-item-right-col{
            margin-left: 10px;
            .product-result-search-item-stock{
                color: #62D862;
                font-size: 12px;
                font-family: Ubuntu-Medium;
                text-align: right;
                margin-bottom: 16px;
            }
            .product-result-search-item-number-input{

            }
        }
    }

}