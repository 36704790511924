@import "../../../../../assets/styles/variables";

.dialog_device {
  .btn_cancel{
    background-color: grey;
    color: white;
    font-weight: bold;
    &:hover{
      background-color: #d3d3d3;
    }
  }

  .btn_save{
    background-color: $btn-color-bg-primary;
    color: white;
    font-weight: bold;
    &:hover{
      background-color: $btn-color-bg-primary;
    }
    &:disabled{
      background-color: $btn-color-bg-primary;
      color: white;
    }
  }
}