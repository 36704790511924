@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.edit-category-modal-container {
  .main-app-generic-modal-container {
    .main-app-generic-modal-modal-container {
      width: 489px;
      height: auto;
      min-height: unset;
      position: relative;
    }
  }
  .edit-category-modal-wrapper {
    padding: 6px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .product-modal-wrapper-close {
      color: #676f8f;
      font-size: 16px;
      position: absolute;
      right: 26px;
      top: 24px;
      cursor: pointer;
    }

    .edit-category-modal-title {
      font-family: Roboto-Medium;
      font-size: 20px;
      letter-spacing: 1.25px;
      color: #494f66;
      text-align: center;
      margin-bottom: 38px;
    }

    .edit-category-modal-input {
      .main-input-label {
        display: none;
      }

      .main-input-wrapper {
        height: 40px;
      }

      input {
        font-size: 20px;
      }
    }

    .edit-category-modal-buttons {
      display: flex;
      margin-top: 30px;
      width: 86%;
      justify-content: space-between;
    }

    .edit-category-modal-button {
      font-family: Ubuntu-Medium;
      font-size: 14px;
      color: #3c7af5;
      cursor: pointer;
    }
  }
}

@media (orientation: portrait) {
  .edit-category-modal-container {
    .main-app-generic-modal-container {
      width: 330px;

      .main-app-generic-modal-modal-container {
        // width: 90%;
        max-width: 330px;
        height: auto;
        min-height: unset;
      }
    }
    .edit-category-modal-wrapper {
      padding: 6px 27px;
      // padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .product-modal-wrapper-close {
        display: none;
      }

      .edit-category-modal-title {
        font-family: Ubuntu-Medium;
        font-size: 20px;
        letter-spacing: 0.32px;
        color: #494f66;
        margin-bottom: 31px;
      }

      .edit-category-modal-text {
        font-family: Ubuntu-Light;
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #676f8f;
        padding: 0px 12px;
        margin-bottom: 42px;
      }

      .edit-category-modal-input {
        .main-input-label {
          display: none;
        }

        .main-input-wrapper {
          height: 40px;
        }

        input {
          font-size: 20px;
        }
      }

      .edit-category-modal-buttons {
        display: flex;
        margin-top: 30px;
        width: 97%;
        justify-content: space-evenly;
      }

      .edit-category-modal-button {
        font-family: Ubuntu-Medium;
        font-size: 14px;
        color: #3c7af5;
      }
    }
  }
}
