@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

@import "../../../assets/fontello/css/fontello.css";

.table-item-stock-mobile-container {
  display: flex;
  flex-direction: row;
  // width: 330px;
  height: 96px;
  box-shadow: 0 1px 15px #0022ab1a;
  box-sizing: border-box;
  padding: 18px 15px 18px 11px;
  border-radius: 10px;
  // margin-bottom: 8px;

  .table-item-stock-mobile-col-2 {
    display: flex;
    flex-direction: column;
    width: 177px;
    padding-left: 9px;
    box-sizing: border-box;
    flex: 1;
    .table-item-stock-mobile-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .table-item-stock-mobile-name {
        font-family: Ubuntu-Medium;
        font-size: 15px;
        color: #2f2e3c;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .table-item-stock-mobile-header-separator {
        height: 14.4px;
        width: 0.5px;
        background: #494f66;
        margin: 0 8px;
      }
      .table-item-stock-mobile-brand {
        font-family: Ubuntu-Medium;
        font-size: 15px;
        color: #2f2e3c;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .table-item-stock-mobile-code {
      font-family: Ubuntu-Light;
      font-size: 12px;
      color: #2f2e3c;
      margin-top: 9px;
    }
    .table-item-stock-mobile-stock {
      font-family: Ubuntu-Light;
      font-size: 12px;
      color: #2f2e3c;
      margin-top: 6px;
      .table-item-stock-mobile-stock-title {
        font-family: Ubuntu-Medium;
        font-size: 12px;
        color: #62d862;
      }
      .table-item-stock-mobile-stock-title-out {
        font-family: Ubuntu-Medium;
        font-size: 12px;
        color: #f55d68;
      }
    }
  }
  .table-item-stock-mobile-col-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .table-item-stock-mobile-category {
      display: flex;
      text-align: right;

      .table-item-stock-mobile-category-inner {
        display: inline-block;
        color: white;
        background-color: #3c7af5;
        padding: 4px 10px;
        font-family: Ubuntu-Medium;
        font-size: 10px;
        text-transform: capitalize;
        border-radius: 15px;
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
    .table-item-stock-mobile-total {
      font-size: 12px;
      font-family: Ubuntu-Medium;
      color: #2f2e3c;
    }
  }
}
