@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';


.acct-selection-container{
    .title{
        color: #475157;
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-family: Roboto-Medium;
        margin-bottom: 21px;
    }

    .add-email{
        cursor: pointer;
    }
    .account-container{
        display: flex;
        align-items: center;
        height: 56px;
        cursor: pointer;

        p{
            padding: 0;
            margin: 0;
        }
        .icon{
            margin-right: 8px;
            font-size: 40px;
            // color: #8D8D8D;
        }

        .email{
            font-size: 16px;
            font-family: Roboto;
            color: #000000DE;
        }

        .icon-plus{
            width: 40px;
            height: 40px;
            background: grey;
            color: white;
            font-size: 30px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            margin-left: 6px;
        }
    }
}