@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.delivered-orders-card-container {
  width: 100%;
  height: 100%;
}

.dashboard-card-title {
  color: #494f66;
  font-family: Ubuntu-Medium;
  font-size: 21px;
  margin: 0;
  font-weight: normal;
}

.delivered-orders-item {
  border-bottom: 0.5px solid #858ba5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivered-orders-item-client {
  color: #494f66;
  font-family: Ubuntu-Medium;
  font-size: 16px;
  line-height: 37px;
}

.delivered-orders-item-orders {
  color: #494f66;
  font-family: Ubuntu;
  font-size: 16px;
  letter-spacing: 0.32px;
}

.delivered-orders-item-client-first {
  color: #62d862;
  font-family: Ubuntu-Medium;
  font-size: 18px;
}

.delivered-orders-item-orders-first {
  color: #62d862;
  font-family: Ubuntu-Light;
  font-size: 50px;
  display: flex;
  align-items: center;

  & span {
    color: #858ba5;
    font-family: Ubuntu-Light;
    font-size: 15px;
    margin-right: 10px;
  }
}

@media (orientation: portrait) {

  .dashboard-card-title {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
}
