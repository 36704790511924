@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.multi-select-wrapper{
    background: white;
}
.MuiPopover-paper{
    margin-top: -1px;
    border: 1px solid #D4D5E8 !important;
    border-top: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding-right: 17px;
    margin-left: -18px !important;
}

.MuiSelect-icon{
    top: -4px!important;
}
.MuiFormControl-root{
}
.MuiInputLabel-root{
    font-family: Ubuntu-Light!important;
    color: #A2A9C8!important;
}
.MuiInputLabel-formControl{
    top: -6px !important;
    color: #A2A9C8!important;

}
.MuiInputLabel-formControl.Mui-focused{
    top: 8px !important;
    color: #A2A9C8!important;

}

.MuiInputLabel-formControl.MuiFormLabel-filled{
    top: 8px !important;
    color: #A2A9C8 !important;

}

.required-span{
    color: #3C7AF5;
}

.MuiSelect-root{
    margin-top: 8px;
}

.MuiSelect-select{
    color: #494F66!important;
    font-family: Ubuntu-Medium!important;
    font-size: 16px!important;
}
.MuiSelect-select:focus{
    background-color: transparent!important;
}

.MuiInputBase-input{
    border-bottom: none !important;
    input{
    border-bottom-color: white !important;

    }
}

.MuiInput-underline:after{
    border-bottom-color: white;
}

.MuiSelect-root{
    input{
        border-bottom-color: white;
    }
}

.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none!important;
}

.MuiInput-underline:before{
    border-bottom: none!important;
}

.MuiInput-underline:after{
    border-bottom: none!important;
}

.MuiListItem-root{
    margin-right: -17px !important;
}

.items-selected {
    .MuiTouchRipple-root {
        border: none !important;
    }
}

@media (orientation: portrait) {
    .MuiPopover-paper{
        margin-top: -1px;
        border: 1px solid #D4D5E8 !important;
        border-top: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        padding-right: 17px;
        margin-left: 1px !important;
    }
}
