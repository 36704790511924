@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

.provider-list-container {
  height: 100%;
  overflow: auto;

  .outter-wrapper {
    height: 100%;
  }
  .table-provider-wrapper {
    height: 100%;
    max-height: calc(100% - 116px);
    overflow: auto;
    padding: 15px 56px;
    .provider-no-provider-content {
      margin-top: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
      }
      .provider-no-provider-title {
        width: 274px;
        text-align: center;
        margin-bottom: 44px;
        color: #a9b1d1;
        font-size: 16px;
        font-family: "Ubuntu-Medium";
        letter-spacing: 0.67px;
      }
      .provider-create-button {
        width: $btn-width;
        height: $btn-height;
        text-align: center;
        background: $btn-color-bg-primary;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $btn-font-size;
        font-family: $btn-font;
        color: white;
        cursor: pointer;
      }
    }
    .provider-list-content {
      .provider-list-table {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;

        .generic-table-container {
          width: 100%;
        }
        .table-pagination {
          margin-top: 30px;
        }
      }
    }
  }
  .provider-table-mobile-panel {
    display: none;

    &.opened {
      display: block;
    }
  }
}

@media (orientation: portrait) {

  .provider-list-container {
    height: 100%;

    .table-provider-wrapper {
      max-height: calc(100% - 70px);
      padding: 0px;
      overflow-y: scroll;

      .provider-no-provider-content {
        img {
        }
        .provider-no-provider-title {
        }
        .provider-create-button {
          width: $btn-width-short;
          border-radius: $btn-border-radius;
          font-size: $btn-font-size;
          font-family: $btn-font;
        }
      }

      .provider-table-inner {
        padding: 0;
        height: 100%;

        .provider-table-filter-container {
          .simple-search-panel {
            margin-bottom: 18px;
          }
        }

        .filter-panel-container {
          .filter-panel-wrapper {
            .filter-panels-button {
              display: none;
            }
          }
        }

        .pagination-container {
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }

    .provider-table-table {
      padding: 20px;
      margin-bottom: 60px;
      height: 100%;
      max-height: calc(100% - 178px);
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .header-button {
      svg {
        path {
          fill: #3c7af5;
        }
      }
    }

    .provider-table-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;

      .provider-table-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;

        .provider-table-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .provider-table-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.provider-table-active-filter {
          color: #3c7af5;
        }

        .provider-table-top-icon {
          font-size: 16px;
          margin-top: 4px;
          margin-left: 3px;

          &.provider-table-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }
}
