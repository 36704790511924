@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';


.load-products-container{
    height: 100%;
    .load-products-content{
        flex: 1;
    overflow: auto;
        background-color: #FAFAFC;
        display: flex;
        flex-direction: column;
        align-items: center;
        .load-products-title{
            color: #858BA5;
            font-family: Ubuntu-Medium;
            font-size: 22px;
            width: 380px;
            text-align: center;
            letter-spacing: 0.92px;
            margin-top: 130px;
        }

        .load-products-buttons-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            .load-products-button{
                margin-bottom: 24px;
            }
        }
    }
}

@media (orientation: portrait) {

        .table-products-wrapper {
          flex: 1;
          overflow: hidden;
          margin-bottom: 0px;
          padding: 0 0 0px;
          .table-products-table {
            padding: 0 16px;
            height: calc(100% - 16px);
            max-height: calc(100% - 16px);
            overflow-y: scroll;
          }
        }
}