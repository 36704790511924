@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.edit-device-form-container {
  .edit-device-form-content {
    .edit-device-form-field {
      margin-bottom: 16px;
      width: 280px;
    }
  }
}

@media (orientation: portrait) {

  .edit-device-form-container {
    .edit-device-form-content {
      .edit-device-form-field {
        margin-bottom: 16px;
        width: 280px;
      }
    }
  }
}
