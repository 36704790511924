.maintabs{
    width: 100%;
    height: 100%;
}

.MuiTabs-root {
    overflow: visible !important;
}

.celltable-devices{
    padding:9px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 110px;
    min-width: 110px;
    line-height: 1.0rem !important;
    cursor: pointer;
}


.cellwidth{
    width: 60px !important;
}