@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';


.hi-again{
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form{
        width: 370px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logo-container {
        width: 95px;
    }
    .title{
        font-size: 35px;
        font-family: Roboto-Medium;
        color: #3C7AF5;

        margin-bottom: 0px;
        margin-top: 42px;
    }
    .form-container{

        #user{
        }
        .inputs-wrapper{
            width: 370px;
            .account-dropdown-container{
                margin-top: 33px
            }
        }
        .remember-row{
            display: flex;
            justify-content: flex-end;
            margin-top: 25px;
            padding-left: 39px;

            button{
                background: transparent;
                border: none;
                color: #477CEE;
                font-size: 14px;
                font-family: Roboto;
                cursor: pointer;
            }
        }

    }
    .rounded-button-wrapper{
        padding-left: 34px;
        box-sizing: border-box;
        width: 370px;

        .rounded-button{
            margin-top: 45px;
            width: 100%;
        }
    }

    .form-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 63px;
        width: 100%;
        padding-left: 34px;
        box-sizing: border-box;
        .footer-legend{
            margin-bottom: 5px;
            font-size: 20px;
            font-family: Roboto;
            color: #3D3935;

        }
    }
}

@media (orientation: portrait) {
    .hi-again{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 35px;
        box-sizing: border-box;

        form{
            width: 370px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 35px;
            box-sizing: border-box;
        }
        .logo-container {
            display: none;
        }
        .title{
            width: 100%;
            text-align: center;
            font-size: 24px;
            margin-top: 39px;
            margin-bottom: 0;
            font-family: Roboto-Medium;
            color: #3C7AF5;
        }
        .form-container{
            width: 100%;
            .inputs-wrapper{
                width: 100%;

                .account-dropdown-container{
                    margin-top: 35px
                }
            }
            .remember-row{
                display: flex;
                justify-content: flex-end;
                margin-top: 25px;
                padding: 0;
                button{
                    background: transparent;
                    border: none;
                    color: #477CEE;
                    font-size: 14px;
                    font-family: Roboto;
                }
            }

        }
        .rounded-button-wrapper{
            box-sizing: border-box;
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            padding: 0;
            .rounded-button{
                margin-top: 30px;
                width: 180px;
            }
        }

        .form-footer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-top: 30px;
            padding: 0;

            .footer-legend{
                font-size: 14px;
                margin-top: 0;
                font-family: Roboto;
                color: #3D3935;
                margin-bottom: 5px;

            }
        }
    }
}