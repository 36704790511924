@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.add-role-container{
    height: 100%;
}
.add-role-content-wrapper{
    overflow: auto;

    .add-role-inner{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
        .role-form-content{

        }
    }
}

@media (orientation: portrait) {


    .add-role-content-wrapper{
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        .add-role-inner{
            margin-top: 10px;
            width: 100%;
            .role-form-content{
              
              padding-top: 10px !important;
              padding-bottom: 50px !important;
              overflow-y: auto;

            }
        }
    }
}