.div-colours {
    display: flex;
}

.div-sizes {
    margin-top: 15px;
    display: flex;
}

.colours{
    display: block;
}

.dot-selected{
    font-size: 50px;
    margin-top: 55px;
}