@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.single-load-exit-modal-container {
  .main-app-generic-modal-container {
    .main-app-generic-modal-modal-container {
      width: 520px;
      height: auto;
      min-height: unset;
    }
  }
  .single-load-exit-modal-wrapper {
    padding: 54px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .single-load-exit-modal-title {
      font-family: Ubuntu-Medium;
      font-size: 30px;
      letter-spacing: 1.25px;
      color: #494F66;
      text-align: center;

      margin-bottom: 13px;
    }
    .single-load-exit-modal-text {
      font-family: Ubuntu-Light;
      font-size: 22px;
      letter-spacing: 0.92px;
      color: #494F66;
      text-align: center;

      padding: 0px 45px;
      box-sizing: border-box;
      margin-bottom: 54px;
    }
    .single-load-exit-modal-buttons {
      display: flex;

      width: 100%;
      justify-content: space-evenly
    }
    .single-load-exit-modal-button {
      font-family: Ubuntu-Medium;
      font-size: 18px;
      color: #3C7AF5;
      cursor: pointer;
    }
  }
}

@media (orientation: portrait) {

  .single-load-exit-modal-container {
    .main-app-generic-modal-container {
      .main-app-generic-modal-modal-container {
        width: 90%;
        max-width: 279px;
        height: auto;
        min-height: unset;
      }
    }
    .single-load-exit-modal-wrapper {
      padding: 36px 27px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .single-load-exit-modal-title {
        font-family: Ubuntu-Medium;
        font-size: 16px;
        letter-spacing: 0.32px;
        color: #494F66;

        margin-bottom: 7px;
      }

      .single-load-exit-modal-text {
        font-family: Ubuntu-Light;
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #676F8F;
        padding: 0px 12px;

        margin-bottom: 42px;
      }
      .single-load-exit-modal-buttons {
      display: flex;

      }
      .single-load-exit-modal-button {
        font-family: Ubuntu-Medium;
        font-size: 14px;
        color: #3C7AF5;
        }
    }
  }
}
