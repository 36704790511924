@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/fonts";

@import "../../../../../../../assets/fontello/css/fontello.css";

.reception-order-step-one-container {
  width: 330px;
  .step-container {
    .step-content-wrapper {
      .reception-order-field {
        margin-bottom: 18px;
      }
    }
    .reception-order-step-one-content {
      width: 100% !important;

      .step-title-wrapper .step-title {
        font-size: 16px;
      }
    }
  }
}

@media (orientation: portrait) {

  .reception-order-step-two-container {
    width: 330px;
    overflow: auto;
    height: 100%;
    .step-container {
      .step-content-wrapper {
        .reception-order-field {
          margin-bottom: 18px;
        }
      }
      .reception-order-step-one-content {
        width: 100% !important;

        .step-title-wrapper .step-title {
          font-size: 14px;
        }
      }
    }
  }
}
