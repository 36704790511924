@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";
@import "../../../assets/fontello/css/fontello.css";

.notFound-container {
  .notFound-table-no-notFound-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin-top: 75px;

    .notFound-table-no-notFound-icon {
      font-size: 50px;
      color: #3c7af5;
      margin-bottom: 20px;
    }

    .notFound-table-no-notFound-title {
      font-family: Ubuntu-Medium;
      font-size: 30px;
      color: #494f66;
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
    }

    .notFound-table-no-notFound-subtitle {
      font-size: 22px;
      font-family: Ubuntu-Light;
      color: #676f8f;
      margin: 0;
      padding: 0;
    }
  }

  .notFound-table-table {
    .notFound-table-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;

      .notFound-table-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;

        .notFound-table-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .notFound-table-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.notFound-table-active-filter {
          color: #3c7af5;
        }

        .notFound-table-top-icon {
          font-size: 11px;
          margin-top: 4px;
          margin-left: 3px;

          &.notFound-table-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .notFound-content-container {
    flex: 1;
    overflow: hidden !important;
    padding: 10px 28px 0 61px;
    overflow-y: scroll !important;
    max-height: calc(100svh - 99px);

    .pagination-container {
      margin-top: 26px;
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (orientation: portrait) {

  .notFound-container {
    .notFound-content-container {
      padding: 0;
      flex: 1;
      overflow: hidden !important;
      padding: 0 0;
      margin-bottom: 60px;
    }

    .table-load-notFound-content {
      .table-load-notFound-title {
        color: #858ba5;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        width: 280px;
        text-align: center;
        letter-spacing: 0.67px;
        margin-top: 56px;
        margin-bottom: 43px;
      }
    }

    .notFound-table-table {
      padding: 0 16px;
      overflow-y: scroll;
      max-height: calc(100% - 80px);
      height: 100%;

      .notFound-table-top-table {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        padding: 0 0;
        align-items: center;

        .notFound-table-top-total {
          font-family: Ubuntu-Light;
          font-size: 16px;
          color: #494f66;
          text-transform: none;

          .notFound-table-top-total-bold {
            font-family: Ubuntu-Medium;
          }
        }

        .notFound-table-top-sort {
          color: grey;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          display: flex;
          align-items: center;

          &.notFound-table-active-filter {
            color: #3c7af5;
          }

          .notFound-table-top-icon {
            font-size: 16px;
          }
        }
      }

      .notFound-table-container {
        padding-bottom: 20px;
      }
    }

    .notFound-table-no-notFound-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      text-align: center;

      .notFound-table-no-notFound-icon {
        font-size: 33px;
        color: #3c7af5;
        margin-bottom: 20px;
      }

      .notFound-table-no-notFound-title {
        font-family: Ubuntu-Medium;
        font-size: 16px;
        color: #494f66;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        width: 250px;
      }
    }
  }
}
