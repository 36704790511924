@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.roles-container{
    height: 100%;

    .roles-table-wrapper{
        overflow: auto;
        .roles-table-inner{
            padding: 15px 50px;

            .filter-panel-container{
                .filter-panel-wrapper{
                    .filter-panels-button{
                        display: none;
                    }
                }
            }
            .pagination-container{
                justify-content: flex-end;
                margin-top: 20px;
            }
        }

        .roles-table-top-table {
            margin-bottom: 18px;
            display: flex;
            justify-content: space-between;
            padding: 0 11px;
            align-items: center;
            .roles-table-top-total {
              font-family: Ubuntu-Light;
              font-size: 16px;
              color: #494f66;
              text-transform: capitalize;
              .roles-table-top-total-bold {
                font-family: Ubuntu-Medium;
              }
            }

            .roles-table-top-sort {
              color: grey;
              font-family: Ubuntu-Medium;
              font-size: 16px;
              display: flex;
              align-items: center;

              &.roles-table-active-filter {
                color: #3c7af5;
              }
              .roles-table-top-icon {
                font-size: 11px;
                margin-top: 4px;
                margin-left: 3px;
                &.roles-table-chevron-down {
                  font-size: 6px;
                  margin-top: 4px;
                  margin-left: 3px;
                }
              }
            }
          }

    }

    .table-roles-no-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin-top: 100px;

        i {
          font-size: 50px;
          color: #3c7af5;
          margin-bottom: 20px;
        }
        .table-roles-title{
          color: #A9B1D1;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          letter-spacing: 0.67px;
          margin: 0;
        }

        .table-roles-subtitle{
          margin: 0;
          color: #A9B1D1;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          letter-spacing: 0.67px;

        }

        .table-roles-button{
          height: $btn-height;
          width: $btn-width;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border-radius: $btn-border-radius;
          font-family: $btn-font;
          font-size: $btn-font-size;
          background-color: $btn-color-bg-primary;
          margin-top: 50px;
          cursor: pointer;
        }
      }

    .header-button{
        svg{
            path{
                fill: #3C7AF5;
            }
        }
    }
}

@media (orientation: portrait) {

    .search-container {
      &.focused {
        position: relative;
      }
    }

    .roles-container{
        height: 100%;

        .roles-table-wrapper{
            overflow: auto;
            .roles-table-inner{
                padding: 0;

                .filter-panel-container{
                    .filter-panel-wrapper{
                        .filter-panels-button{
                            display: none;
                        }
                    }
                }
                .pagination-container{
                    justify-content: flex-end;
                    margin-top: 20px;
                }
            }
        }

        .roles-table-table{
            padding: 20px;
            .generic-table-content{
              padding-bottom: 50px !important;
            }
        }

        .header-button{
            svg{
                path{
                    fill: #3C7AF5;
                }
            }
        }
    }
}