@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.credential{
    display: flex;
    border: 1px solid #D4D5E8;
    border-radius: 4px;
    background-color: white;
    padding: 18px;
    height: 56px;
    box-sizing: border-box;
    margin-bottom: 7px;

    .title-credential{
        width: 128px;
        color: #494F66;
        font-size: 16px;
        font-family: Ubuntu-Medium;
        letter-spacing: 0.12px;
        flex-shrink: 0;
    }
    .value-credential{
        display: flex;
        align-items: center;

        color: #494F66;
        font-size: 14px;
        font-family: Ubuntu-Light;
        justify-content: space-between;
        flex: 1;
        min-width: 0;

        .value{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
        }
        .buttons-credentials{
            display: flex;
            align-items: center;


            justify-content: flex-start;
            width: 70px;
            .icon{
                margin-right: 19px;
                cursor: pointer;
            }

            .icon-ojo{
                font-size: 19px;
                cursor: pointer;
            }
        }
    }
}

@media (orientation: portrait) {

    .credential{
        border: none;
        background-color: transparent;
        flex-direction: column;
        width: 100%;
        padding: 0;
        .title-credential{
            text-align: left;
            font-size: 14px;
            letter-spacing: 0.28px;
            font-family: Ubuntu-Light;
        }

        .value-credential{
            color: #2F2E3C;
            .value{
                margin-right: 10px;
            }
        }
    }
}