@import "../../../../assets/styles/variables";

.add-user-container {
  height: 100%;
  overflow: auto;

  .add-user-content-wrapper {
    overflow: auto;
    display: flex;
    justify-content: center;
    padding-top: 91px;

    @media (orientation: portrait) {

      padding-top: 0;
      .user-form-content {
        width: 330px;
        padding-bottom: 70px !important;
      }
    }

    .add-business-button {
      cursor: pointer;
    }
  }
}