@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';

.logo-container{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
    }
}