@import "../../../../../../assets/styles/fonts";
@import "../../../../../../assets/styles/variables";

.user-table-item-wrapper {
  box-shadow: 0 1px 5px #0022ab1a;
  border-radius: 12px;
  width: 100%;
  background: white;

  .user-table-item-container {
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 32px;
    box-sizing: border-box;

    .user-table-item {
      font-size: 18px;
      font-family: Ubuntu-Light;
      color: #494f66;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      height: 100%;
      box-sizing: border-box;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
      }
    }

    .user-table-item-image {
      font-family: Ubuntu-Medium;
      font-size: 20px;

      flex-basis: 138px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;


      p {
        display: flex;
        align-items: center;
      }
      img {
        height: 40px;
      }
    }

    .user-table-item-name {
      font-size: 19px;
      flex-basis: 309px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;
      font-family: Ubuntu-Medium;
      color: #494F66;
      div{
        overflow: hidden;
      }
    }

    .user-table-item-email {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 342px;
      flex-grow: 1;
      margin-right: 60px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;

      box-sizing: border-box;
    }

    .user-table-item-role {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 268px;
      flex-grow: 1;
      margin-right: 60px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;

      box-sizing: border-box;

    }


    .user-table-item-status {
    font-family: Ubuntu-Medium;
    font-size: 19px;

    flex-basis: 168px;
    flex-grow: 1;
    justify-content: center;
    margin-right: 60px;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;


    }

    .user-table-item-button {
      color: #3c7af5;
      font-size: 18px;
      margin-right: 18px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      overflow: visible;
      i {
        transform: rotate(90deg);
      }
    }
  }


  .user-table-pill-container{
    width: 100%;

    .user-table-pill{
      border-radius: 14px;
      color: white;
      font-size: 10px;
      font-family: Ubuntu-Bold;
      padding: 2px 9px;
      display: inline;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0.72px;
      text-align: center;

      p{
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        width: 100%;
        min-width: 0;
        background-color: #3C7AF5;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 7px;
        box-sizing: border-box;
        white-space: break-spaces;
      }

      &.int{
        p{
            background-color: #3C7AF5;
        }
      }

      &.not{
        p{
            background-color: #F55D68;
        }
      }

      &.pen{
        p{
            background-color: #FFAF3A;
        }
      }

      &.act{
        p{
            background-color: #3C7AF5;
        }
      }

    }
  }

  .user-table-item-container-mobile{
    padding: 14px;
    display: flex;
    align-items: center;
    .user-table-item-column-mobile{
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      .user-pill-mobile-container{
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
      }
      .user-logo{
        margin-right: 5px;
        img{
          width: 76px;
        }
      }



    }
    .user-table-item-row-mobile{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .header{
        font-size: 15px;
        font-family: Ubuntu-Medium;
        color: #2F2E3C;
        letter-spacing: 0.3px;
        margin-bottom: 8px;

        span{
          font-size: 12px;
          font-family: Ubuntu;
          letter-spacing: 0.24px;
        }
      }

      .body{
        font-size: 12px;
        font-family: Ubuntu;
        color: #2F2E3C;
        letter-spacing: 0.24px;
        margin-bottom: 5px;

        span{
          font-family: Ubuntu-Light;
        }
      }
      p{
        margin: 0;
      }


    }

  }
}
@media (orientation: portrait) {


  .user-table-item-wrapper {
    box-shadow: 0px 3px 6px #0022AB1A;
    border-radius: 20px;
  }

}