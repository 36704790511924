@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

.add-provider-wizard-step-two-container {
  .add-provider-wizard-step-two-content {
    .add-provider-wizard-step-two-field {
      margin-bottom: 16px;
      width: 280px;
    }
    .add-provider-wizard-step-two-action-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
      background-color: #ffffff;

      .add-provider-wizard-step-two-action-button {
        width: $btn-width-short;
        height: $btn-height;
        border-radius: $btn-border-radius;
        border: none;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #ffffff;
        font-size: $btn-font-size;
        font-family: $btn-font;

        background-color: $btn-color-bg-primary;
        cursor: pointer;

        &.disabled {
          background-color: $btn-color-disabled;
          pointer-events: none;
        }
      }
    }
  }
}
@media (orientation: portrait) {

  .add-provider-wizard-step-two-container {
    .add-provider-wizard-step-two-content {
      .add-provider-wizard-step-two-field {
        margin-bottom: 16px;
        width: 280px;
      }
      .add-provider-wizard-step-two-action-wrapper {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #00000029;
        .add-provider-wizard-step-two-action-button {
          width: $btn-width-short;
          height: $btn-height;
          border-radius: $btn-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;

          color: #ffffff;
          font-size: $btn-font-size;
          font-family: $btn-font;

          background-color: $btn-color-bg-primary;

          &.disabled {
            background-color: $btn-color-disabled;
          }
        }
      }
    }
  }
}
