@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.wrong-file-modal-container {
  .wrong-file-modal-wrapper {
    .wrong-file-modal-title {
      font-size: 30px;
      font-family: Ubuntu-Medium;
      letter-spacing: 0.6px;
      color: #494f66;
      text-align: center;
      margin-top: 12px;
    }
    .wrong-file-modal-text {
      color: #676f8f;
      font-family: Ubuntu-Light;
      font-size: 22px;
      letter-spacing: 0.44px;
      text-align: center;
      margin-top: 14px;
      margin-bottom: 35px;
    }
  }
}

@media (orientation: portrait) {

  .wrong-file-modal-container {
    .wrong-file-modal-wrapper {
      .wrong-file-modal-title {
        font-size: 16px;
        font-family: Ubuntu-Medium;
        letter-spacing: 0.32px;
        color: #494f66;
        text-align: center;
        margin-top: 12px;
      }
      .wrong-file-modal-text {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 14px;
        letter-spacing: 0.28px;
        text-align: center;
        margin-top: 11px;
        margin-bottom: 35px;
      }
    }
  }
}
