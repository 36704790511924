@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.shelving{
  background-image: url("../../../../assets/images/shelving.png") !important;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  box-shadow: 0px 1px 5px #0022ab1a;
  background-color: white;
  position: relative;
  flex-shrink: 0;
  z-index: 10;
  .left-container {
    display: flex;
    align-items: center;
    .icon-go-back-desktop {
      padding-left: 53px;
      color: #3c7af5;
      font-size: 20px;
      margin-top: -2px;

      cursor: pointer;
    }
    .icon-go-back-mobile {
      display: none;
    }

    .title {
      display: flex;
      font-size: 24px;
      font-family: Ubuntu-Medium;
      color: #3c7af5;
      padding-left: 89px;
    }
    .title-go-back {
      display: flex;
      font-size: 24px;
      font-family: Ubuntu-Medium;
      color: #3c7af5;
      padding-left: 13px;
    }
  }

  .right-container {
    display: flex;
    padding-right: 38px;
    align-items: center;
    .header-button {
      margin-right: 34px;
      width: $btn-width;
      button {
        font-size: $btn-font-size;
        font-family: $btn-font;
      }
    }
    .header-item {
      position: relative;
      color: #3c7af5;
      font-size: 27px;
      // margin-right: 5px;
      font-size: 26px;
      cursor: pointer;
      height: 44px;
      width: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #3C7AF5;
        font-family: Ubuntu-Medium;
        font-size: 9px;
        font-weight: 700;
        color: #FFFFFF;
        display: grid;
        place-content: center;
      }

      &.sucursal {
        width: unset;
        height: unset;
        margin-right: 0;
        height: initial;
        width: initial;
        border-radius: 10px;
        padding: 10px;
        .icon {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        p {
          color: #3C7AF5;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          margin-bottom: -3px;
          margin-left: 5px;
        }
      }
      &:hover {
        background: #ecf8ff;
      }
      // &:last-child {
      //   margin-right: 11px;
      //   // margin-right: 0;
      // }
    }
  }
}

@media (orientation: portrait) {
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    flex-shrink: 0;
    box-shadow: 0px 4px 4px #0000003d;
    background-color: #3c7af5;
    z-index: 0 !important;
    .left-container {
      display: flex;
      align-items: center;
      .icon-go-back-mobile {
        display: block;
        padding-left: 10px;
        color: #ffffff;
      }
      .icon-go-back-desktop {
        display: none;
      }
      .title {
        display: flex;
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #ffffff;
        padding-left: 1em;
      }
      .title-go-back {
        display: flex;
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #ffffff;
        padding-left: 17px;
      }
    }

    .right-container {
      display: flex;
      padding-right: 10px;
      .header-item {
        color: #ffffff;
        font-size: 20px;
        // margin-right: 20px;
        font-size: 18px;
        &:hover {
          background: transparent;
        }

        .tooltip-text {
          display: none !important;
        }

        > span {
          background-color: #FFFFFF;
          color: #3C7AF5;
        }

        &:last-child {
          // margin-right: 11px;
          // margin-right: 0;
        }
      }
    }
  }
}
