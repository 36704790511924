@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.main-app-generic-modal-container {
  font-family: Ubuntu-Medium, sans-serif;
  height: fit-content;
  width: 100% !important;
  max-width: 600px;
  margin-inline: 10px;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .main-app-generic-modal-title {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 16px;
    font-weight: normal;
    font-family: Ubuntu-Medium;
    letter-spacing: 0.32px;
    margin: 0 !important;
  }
}

.batch-tags-error-content {
  font-family: Ubuntu-Medium;
  padding: 15px;
  overflow: auto;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .icon-div {
    display: flex;
    justify-content: center;
    .icon-error {
      font-size: 80px;
      color: #FF0000;
      margin: 20px 0;
    }
  }

  .batch-tags-error-title {
    margin: 20px 0;
    font-family: Ubuntu-Medium;
    font-size: 30px;
    letter-spacing: 0.6px;
    letter-spacing: 0.28px;
    text-align: center;
  }

  .batch-tags-error-subtitle {
    margin: 20px 0;
    font-family: 'Ubuntu-Light';
    font-size: 22px;
    letter-spacing: 0.28px;
    text-align: center;
  }

  .batch-tags-error-buttons {
    font-size: 18px;
    font-family: 'Ubuntu-Medium';
    color: #3C7AF5;
    display: flex;
    justify-content: space-around;

    div {
      cursor: pointer;
    }
  }

  .btn-close-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;

    .btn-close{
      font-family: 'Ubuntu-Medium';
      color: white;
      background-color: $btn-color-bg-primary;
      font-family: $btn-font;
      font-size: $btn-font-size;
      border-radius: $btn-border-radius;
      margin-top: 15px;;
    }
  }

}

@media (orientation: portrait) {

  .batch-tags-error-content {

    .batch-tags-error-title {
      font-size: 16px;
    }
    .batch-tags-error-subtitle,
    .batch-tags-error-buttons,
    .MuiButton-label {
      font-size: 14px;
    }

  }

}