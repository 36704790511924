@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";


.simple-search-wrapper{
  position: relative;
  form {
    width: 100%;
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #a9b1d1;
    // background-color: white;
    background-color: transparent;
    padding: 0 6px;
    box-sizing: border-box;
    .search-icon-desktop {
      font-size: 18px;
      color: #3c7af5;
    }
    .search-icon-mobile{
      display: none;
    }
    .search-icon-mobile-focused{
      display: none;
    }
    .search-input {
        flex: 1 1;
        border: none;
        height: 43px;
        box-sizing: border-box;
        font-family: Ubuntu-Medium;
        color: #494F66;
        font-size: 20px;

        padding-left: 8px;
        padding-right: 25px;
        overflow: hidden;
        background: transparent;
      &::placeholder {
        color: #a9b1d1;
        font-family: Ubuntu-Medium;
        font-size: 20px;
      }

      &:focus {
        border: none;
        outline-width: 0;
      }
    }
    .search-close-wrapper {
        position: absolute;
        right: 10px;
      .search-icon-close {
        font-size: 13px;
        color: #494f66;
        cursor: pointer;
      }
    }
  }
}

@media (orientation: portrait) {
  .simple-search-wrapper{
    form {
      width: 100%;
      display: flex;
      height: 48px;
      align-items: center;
      border-bottom: 1px solid #a9b1d1;
      box-shadow: 0 2px 2px #0000003D;
      padding: 0 6px;
      box-sizing: border-box;
      background-color: white;

      .search-icon-mobile {
        display: block;
        font-size: 17px;
        color: #A9B1D1;
        opacity: 0.54;

      }

      .search-icon-mobile-focused {
        display: block;
        font-size: 16px;
        color: #3C7AF5;
        opacity: 0.54;

      }
      .search-icon-desktop{
        display: none;
      }
      .search-input {
          flex: 1 1;
          border: none;
          height: 47px;
          box-sizing: border-box;
          font-family: Ubuntu-Medium;
          color: #494F66;
          font-size: 16px;
          background: white;
          padding-left: 8px;
        &::placeholder {
          color: #a9b1d1;
          font-family: Ubuntu-Medium;
          font-size: 16px;
        }

        &:focus {
          border: none;
          outline-width: 0;
        }
      }
      .search-close-wrapper {
          position: absolute;
          right: 10px;
        .search-icon-close {
          font-size: 13px;
          color: #494f66;
          cursor: pointer;
        }
      }
    }
  }
}