@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.wrong-content-modal-container {
  .wrong-content-modal-wrapper {
    .wrong-content-modal-title {
      font-size: 30px;
      font-family: Ubuntu-Medium;
      letter-spacing: 0.6px;
      color: #494f66;
      text-align: center;
      margin-top: 12px;
    }
    .wrong-content-modal-text {
      color: #676f8f;
      font-family: Ubuntu-Light;
      font-size: 22px;
      letter-spacing: 0.44px;
      text-align: center;
      margin-top: 14px;
      margin-bottom: 0;

      .wrong-content-modal-span {
        font-family: Ubuntu-Medium;
      }
    }

    .wrong-content-modal-download{
      font-size: 18px;
        font-family: Ubuntu-Medium;
        color: #3C7AF5;
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

      .wrong-content-modal-icon{
        margin-right: 7px;

      }
    }
  }
}

@media (orientation: portrait) {

  .wrong-content-modal-container {
    .wrong-content-modal-wrapper {
      .wrong-content-modal-title {
        font-size: 16px;
        font-family: Ubuntu-Medium;
        letter-spacing: 0.32px;
        color: #494f66;
        text-align: center;
        margin-top: 12px;
      }
      .wrong-content-modal-text {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 14px;
        letter-spacing: 0.28px;
        text-align: center;
        margin-top: 11px;
        margin-bottom: 0;

        .wrong-content-modal-span {
          font-family: Ubuntu-Medium;
        }
      }

      .wrong-content-modal-download {
        font-size: 14px;
        font-family: Ubuntu-Medium;
        color: #3C7AF5;
        margin-top: 14px;
        margin-bottom: 27px;
        text-align: center;
        cursor: pointer;
        .wrong-content-modal-download-icon{

        }
      }
    }
  }
}
