@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.active-node-container{
    height: 100%;
    overflow: auto;

    .active-node-content{
        padding: 44px;

        .active-node-button-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
            .active-node-button{
                width: $btn-width;
                height: $btn-height;
                border-radius: $btn-border-radius;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                color: #FFFFFF;
                font-size: $btn-font-size;
                font-family: $btn-font;

                background-color: $btn-color-bg-primary;
                cursor: pointer;

                &.disabled{
                    background-color: $btn-color-disabled;
                }}
        }
    }
}

@media (orientation: portrait) {


    .active-node-container{
        height: 100%;
        overflow: auto;

        .active-node-content{
            padding: 15px;

            .active-node-button-wrapper{
                margin-top: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 6px #00000029;
                .active-node-button{
                     width: $btn-width-short;
                    height: $btn-height;
                    border-radius: $btn-border-radius;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #FFFFFF;
                    font-size: $btn-font-size;
                    font-family: $btn-font;

                    background-color: $btn-color-bg-primary;
                    cursor: pointer;

                    &.disabled{
                        background-color: $btn-color-disabled;
                    }}
            }
        }
    }
}