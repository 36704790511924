@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

.users-container {
  height: 100%;

  .users-table-wrapper {
    overflow: auto;

    .users-table-inner {
      padding: 15px 50px;
      //Ocultar boton para panel de Filtros
      // .filter-panel-container{
      //     .filter-panel-wrapper{
      //         .filter-panels-button{
      // display: none;
      //         }
      //     }
      // }
      .pagination-container {
        justify-content: flex-end;
        margin-top: 20px;
      }
    }

    .users-table-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;

      .users-table-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;

        .users-table-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .users-table-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.users-table-active-filter {
          color: #3c7af5;
        }

        .users-table-top-icon {
          font-size: 16px;
          margin-top: 4px;
          margin-left: 3px;

          &.users-table-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .header-button {
    svg {
      path {
        fill: #3c7af5;
      }
    }
  }

  .users-table-mobile-panel {
    display: none;

    &.opened {
      display: block;
    }
  }

  .table-users-no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 100px;

    i {
      font-size: 50px;
      color: #3c7af5;
      margin-bottom: 20px;
    }

    .table-users-title {
      color: #a9b1d1;
      font-family: Ubuntu-Medium;
      font-size: 16px;
      letter-spacing: 0.67px;
      margin: 0;
    }

    .table-users-subtitle {
      margin: 0;
      color: #a9b1d1;
      font-family: Ubuntu-Medium;
      font-size: 16px;
      letter-spacing: 0.67px;
    }

    .table-users-button {
      height: $btn-height;
      width: $btn-width;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: $btn-border-radius;
      font-family: $btn-font;
      font-size: $btn-font-size;
      background-color: $btn-color-bg-primary;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}

@media (orientation: portrait) {

  .users-container {
    height: 100%;

    .users-table-wrapper {
      overflow: auto;

      .users-table-inner {
        padding: 0;
        height: 100%;

        .users-table-filter-container {
          .simple-search-panel {
            margin-bottom: 18px;
          }
        }

        .filter-panel-container {
          .filter-panel-wrapper {
            .filter-panels-button {
              display: none;
            }
          }
        }

        .pagination-container {
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }

    .users-table-table {
      padding: 20px;
      margin-bottom: 60px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .header-button {
      svg {
        path {
          fill: #3c7af5;
        }
      }
    }
  }
}
