
.path-hand {
    width: 23px;
    height: 23px;
    position: relative;
    background: rgba(149, 147, 241, 0.5);
    border-radius: 40px;
    top: 28px;
    visibility: hidden;
    left: 70px;
    animation: swipe-dot 2s 0.55s infinite;
  }
  
  .hand-icon {
    position: relative;
    background-image: url(./../../../../assets/images/hand.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100px;
    background-size: contain;
    transform-origin: 52% 62%;
    animation: swipe-hand 2s infinite;
  }
  
  @keyframes swipe-hand {
    25% { transform: translate(20px) rotate(30deg); }
    50% { transform: translate(-20px) rotate(-15deg); }
    100% { transform: translate(0px) rotate(0); }
  }
  
  @keyframes swipe-dot {
  12% {
    visibility: visible;
    width: 40px;
  }
  25% {
    visibility: visible;
    transform: translate(-65px);
    width: 20px;
  }
  26% { visibility: hidden; }
  }
  