@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.localization-filter-panel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #0022ab1a;
  box-sizing: border-box;
  padding: 32px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 31px;

  height: 125px;
  min-height: 125px;
  max-height: 125px;



  .localization-filter-panel-row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 35px;
    }
  }

  .localization-filter-panel-inputs {
    display: flex;
    align-items: center;

    .dropdown-filter-container {
      width: 280px;
      margin-right: 14px;
    }

    .localization-filter-panel-input {
      width: 280px;
    }
  }

  .localization-filter-panel-buttons {
    display: flex;
    align-items: center;

    .localization-filter-panel-filter-apply {
      background: #3c7af5;
      border-color: transparent;

      height: $btn-height;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: $btn-border-radius;
      padding: 0 35px;
      font-size: $btn-font-size;
      font-family: $btn-font;
      cursor: pointer;

      &:focus {
        outline-width: 0;
      }
    }

    .localization-filter-panel-filter-delete {
      background: transparent;
      border-color: transparent;
      height: $btn-height;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $btn-color-disabled;
      padding: 0 35px;
      font-size: $btn-font-size;
      font-family: $btn-font;
      cursor: pointer;

      &:focus {
        outline-width: 0;
      }
    }
  }
}

@media (orientation: portrait) {

  .localization-filter-panel-container {

    left: 0;
    background-color: #fafafc;
    border-radius: 0;
    align-items: flex-start;
    padding: 0;
    height: 5% !important;
    min-height: 5% !important;



    .inputs {
      overflow: scroll;
    }

    .localization-filter-panel-row {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      &:first-child {
        margin-bottom: 0px;
      }
    }

    .localization-filter-panel-inputs {
      flex-direction: column;
      width: 100%;
      padding: 19px 27px;
      overflow: scroll;
      height: 100%;

      .dropdown-filter-container {
        width: 100%;
        margin-right: 0;
      }

      .localization-filter-panel-input {
        width: 100%;
      }
    }

    .localization-filter-panel-buttons {
      position: absolute;
      bottom: 0;
      background-color: white;
      box-shadow: 0px 0px 6px #00000029;
      height: 71px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
    }
  }

  .localization-filter-panel-summary {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    padding: 0 17px;
    height: 74px;
    justify-content: center;
    margin-bottom: 19px;

    .localization-filter-panel-summary-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .localization-filter-panel-summary-filters {
        font-family: Ubuntu-Light;
        font-size: 15px;
        color: #2f2e3c;
        display: flex;
        align-items: center;

        .separator {
          width: 1px;
          height: 20px;
          background-color: #2f2e3c;
          margin: 0 4px;

          &:last-child {
            display: none;
            font-size: 16px;
          }
        }
      }

      .localization-filter-panel-summary-button {
        i {
          color: #3c7af5;
          cursor: pointer;
        }
      }

      .localization-filter-panel-summary-stock {
        font-size: 12px;
        font-family: Ubuntu-Medium;
        color: #494f66;
      }
    }
  }
}