.grid-content {
  background-color: white;
  padding: 30px;
  padding-bottom: 15px !important;

  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.font-data {
  font-size: 14px;
  font-family: Ubuntu-Medium;
  color: #676f8f;
  letter-spacing: 0.28px;
  margin-bottom: 5px;
}

.product-content {
  display: flex;
  height: 60px;
}

.product-name {
  margin-left: 10px;
  color: #494f66;
  font-family: Ubuntu-Medium;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-sku {
  margin-left: 10px;
  font-size: 14px;
  font-family: Ubuntu-Medium;
  color: #676f8f;
  letter-spacing: 0.28px;
  margin-bottom: 8px;
}