@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";


.disable-product-container{
    display: flex;
    align-items: center;
    width: 510px;
    justify-content: space-between;
    .disable-product-label{
      font-family: Ubuntu-Light;
      font-size: 18px;
      color: #676F8F;
      .disable-product-label-span{
        font-family: Ubuntu-Medium;
      }
    }

    .disable-product-toggle{

    }

}


@media (orientation: portrait) {


  .disable-product-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .disable-product-label{
      font-family: Ubuntu-Light;
      font-size: 12px;
      color: #676F8F;
      .disable-product-label-span{
        font-family: Ubuntu-Medium;
      }
    }

    .disable-product-toggle{

    }

}
}