@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.step-container {
    background: white;
    width: 330px;
    border-radius: 10px;
    height: auto;
    padding: 31px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .step-title-wrapper{
        margin-bottom: 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        &.go-back{
            margin-left: -9px;
        }
        .step-back-icon{
            font-size: 19px;
            color: #62D862;
            cursor: pointer;
            margin-top: -2px;

        }
        .step-title{
            font-family: Ubuntu-Medium;
            font-size: 14px;
            color: #676F8F;
            box-sizing: border-box;
            letter-spacing: 0.28px;
        }
    }

    .request-order-step-two-field{
        .search-container{
            width: 100%;
            margin: 0 0 18px 0;
        }
    }

    .step-content-wrapper{
        text-align: left;
        flex: 1;
        box-sizing: border-box;
    }
}
@media (orientation: portrait) {
  .step-container {
    margin-bottom: 50px;
  }
}
