@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

.app-add-success-container{
 .subtitle{
    margin-bottom: 23px;
 }
 .credentials-title{
    margin-bottom: 14px;
    color: #676F8F;
    font-family: Ubuntu-Medium;
    font-size: 16px;
    text-align: left;

 }
 .credentials-wrap{

 }
}

@media (orientation: portrait) {

   .app-add-success-container{
      box-sizing: border-box;

      .add-success-application-wrapper{
         .load-finished-general-container{
            width: 100%;
            box-sizing: border-box;

            .load-finished-general-subtitle{
               width: 80%;
            }
         }
      }

   }
}