@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.load-buttons-container {
  height: 179px;
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 15px #0022ab1a;
  padding: 0 34px;
  box-sizing: border-box;
  border-radius: $btn-border-radius;
  background-color: white;
  .icon-load-button {
    font-size: 30px;
    color: #858BA5;
    margin-top: 28px;
    font-size: 40px;

    margin-bottom: 10px;

    &.icon-iconos_varios_productos {
      margin-top: 16px;
      font-size: 47px;
      margin-bottom: 8px;
    }
  }

  .title {
    font-family: Ubuntu-Medium;
    color: #3c7af5;
    font-size: 24px;
    letter-spacing: 1px;
  }
  .subtitle {
    font-family: Ubuntu-Light;
    color: #676f8f;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.75px;
  }

  cursor: pointer;
}

@media (orientation: portrait) {

  .load-buttons-container {
    width: 293px;
    height: 143px;

    padding: 0px 27px;

    .icon-load-button {
      font-size: 35px;
      margin-top: 6px;
      &.icon-iconos_varios_productos {
        margin-top: 4px;
        font-size: 47px;
        margin-bottom: 4px;
      }
    }

    .title {
      font-size: 20px;
      letter-spacing: 0.83px;
    }

    .subtitle {
      font-family: Ubuntu-Light;
      font-size: 14px;

      letter-spacing: 0.58px;
    }
  }
}
