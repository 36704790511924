@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.applications-edit-container{
    height: 100%;

    .submit-wrapper{
        height: 100%;
        display: flex;
        align-items: center;
        .button-submit{
            background-color: $btn-color-disabled;
            border: none;
            border-radius: $btn-border-radius;
            width: $btn-width-short;
            height: $btn-height;
            color: white;
            font-family: $btn-font;
            font-size: $btn-font-size;
            margin-right: 37px;

            &.enabled{
                background-color: $btn-color-bg-primary;
                cursor: pointer;
            }
        }
    }
    .application-edit-wrapper{
        height: 100%;
        width: 100%;
        overflow: auto;
        padding: 46px 26px 46px 58px;
        box-sizing: border-box;

    }
}
@media (orientation: portrait) {


    .applications-edit-container{
        .submit-wrapper{

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 71px;
            box-shadow: 0px 0px 6px #00000029;
            .button-submit{
                width: $btn-width-short;
                border-radius: $btn-border-radius;
                font-family: $btn-font;
                font-size: $btn-font-size;
            }

        }

        .application-edit-wrapper{
            margin-bottom: 71px;

            padding: 21px 16px;
        }
    }
}