@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#4b83f0, #3152e6);
  width: 97px;
  height: 100%;
  position: relative;
  z-index: 100;
  // height: 100vh;
  transition: width 0.2s linear;

  &.expanded {
    width: 210px;
  }

  a {
    text-decoration: none;
  }

  .item {
    box-sizing: border-box;
    flex-basis: 81px;
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 81px;
    padding-left: 30px;
    overflow-x: hidden;
    overflow-y: visible;

    svg {
      resize: none;
      flex: 0 0 40px;
      font-size: 32px !important;
      color: white !important;
      width: 40px;
      &.documento,
      &.configuraciones,
      &#Mi_cuenta {
        height: 28px;
      }
    }

    &.active-item {
      background-color: #62d862;
    }

    &.mobile-only {
      display: none;
    }

    .item-title {
      display: block !important;
      margin-left: 12px;
      font-size: 16px;
      letter-spacing: 0.32px;
      width: fit-content;
      min-width: 210px;
      visibility: hidden;

      &.expanded {
        color: white;
        visibility: visible;
      }
    }
  }

  .home {
    flex-basis: auto !important;
    color: white;
    font-family: Roboto-Bold;
    height: 126px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 30px;
    padding: 30px 0;
    width: 100%;

    &.expanded {
      padding-left: 0;
    }

    .nav-icon {
      height: 100%;
      width: 100%;

      img {
        height: 65px;
        width: auto;
        margin: 0 auto;
        margin-left: 18px;
      }
    }

    &.active-home {
      background-color: #62d862;
    }
  }

  .sidebar-bottom {
    position: absolute;
    bottom: 0;
    width: 97px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .item-separator {
    height: 2px;
    background: white;
    width: 46px;
    text-align: center;
    margin: 45px 0 13px 0;
  }
}

.sidebar-toggle {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #3c7af5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: 140px;
  cursor: pointer;

  .icon {
    font-size: 10px;
    color: #3c7af5;
  }
}

@media (orientation: portrait) {
  .sidebar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    box-shadow: 0 0 16px #00000029;
    width: 100%;
    height: 8vh;
    position: absolute;
    z-index: 10;
    bottom: 0;
    background: white;
    overflow-x: hidden;
    overflow-y: hidden;

    a {
      text-decoration: none;
    }

    .item {
      text-decoration: none;
      height: 100%;
      width: 100% !important;
      //min-width: 50px;
      //flex-shrink: 0;
      flex-basis: auto !important;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // position: relative;
      padding-left: 0 !important;

      svg {
        resize: none;
        flex: 0 0 5vh;
        font-size: 32px !important;
        color: white !important;
        width: 5vh;
        path {
          fill: #0000003d;
        }
        &.product,
        &.yax {
          width: 4vh !important;
        }
        &.documento {
          width: 3vh !important;
        }
        &.configuraciones {
          width: 4.5vh !important;
          path {
            stroke: none !important;
          }
        }
      }

      .inner-sep {
        height: 59px;
        background: #a9b1d1;
        opacity: 0.5;
        width: 0.5px;
        position: absolute;
        right: 0;
      }

      .icon {
        font-size: 28px;
        position: absolute;
        color: #a9b1d1;
        top: 12px;
      }

      .item-title {
        display: block;
        color: #a9b1d1;
        font-size: 10px;
        /* margin-top: 10px; */
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100%;
        text-align: center;
        font-family: Ubuntu-Light;
        margin-left: 0;
      }

      &.active-item {
        background-color: #62d862;
        height: 8vh;

        .icon {
          color: white;
        }

        svg {
          path {
            fill: white;
          }
        }

        .item-title {
          color: white;
        }
      }

      &.mobile-hidden {
        display: none;
      }

      &.mobile-only {
        display: flex;
      }
    }

    .home {
      display: none;
    }

    .item-separator {
      display: none;
    }
  }
}
