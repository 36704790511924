
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';
.summary-button-container{
    display: flex;
    box-shadow: 0 3px 6px #00000029;
    padding: 0 40px;
    height: 100px;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;

    .summary-button-left{
        display: flex;
        align-items: center;
        width: 234px;
        .summary-button-title{
            font-family: Ubuntu-Medium;
            font-size: 21px;
            color: #494F66;
            align-items: center;
            margin-left: 16px;

        }
    }
    .summary-button-divider{
        height: 54%;
        width: 2px;
        background-color: #00000029;
        margin: 0 40px 0 0;

    }
    .summary-button-right{
        display: flex;
        align-items: center;
        flex: 1;

        .summary-button-right-wrapper{
            display: flex;
        }
        .summary-button-pending{
            display: flex;
            align-items: center;
            margin-right: 28px;
            .summary-button-pending-text{
                margin-left: 5px;
                font-size: 17px;
                font-family: Ubuntu-Medium;
                color: #FFAF3A;
            }
        }
        .summary-button-processing{
            display: flex;
            align-items: center;
            .summary-button-processing-text{
                margin-left: 5px;
                font-size: 16px;
                font-family: Ubuntu-Medium;
                color: #6F7DFF;
            }
        }
    }

    .summary-button-chevron{
        color: #3C7AF5
    }
}

@media (orientation: portrait) {
    .summary-button-container{
        display: flex;
        box-shadow: 0 3px 6px #00000029;
        padding: 0 11px;
        height: 76px;
        align-items: center;
        border-radius: 6px;
        box-sizing: border-box;
        width: 100%;

        .summary-button-left{
            display: flex;
            flex: 1;
            align-items: center;

            .summary-button-title{
                font-family: Ubuntu-Medium;
                font-size: 16px;
                color: #494F66;
                align-items: center;
                margin-left: 24px;

            }
        }
        .summary-button-divider{
            height: 54%;
            width: 2px;
            background-color: #00000029;
            margin: 0 5px;

        }
        .summary-button-right{
            display: flex;
            align-items: center;
            flex: 1;
            flex-direction: column;

            .summary-button-right-wrapper{
                display: flex;
                flex-direction: column;
            }
            .summary-button-pending{
                display: flex;
                align-items: center;
                margin-right: 0;
                margin-bottom: 14px;

                svg{
                    margin-right: 10px;
                }

                .summary-button-pending-text{
                    margin-left: 5px;
                    font-size: 12px;
                    font-family: Ubuntu-Medium;
                    color: #FFAF3A;
                    margin: 0;
                }
            }
            .summary-button-processing{
                display: flex;
                align-items: center;

                svg{
                    margin-right: 10px;

                }
                .summary-button-processing-text{
                    margin-left: 5px;
                    font-size: 12px;
                    font-family: Ubuntu-Medium;
                    color: #6F7DFF;
                    margin: 0;
                }
            }
        }

        .summary-button-chevron{
           display: none;
        }
    }

    }