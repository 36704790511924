@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.search-with-components-container {
  border: 1px solid #a2a9c8;
  width: 100%;
  position: relative;
  .search-with-components-input-wrapper #divForm {
    width: 100%;
    display: flex;
    height: 44px;
    align-items: center;
    background-color: transparent;
    border-bottom: none;
    padding: 0 20px;

    box-sizing: border-box;
    .search-with-components-icon-desktop {
      font-size: 18px;
      color: #a9b1d1;
    }
    .search-with-components-icon-mobile {
      display: none;
    }
    .search-with-components-icon-mobile-focused {
      display: none;
    }
    .search-with-components-input {
      flex: 1 1;
      border: none;
      height: 43px;
      box-sizing: border-box;
      font-family: Ubuntu-Light;
      color: #494f66;
      font-size: 16px;

      padding-left: 8px;
      padding-right: 25px;
      overflow: hidden;
      background: transparent;
      &::placeholder {
        color: #a9b1d1;
        font-family: Ubuntu-Light;
        font-size: 16px;
      }

      &:focus {
        border: none;
        outline-width: 0;
      }
    }
    .divider {
      height: 74%;
      width: 1px;
      background-color: #a2a9c8;
      margin: 0 10px;
    }
    .search-with-component-only-stock {
      display: flex;
      align-items: center;

      .search-with-component-checkboxl-label {
        color: #494f66;
        font-family: Ubuntu-Light;
        font-size: 14px;
        margin-top: 2px;
      }
    }
    .search-with-components-close-wrapper {
      position: absolute;
      right: 10px;
      .search-with-components-icon-close {
        font-size: 13px;
        color: #494f66;
        cursor: pointer;
      }
    }
  }
  .search-with-components-results-mobile {
    display: none;
  }
  .search-with-components-results-desktop {
    border: 1px solid #858ba5;
    background-color: #f9f9fb;
    z-index: 10;

    position: absolute;
    top: 44px;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    overflow: auto;
    padding: 0;

    .search-with-components-results-desktop-inner {
      list-style: none;
      padding-left: 0;
      background-color: #f9f9fb;

      max-height: 330px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #edeff5;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #62d862;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      li {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 16px;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 23px;
        cursor: pointer;
        &:hover {
          background-color: #fafafc;
        }
      }

      .search-with-components-result-item {
        display: flex;
        background-color: #f9f9fb;
        padding: 0 20px;
        cursor: pointer;
        .product-result-search-item-container {
          flex: 1;
          min-width: 0;
        }

        .product-result-search-item-container
          .product-result-search-item-wrapper {
          margin-top: 2px;
          margin-left: 8px;
        }

        &.disabled {
          background-color: #f9f9fb;
        }
      }
    }

    .search-with-components-results-add-wrapper {
      display: flex;
      justify-content: center;
      height: 71px;
      align-items: center;
      .search-with-components-results-add-button {
        height: $btn-height;
        width: $btn-width-short;
        background-color: $btn-color-bg-primary;
        font-family: $btn-font;
        font-size: $btn-font-size;
        color: #ffffff;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }
    }
  }
}

/////////@extend

@media (orientation: portrait) {
  .search-with-components-container {
    width: 100%;
    // width: 335px;
    position: relative;

    &.focused {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fafafc;

      border: none;
      z-index: 20;

      .search-with-components-input-wrapper {
        box-shadow: 0 2px 2px #0000003d;
      }
      .search-with-components-icon-mobile {
        display: block;
        font-size: 17px;
        color: #3c7af5;
        opacity: 0.54;
      }
    }

    .search-with-components-input-wrapper .search-with-components-input-form {
      width: 100%;
      display: flex;
      height: 48px;
      align-items: center;
      padding: 0 6px;
      box-sizing: border-box;
      background-color: white;
      margin-top: 0;

      .search-with-components-icon-mobile {
        display: block;
        font-size: 17px;
        color: #a9b1d1;
        opacity: 0.54;
      }

      .search-with-components-icon-mobile-focused {
        display: block;
        font-size: 16px;
        color: #3c7af5;
        opacity: 0.54;
      }
      .search-with-components-icon-desktop {
        display: none;
      }
      .search-with-components-input {
        flex: 1 1;
        border: none;
        height: 47px;
        box-sizing: border-box;
        font-family: Ubuntu-Light;
        color: #494f66;
        font-size: 16px;
        background: white;
        padding-left: 8px;
        &::placeholder {
          color: #a9b1d1;
          font-family: Ubuntu-Light;
          font-size: 16px;
        }

        &:focus {
          border: none;
          outline-width: 0;
        }
      }

      .divider {
        display: none;
      }
      .search-with-component-only-stock {
        display: none;
      }

      .search-with-components-close-wrapper {
        position: absolute;
        right: 10px;
        .search-with-components-icon-close {
          font-size: 13px;
          color: #494f66;
          cursor: pointer;
        }
      }
    }
    .search-with-components-results-desktop {
      display: none;
    }
    .search-with-components-results-mobile {
      display: block;
      list-style: none;
      border: none;
      padding: 0 15px;
      position: absolute;
      top: 27px;
      width: 100%;
      border-radius: 2px;
      box-sizing: border-box;
      max-height: unset;
      overflow: auto;
      padding-top: 0;
      margin: 0;
      top: 51px;
      bottom: 0px;
      background-color: #f9f9fb;
      z-index: 10;
      padding: 0;

      .search-with-component-only-stock {
        display: flex;
        background: white;
        padding: 0 15px;
        height: 42px;
        align-items: center;

        .search-with-component-checkboxl-label {
          color: #494f66;
          font-family: Ubuntu-Light;
          font-size: 14px;
        }
      }
      li {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 16px;
        margin-bottom: 0;
        height: 57px;
        border-bottom: 1px solid #858ba5;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #fafafc;
        }
      }

      .search-with-components-result-item {
        .search-with-components-result-item-inner {
          display: flex;
          padding: 12px 15px;
          .product-result-search-item-container {
            margin-left: 8px;
            width: 100%;
            .product-result-search-item-wrapper {
              margin-top: 2px;
            }
          }
        }

        .search-with-components-result-item-divider {
          height: 1px;
          width: 90%;
          background-color: #494f66;
          opacity: 0.1;
          margin: 0 5%;
        }
      }
    }

    .search-with-components-results-add-wrapper {
      display: flex;
      justify-content: center;
      height: 71px;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 6px #00000029;
      background-color: white;
      .search-with-components-results-add-button {
        height: $btn-height;
        width: $btn-width-short;
        background-color: $btn-color-bg-primary;
        font-family: $btn-font;
        font-size: $btn-font-size;
        color: #ffffff;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }
    }
  }
}
