@import "../../../../../assets//styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.hand{
  align-self: center !important;
  position: absolute;
  z-index: 100;
  display: flex;
  width: 39vw;
  padding-left: 11vw;
  margin-top: -75px
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
  }
}

.container-product-info {
  display: flex;
  height: 92vh;
}

.container-image{
  margin-top: 10px;
  width: 29vw; 
  height: 16vh;
  overflow-x: auto;
  white-space: nowrap;
}

.column-product-info {
  box-sizing: border-box;
  width: 31vw;
  height: 100%;
  max-height: 100%;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .images-related {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    div {
      margin: 5px;
      padding: 5px;
      border: 1px solid lightgray;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);
      max-width: 20%;

      h4 {
        margin-bottom: 5px;
      }
      img {
        max-width: 100% !important;
        object-fit: contain !important;
      }
    }
  }

  .image-large {
    img, video {
      width: 100%;
      height: 65vh;
      max-height: 65vh;
      object-fit: contain;
    }
  }

  .image-carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    overflow: visible;
    position: relative;

    img, video {
      max-height: 8vh;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
      transform-origin: center center;
      margin: 5px;
      padding: 5px;
      border: 1px solid lightgray;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);

      &:hover {
        transform: scale(1.5);
        z-index: 1;
        border-color: #3c7af5;
      }
    }
  }
  .price {
    font-size: 1.3rem;
    font-weight: bold;
    color: #3c7af5;
  }
}

.image-thumbnails {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 10vh;
  overflow: visible;
  position: relative;
  display: inline-block;

  img {
    height: 8vh;
    margin: 5px;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);
  }
}

.btnShare,
.btnCancel,
.btnSend {
  margin: 20px 10px 0px 20px;
  width: 250px;
}

.btnContainer {
  display: flex;
  justify-content: space-around;
}

@media  (orientation: portrait){

  .hand{
    align-self: center !important;
    position: absolute;
    z-index: 100;
    display: flex;
    width: 39vw;
    padding-left: 18vw;
    margin-top: -40px
  }

  .column-product-info {
    height: 60vh;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(181, 177, 177, 0.5);
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .image-large {
      img, video {
        width: 100%;
        max-height: 40vh;
        object-fit: contain;
      }
    }
  }

  .recomendation{
    width: 100vw !important;
  }

  
.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
    height: 100vh;
    width: 100vw;
    background: black;
  }
}

.container-image{
  margin-top: 36px;
  width: 45vw; 
  height: 16vh;
  overflow-x: auto;
  white-space: nowrap;
}


  
}
