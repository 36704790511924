@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.main-button-input-container {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 19px;
  height: 56px;
  border: 1px solid #858BA5;
  border-radius: 4px;
  box-sizing: border-box;

  .main-button-input-label-placeholder {
    color: #a9b1d1;
    font-family: Ubuntu-Light;
    font-size: 16px;
    margin-left: 17px;
    .main-button-input-required-label {
      color: #3c7af5;
    }
  }

  .main-button-input-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    width: 86%;
    flex: 1;
  }
  .main-input-label {
    font-family: Ubuntu-Light;
    font-size: 12px;
    color: #a9b1d1;
    margin-top: 10px;
    margin-left: 16px;

    text-align: left;
    .main-input-required-label {
      color: #3c7af5;
    }
  }

  .main-button-input-value {
    padding-left: 17px;
    font-family: Ubuntu-Medium;
    font-size: 16px;
    color: #494f66;
    flex: 1 1;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1;
    margin-top: 5px;
    flex: 1;
    text-align: left;
  }

  .icon {
    margin-right: 13px;
    font-size: 9px;
    color: #3c7af5;
  }
}
@media (orientation: portrait) {
}
