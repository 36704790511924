@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.add-provider-container {
  height: 100%;
  overflow: auto;
  .add-provider-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 70px 15px 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .add-provider-wrapper {
      width: 350px;
      .add-provider-step-wrapper {
        margin-top: 15px;
      }
    }
  }
}
@media (orientation: portrait) {

  .add-provider-container {
    .add-provider-content {
      overflow: auto;

      width: 100%;
      height: 100%;
      padding: 22px 22px;
      display: block;

      box-sizing: border-box;
      margin-bottom: 71px;
      .add-provider-wrapper {
        width: 100%;
        .add-provider-step-wrapper {
          margin-top: 15px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
