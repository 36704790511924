@import "../../../assets/styles/variables";

.localization-product-item {
  display: flex;
  height: 100px;
  img {
    height: 100% !important;
    object-fit: contain !important;
    margin-right: 100px;
  }

  .localization-product-data {
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .localization-product-sku {
      font-size: 15px;
      font-family: "Ubuntu-Light";
    }
  }
}

@media (max-width: $mobile-width) and (orientation: portrait),
  (max-width: $mobile-height) and (orientation: landscape) {
  .localization-product-item {
    height: auto;
    img {
      margin-right: 30px;
      max-height: 100px;
    }

    .localization-product-data {
      white-space: wrap;
    }
  }
}
