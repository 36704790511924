.dashboard-card-container {
  width: 100%;
  height: 100%;

  padding: 30px;
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}
