@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.tag-container {
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d4d5e8;
  height: auto !important;
  .left-container {
    display: flex;
    align-items: center;
    width: 100%
  }
  .tag {
    .tag-selector {
      margin-right: 20px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


  }
  .tag-number {
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;

    .icon-reprint{
      margin-left: 150px;
      cursor: pointer;
    }
  }
  .barcode {
    color: #a9b1d1;
    font-family: Ubuntu-Light;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
  }
}

.note_process_order{
  color: #494f66;
  font-size: 16px;
  font-family: Ubuntu-Light;
  font-style: italic;
  margin-bottom: 10px;
}



@media (orientation: portrait) {

  .tag-container {
    display: flex;
    align-items: center;

    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid #d4d5e8;
    height: 60px;
    .left-container {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .tag {
      .tag-selector {
        margin-right: 20px;
      }

      color: #494f66;
      font-family: Ubuntu-Medium;
      font-size: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: center;
    }
    .tag-rfid{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: center;

      .icon-reprint{
        cursor: pointer;
      }
    }

    .tag-number {
      flex-direction: row;
      align-items: center;
    }

  }
  .note_process_order{
    font-size: 9px;
  }

  .pmessage{
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
  }
}
