@import "../../../assets/styles/fonts";
@import "../../../assets/styles/variables";

.extra-menu-container{
    height: 100%;
    overflow: auto;
    .extra-menu-content{
        display: flex;
        justify-content: center;
        overflow: auto;

        .extra-menu-list{
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 38px;
            cursor: pointer;
            a{
                text-decoration: none;
            }

            .version-container{
              cursor: default;
              display: block;
              width: 100%;
              text-align: right;
              color: #4b83f0;
              user-select: none;
              margin: 10px 0;
            }
        }
    }
}

@media (orientation: portrait) {

    .extra-menu-container{
        height: 100%;
        overflow: auto;
        .extra-menu-content{
            height: 100%;
            overflow: auto;
            padding-bottom: 103px;

            .extra-menu-list{
                width: 85%;
                overflow: auto;
                height: 99%;
            }
        }
    }
}