@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.embalajes-container {
  height: 100%;

  .embalajes-table-wrapper {
    overflow: auto;
    .embalajes-table-inner {
      padding: 15px 50px;

      .filter-panel-container {
        .filter-panel-wrapper {
          // .filter-panels-button{
          //     display: none;
          // }
        }
      }
      .pagination-container {
        justify-content: flex-end;
        margin-top: 20px;
      }
    }

    .embalajes-table-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;
      .embalajes-table-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;
        .embalajes-table-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .embalajes-table-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.embalajes-table-active-filter {
          color: #3c7af5;
        }
        .embalajes-table-top-icon {
          font-size: 16px;
          margin-top: 4px;
          margin-left: 3px;
          &.embalajes-table-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .embalajes-table-mobile-panel {
    display: none;
    &.opened {
      display: block;
    }
  }

  .table-embalajes-no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 100px;

    i {
      font-size: 50px;
      color: #3c7af5;
      margin-bottom: 20px;
    }
    .table-embalajes-title {
      text-align: center;
      color: #a9b1d1;
      font-family: Ubuntu-Medium;
      font-size: 16px;
      letter-spacing: 0.67px;
      margin: 0;
    }

    .table-embalajes-subtitle {
      text-align: center;
      margin: 0;
      color: #a9b1d1;
      font-family: Ubuntu-Medium;
      font-size: 16px;
      letter-spacing: 0.67px;
    }

    .table-embalajes-button {
      height: $btn-height;
      width: $btn-width;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: $btn-border-radius;
      font-family: $btn-font;
      font-size: $btn-font-size;
      background-color: $btn-color-bg-primary;
      margin-top: 50px;
      cursor: pointer;
    }
  }

  .header-button {
    svg {
      path {
        fill: $btn-color-bg-primary;
      }
    }
  }
}

@media (orientation: portrait) {

  .embalajes-container {
    height: 100%;

    .embalajes-table-wrapper {
      overflow: auto;

      .embalajes-table-inner {
        padding: 0;
        height: 100%;

        .simple-search-panel{
          margin-bottom: 18px;
        }

        .filter-panel-container {
          .filter-panel-wrapper {
            .filter-panels-button {
              display: none;
            }
          }
        }
        .pagination-container {
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }

    .embalajes-table-table {
      max-height: calc(100% - 160px);
      overflow-x: hidden;
      overflow-y: scroll;

      padding: 15px;
      margin-bottom: 60px;
    }

    .header-button {
      svg {
        path {
          fill: #3c7af5;
        }
      }
    }
  }
}
