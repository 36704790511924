@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.product-details-container {
  height: 100%;
  .desktop-main-layout-container{
    .right-layout-container{
      width: calc(100% - 97px);
      .header-container{
        .right-container{
          .header-button{
            .main-button{
              &.enabled{
                background-color: $btn-color-bg-primary;
              }
            }
          }
        }
      }
    }
  }
  .product-details-content {
    display: flex;
    padding: 28px 32px;
    margin-bottom: 0;
    overflow: auto;

    .product-details-col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .product-details-col-description {
      flex-basis: 590px;
      flex: 1;
      margin-right: 31px;
      .product-details-description{
        width: 100%;
        box-sizing: border-box;
      }
    }

    .product-details-col-image-stock {
      width: 510px;
    }
  }
  .product-details-button{
    display: none;
  }
}

@media (orientation: portrait) {

  .product-details-container {

    .desktop-main-layout-container{
      .right-layout-container{
        width: 100%;
        .header-container{
          .right-container{
            .header-button{
              .main-button{
                &.enabled{
                  background-color: $btn-color-bg-primary;
                }
              }
            }
          }
        }
      }
    }

    .disable-product-container{
      margin: 12px 0;
    }
    .product-details-content {
      flex-direction: column;
      overflow: auto;
      align-items: center;
      // margin-bottom: 70px;

      .product-details-col {
        width: 330px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .product-details-description {
          width: 100%;
        }
        &.product-details-col-image-stock {
          margin-left: -3%;
        }
      }
      .product-details-col-description {
        order: 2;
      }

      .product-details-col-image-stock {
        order: 1;
      }
    }

    .product-details-button {
      // width: $btn-width;
      margin-top: 17px;
      position: absolute;
      bottom: 0;
      height: 71px;
      background: white;
      width: 100%;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $btn-font;
      font-size: $btn-font-size;

      .product-details-button-inner {
        width: $btn-width-short;
      }
    }
  }
}
