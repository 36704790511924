

@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/fonts';

.prep-order-table-item-container{
    display: flex;
    height: 72px;
    border-radius: 12px;
    box-shadow: 0 1px 5px #0022AB1A;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 13px;
    box-sizing: border-box;
    background: white;
    margin-bottom: 15px;

    .table-item-klass{
        font-size: 19px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        flex-basis: 193px;
        flex-grow: 1;
        margin-left: 15px;
        overflow-x: hidden;
        overflow-y: visible;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }

        div{
            overflow: hidden;
        }
        .main-app-tooltip{
            position: unset;
            overflow: hidden;

            .tooltip-text{
                bottom: 3px;
            }
        }


    }

    .table-item-code{
        font-size: 19px;
        font-family: Ubuntu-Light;
        color: #494F66;
        flex-basis: 320px;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: visible;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }

        div{
            overflow: hidden;
        }
        .main-app-tooltip{
            position: unset;
            overflow: hidden;

            .tooltip-text{
                bottom: 3px;
            }
        }


    }
    .table-item-date{
        font-size: 18px;
        font-family: Ubuntu-Light;
        color: #494F66;
        flex-basis: 153px;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;

        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;



        div{
            overflow: hidden;
        }
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }

    }
    .table-item-owner{
        font-size: 18px;
        font-family: Ubuntu-Light;
        color: #494F66;
        flex-basis: 226px;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;

        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;


        div{
            overflow: hidden;
        }
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }

    }
    .table-item-state{
        flex-basis: 125px;
        flex-grow: 1;
        display: flex;

    }
    .table-item-button{
        color: #3C7AF5;
        font-size: 16px;
        margin-right: 10px;
        margin-left: 8px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;

    }
}