@import 'assets/styles/variables';
@import 'assets/styles/fonts';
@import 'assets/fontello/css/fontello.css';

.ArcActivityView__content {
  position: relative;
  height: calc(100dvh - 140px);
  overflow: hidden;
  max-width: none !important;
  padding: 40px 0 0 !important;
  @media (orientation: portrait) {
    padding: 25px 0 0 !important;
    height: calc(100% - 56px);
  }

  .header {
    display: inline;
    width: 100%;
    box-sizing: border-box;
    max-width: 1280px;
    margin: auto;
    padding: 0 24px;
    @media (orientation: portrait) {
      padding: 0 15px;
    }
    > div {
      max-width: 1280px;
      margin: auto;
    }
  }
}

.ArcActivityView__tags-reads {
  padding: 5px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0.49px 0.49px 1.96px 0px #5F638821;

  > article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    background: #F5F8FE;
    
    > div {
      display: flex;
      align-items: center;
      gap: 8px;

      picture {
        width: 27px;
        height: 27px;
        background-color: #FFFFFF;
        display: grid;
        place-content: center;
        border-radius: 50%;
        
        img {
          width: fit-content;
          height: fit-content;
        }
      }

      h4 {
        margin: 0;
        font-family: Ubuntu;
        font-size: 14px;
        color: $btn-text-color;
      }
    }
      
    > span {
      margin: 0;
      color: $green;
      font-family: Ubuntu-Medium;
      font-size: 38px;
      margin-right: 36px;
    }
  }
}

.ArcActivityView__nav-tab {
  margin: 30px 0 20px;
  width: 100%;
  display: flex;
  gap: 20px;

  > button {
    width: 100%;
    height: 40px;
    font-family: Ubuntu-Medium;
    font-size: 16px;
    background: transparent;
    color: #B6B9C2;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
    transition: 0.1s;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    > span {
      width: 21px;
      height: 21px;
      color: #B6B9C2;
      border: 2px solid #B6B9C2;
      background-color: #EDEDF0;
      border-radius: 4px;
      display: grid;
      place-items: center;
    }
  }

  .active {
    color: $btn-text-color;
    border-bottom: 4px solid $btn-text-color;

    > span {
      color: $green;
      border: 2px solid $green;
      background: transparent;
    }
  }
}

.ArcActivityView__list {
  width: 100%;
  background: transparent;
  overflow-y: auto;
  box-sizing: border-box;
  height: calc(100dvh - 400px);
  @media (orientation: portrait) {
    height: calc(100dvh - 420px);
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a6a6a6;
    border-radius: 10px;
  }
}

.grid-item-view-arc {
  max-width: 1280px;
  margin: 10px auto 0 !important;
  padding: 0;
  overflow: hidden;
  height: calc(100dvh - 230px);
  padding: 0 24px;
  @media (orientation: portrait) {
    padding: 0 15px;
    overflow-y: auto;
    height: calc(100dvh - 260px);
  }

  &__confirmation-view {
    height: 100%;
    overflow-y: auto;
    @media (orientation: portrait) {
      height: calc(100dvh - 168px);
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #a6a6a6;
      border-radius: 10px;
    }
  }
}

.grid-column-arc {
  font-family: Ubuntu-Medium;
  font-size: 18px;
  color: #2f2e3c;
  border-radius: 10px;
  justify-content: center;
}

.ArcActivityView__confirmation-view {
  &:last-of-type {
    padding-bottom: 30px;
  }
  
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h4 {
      font-family: Ubuntu;
      font-size: 14px;
      color: $btn-text-color;
    }

    > span {
      width: 21px;
      height: 21px;
      color: $green;
      border: 2px solid $green;
      background: transparent;
      border-radius: 4px;
      display: grid;
      place-items: center;
    }
  }
}

// .count-view {
//   font-family: Ubuntu-Medium;
//   color: #2f2e3c;
//   font-size: 20px !important;
//   margin: 10px !important;
//   left: 40vw !important;
//   position: absolute;
// }

// .update {
//   margin-left: 50px !important;
// }

// .div-header-reads{
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
// }

// .count-tags {
//   color: #62d862;
//   font-size: 20px !important;
// }

// .icon-delete {
//   position: fixed;
// }

// .bottom-grid {
//   height: 50px;
//   bottom: 0;
// }