@import '../../../assets/styles/fonts';

.card{
  width: 530px;
  height: 110px;
  margin: 5px;
}

.name{
  margin-top: -85px !important;
  color: #494f66 !important;
  font-family: Ubuntu-Medium !important;
  font-size: 17px !important;
}

.sku{
  margin-top: 10px !important;
  color: #494f66 !important;
  font-family: Ubuntu-Light !important;
  font-size: 17px !important;
}

.price{
  color: #494f66 !important;
  font-family: Ubuntu-Light !important;
  font-size: 17px !important;
}

.alert-icon{
  margin-left: auto;
  height: 35px;
  margin-top: -20px !important;
}

.image-est1{
  width: 80px !important;
  height: 80px !important;
  object-fit: contain !important;
}