@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';

.generic-modal-container{
    height: 100%;

    .wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .overlay{
        background-color: #00000061;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .modal-container{
        background-color: #FFFFFF;
        opacity: 1;
        width: 280px;
        min-height: 256px;
        z-index: 3;
        padding: 25px;
        box-sizing: border-box;
        display: flex;
        box-shadow: 0px 24px 24px #0000004D;
        flex-direction: column;
        align-items: center;
        .title{
            text-align: center;
            font-size: 20px;
            font-family: Roboto-Medium;
            display: block;
            margin-top: 0;
            margin-bottom: 33px;
            color: #3D3935;
        }
    }
}