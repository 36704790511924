@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.extra-menu-item-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 21px 0 36px;
    height: 72px;

    box-shadow: 0px 1px 5px #0022AB1A;
    background-color: white;
    border-radius: 10px;
    .extra-menu-item-title{
        font-size: 19px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        font-weight: normal;
        margin: 0;
        padding: 0;
    }
}