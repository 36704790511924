@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.auth-generic-container{
    width: 100%;
    // height: 100vh;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    .auth-mobile-container{
        display: none;
    }

}


@media (orientation: portrait) {
    .auth-generic-container{
        width: 100%;
        // height: 100vh;
        height: 100%;
        min-height: 100%;
        max-height: 100%;

        .auth-mobile-container{
            display: initial;
        }

        .auth-desktop-container{
            display: none;
        }

    }
}