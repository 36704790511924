@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/fontello/css/fontello.css';


.content {
  margin-top: 40px;
  max-width: 90vw !important;
  width: 90vw !important;
}

.grid-item-view {
  margin-top: 5px;
  height: 58vh;
  overflow-y: auto;
  padding: 10px;
}

.grid-column {
  font-family: Ubuntu-Medium;
  font-size: 15px;
  color: #2f2e3c;
  background-color: white;
  border-radius: 10px;
  padding: 10px !important;
  margin-right: 15px !important;
  width: 27vw;
  justify-content: center;
}

.alarm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -71px;

  .alarm-content {
    font-family: Ubuntu-Medium;
    font-size: 20px;
    color: #2f2e3c;
    background-color: white;
    justify-content: center;
    width: 200px !important;
    height: 25px;
    border-radius: 10px;
    padding: 25px !important;
    text-align: center;
    border: 4px solid white;
    transition: all .5s ease-in-out !important;
    -webkit-transition: all .5s ease-in-out !important;
    /** Chrome & Safari **/
    -moz-transition: all .5s ease-in-out !important;
    /** Firefox **/
    -o-transition: all .5s ease-in-out !important;
    /** Opera **/

    animation: infiniteScale 2s infinite;
  }
}


@keyframes infiniteScale {
  0% {
    border-color: white;
  }

  25% {
    border-color: red;
  }

  50% {
    border-color: white;
  }

  75% {
    border-color: red;
  }

  100% {
    border-color: white;
  }
}

.header {
  display: inline;
  width: 100%;
}

.count-view {
  font-family: Ubuntu-Medium;
  color: #2f2e3c;
  font-size: 20px !important;
  margin: 10px !important;
  left: 40vw !important;
  position: absolute;
}

.update {
  margin-left: 50px !important;

}

.queue {

  margin-left: 20px !important;
}