@import "../../../../../../assets/styles/fonts";
@import "../../../../../../assets/styles/variables";

.business-table-item-wrapper {
  box-shadow: 0 1px 5px #0022ab1a;
  border-radius: 12px;
  width: 100%;
  background: white;

  .business-table-item-container {
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 32px;
    box-sizing: border-box;

    .business-table-item {
      font-size: 18px;
      font-family: Ubuntu-Light;
      color: #494f66;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      height: 100%;
      box-sizing: border-box;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
      }
    }

    .business-table-item-image {
      font-family: Ubuntu-Medium;
      font-size: 20px;

      overflow: hidden;
      flex-basis: 86px;
      flex-grow: 1;
      display: flex;
      align-items: center;


      p {
        display: flex;
        align-items: center;
      }

      img {
        height: auto;
        width: 100%;
        max-height: 60px;
        vertical-align: middle;
      }
    }

    .business-table-item-name {
      font-size: 19px;
      flex-basis: 259px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;

      font-family: Ubuntu-Medium;
      color: #494f66;
      div {
        display: flex;
        width: 100%;
      }
    }

    .business-table-item-document {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 195px;
      flex-grow: 1;
      margin-right: 40px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;


      box-sizing: border-box;

      div {
        display: flex;
        width: 100%;
      }
      p {
        margin: 0;
      }
    }
    .business-table-item-sector {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 171px;
      flex-grow: 1;
      margin-right: 40px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;

      div {
        display: flex;
        width: 100%;
      }
      p {
        margin: 0;
      }
    }

    .business-table-item-country {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 106px;
      flex-grow: 1;
      margin-right: 40px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;

      div {
        display: flex;
        width: 100%;
      }
      p {
        margin: 0;
      }
    }

    .business-table-item-email {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 259px;
      flex-grow: 1;
      margin-right: 40px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;

      div {
        display: flex;
        width: 100%;
      }
      p {
        margin: 0;
      }
    }

    .business-table-item-phone {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 174px;
      flex-grow: 1;
      margin-right: 40px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;

      div {
        display: flex;
        width: 100%;
      }
      p {
        margin: 0;
      }
    }

    .business-table-item-button {
      color: #3c7af5;
      font-size: 18px;
      margin-right: 18px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 10px;

      i {
        transform: rotate(90deg);
      }
    }
  }

  .business-table-item-container-mobile {
    padding: 14px;
    display: flex;
    align-items: center;
    background-color: white;
    .business-table-item-column-mobile {
      display: flex;
      flex-direction: column;

      .business-logo {
        margin-right: 5px;
        img {
          width: 76px;
        }
      }
    }
    .business-table-item-row-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .header {
        font-size: 15px;
        font-family: Ubuntu-Medium;
        color: #2f2e3c;
        letter-spacing: 0.3px;
        margin-bottom: 8px;

        span {
          font-size: 12px;
          font-family: Ubuntu;
          letter-spacing: 0.24px;
        }
      }

      .body {
        font-size: 12px;
        font-family: Ubuntu;
        color: #2f2e3c;
        letter-spacing: 0.24px;
        margin-bottom: 5px;

        span {
          font-family: Ubuntu-Light;
        }
      }
      p {
        margin: 0;
      }
    }
  }
}
@media (orientation: portrait) {

  .business-table-item-wrapper {
    box-shadow: 0px 3px 6px #0022ab1a;
    border-radius: 10px;
    background: transparent;
  }
}
