@import '../../../assets/styles/fonts';

.card{
  width: 350px;
  height: 300px;
  margin: 20px;
}


.card-small{
  width: 200px;
  height: 200px;
  margin: 10px;
}