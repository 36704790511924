@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.table-item-mobile-container{
    display: flex;
    flex-direction: row;
    // width: 330px;
    height: 96px;
    box-shadow: 0 1px 15px #0022AB1A;
    box-sizing: border-box;
    padding: 18px 15px 18px 11px;
    border-radius: 10px;
    // margin-bottom: 8px;
    background-color: white;

    .table-item-mobile-col-1{
        display: flex;
        justify-content: center;
        .table-item-mobile-img img{
            width: 59px;
            height: 59px;
            object-fit: contain;
        }
    }
    .table-item-mobile-col-2{
        display: flex;
        flex-direction: column;
        width: 177px;
        padding-left: 9px;
        box-sizing: border-box;
        flex: 1;
        .table-item-mobile-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            .table-item-mobile-name{
                font-family: Ubuntu-Medium;
                font-size: 15px;
                color: #2F2E3C;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .table-item-mobile-header-separator{
                height: 14.4px;
                width: 0.5px;
                background: #494F66;
                margin: 0 8px;
            }
            .table-item-mobile-brand{
                font-family: Ubuntu-Light;
                font-size: 15px;
                color: #2F2E3C;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .table-item-mobile-code{
            font-family: Ubuntu-Light;
            font-size: 12px;
            color: #2F2E3C;
            margin-top: 9px;
        }
        .table-item-mobile-stock{
            font-family: Ubuntu-Light;
            font-size: 12px;
            color: #2F2E3C;
            margin-top: 6px;
            .table-item-mobile-stock-title{
                font-family: Ubuntu-Medium;
                font-size: 12px;
                color: #62D862;
            }

        }
    }
    .table-item-mobile-col-3{
        width: 58px;
        .table-item-mobile-category{
            width: 58px;
            display: flex;
            text-align: right;
            margin-top: 41px;

            .table-item-mobile-category-inner{
                display: inline-block;
                width: 58px;
                color: white;
                background-color: #3C7AF5;
                padding: 4px 10px;
                font-family: Ubuntu-Medium;
                font-size: 10px;
                text-transform: capitalize;
                border-radius: 15px;
                text-align: right;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }
}
