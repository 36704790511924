@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.businessnode-detail-container{
    height: 100%;
    overflow: auto;
    .businessnode-detail-content-wrapper{
        padding: 15px;
        overflow: auto;

        .location-panel{
            margin-bottom: 16px;
        }
    }
}
@media (orientation: portrait) {

}

.icon-code::before {
  content: "<>";
  font-size: 24px;
  font-family: Ubuntu-Bold;
}