@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/fonts';

.item-summary-container{
    .item-summary-name{
        color: #494F66;
        font-family: Ubuntu-Medium;
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 7px;
    }
    .item-summary-brand{
        color: #676F8F;
        font-size: 16px;
        font-family: Ubuntu-Light;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 7px;

    }
    .item-summary-code{
        color: #676F8F;
        font-size: 16px;
        font-family: Ubuntu-Light;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 9px;
        text-overflow: ellipsis;
    }
    .item-summary-units{
        color: #62D862;
        font-size: 14px;
        font-family: Ubuntu-Medium;
        margin-bottom: 7px;
        .disabled{
            color: #F55D68;
        }

    }
    .item-summary-divider{
        width: 100%;
        height: 0.5px;
        background-color: #D4D5E8;
        margin-bottom: 20px;
    }

    &:last-child{
        .item-summary-divider{
            display: none;
        }
    }
}

@media (orientation: portrait) {

    .item-summary-container{
        min-width: 0;
        .item-summary-name{
            color: #494F66;
            font-family: Ubuntu-Medium;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .item-summary-brand{
            color: #676F8F;
            font-size: 10px;
            font-family: Ubuntu-Light;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .item-summary-code{
            color: #676F8F;
            font-size: 10px;
            font-family: Ubuntu-Light;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .item-summary-units{
            color: #62D862;
            font-size: 10px;
            font-family: Ubuntu-Medium;
            margin-bottom: 20px;

        }
        .item-summary-divider{
            width: 100%;
            height: 2px;
            color: #D4D5E8;

        }
    }
}