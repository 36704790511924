.autocomplete-container {
  position: relative;
}

.autocomplete-error {
  color: #FC4E55;
  font-size: 12px;
  font-family: Ubuntu-Medium;
  margin-left: 5px;
  margin-top: 7px;
}