@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.edit-account-form-container{
    background-color: white;
    padding: 24px;
    border-radius: 6px;
    .edit-account-form-content{
        .edit-account-form-field{
            margin-bottom: 13px;
            .main-input-container .main-input-wrapper{
                width: 100%;
            }
        }
    }
}

@media (orientation: portrait) {

}