@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/variables";


.generic-table-container {

  .generic-table-total{
      color: #494F66;
      font-size: 16px;
      font-family: Ubuntu-Light;
      padding-left: 32px;
      text-transform: uppercase;
      margin-bottom: 30px;
      span{
        font-family: Ubuntu-Medium;
      }
  }
  .generic-table-header-wrapper {
    display: flex;
    padding-left: 32px;

    margin-bottom: 25px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    .generic-table-header {
      font-family: Ubuntu-Light;
      color: #a9b1d1;
      font-size: 15px;
      display: flex;
      align-items: flex-start;


      flex-basis: 150px;
      flex-grow: 1;
      margin-right: 15px;


      &.pill{
        justify-content: center;
      }

      &:first-child{
        flex-basis: 80px;
      }
    }
    .generic-table-header-button {
      margin-right: 18px;
      width: 22px;
    }
  }

  .generic-table-content-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .generic-table-no-items {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: #494f66;
      font-size: 30px;
      font-family: Ubuntu-Medium;
      font-weight: 400;
    }
  }
}

@media (orientation: portrait) {

  .generic-table-container {
    .generic-table-total{
        color: #494F66;
        font-size: 16px;
        font-family: Ubuntu-Light;
        padding-left: 4px;
        text-transform: uppercase;
        margin-bottom: 20px;
        span{
          font-family: Ubuntu-Light;
        }
    }
  }
}
