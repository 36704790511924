
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';
@import '../../../../../assets/fontello/css/fontello.css';

.order-state-pill-container{
    width: 100%;
}
.order-state-pill-inner{
    border-radius: 14px;
    color: white;
    font-size: 12px;
    font-family: Ubuntu-Medium;
    padding: 2px 9px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 0.72px;
    text-align: center;


    p{
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        width: 100%;
        min-width: 0;
        background-color: #3C7AF5;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 7px;
    }

    &.big{
        p{
            height: 26px;
            font-size: 16px;
            letter-spacing: 0.96px;
        }
    }

    &.pen{
        p{
            background-color: #FFAF3A;
        }
    }

    &.pro{
        p{
            background-color: #6F7DFF;
        }
    }

    &.disp{
        p{
            background-color: #00CC00;
        }
    }

    &.rej{
        p{
            background-color: #F55D68;
        }
    }

    &.sup{
        p{
            background-color: #FFAF3A;
        }
    }
}


@media (orientation: portrait) {
    .order-state-pill-container{
        width: 100%;
    }
    .order-state-pill-inner{
        border-radius: 14px;
        color: white;
        font-size: 12px;
        font-family: Ubuntu-Medium;
        padding: 2px 9px;
        display: inline;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        letter-spacing: 0.72px;
        text-align: center;

        padding: 0;
        p{
            overflow: hidden;
            text-overflow: ellipsis;
            color: white;
            width: 100%;
            min-width: 0;
            background-color: #3C7AF5;
            border-radius: 14px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;

            margin: 0;
            width: 95px;

            height: 16px;
            font-size: 10px;

        }

        &.big{
            p{
                height: 16px;
            font-size: 10px;
            }
        }
    }

}