@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.table-item-container{
    display: flex;
    height: 72px;
    border-radius: 12px;
    box-shadow: 0 1px 5px #0022AB1A;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 13px;
    box-sizing: border-box;
    background: white;

    .table-item-img{
        width: 53px;
        height: 53px;
        margin-left: 14px;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        // border: 1px solid lightcoral;

        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .table-item-name{
        font-size: 19px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        flex-basis: 269px;
        flex-grow: 1;
        margin-left: 31px;
        overflow-x: hidden;
        overflow-y: visible;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }

        div{
            overflow: hidden;
        }
        .main-app-tooltip{
            position: unset;
            overflow: hidden;

            .tooltip-text{
                bottom: 3px;
            }
        }

    }
    .table-item-brand{
        font-size: 18px;
        font-family: Ubuntu-Light;
        color: #494F66;
        flex-basis: 185px;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;

        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }

        div{
            overflow: hidden;
        }
    }
    .table-item-code{
        font-size: 18px;
        font-family: Ubuntu-Light;
        color: #494F66;
        flex-basis: 238px;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;

        // border: 1px solid lightcoral;
        padding-right: 14px;
        box-sizing: border-box;

        div{
            overflow: hidden;
        }
        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }
    }
    .table-item-category{
        width: 325px;
        text-align: center;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid lightcoral;

        p{
            overflow: hidden;
            text-overflow: ellipsis;
            color: white;
            width: 100%;
            min-width: 0;
            background-color: #3C7AF5;
            border-radius: 14px;
            color: white;
            display: flex;
            align-items: center;
            margin: 0;
        }
        .table-item-category-inner{
            background-color: #3C7AF5;
            border-radius: 14px;
            color: white;
            font-size: 12px;
            font-family: Ubuntu-Medium;
            padding: 2px 9px;
            display: inline;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            letter-spacing: 0.72px;
        }

        div{
            display: flex !important;
        }

    }
    .table-item-stock{
        font-size: 19px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        flex-basis: 61px;
        flex-grow: 1;
        text-align: right;
        margin-right: 30px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // border: 1px solid lightcoral;


    }
    .table-item-button{
        color: #3C7AF5;
        font-size: 16px;
        margin-right: 10px;
        margin-left: 8px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;

    }
}
