@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.main-dropdown-container {
  width: 100%;
  .main-dropdown-input {
    border: 1px solid #858ba5;
    border-radius: 6px;
    height: 56px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    &.focused {
      border-bottom: none;
      border-radius: 6px 6px 0 0;

      .main-dropdown-placeholder {
        color: #3C7AF5;
      }
    }

    .main-dropdown-label {
    padding: 8px 17px 0 17px;

      color: #676f8f;
      font-family: Ubuntu-Light;
      font-size: 12px;
      margin-bottom: 0;
      text-align: left;
      .main-dropdown-label-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
      }
    }

    .main-dropdown-placeholder {
    padding: 17px 17px 0 17px;

    text-align: left;

      color: #494f66;
      font-family: Ubuntu-Light;
      font-size: 16px;
    }

    .main-dropdown-selection {
      flex: 1;
      display: flex;
    padding: 5px 17px 0 17px;
    color: #494F66;
    font-family: Ubuntu-Medium;

    }
    .main-dropdown-chevron {
      font-size: 8px;
      transform: rotate(180deg);
      color: #3c7af5;
      position: absolute;
      top: 22px;
      right: 13px;

      &.open {
        transform: unset;
        border-bottom: none;
      }
    }
  }
    .main-dropdown-option-container {
      margin-top: -7px;
      border-left: 1px solid #858ba5;
      border-right:  1px solid #858ba5;
      border-bottom:  1px solid #858ba5;
      border-radius: 0 0 6px 6px;
      padding: 10px 18px 13px 0;
      background-color: white;
      left: 0;
      right: 0;

      .main-dropdown-option-divider{
        height: 1px;
        margin: 0 16px;
        overflow: hidden;

        // margin-left: 2.5%;
        background-color: #EDEFF5;
      }

      .main-dropdown-option-wrapper {
        max-height: 320px;

        overflow: auto;
  
        /* Track */
        &::-webkit-scrollbar-track {
          background: #edeff5;
        }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #62d862;
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 10px;
        }
        .main-dropdown-option {
            font-family: Ubuntu-Light;
        color: #494f66;
        font-size: 14px;
        letter-spacing: 0.11px;
        height: 56px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: white;
        &:hover {
          background-color: #fafafc;
        
        }
      }
    }

    
  }
  &.disabled{
    background-color: #F7F7FA;
    .main-dropdown-input {
    border: none;

      .main-dropdown-placeholder{
        color: #D4D5E8;
      }
    }
  }
 
}

.main-input-error{
  color: #FC4E55;
  font-size: 12px;
  font-family: Ubuntu-Medium;
  margin-left: 6px;
  margin-top: 7px;
}