@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/fonts";

@import "../../../../../../../assets/fontello/css/fontello.css";


.relocation-order-step-two-container{
    width: 760px;

    .step-container{
        width: 100% !important;
        .step-title-wrapper .step-title{
            font-size: 16px;
        }
    }

    .product-added-list-wrapper{
        margin-top: 16px;
    }
}


@media (orientation: portrait) {

    .relocation-order-step-two-container{
        width: 330px;
        margin-bottom: 100px;
        .step-container{
            width: 100% !important;
            .step-title-wrapper .step-title{
                font-size: 14px;
            }

        }
    }
}