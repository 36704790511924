@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";
@import "../../../../assets/fontello/css/fontello.css";

.grid-wrapper {
  overflow-y: visible;
  max-height: none;
}

.grid-container {
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0 1px 5px #0022ab1a;
  background: #fff;
  padding: 16px 22px;
  height: auto !important;
  min-height: auto;
  max-height: none;
  margin-inline: 0px;
}

.container-localization-products {
  overflow: visible;
  
  max-width: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .localization-filter-panel-container {
    margin-top: 0px;
  }
}

.load-locprod-container {
  height: 100%;
  overflow: auto;

  .table-locprod-no-locprod-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;

    .table-locprod-no-locprod-icon {
      font-size: 50px;
      color: #3c7af5;
      margin-bottom: 20px;
    }

    .table-locprod-no-locprod-title {
      font-family: Ubuntu-Medium;
      font-size: 30px;
      color: #494f66;
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
      text-align: center;
    }

    .table-locprod-no-locprod-subtitle {
      font-size: 22px;
      font-family: Ubuntu-Light;
      color: #676f8f;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  .table-load-locprod-content {
    flex: 1;
    overflow: auto;
    background-color: #fafafc;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-load-locprod-title {
      color: #858ba5;
      font-family: Ubuntu-Medium;
      font-size: 22px;
      width: 380px;
      text-align: center;
      letter-spacing: 0.92px;
      margin-top: 130px;
      margin-bottom: 48px;
    }
  }

  .table-locprod-filter-container {
    .table-locprod-filter-wrapper {
      width: 100%;
      box-shadow: 0 2px 10px #0022ab1a;
      border-radius: 10px;
      background: white;
      padding: 25px 32px;
      box-sizing: border-box;
      display: flex;

      .table-locprod-filters-button {
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #3c7af5;
        margin-left: 30px;
        background: none;
        border: none;
        display: flex;
        align-items: center;

        .table-locprod-filter-icon {
          color: #3c7af5;
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }

    margin-top: 15px;
    margin-bottom: 31px;
  }

  .table-locprod-table {
    .table-locprod-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;

      .table-locprod-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;

        .table-locprod-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .table-locprod-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.table-locprod-active-filter {
          color: #3c7af5;
        }

        .table-locprod-top-icon {
          font-size: 11px;
          margin-top: 4px;
          margin-left: 3px;

          &.table-locprod-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .report-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .report-button {
    width: 100px;
    margin-top: 30px;
  }

  .pagination-container {
    margin-top: 26px;
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-end;
  }
}

.outter-wrapper {
  padding: 15px 58px;
  overflow-y: scroll;
}

@media (orientation: portrait) {

  .grid-wrapper {
    overflow-y: scroll;
    max-height: calc(100% - 52px);
  }

  .outter-wrapper {
    padding: 27px 27px 27px 27px !important;
    overflow-y: scroll;
  }

  .table-locprod-wrapper {
    flex: 1;
    overflow: auto;
    padding: 0 0;
    margin-bottom: 60px;
    padding-bottom: 20px;

    .table-load-locprod-content {
      .table-load-locprod-title {
        color: #858ba5;
        font-family: Ubuntu-Medium;
        font-size: 16px !important;
        width: 280px;
        text-align: center;
        letter-spacing: 0.67px;
        margin-top: 56px;
        margin-bottom: 43px;
        text-align: center;
      }
    }

    .table-locprod-filter-container {
      margin-top: 0;
      margin-bottom: 12px;

      .table-locprod-filter-wrapper {
        width: 100%;
        box-shadow: 0 2px 10px #0022ab1a;
        border-radius: 10px;
        background: white;
        padding: 0 32px;
        box-sizing: border-box;
        display: flex;

        .table-locprod-filters-button {
          font-size: 20px;
          font-family: Ubuntu-Medium;
          color: #3c7af5;
          margin-left: 30px;
          background: none;
          border: none;
          display: flex;
          align-items: center;

          .table-locprod-filter-icon {
            color: #3c7af5;
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }
    }

    .table-locprod-mobile-panel {
      display: none;

      &.opened {
        display: block;
      }
    }

    .table-locprod-table {
      padding: 0 16px;

      .table-locprod-top-table {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        padding: 0 0;
        align-items: center;

        .table-locprod-top-total {
          font-family: Ubuntu-Light;
          font-size: 16px;
          color: #494f66;
          text-transform: none;

          .table-locprod-top-total-bold {
            font-family: Ubuntu-Medium;
          }
        }

        .table-locprod-top-sort {
          color: grey;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          display: flex;
          align-items: center;

          &.table-locprod-active-filter {
            color: #3c7af5;
          }

          .table-locprod-top-icon {
            font-size: 16px;
          }
        }
      }
    }

    .table-locprod-no-locprod-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      text-align: center;

      .table-locprod-no-locprod-icon {
        font-size: 33px;
        color: #3c7af5;
        margin-bottom: 20px;
      }

      .table-locprod-no-locprod-title {
        font-family: Ubuntu-Medium;
        font-size: 16px;
        color: #494f66;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        width: 250px;
        text-align: center;
      }
    }

    .report-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .report-button {
      width: 100px;
      margin: 10px 10px;
    }
  }

  .load-locprod-container {
    .table-locprod-no-locprod-container {
      .table-locprod-no-locprod-icon {
        font-size: 33px;
        color: #3c7af5;
        margin-bottom: 20px;
      }

      .table-locprod-no-locprod-title {
        font-family: Ubuntu-Medium;
        font-size: 16px;
        color: #494f66;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        width: 250px;
        text-align: center;
      }

      .table-locprod-no-locprod-subtitle {
        display: none;
      }
    }
  }
}
