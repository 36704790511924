@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/fonts";

@import "../../../../../../../assets/fontello/css/fontello.css";


.request-order-step-three-container{
    width: 520px;
    .step-container{
        width: 100% !important;
       .step-title-wrapper .step-title{
          font-size: 16px;
      }

    }
}
.request-order-step-three-content {

   .step-title-wrapper .step-title{
        font-size: 16px;
    }
  .request-order-step-title {
    display: flex;
    justify-content: space-between;
    .request-order-step-title-wrapper {
      font-family: Ubuntu-Light;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #676f8f;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .request-order-step-three-icon-wrapper {
      cursor: pointer;
    }
  }

  .request-order-step-dest {
    font-family: Ubuntu-Medium;
    font-size: 18px;
    color: #494F66;

    margin-bottom: 41px;
  }
}

@media (orientation: portrait) {

    .request-order-step-three-container{
        width: 330px;

        .step-container{
            width: 100% !important;
           .step-title-wrapper .step-title{
              font-size: 14px;
          }

        }
    }
  .request-order-step-three-content {
    .request-order-step-title {
      display: flex;
      justify-content: space-between;
      .request-order-step-title-wrapper {
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #676f8f;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      .request-order-step-edit-icon {
      }
    }

    .request-order-step-dest {
      font-family: Ubuntu-Medium;
      font-size: 12px;
      color: #494F66;

      margin-bottom: 12px;
    }
  }
}
