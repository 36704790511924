@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.form-edit-image-container {
  width: 510px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 22px 42px 31px 42px;
  box-sizing: border-box;
  position: relative;
  &.disabled{
    opacity: 0.5;
  }
  .form-edit-image-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .form-edit-image-title {
    color: #676f8f;
    font-size: 20px;
    font-family: Ubuntu-Medium;
    letter-spacing: 0.4px;
    margin-bottom: 23px;
  }
}

@media (orientation: portrait) {

  .form-edit-image-container {
    width: 100%;
    background-color: #ffffff;
    padding: 14px 0 14px 0;
    box-sizing: border-box;

    .form-edit-image-title {
      display: none;
    }
  }
}
