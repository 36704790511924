@import "../../../../../../assets/styles/fonts";
@import "../../../../../../assets/styles/variables";

.load-movement-items-outter {
  overflow: auto;
  height: 100%;
}
.load-movement-items-wrapper {
  margin: 40px 73px;
  .reception-order-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #676f8f;
    font-family: Ubuntu-Light;
    font-size: 14px;
    margin-top: 30px;
    padding-left: 31px;
    p {
      margin: 0;
      margin-right: 10px;
    }
    span {
      font-family: Ubuntu-Medium;
    }
  }
  .load-movement-items-title-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .load-movement-items-title-icon {
      transform: rotate(270deg);
    }
    .load-movement-items-title {
      margin: 0;
      font-size: 18px;
      font-family: Ubuntu-Medium;
      font-weight: normal;
      margin-left: 13px;
      color: #676f8f;
    }
  }

  .device-selector-wrapper{
    margin-top: 20px;
    background-color: white;
    padding: 31px 43px;

  }

  .load-movement-items-content {
    padding: 31px 43px;
    background: white;
    margin-top: 20px;
    border-radius: 10px;
    .load-movement-items-input-wrapper {
      .load-movement-items-input-label {
        color: #676f8f;
        font-family: Ubuntu-Medium;
        font-size: 18px;
        letter-spacing: 0.36px;
        margin-bottom: 18px;
      }
      .load-movement-items-input {
        display: flex;
        align-items: center;
        .main-input-container {
          margin-right: 26px;
          max-width: 684px;
          width: 100%;
        }
        .main-input-container .main-input-wrapper {
          width: 100%;
        }
        .main-input-container .main-input-error {
          margin-top: 0;
        }
      }
    }
    .load-movement-items-count {
      color: #62d862;
      font-size: 28px;
      font-family: Ubuntu-Medium;
      margin-top: 27px;
    }

    .load-movement-items-list {
      margin-top: 57px;
      width: 100%;
      max-width: 684px;
      .item-tag-container {
        margin-bottom: 30px;
      }
    }

    .load-movement-items-buttons-wrapper {
      display: flex;
      margin-top: 55px;
      .load-movement-items-button {
        cursor: pointer;
        font-family: $btn-font;
        font-size: $btn-font-size;
        border-radius: $btn-border-radius;
        width: $btn-width;
        height: $btn-height;
        display: flex;
        color: white;
        align-items: center;
        justify-content: center;
      }
      .load-movement-items-clean {
        background-color: $btn-color-bg-dark;
        margin-right: 68px;
      }
      .load-movement-items-send {
        background-color: $btn-color-bg-primary;
      }
    }
  }
}

@media (orientation: portrait) {

  .load-movement-items-outter {
    .load-movement-items-wrapper {
      margin: 22px 15px;
      .reception-order-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 12px;
        margin-top: 30px;
        padding-left: 31px;
        p {
          margin: 0;
          margin-right: 10px;
        }
        span {
          font-family: Ubuntu-Medium;
        }
      }
      .load-movement-items-title-wrapper {
        .load-movement-items-title {
          font-size: 14px;
        }
      }
      .load-movement-items-content {
        margin-top: 20px;
        padding: 24px 12px 24px 24px;

        .load-movement-items-input-wrapper {
          .load-movement-items-input-label {
            font-size: 14px;
            letter-spacing: 0.28px;
          }

          .load-movement-items-input {
            .main-input-container {
              margin-right: 13px;
            }
          }
        }

        .load-movement-items-count {
          text-align: center;
          margin-top: 14px;
        }
        .load-movement-items-list {
          margin-top: 46px;
        }

        .load-movement-items-buttons-wrapper {
          position: absolute;
          bottom: 0;
          background-color: white;
          height: 71px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          box-shadow: 0 0 6px #00000029;


          .load-movement-items-button {
            width: $btn-width-short;
            margin-left: $btn-margin;
            font-family: $btn-font;
            font-size: $btn-font-size;
            margin-right: 0px;
          }
          .load-movement-items-button.load-movement-items-clean {
            margin: 0px !important;
          }

        }
      }
    }
  }
  .reception-order-details-init-movement {
    background-color: $btn-color-bg-primary;
    width: $btn-width-short;
    height: $btn-height;
    border-radius: $btn-border-radius;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: $btn-font;
    font-size: $btn-font-size;
    cursor: pointer;
  }
}
