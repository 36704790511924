@import '../../../assets/styles/fonts';


.image{
  height: 50px;
  width: 50px;
}

.button-list{
  width: 25vw;
}

.text{
  padding: 10px;
  font-family: Ubuntu-Light !important;
  font-size: 15px !important;
  color: #2f2e3c !important;
  width: 120px;
}

.text-end{
  margin-right: 0px;
  margin-left: 15px;
  font-family: Ubuntu-Light !important;
  font-size: 12px !important;
  color: #2f2e3c !important;
}