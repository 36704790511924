@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.wizard-container {
  .wizard-step-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .step-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;

      .name{
        font-family: Ubuntu-Light;
        font-size: 16px;
        margin-bottom: 0;
        height: 45px;
        color: #858BA5;


        &.active{
          color: #62D862;
          font-family: Ubuntu-Medium;
        }
      }
      .step-point{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        width: 100%;
      }

      .step-line {
        width: 15px;
        height: 1px;
        background-color: lightgrey;
        flex: 1;
      }

      .outer-point {
        width: 22px;
        height: 22px;
        border-radius: 22px;
        background: transparent;
        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .outer-point-active {
        width: 27px;
        height: 27px;
        border-radius: 27px;
        background: white;
        border: 1px solid lightgrey;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .inner-point {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: lightgray;
      }

      .inner-point-active {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: #62D862;
        margin-right: -1px;
        margin-top: -0.2px;
      }

      &:last-child {
        flex: 0;
        .step-line-right{
            background-color: transparent;
        }

      }

      &:first-child {
        flex: 0;
        .step-line-left{
            background-color: transparent;
        }

      }
    }
  }

  .step-line-general {
    height: 1px;
    background-color: lightgrey;
    flex: 1 1;
    position: absolute;
    top: 20px;
    left: 16px;
    right: 16px;

  }
}

@media (orientation: portrait) {

  .wizard-container {
    display: flex;
    justify-content: center;
    .wizard-step-wrapper {
      display: flex;
      align-items: center;
      width: 300px;
      justify-content: space-between;

      .step-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1;

        .name{
          font-family: Ubuntu-Light;
          font-size: 14px;
          margin-bottom: 0;

          color: #858BA5;


          &.active{
            color: #62D862;
            font-family: Ubuntu-Medium;
          }
        }
        .step-point{
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;
          width: 100%;
        }

        .step-line {
          width: 15px;
          height: 1px;
          background-color: lightgrey;
          flex: 1;
        }

        .outer-point {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          background: transparent;
          border: 1px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .outer-point-active {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          background: white;
          border: 1px solid lightgrey;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .inner-point {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: lightgray;
        }

        .inner-point-active {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: #62D862;
          margin-right: 0;
          margin-top: 0;
        }

        &:last-child {
          flex: 0;
          .step-line-right{
              background-color: transparent;
          }

        }

        &:first-child {
          flex: 0;
          .step-line-left{
              background-color: transparent;
          }

        }
      }
    }

    .step-line-general {
      height: 1px;
      background-color: lightgrey;
      flex: 1 1;
      position: absolute;
      top: 20px;
      left: 16px;
      right: 16px;

    }
  }

}
