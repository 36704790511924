@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.search-container {
  width: 100%;
  width: 335px;
  position: relative;
  .search-input-wrapper form {
    width: 100%;
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #a9b1d1;
    // background-color: white;
    background-color: transparent;
    padding: 0 6px;
    box-sizing: border-box;
    .search-icon-desktop {
      font-size: 18px;
      color: #3c7af5;
    }
    .search-icon-mobile {
      display: none;
    }
    .search-icon-mobile-focused {
      display: none;
    }
    .search-input {
      flex: 1 1;
      border: none;
      height: 43px;
      box-sizing: border-box;
      font-family: Ubuntu-Medium;
      color: #494F66;
      font-size: 20px;
      padding-left: 8px;
      padding-right: 25px;
      overflow: hidden;
      background: transparent;
      &::placeholder {
        color: #a9b1d1;
        font-family: Ubuntu-Medium;
        font-size: 20px;
      }
      &:focus {
        border: none;
        outline-width: 0;
      }
    }
    .search-close-wrapper {
      position: absolute;
      right: 10px;
      .search-icon-close {
        font-size: 13px;
        color: #494f66;
        cursor: pointer;
      }
    }
  }
  .search-results-mobile {
    display: none;
  }
  .search-results-desktop {
    border: 1px solid #858BA5;
    background-color: white;
    z-index: 10;
    position: absolute;
    top: 44px;
    width: 99%;
    border-radius: 2px;
    box-sizing: border-box;
    overflow: auto;
    padding-right: 17px;
    .search-results-desktop-inner {
      list-style: none;
      padding-left: 0;
      max-height: 410px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #EDEFF5;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #62D862;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      li{
        color: #676F8F;
        font-family: Ubuntu-Light;
        font-size: 16px;
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 23px;
        cursor: pointer;
        &:hover{
          background-color: #FAFAFC;
        }
      }
    }
  }
}

/////////@extend

@media (orientation: portrait) {
  .search-container {
    width: 100%;
    // width: 335px;
    position: relative;
    &.focused{
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 20;
      background: #FAFAFC;
      height: calc(100% - 0px);
      .search-icon-mobile {
        display: block;
        font-size: 17px;
        color: #3C7AF5;
        opacity: 0.54;
      }
    }
    .search-input-wrapper form {
      width: 100%;
      display: flex;
      height: 48px;
      align-items: center;
      border-bottom: 1px solid #a9b1d1;
      box-shadow: 0 2px 2px #0000003D;
      padding: 0 6px;
      box-sizing: border-box;
      background-color: white;
      .search-icon-mobile {
        display: block;
        font-size: 17px;
        color: #A9B1D1;
        opacity: 0.54;
      }
      .search-icon-mobile-focused {
        display: block;
        font-size: 16px;
        color: #3C7AF5;
        opacity: 0.54;
      }
      .search-icon-desktop {
        display: none;
      }
      .search-input {
        flex: 1 1;
        border: none;
        height: 47px;
        box-sizing: border-box;
        font-family: Ubuntu-Medium;
        color: #494F66;
        font-size: 16px;
        background: white;
        padding-left: 8px;
        &::placeholder {
          color: #a9b1d1;
          font-family: Ubuntu-Medium;
          font-size: 16px;
        }
        &:focus {
          border: none;
          outline-width: 0;
        }
      }
      .search-close-wrapper {
        position: absolute;
        right: 10px;
        .search-icon-close {
          font-size: 13px;
          color: #494f66;
          cursor: pointer;
        }
      }
    }
    .search-results-desktop {
      display: none;
    }
    .search-results-mobile {
      display: block;
      list-style: none;
      border: none;
      background-color: white;
      padding: 0 24px;
      position: absolute;
      top: 27px;
      width: 100%;
      border-radius: 2px;
      box-sizing: border-box;
      max-height: unset;
      overflow: auto;
      padding-top: 0;
      margin: 0;
      top: 51px;
      height: 88vh;
      li {
        color: #676F8F;
        font-family: Ubuntu-Light;
        font-size: 16px;
        margin-bottom: 0;
        height: 57px;
        border-bottom: 1px solid #858BA5;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #FAFAFC;
        }
      }
    }
  }
}
