@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.blue-summary-item-container{
    width: 155px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: 52px;

    .blue-summary-item-number{
        font-family: Ubuntu-Light;
        font-size: 44px;
        color: #535353;
        margin-top: 16px;
    }
    .blue-summary-item-label{
        font-family: Ubuntu-Medium;
        font-size: 20px;
        color: #535353;
        text-align: center;
        margin-top: 7px;
    }

    &:first-child{
        margin-left: 13px;
    }
}

@media (orientation: portrait) {
    .blue-summary-item-container{
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0px;


        .blue-summary-item-number{
            font-family: Ubuntu-Medium;
            font-size: 20px;
            color: #535353;
            margin-top: 9px;

        }
        .blue-summary-item-label{
            font-family: Ubuntu-Medium;
            font-size: 10px;
            color: #535353;
            text-align: center;
            margin-top: 2px;
        }
    }
}