@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.dropdown-filter-container {
  width: 100%;
  position: relative;

  .dropdown-filter-input {
    border: 1px solid #858ba5;
    border-radius: 6px;
    height: 56px;
    padding: 7px 17px 0 17px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    &.focused {
      border: 1px solid #3c7af5;

      input {
        background-color: white;
      }
    }

    display: flex;
    flex-direction: column;

    .dropdown-filter-chevron {
      font-size: 8px;
      transform: rotate(180deg);
      color: #3c7af5;
      position: absolute;
      top: 22px;
      right: 13px;

      &.open {
        transform: unset;
      }
    }
    .dropdown-filter-label-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .dropdown-filter-label {
      color: #676f8f;
      font-family: Ubuntu-Light;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .dropdown-filter-placeholder {
      color: #494f66;
      font-family: Ubuntu-Medium;
      font-size: 16px;
    }
    .dropdown-filter-selection {
      flex: 1;
      display: flex;
      .dropdown-filter-selected-item {
        background: #ecf8ff;
        height: 22px;
        padding: 4px 7px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;

        font-family: Ubuntu-Light;
        font-size: 12px;
        color: #494f66;
        letter-spacing: 0.24px;
        margin-right: 7px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        i {
          color: #3c7af5;
          font-size: 9px;
          margin-left: 12px;
          margin-top: 2px;
        }
      }
    }
  }

  .dropdown-filter-option-container {
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px 18px 40px 0;
    z-index: 10;
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    .dropdown-filter-option-wrapper {
      max-height: 320px;

      overflow: auto;

      /* Track */
      &::-webkit-scrollbar-track {
        background: #edeff5;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #62d862;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
      }
      .dropdown-filter-option {
        font-family: Ubuntu-Light;
        color: #494f66;
        font-size: 16px;
        letter-spacing: 0.12px;
        height: 56px;
        padding-left: 28px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: white;
        &:hover {
          background-color: #fafafc;
        }

        .checkbox-container {
          width: 97%;
        }
        .label {
          margin-left: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          text-align: left;
          cursor: pointer;
        }

        .unchecked {
          width: 21px;
        }
        .button {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .dropdown-filter-container {
    width: 100%;

    border: 1px solid #d4d5e8;
    .dropdown-filter-input {
      border: unset;
      border-radius: 6px;
      height: 66px;
      padding: 12px 17px 0 17px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      &.focused {
        border: unset;
      }

      display: flex;
      flex-direction: column;

      .dropdown-filter-chevron {
        font-size: 8px;
        transform: rotate(180deg);
        color: #3c7af5;
        position: absolute;
        top: 28px;
        right: 17px;

        &.open {
          transform: unset;
        }
      }
      .dropdown-filter-label-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
      }
      .dropdown-filter-label {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 12px;
        margin-bottom: 10px;
      }

      .dropdown-filter-placeholder {
        color: #494f66;
        font-family: Ubuntu-Medium;
        font-size: 16px;
      }
      .dropdown-filter-selection {
        flex: 1;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        .dropdown-filter-selected-item {
          background: none;
          height: 22px;
          padding: 4px 7px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;

          font-family: Ubuntu-Medium;
          font-size: 16px;
          color: #494f66;
          letter-spacing: 0.24px;
          margin-right: 7px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          i {
          }
        }
      }
    }

    .dropdown-filter-option-container {
      box-shadow: unset;
      padding: 0 18px 40px 0;
      position: unset;
      margin-bottom: 14px;

      padding-bottom: 0;

      .dropdown-filter-option-wrapper {
        max-height: unset;

        overflow: auto;

        /* Track */
        &::-webkit-scrollbar-track {
          background: #edeff5;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #62d862;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 10px;
        }
        .dropdown-filter-option {
          font-family: Ubuntu-Light;
          color: #494f66;
          font-size: 14px;
          letter-spacing: 0.12px;
          height: 51px;
          padding-left: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: #fafafc;
          }

          .checkbox-container {
            width: 97%;
          }
          .label {
            margin-left: 19px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
            cursor: pointer;
            font-family: Ubuntu-Light;
            font-size: 14px;
          }

          .unchecked {
            width: 21px;
          }
          .button {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
        }
      }
    }
  }
}
