@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.desktop-main-layout-container{
    height: 100%;
    display: flex;
    background-color: #FAFAFC;
    width: 100%;
    overflow: hidden;

    .mobile-nav{
        display: none;
    }
    .right-layout-container{
        overflow: hidden;
        flex: 1;
        height: 100%;
        flex-direction: column;
        display: flex;
        width: calc(100% - 97px);
    }
}

@media (orientation: portrait) {
    .desktop-main-layout-container{
        height: 100%;
        display: flex;
        background-color: #FAFAFC;
        flex-direction: column;
        /* height: 100vh; */
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .right-layout-container{
            width: 100%;
            flex: 1
        }
        .desktop-nav{
            display: none;
        }
        .mobile-nav{
            display: initial;
        }
    }

}