.devices-activation-button {
    max-width: 279px;
    padding: 9px 18px 9px 10px;
    background-color: #EBF2FE;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto;
    cursor: pointer;

    @media (orientation: portrait) {
        max-width: 75%;
        margin-left: 0;
    }

    > span {
        width: 175px;
        font-family: Ubuntu;
        font-size: 14px;
        color: #494F66;
        text-align: start;
        @media (orientation: portrait) {
            max-width: 65%;
        }
    }

    > picture {
        position: relative;
        width: 37px;
        height: 37px;
        display: grid;
        place-items: center;
        background-color: #FFFFFF;
        border-radius: 4px;

        > span {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            font-family: Ubuntu-Medium;
            font-size: 9px;
            font-weight: 700;
            color: #FFFFFF;
            background: #3C7AF5;
            display: grid;
            place-content: center;
        }
    }
}