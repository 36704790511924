@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.device-detail-container {
  height: 100%;
  overflow: auto;

  .device-detail-content-wrapper {
    padding: 15px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .toggle-panel-container {
      width: 100%;
    }
    .disable-device-container {
      display: flex;
      align-items: center;
      width: 510px;
      justify-content: space-between;
      .disable-device-label {
        font-family: Ubuntu-Light;
        font-size: 18px;
        color: #676f8f;
        .disable-device-label-span {
          font-family: Ubuntu-Medium;
        }
      }
    }

    .location-panel {
      margin-bottom: 16px;
      width: 100%;
      box-sizing: border-box;
    }
    .text-panel {
      width: 100%;
      text-transform: uppercase;
      font-size: 14px;
      font-family: Ubuntu-Light;
      color: #2F2E3C;
      letter-spacing: 0.28px;
    }
    .media-panel {
      margin-bottom: 16px;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

@media (orientation: portrait) {

  .device-detail-container {
    .device-detail-content-wrapper {
      .disable-device-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .disable-device-label {
          font-family: Ubuntu-Light;
          font-size: 12px;
          color: #676f8f;
          .disable-device-label-span {
            font-family: Ubuntu-Medium;
          }
        }
      }
    }
  }
}
