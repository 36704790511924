@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";


.app-table-container {
  .app-table-mobile {
    display: none;
  }

  .app-table-desktop{
    width: 100%;
  }

  .app-table-total{
      color: #494F66;
      font-size: 16px;
      font-family: Ubuntu-Light;
      padding-left: 30px;
      text-transform: uppercase;
      margin-bottom: 30px;
      span{
        font-family: Ubuntu-Medium;
      }
  }
  .app-table-header {
    display: flex;
    padding-left: 32px;

    margin-bottom: 25px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    .app-table-header-item {
      font-family: Ubuntu-Light;
      color: #a9b1d1;
      font-size: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .app-table-header-icon-span {
        font-size: 6px;
        font-size: 12px;
        margin-left: 8px;

        &.active {
          color: #3c7af5;
        }
        &.down {
          transform: rotate(180deg);
        }
      }
    }
    .app-table-header-logo {
      flex-basis: 152px;
      flex-grow: 1;
      padding-right: 14px;

    }
    .app-table-header-name {
      flex-basis: 181px;
      flex-grow: 1;
      padding-right: 14px;
    }

    .app-table-header-client_id {
      flex-basis: 225px;
      flex-grow: 1;
      margin-right: 60px;
      padding-right: 14px;
    }
    .app-table-header-client_secret {
        flex-basis: 389px;
        flex-grow: 1;
        margin-right: 60px;
        padding-right: 14px;
    }
    .app-table-header-status {
        flex-basis: 168px;
        flex-grow: 1;
        justify-content: center;
        margin-right: 60px;
        padding-right: 14px;
    }

    .app-table-header-scopes {
        flex-basis: 64px;
        flex-grow: 1;
        margin-right: 60px;
        padding-right: 14px;
        justify-content: center;
    }

    .app-table-header-button {
      margin-right: 18px;
      width: 22px;
    }
  }

  .app-table-content-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .app-table-no-items {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: #494f66;
      font-size: 30px;
      font-family: Ubuntu-Medium;
      font-weight: 400;
    }
  }
}

@media (orientation: portrait) {

  .app-table-container {
    .app-table-total{
        color: #494F66;
        font-size: 16px;
        font-family: Ubuntu-Light;
        padding-left: 4px;
        text-transform: uppercase;
        margin-bottom: 20px;
        span{
          font-family: Ubuntu-Light;
        }
    }
    .app-table-header {
      display: none;
    }
  }
}
