@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

.add-provider-wizard-step-three-container {
  .add-provider-wizard-step-three-content {
    .add-provider-wizard-step-three-title {
      color: #3c7af5;
      font-size: 12px;
      font-family: Ubuntu-Medium;
      letter-spacing: 0.24px;
    }
    .add-provider-wizard-step-three-item {
      margin-bottom: 16px;
      width: 280px;
      .add-provider-wizard-step-three-title {
        font-size: 14px;
        font-family: Ubuntu-Light;
        color: #2f2e3c;
        letter-spacing: 0.24px;
        text-transform: uppercase;
      }
      .add-provider-wizard-step-three-value {
        font-size: 14px;
        font-family: Ubuntu-Medium;
        color: #2f2e3c;
        letter-spacing: 0.24px;
      }
    }
    .add-provider-wizard-step-three-action-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
      background-color: #ffffff;

      .add-provider-wizard-step-three-action-button {
        width: $btn-width;
        height: $btn-height;
        border-radius: $btn-border-radius;
        border: none;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #ffffff;
        font-size: $btn-font-size;
        font-family: $btn-font;

        background-color: $btn-color-bg-primary;
        cursor: pointer;

        &.disabled {
          background-color: $btn-color-disabled;
        }
      }
    }
  }
}
@media (orientation: portrait) {

  .add-provider-wizard-step-three-container {
    .add-provider-wizard-step-three-content {
      .add-provider-wizard-step-three-item {
        margin-bottom: 16px;
        width: 280px;
        .add-provider-wizard-step-three-title {
          font-size: 12px;
          font-family: Ubuntu-Light;
          color: #2f2e3c;
          letter-spacing: 0.24px;
          text-transform: uppercase;
        }
        .add-provider-wizard-step-three-value {
          font-size: 12px;
          font-family: Ubuntu-Medium;
          color: #2f2e3c;
          letter-spacing: 0.24px;
        }
      }
      .add-provider-wizard-step-three-action-wrapper {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #00000029;
        .add-provider-wizard-step-three-action-button {
          width: $btn-width-short;
          height: $btn-height;
          border-radius: $btn-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;

          color: #ffffff;
          font-size: $btn-font-size;
          font-family: $btn-font;

          background-color: $btn-color-bg-primary;

          &.disabled {
            background-color: $btn-color-disabled;
          }
        }
      }
    }
  }
}
