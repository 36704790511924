@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/fonts';

.add-business-wizard-step-three-container{
    .add-business-wizard-step-three-content{
        .section{
            .section-title{
                color: #3C7AF5;
                font-size: 12px;
                font-family: Ubuntu-Medium;
                letter-spacing: 0.24px;
                margin-bottom: 20px;
            }
            .title{
                font-size: 14px;
                font-family: Ubuntu-Light;
                color: #2F2E3C;
                letter-spacing: 0.24px;
                text-transform: uppercase;
            }
            .value{
                font-size: 14px;
                font-family: Ubuntu-Medium;
                color: #2F2E3C;
                letter-spacing: 0.24px;
                margin-bottom: 20px;
            }

            .logo{
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

                img{
                    width: 100%;
                }

            }
        }


        .add-business-wizard-step-three-action-wrapper{

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
            background-color: #FFFFFF;

            .add-business-wizard-step-three-action-button{
                width: $btn-width;
                height: $btn-height;
                border-radius: $btn-border-radius;
                border: none;

                display: flex;
                justify-content: center;
                align-items: center;

                color: #FFFFFF;
                font-size: $btn-font-size;
                font-family: $btn-font;

                background-color: $btn-color-bg-primary;
                cursor: pointer;

                &.disabled{
                    background-color: $btn-color-disabled;
                }


            }
        }
    }
}

@media (orientation: portrait) {

    .add-business-wizard-step-three-container{
        .add-business-wizard-step-three-content{


            .add-business-wizard-step-three-action-wrapper{
                margin-top: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 6px #00000029;
                .add-business-wizard-step-three-action-button{
                    width: $btn-width-short;
                    height: $btn-height;
                    border-radius: $btn-border-radius;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #FFFFFF;
                    font-size: $btn-font-size;
                    font-family: $btn-font;

                    background-color: $btn-color-bg-primary;

                    &.disabled{
                        background-color: $btn-color-disabled;
                    }


                }
            }
        }
    }

}

