@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.add-businessnode-success-container{
    .add-businessnode-success-id{
        margin-top: 15px;
        font-size: 14px;
        font-family: Ubuntu-Medium;
        letter-spacing: 0.58px;
        color: #676F8F;
    }
    .add-businessnode-success-text{
        margin-top: 29px;
        font-size: 16px;
        font-family: Ubuntu-Light;
        color: #494F66;
    }
}

@media (orientation: portrait) {

}