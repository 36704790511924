.tittle-comment{
    text-align: center
}

.txt-comment{
    width: 100%;
}


.dialog-action{
    flex-flow: column;
}

.main-dialog-content{
    height: 600px;
    width: 400px;
    padding: 30px;
}

.button-bottom{
    align-items: center;
    background-color: #3c7af5 !important;
    border-radius: 20px !important;
    color: #fff !important;
    cursor: pointer;
    display: flex;
    font-family: Ubuntu-Bold !important;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    width: 171px;
}

.div-button-bottom{
    align-items: center;
}

.btn-cancel{
    text-align-last: end;
    margin-right: 5px !important;
    color: #548af6 !important;
    font-weight: bold !important;
    font-size: 15px !important;
    margin-top: 5px  !important;
}