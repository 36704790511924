@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.load-finished-general-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 357px;
    .load-finished-general-icon{
        color: #62D862;
        font-size: 56px;
        margin-bottom: 22px;
    }

    .load-finished-general-title{
        color: #494F66;
        font-family: Ubuntu-Medium;
        font-size: 30px;
        margin-bottom: 12px;
        text-align: center;
        letter-spacing: 1.25px;

    }

    .load-finished-general-subtitle{
        color: #494F66;
        font-family: Ubuntu-Light;
        font-size: 22px;
        text-align: center;
        margin-bottom: 48px;
        letter-spacing: 0.92px;

    }

    .load-finished-general-button{
        width: $btn-width;
        margin-bottom: 31px;



    }

    .load-finished-general-link{
        color: #3C7AF5;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        a{
            text-decoration: none;
        }
    }
}

@media (orientation: portrait) {


    .load-finished-general-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 254px;
        .load-finished-general-icon{
            color: #62D862;
            font-size: 47px;
            margin-bottom: 13px;
        }

        .load-finished-general-title{
            color: #494F66;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            margin-bottom: 5px;
            text-align: center;
            letter-spacing: 0;
        }

        .load-finished-general-subtitle{
            color: #494F66;
            font-family: Ubuntu-Light;
            font-size: 16px;
            text-align: center;
            margin-bottom: 48px;
            letter-spacing: 0.58px;


        }

        .load-finished-general-button{
            width: $btn-width;
            margin-bottom: 22px;

        }

        .load-finished-general-link{
            color: #3C7AF5;
            font-family: Ubuntu-Medium;
            font-size: 14px;
        }
    }
}