@import "../../../../assets/styles/variables";

.content{
  margin-top: 40px;
}

.grid-item{
  margin-top: 40px;
  height: 68vh;
  overflow-y: auto;
}

.header{
  display: inline;
  width: 100%;
}

.count{
  font-size: 25px !important;
  margin: 10px !important;
  left: 40vw !important;
  position: absolute;
}

.update{
  margin-left: 50px !important;

}

.queue{

  margin-left: 20px !important;
}

.textupdate{
  margin-top: 20px !important;
  color: #676f8f;
  display: flex;
  font-family: Ubuntu-Light !important;
  font-size: 12px;
}

.btnrefresh{
  margin-top: 20px !important;
  align-items: end !important;
  width: 170px;
  background: #494f66 !important;
  border-color: transparent;
  border-radius: $btn-border-radius !important;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  font-family: $btn-font !important;
  font-size: $btn-font-size;
  height: $btn-height;
  justify-content: center;
  padding: 0 35px;
  line-height: 1.1 !important;
}

.txtcantidad{
  color: #62d862;
  font-family: Ubuntu-Medium !important;
  font-size: 70px;
  margin-left: 10px;
  margin-top: -5px !important;
}

.textTotal{
  color: #494f66 !important;
  font-family: Ubuntu-Medium !important;
  font-size: 30px !important;
  margin-top: 10px !important;
  margin-left: 160px !important;

}

.zona{
  color: #494f66 !important;
  font-family: Ubuntu-Medium !important;
  font-size: 30px !important;
  margin-top: 10px !important;
  word-break:break-all !important;
  margin-left: 18px;
  width: 180px;
  text-align: center;
}


.iamgeestanteria{
  height: 630px;
  margin-left: 20px;
}

.image-flow{
  background-image: url("../../../../assets/images/AdvanFlow_200.png");
  background-size: 430px !important;
  background-repeat: no-repeat;
  height: 880px;
  width: 424px;
  margin-left: 120px;
}

.grid{
  padding-top: 120px !important;
  padding-left: 16px !important;
}

.rigth-box{
  padding-right: 0px;
}

.van {
	top: 45%;
	left: 25%;
}

.van-left{
	top: 45%;
	left: 70%;
}

.move-right{
  visibility: visible !important;
  transition: all 1s ease-in-out;
	-webkit-transition: all 1s ease-in-out; /** Chrome & Safari **/
	-moz-transition: all 1s ease-in-out; /** Firefox **/
	-o-transition: all 1s ease-in-out; /** Opera **/


	transform: translate(350px,0);
	-webkit-transform: translate(350px,0); /** Chrome & Safari **/
	-o-transform: translate(350px,0); /** Opera **/
	-moz-transform: translate(350px,0); /** Firefox **/

}

.none{
  transition: all 0s ease-in-out;
	-webkit-transition: all 0s ease-in-out; /** Chrome & Safari **/
	-moz-transition: all 0s ease-in-out; /** Firefox **/
	-o-transition: all 0s ease-in-out; /** Opera **/
  -webkit-transform: none !important; /* Safari and Chrome */
  -moz-transform: none !important; /* Firefox */
   -ms-transform: none !important; /* IE 9 */
    -o-transform: none !important; /* Opera */
       transform: none !important;
       visibility: hidden !important;
}

.move-left{
  visibility: visible !important;
  transition: all 1s ease-in-out;
	-webkit-transition: all 1s ease-in-out; /** Chrome & Safari **/
	-moz-transition: all 1s ease-in-out; /** Firefox **/
	-o-transition: all 1s ease-in-out; /** Opera **/


	transform: translate(-350px,0);
	-webkit-transform: translate-(350px,0); /** Chrome & Safari **/
	-o-transform: translate(-350px,0); /** Opera **/
	-moz-transform: translate(-350px,0); /** Firefox **/
  visibility: visible !important;
}

.object {
	position: absolute;

}