@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/fonts";

@import "../../../../../../../assets/fontello/css/fontello.css";


.relocation-order-step-four-container {
  width: 520px;

  .step-container {
    width: 100% !important;

    .step-title-wrapper .step-title {
      font-size: 16px;
    }

  }
}

.relocation-order-step-four-content {

  .step-title-wrapper .step-title {
    font-size: 16px;
  }

  .relocation-order-step-title {
    display: flex;
    justify-content: space-between;

    .relocation-order-step-title-wrapper {
      font-family: Ubuntu-Light;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #676f8f;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    .relocation-order-step-four-icon-wrapper {
      cursor: pointer;
    }
  }

  .relocation-order-step-dest {
    font-family: Ubuntu-Medium;
    font-size: 18px;
    color: #494F66;

    margin-bottom: 41px;
  }

  .relocation-order-step-four-field{
    .relocation-order-step-four-row{
      margin: 12px 0;

      &:first-child{
        margin-top: 0px !important;
      }
    }
  }
}

@media (orientation: portrait) {

  .relocation-order-step-four-container {
    width: 330px;

    .step-container {
      width: 100% !important;

      .step-title-wrapper .step-title {
        font-size: 14px;
      }

    }
  }

  .relocation-order-step-four-content {
    .relocation-order-step-title {
      display: flex;
      justify-content: space-between;

      .relocation-order-step-title-wrapper {
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #676f8f;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .relocation-order-step-edit-icon {}
    }

    .relocation-order-step-dest {
      font-family: Ubuntu-Medium;
      font-size: 12px;
      color: #494F66;

      margin-bottom: 12px;
    }
  }
}