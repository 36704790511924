@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';

.input-group{
    margin-top: 25px;
    .label{
        visibility: hidden;
        font-size: 15px;
        font-family: Roboto-Light;
        color: #8D8D8D;
        margin-left: 39px;

        &.visible{
            visibility: visible;
        }
    }
    .input-row{
        // border-bottom: 1px solid #8D8D8D;
        // padding: 8px 0;
        display: flex;
        align-items: center;


        .icon{
            color: #8D8D8D;
            font-size: 20px;
            margin-right: 12px;
        }

        .input-wrapper{
            display: flex;
            justify-content:space-between;
            flex: 1;
            border-bottom: 1px solid #8D8D8D;
            padding: 8px 0;

            .form-input{
                border: none;
                flex: 1;
                color: #292A31;
                font-size: 20px;

                &::placeholder{
                    color: #8D8D8D;
                    font-family: Roboto;
                    font-size: 20px;
                }

                &:focus{
                    border: none;
                    outline-width: 0;
                }
            }

        }

    }
    .error{
        margin-left: 39px;
        color: #E8553E;
        font-size: 12px;
        font-family: Roboto;

    }
}

.input-group-focused{
    .label{
        visibility: visible;
        font-family: Roboto-Light;
        color: #477CEE;
    }
    .input-row{
        //border-bottom: 1px solid #477CEE;
        //padding: 8px 0;
        display: flex;
        align-items: center;

        .icon{
            color: #477CEE;
        }

        .input-wrapper{
            border-bottom: 1px solid #477CEE;
            padding: 8px 0;
            display: flex;
            justify-content: space-between;
            flex: 1 1;

            .form-input{
                border: none;
                flex: 1;
                color: #292A31;

                &:focus{
                    border: none;
                    outline-width: 0;
                }
            }
        }

    }
    .error{
        margin-left: 39px;
        color: #E8553E;
        font-family: Roboto-Light;

    }
}

.input-group-error{
    .label{
        visibility: hidden;
        font-family: Roboto;
        color: #E8553E;
        &.visible{
            visibility: visible;
        }
    }
    .input-row{

        display: flex;
        align-items: center;
        .icon{
            color: #E8553E;
        }

        .input-wrapper{
            border-bottom: 1px solid #E8553E;
            padding: 8px 0;
            display: flex;
            justify-content: space-between;
            flex: 1 1;

            .form-input{
                border: none;
                flex: 1;
                color: #292A31;

                &:focus{
                    border: none;
                    outline-width: 0;
                }
            }

        }


    }
    .error{
        margin-left: 39px;
        color: #E8553E;
        font-family: Roboto;

    }
}


.visibility-button{
    background-color: transparent;
    border: none;
    cursor: pointer;

    .visibility-icon{
        color: #B2B3BF;
        font-size: 20px
    }

    &:focus{
        border: none;
        outline-width: 0;
    }
}


@media (orientation: portrait) {

    .input-group{
        margin-top: 15px;
        .label{
            font-size: 12px;
            margin-left: 34px;


            &.visible{
                visibility: visible;
            }
        }
        .input-row{
            border-bottom: 1px solid #53596F;
            padding: 4px 0;

            .icon{
                font-size: 16px;
                margin-right: 12px;
            }

            .input-wrapper{
                display: flex;
                justify-content: space-between;
                flex: 1;
                border:none;
                padding: 0;

                .form-input{
                    border: none;
                    flex: 1;
                    font-size: 16px;

                    &::placeholder{
                        font-size: 16px;
                    }

                    &:focus{
                        border: none;
                        outline-width: 0;
                    }
                }
            }

        }
        .error{
            font-size: 12px;
            margin-left: 34px;


        }
    }

    .input-group-focused{
        .label{
            visibility: visible;
            margin-left: 34px;

        }
        .input-row{
            border-bottom: 1px solid #477CEE;
            padding: 4px 0;
            display: flex;

            .icon{
                color: #477CEE;
            }
            .input-wrapper{
                border: none;
                padding: 0;

                .form-input{
                    border: none;
                    flex: 1;
                    color: #292A31;

                    &:focus{
                        border: none;
                        outline-width: 0;
                    }
                }
            }


        }
        .error{
            color: #E8553E;
            font-family: Roboto;
            margin-left: 34px;

        }
    }

    .input-group-error{
        .label{
            visibility: hidden;
            font-family: Roboto;
            color: #E8553E;
            margin-left: 34px;

            &.visible{
                visibility: visible;
            }
        }
        .input-row{
            border-bottom: 1px solid #E8553E;
            padding: 4px 0;
            display: flex;

            .icon{
                color: #E8553E;
            }

            .input-wrapper{
                border: none;
                padding: 0;
                .form-input{
                    border: none;
                    flex: 1;
                    color: #292A31;

                    &:focus{
                        border: none;
                        outline-width: 0;
                    }
                }

            }

        }
        .error{
            color: #E8553E;
            font-family: Roboto;
            margin-left: 34px;

        }
    }


    .visibility-button{
        background-color: transparent;
        border: none;
        cursor: pointer;

        .visibility-icon{
            color: #B2B3BF;
        }

        &:focus{
            border: none;
            outline-width: 0;
        }
    }



}