@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.dropdown-options-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.dropdown-options-header {
  font-family: Ubuntu-Medium;
  color: #3c7af5;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: auto;
  margin-top:3px;
  text-align: right;
}
.dropdown-options-label {
  color: #494f66;
  margin-right: 10px;
}
.dropdown-options-icons {
  font-size: 7px;
  margin-left: 5px;
}

.dropdown-options-options {
  box-shadow: 2px 2px lightgray;
  position: absolute;
  background-color: white;
  top: 45px;
  padding: 10px;
  z-index: 10;
}

.dropdown-options-option {
  font-family: Ubuntu-Light;
  padding: 10px 0;
  cursor: pointer;
}
