@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";



.category-table-container{

    .category-table-content-item{
        margin-bottom: 21px;
        &:last-child{
            margin-bottom: 0
        }
    }
}

@media (orientation: portrait) {

.category-table-container{

    .category-table-content-item{
        margin-bottom: 13px;
        &:last-child{
            margin-bottom: 0
        }
    }
}
}