
.request-order-table-item-mobile-container{
    display: flex;
    flex-direction: column;
    // width: 330px;
    height: 96px;
    box-shadow: 0 1px 15px #0022AB1A;
    box-sizing: border-box;
    padding: 18px 17px 0 16px;
    border-radius: 10px;
    margin-bottom: 15px;
    // width: 330px;

    
    .request-order-table-item-mobile-code{
        font-family: Ubuntu-Medium;
        font-size: 15px;
        letter-spacing: 0.3px;
        color: #2F2E3C;
        overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    }
    .request-order-table-item-mobile-date{
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #2F2E3C;
        margin-top: 8px;
        overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    }
    .request-order-table-item-mobile-bottom{
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin-top: 9px;
        .request-order-table-item-mobile-owner{
            font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #2F2E3C;
        overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

        }
        
    }
}