@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

.add-tag-wizard-step-two-container {
    height: 100%;
    overflow: auto;
    .step-container{
        width: 100%;
    }
  .add-tag-wizard-step-two-content {
    }
      .config-tag-confirmation-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .config-tag-confirmation-title {
          color: #676f8f;
          font-size: 14px;
          font-family: Ubuntu-Light;
          letter-spacing: 0.24px;
          font-weight: normal;
          text-transform: uppercase;
        }
      }
      .config-tag-confirmation-content {
        .config-tag-confirmation-item {
          color: #494f66;
          font-size: 14px;
          font-family: Ubuntu-Medium;
        }
        margin-bottom: 20px;
      }

      .item-tags-group{
        border-bottom: 1px solid #D4D5E8;
        margin-bottom: 10px;
        padding-bottom: 20px;
        .product-summary-container{
            .product-summary-units{
                display: none;
            }
            .product-summary-divider{
                display: none;
            }
            .product-summary-code{
                margin-bottom: 10px;
            }
        }
        .tags-quantity{
            color: #62D862;
            font-family: 'Ubuntu-Medium';
            font-size: 12px;
            margin-bottom: 10px;
        }
          .tag{
            color: #676F8F;
            font-family: 'Ubuntu-Light';
            font-size: 12px;
          }
      }

    .add-tag-wizard-step-two-action-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
      background-color: #ffffff;

      .add-tag-wizard-step-two-action-button {
        width: $btn-width;
        height: $btn-height;
        border-radius: $btn-border-radius;
        border: none;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #ffffff;
        font-size: $btn-font-size;
        font-family: $btn-font;

        background-color: $btn-color-bg-primary;
        cursor: pointer;

        &.disabled {
          background-color: $btn-color-disabled;
        }

        &.finish{
          margin-right: $btn-margin;
        }
      }
    }

}
@media (orientation: portrait) {

  .add-tag-wizard-step-two-container {
    .add-tag-wizard-step-two-content {
      .config-tag-confirmation-wrapper {
        .config-tag-confirmation-main-title {
        }
        .config-tag-confirmation-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .config-tag-confirmation-title {
              color: #676f8f;
              font-size: 12px;
              font-family: Ubuntu-Light;
              letter-spacing: 0.24px;
              font-weight: normal;
              text-transform: uppercase;
            }
          }
          .config-tag-confirmation-content {
            .config-tag-confirmation-item {
              color: #494f66;
              font-size: 12px;
              font-family: Ubuntu-Medium;
            }
            margin-bottom: 20px;
          }

          .item-tags-group{
            border-bottom: 1px solid #D4D5E8;
            margin-bottom: 10px;
            padding-bottom: 20px;
            .product-summary-container{
                .product-summary-units{
                    display: none;
                }
                .product-summary-divider{
                    display: none;
                }
                .product-summary-code{
                    margin-bottom: 10px;
                }
            }
            .tags-quantity{
                color: #62D862;
                font-family: 'Ubuntu-Medium';
                font-size: 12px;
                margin-bottom: 10px;
            }
              .tag{
                color: #676F8F;
                font-family: 'Ubuntu-Light';
                font-size: 12px;
              }
          }

      }

      .add-tag-wizard-step-two-field {
        margin-bottom: 16px;
        width: 280px;
      }
      .add-tag-wizard-step-two-action-wrapper {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 71px;
        display: flex;
        padding: 0 10px 0 10px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #00000029;
        .add-tag-wizard-step-two-action-button {
          width: $btn-width-short;
          height: $btn-height;
          border-radius: $btn-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;

          color: #ffffff;
          font-size: $btn-font-size;
          font-family: $btn-font;

          background-color: $btn-color-bg-primary;

          &.finish{
            background-color: $btn-color-bg-dark;
          }

          &.disabled {
            background-color: $btn-color-disabled;
          }
        }
      }
    }
  }
}
