@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.item-container {
  .item-title {
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
  }
  .item-sku {
    color: #676f8f;
    font-size: 16px;
    font-family: Ubuntu-Light;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
  }
  .item-quantity {
    color: #62d862;
    font-size: 14px;
    font-family: Ubuntu-Medium;
    margin-bottom: 7px;
  }

  .item-bottom {
    display: flex;
    justify-content: space-between;
  }
  .item-path {
    display: flex;
    font-size: 16px;
  }
  .item-sucursal {
    text-transform: uppercase;
    color: #62d862;
    font-family: Ubuntu-Medium;
    span {
      font-family: Ubuntu-Light;
      color: #676f8f;
    }

    .item-separator {
      margin: 0 10px;
    }
  }

  .item-location {
    font-family: Ubuntu-Light;
    color: #676f8f;
    font-size: 16px;
    text-transform: uppercase;
  }
}

@media (orientation: portrait) {

  .item-container {
    .item-title {
      color: #494f66;
      font-family: Ubuntu-Medium;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .item-sku {
      color: #676f8f;
      font-size: 12px;
      font-family: Ubuntu-Light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .item-quantity {
      color: #62d862;
      font-size: 12px;
      font-family: Ubuntu-Medium;
      margin-bottom: 7px;
    }

    .item-bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .item-path {
      display: flex;
      font-size: 12px;
    }
    .item-sucursal {
      text-transform: uppercase;
      color: #62d862;
      font-family: Ubuntu-Medium;
      span {
        font-family: Ubuntu-Light;
        color: #676f8f;
      }

      .item-separator {
        margin: 0 12px;
      }
    }

    .item-location {
      font-family: Ubuntu-Light;
      color: #676f8f;
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
