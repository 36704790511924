@import "../../../../../../assets/styles/fonts";
@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/fontello/css/fontello.css";

.reading-table-item-wrapper {
  box-shadow: 0 1px 5px #0022ab1a;
  border-radius: 12px;
  width: 100%;

  .reading-table-item-container {
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 32px;
    box-sizing: border-box;
    background: white;

    .reading-table-item {
      font-size: 18px;
      font-family: Ubuntu-Light;
      color: #494f66;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      height: 100%;
      box-sizing: border-box;

      .container_icons{
        display: flex;
        align-items: center;
        .icons {
          margin-left: 29px;
          &.icon-check {
            color: #62d862;
          }
          &.icon-iconos_menos {
            font-size: 3px;
            margin-right: 19px;
            color: red;
          }
          &.icon-cruz {
            color: red;
          }
          &.question_blue {
            color: #3c7af5;
            font-weight: bold;
            width: 19px;
            margin-left: 39px;
          }
        }
      }

      div {
        overflow: hidden;
      }
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
      }
    }

    .reading-table-item-title {
      font-family: Ubuntu-Medium;
      font-size: 20px;

      flex-basis: 97px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;

      p {
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 30px;
        width: 40px;
        height: 40px;
      }
    }

    .reading-table-item-sku {
      font-size: 19px;
      flex-basis: 173px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;
    }

    .reading-table-item-tags {
      font-family: Ubuntu-Medium;
      font-size: 19px;

      flex-basis: 206px;
      flex-grow: 1;
      justify-content: flex-end;
      margin-right: 15px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;
    }

    .reading-table-item-button {
      color: #3c7af5;
      font-size: 16px;
      margin-right: 18px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      i {
        transform: rotate(180deg);
      }
      &.opened {
        i {
          transform: none;
        }
      }
    }
  }

  .reading-table-tags-separator {
    border-bottom: 1px solid #d4d5e8;
    margin-bottom: 10px;
  }
  .reading-table-list {
    padding: 0 110px 35px 32px;

    background-color: white;
    display: none;
    .tag-container {
      margin-bottom: 10px;
      p{
        margin-left: 20px;
      }
    }

    &.opened {
      display: block;
    }
  }
}

.text-separator{
  font-size: 18px;
  font-family: $btn-font;
  margin-top: 35px;
}

@media (orientation: portrait) {

  .stock-reading-table-container {
    .stock-reading-table-header {
      display: none;
    }
    .reading-table-item-mobile-container {
      flex-direction: column;
      display: flex;
      background-color: white;

      padding: 21px 17px 10px 23px;
      .reading-table-item-row {
        display: flex;
        align-items: flex-start;
        .reading-table-item-column {
          word-break: break-all;

          &.image {
            img {
              height: 30px;
              width: 30px;
              margin-right: 15px;
            }
          }
          &.title {
            flex: 1;

            h1 {
              font-size: 12px;
              font-family: Ubuntu-Medium;
              color: #494f66;
              margin: 0;
              margin-bottom: 5px;
              width: 60vw;
            }
            p {
              color: #676f8f;
              font-size: 10px;
              font-family: Ubuntu-Light;
              margin: 0;
            }
          }

          &.button {
            .reading-table-item-button {
              color: #3c7af5;
              font-size: 8px;
              // margin-right: 18px;
              cursor: pointer;
              height: 100%;
              display: flex;
              // align-items: center;
              i {
                transform: rotate(180deg);
              }
              &.opened {
                i {
                  transform: none;
                }
              }
            }
          }
        }

        &.tags {
          justify-content: flex-end;

          p {
            font-size: 10px;
            font-family: Ubuntu-Medium;
            color: #494f66;
            margin: 0;
            span {
              color: #62d862;
            }
          }
        }
        .icons {
          margin-right: 15px;
          &.icon-check {
            color: #62d862;
          }
          &.icon-iconos_menos {
            font-size: 3px;
            color: red;
          }
          &.icon-cruz {
            color: red;
          }
          &.question_blue {
            color: #3c7af5;
            font-weight: bold;
            width: 19px;
            margin-left: 39px;
          }
        }
      }
    }

    .reading-table-tags-separator {
      border-bottom: 1px solid #d4d5e8;
      margin-bottom: 10px;
    }
    .reading-table-list {
      padding: 0 17px 10px 23px;

      background-color: white;
      display: none;
      .tag-container {
        height: auto;
        margin-bottom: 10px;

        .left-container {
          align-items: flex-start;

          .tag {
            align-items: flex-start;

            .tag-number {
              font-size: 12px;
            }

            image {
              height: 10px;
            }
            .barcode {
              font-size: 10px;
            }
          }
        }
        p {
          max-width: 200px;
          font-size: 12px;
        }
      }

      &.opened {
        display: block;
      }
    }
  }
  .text-separator{
    font-size: 14px;
  }
}
