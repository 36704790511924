@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.add-location-container{
    height: 100%;
    overflow: auto;
    .add-location-content-wrapper{
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: auto;
        .add-location-inner{
            width: 350px;
            // padding: 15px;

        }
        .add-location-title{
            color: #494F66;
            font-size: 16px;
            font-family: Ubuntu-Medium;
            font-weight: normal;
            margin-bottom: 15px;
            margin-top: 40px;
        }
        .add-location-button-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
            margin-bottom: 26px;
            .add-location-button{
                 width: $btn-width;
                height: $btn-height;
                border-radius: $btn-border-radius;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                color: #FFFFFF;
                font-size: $btn-font-size;
                font-family: $btn-font;

                background-color: $btn-color-bg-primary;
                cursor: pointer;

                &.disabled{
                    background-color: $btn-color-disabled;
                    pointer-events: none;
                }}
        }
    }
}

@media (orientation: portrait) {

    .add-location-container{
        height: 100%;
        position: relative;
        .add-location-content-wrapper{
            padding: 15px;
            .add-location-title{
                color: #494F66;
                font-size: 16px;
                font-family: Ubuntu-Medium;
                font-weight: normal;
                margin-bottom: 15px;
                margin-top: 20px;

            }
            .add-location-button-wrapper{
                margin-top: 0;
                margin-bottom: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 6px #00000029;
                background-color: white;
                .add-location-button{
                     width: $btn-width-short;
                    height: $btn-height;
                    border-radius: $btn-border-radius;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #FFFFFF;
                    font-size: $btn-font-size;
                    font-family: $btn-font;

                    background-color: $btn-color-bg-primary;
                    cursor: pointer;

                    &.disabled{
                        background-color: $btn-color-disabled;
                    }}
            }
        }
    }
}