@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.provider-detail-container {
  height: 100%;
  overflow: auto;
  .provider-detail-content-wrapper {
    padding: 25px;
    overflow: auto;
    .provider-detail-panels {
      display: flex;
    }

    .edit-provider-toggle {
      margin-top: 20px;
      padding: 0 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 14px;
        span {
          font-family: Ubuntu-Medium;
        }
      }
    }
    .toggle-panel-container {
      flex: 1;

      &:first-child {
        margin-right: 15px;
      }
    }
    .location-panel {
      margin-bottom: 16px;
    }
  }
}
@media (orientation: portrait) {

  .provider-detail-container {
    height: 100%;
    overflow: auto;
    .provider-detail-content-wrapper {
      padding: 0 15px;
      overflow: auto;

      .provider-detail-panels {
        flex-direction: column;
      }
      .edit-provider-toggle {
        margin: 20px 0;
        padding: 0 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          color: #676f8f;
          font-family: Ubuntu-Light;
          font-size: 12px;
          span {
            font-family: Ubuntu-Medium;
          }
        }
      }
      .toggle-panel-container {
        flex: 1;

        &:first-child {
          margin-right: 0;
        }
      }
      .location-panel {
        margin-bottom: 16px;
      }
    }
  }
}
