.main-app-tooltip{
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;

    .tooltip-text{
        display: none;

        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        height: 22px;
        // display: flex;
        padding: 0 10px;
        position: absolute;
        bottom: -22px;
        color: #494F66;
        font-family: Roboto-Light;
        font-size: 12px;
        align-items: center;
        border-radius: 3px;
        white-space: nowrap;

        
    }

    &.left{
        justify-content: left;

    }

    &.right{
        justify-content: right;


    }
    &:hover{
        .tooltip-text{
            display: flex;
        }
    }
}