@import 'assets/styles/variables.scss';

html{
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}


#root{
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

main{
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
main>div{
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.rotate-icon-orientation {
  position: relative;
  margin-left: 35px;
  margin-top: 70px;
  transform: translate(-50%, -50%); /* Center the icon */
  width: 70px; /* Adjust icon size */
  height: 70px; /* Adjust icon size */
  background-image: url('assets/images/rotation-orientation.png'); /* Add your icon image */
  background-size: cover;
  animation: rotateIcon 2s linear infinite; 
  animation-delay: 0.5s;
}

/* Rotate the icon with animation */
@keyframes rotateIcon {

  0% {
    transform: translate(-50%, -50%) rotate(00deg); /* Start with no rotation */
  }
  10% {
    transform: translate(-50%, -50%) rotate(00deg); /* Start with no rotation */
  }
  50% {
    transform: translate(-50%, -50%) rotate(90deg); /* Rotate 90 degrees */
  }
  100% {
    transform: translate(-50%, -50%) rotate(90deg); /* Rotate 90 degrees */
  }
}

@keyframes slideInFromRight {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

/*
  @media screen and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 100vw;
      .sidebar-container {
        overflow-x: auto;
        overflow-y: auto;
      }
    }
  }*/
