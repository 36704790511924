@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/fonts";

@import "../../../../../../../assets/fontello/css/fontello.css";

.reception-order-step-two-container {
  width: 760px;

  .step-container {
    width: 100% !important;

    .step-title-wrapper {
      margin-bottom: 0;

      .step-title {
        font-size: 16px;
      }
    }

    .step-content-wrapper {
      .reception-order-step-two-content {
        .reception-order-toggle {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #676f8f;
          font-family: Ubuntu-Light;
          font-size: 14px;
          margin-bottom: 20px;

          p {
            margin: 0;
            margin-right: 10px;
          }

          span {
            font-family: Ubuntu-Medium;
          }
        }

        .reception-order-title {
          font-size: 14px;
          font-family: Ubuntu-Medium;
          color: #676f8f;
          letter-spacing: 0.28px;
          margin-bottom: 18px;
        }

        .reception-order-step-two-field {
          .load-movement-items-list {
            .item-tag-container {
              padding-top: 16px;

              &:first-child {
                padding-top: 0px;
              }
            }
          }

          .load-movement-items-input {
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            .main-input-container {
              flex: 1;
              margin-right: 20px;

              .main-input-wrapper {
                width: 100%;
              }
            }
          }
        }

        .load-movement-items-count {
          font-size: 28px;
          color: #62d862;
          font-family: Ubuntu-Medium;
          text-align: center;
        }
      }
    }
  }

  .product-added-list-wrapper {
    margin-top: 16px;
  }
}

@media (orientation: portrait) {

  .reception-order-step-two-container {
    width: 330px;
    margin-bottom: 100px;

    .step-container {
      width: 100% !important;

      .step-content-wrapper {
        .reception-order-step-two-content {
          .reception-order-toggle {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #676f8f;
            font-family: Ubuntu-Light;
            font-size: 12px;

            p {
              margin-right: 10px;
            }

            span {
              font-family: Ubuntu-Medium;
            }
          }
        }
      }

      .step-title-wrapper .step-title {
        font-size: 14px;
      }
    }
  }
}