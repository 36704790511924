@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.main-money-input-container{
    position: relative;
    .main-money-input-overlay{
        width: 100%;
        background: #EDEFF5;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .main-money-input-wrapper{
        width: 280px;
        height: 56px;
        border: 1px solid #858BA5;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        background: transparent;
        border-radius: 4px;
        box-sizing: border-box;

        &.focused{
            border: 1px solid #3C7AF5;
        }
        &.disabled{
            border: none;
        }
        &.error{
            border: 1px solid #F55D68;
        }
        .main-money-input-label{
            font-family: Ubuntu-Light;
            font-size: 12px;
            color: #A9B1D1;
            margin-top: 10px;
            margin-left: 16px;

            &.focused{
                color: #3C7AF5;
            }
            &.error{
                color: #F55D68;
            }
            .main-input-required-label{
                color: #3C7AF5;
            }

            text-align: left;
            .main-money-input-required-label{
                color: #3C7AF5;
            }
        }
        .main-money-input-input-wrapper{
            display: flex;
            flex: 1;
            // align-items: center;

            .money-sign{
                color: #858BA5;
                font-family: Ubuntu-Medium;
                font-size: 16px;
                margin-left: 15px;
                margin-top: 4px;
                &.focused{
                    color: #3C7AF5;
                }
                &.error{
                    color: #F55D68;
                }
                .main-input-required-label{
                    color: #3C7AF5;
                }
            }
            input{
                flex: 1;
                border: none;
                background: transparent;
                // margin-left: 13px;
                font-family: Ubuntu-Medium;
                font-size: 16px;
                color: #494F66;

                height: 100%;
                box-sizing: border-box;

                margin-left: 2px;
                margin-top: -2px;

                &:focus{
                    border: none;
                    outline-width: 0;
                }

            }
        }

    }
    .main-money-input-error{
        color: #FC4E55;
        font-size: 12px;
        font-family: Ubuntu-Medium;
        margin-left: 6px;
        margin-top: 7px;
    }
    .main-money-input-label-placeholder{
        position: absolute;
        top: 19px;
        color: #A9B1D1;
        font-family: Ubuntu-Light;
        font-size: 16px;
        margin-left: 17px;
        .main-money-input-required-label{
            color: #3C7AF5;
        }
    }

}
@media (orientation: portrait) {}