@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.number-input-container{
    position: relative;
    background: white;
    text-align: -webkit-right;

    .number-input-overlay{
        width: 100%;
        background: #EDEFF5;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .number-input-wrapper{
        width: 110px;
        height: 35px;
        border: 1px solid #858BA5;
        display: flex;
        flex-direction: row;
        position: relative;
        top: 0;
        background: transparent;
        border-radius: 2px;
        box-sizing: border-box;
        align-items: center;


        .number-input-handler {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            font-weight: 500;
            cursor: pointer;
        }

        &.focused{
            border: 1px solid #3C7AF5;

            .number-input-handler{
                color: #3C7AF5;
            }

        }
        &.disabled{
            opacity: 0.4;
        }
        &.error{
            border: 1px solid #F55D68;
        }

        input{
            // flex: 1;
            width: 44px;
            border: none;
            background: transparent;
            font-family: Ubuntu-Light;
            font-size: 12px;
            color: #494F66;
            text-align: center;
            &:focus{
                border: none;
                outline-width: 0;
            }
            &::-webkit-inner-spin-button{
                display: none;
            }
        }



        .number-input-handler-divider{
            height: 72%;
            width: 1px;
            background-color: #A9B1D1;
            opacity: 0.5;
        }
    }

    .number-input-error{
        color: #FC4E55;
        font-size: 12px;
        font-family: Ubuntu-Medium;
        margin-left: 6px;
        margin-top: 7px;
    }
    .number-input-label-placeholder{
        position: absolute;
        top: 19px;
        color: #A9B1D1;
        font-family: Ubuntu-Light;
        font-size: 16px;
        .number-input-required-label{
            color: #3C7AF5;
        }
    }

}
@media (orientation: portrait) {}