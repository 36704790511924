@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.product-step-three-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .step-container {
    width: 400px;
    height: auto;
  }

  .product-step-three-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product-step-three-title {
    color: #494f66;
    font-size: 14px;
    font-family: Ubuntu-Light;
    width: 254px;
    text-align: center;
    margin-bottom: 35px;
    letter-spacing: 0.58px;
  }
}

@media (orientation: portrait) {

  .product-step-three-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .step-container {
      width: 330px;
      height: auto;
    }

    .product-step-three-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .product-step-three-title {
      color: #494f66;
      font-size: 14px;
      font-family: Ubuntu-Light;
      width: 254px;
      text-align: center;
      margin-bottom: 38px;
      margin-top: 11px;
      letter-spacing: 0.58px;
    }
  }
}
