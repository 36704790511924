@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.location-panel{
    background-color: #FFFFFF;
    padding: 5px 10px 15px 10px;
    border-radius: 10px;
    .location-panel-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 42px;
        .location-panel-header-title{
            font-family: Ubuntu-Light;
            font-size: 16px;
            letter-spacing: 0.24px;
            color: #494F66;
            margin: 0;
            padding: 0;
            text-transform: uppercase;

            font-weight: normal;
        }
        .location-panel-add-button{
            color: #3C7AF5;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: normal;

            svg{
                margin-right: 6px;
            }
        }

    }
    .location-panel-items{
        .location-panel-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #A9B1D1;
            height: 40px;
            cursor: pointer;
            .location-panel-item-name{
                color: #494F66;
                font-size: 16px;
                font-family: Ubuntu-Medium;
            }
            .location-panel-icon{}

        }
    }
}
@media (orientation: portrait) {

    .location-panel{
        background-color: #FFFFFF;
        padding: 5px 10px 15px 10px;
        border-radius: 10px;
        .location-panel-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 42px;
            .location-panel-header-title{
                font-family: Ubuntu-Light;
                font-size: 12px;
                letter-spacing: 0.24px;
                color: #494F66;
                margin: 0;
                padding: 0;
                text-transform: uppercase;

                font-weight: normal;
            }
            .location-panel-add-button{
                color: #3C7AF5;
                font-family: Ubuntu-Medium;
                font-size: 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
                font-weight: normal;

                svg{
                    margin-right: 6px;
                }
            }

        }
        .location-panel-items{
            .location-panel-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #A9B1D1;
                height: 40px;
                cursor: pointer;
                .location-panel-item-name{
                    color: #494F66;
                    font-size: 12px;
                    font-family: Ubuntu-Medium;
                }
                .location-panel-icon{}

            }
        }
    }
}
