.item-chip {
  height: 19px !important;
  font-family: Ubuntu-Medium !important;
}

.row {
  cursor: pointer;
}

.muiTableContainer-root {
  width: 100%;
  overflow-x: inherit !important;
  background-color: white;
}