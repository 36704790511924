@import 'assets/styles/variables';

.success-view-arc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;

    > h3, ul, p {
        margin: 0;
        padding: 0;
    }

    > h3 {
        margin: 10px 0 26px;
        font-family: Ubuntu;
        font-size: 18px;
        text-align: center;
        max-width: 225px;
        color: $btn-color-bg-dark;
    }

    > ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    > p, li {
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        color: #6D7285;
    }

    > p {
        color: $green;
    }
}