@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.tag-container {
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d4d5e8;
  height: 75px;
  .left-container {
    display: flex;
    align-items: center;
  }
  .tag {
    .tag-selector {
      margin-right: 20px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .tag-rfid {
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tag-epc {
    color: #6c7081;
    font-family: Ubuntu-Medium;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .barcode {
    color: #a9b1d1;
    font-family: Ubuntu-Light;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
  }
  .tag-rfid-step2 {
    width: 420px;
  }
  .tag-epc-step2 {
    width: 420px;
  }
}

@media (orientation: portrait) {

  .tag-container {
    display: flex;
    align-items: normal;

    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid #d4d5e8;
    height: 70px;
    .tag {
      .tag-selector {
        margin-right: 20px;
      }

      display: flex;
      align-items: flex-start;
      color: #494f66;
      font-family: Ubuntu-Medium;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .tag-rfid {
      font-size: 10px;
      width: 100%;
    }
    .tag-epc {
      width: 260px;
    }
    .tag-rfid-step2 {
      font-size: 10px;
      width: 235px;
    }
    .tag-epc-step2 {
      width: 235px;
    }
  }
}
