@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/fontello/css/fontello.css';

.content {
    padding-top: 40px;
    margin-top: 0 !important;
}

.THEFT{
    color: white !important;
    background-color: #f55d68 !important;
    font-family: Ubuntu-Light !important ;
    font-weight: bold !important;
}

.ALARM{
    color: white !important;
    background-color: #3c7af5 !important;
    font-family: Ubuntu-Light !important ;
    font-weight: bold !important;
}

.FALSE_ALARM{
    color: white !important;
    background-color: #62d862 !important;
    font-family: Ubuntu-Light !important ;
    font-weight: bold !important;
}

.ATTEMPTED_THEFT{
    color: white !important;
    background-color: #ffaf3a !important;
    font-family: Ubuntu-Light !important ;
    font-weight: bold !important;
}

.appBarMui{
    background-color: #3c7af5 !important;
}

.card-content-desk {
    margin-top: 3px !important;
    width: 99.5%;
    height: 54px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 4px 0px rgba(95, 99, 136, 0.13) !important;
    border-radius: 10px !important;
    border: 3px solid transparent;
    cursor: pointer;

    transition: all .5s ease-in-out !important;
	-webkit-transition: all .5s ease-in-out !important; /** Chrome & Safari **/
	-moz-transition: all .5s ease-in-out !important; /** Firefox **/
	-o-transition: all .5s ease-in-out !important; /** Opera **/
    
    > .MuiCardContent-root {
        display: flex;
        align-items: center;
        padding: 0 0 0 39px !important;
        width: 100%;
        height: 100%;

        p, span {
            margin: 0;
        }

        > p:nth-of-type(1) { width: 13%; } // date
        > p:nth-of-type(2) { width: 13%; } // hour
        > p:nth-of-type(3) { width: 28%; } // name
        > p:nth-of-type(4) { width: 26%; } // location
        > p:nth-of-type(5) { width: 12%; } // status
        > span { width: 8%; } // arrow

        @media (width < 1300px) {
            > p:nth-of-type(4) { width: 22%; } // location
            > p:nth-of-type(5) { width: 16%; } // status
        }

        > span { // arrow
            padding-right: 21px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        > p {
            font-family: Ubuntu-Medium;
            font-weight: 500;
        }

        > p:nth-of-type(1), > p:nth-of-type(2) { // date and hour
            font-size: 14px;
            color: #858BA5;
            text-transform: uppercase;
        }

        > p:nth-of-type(3), > p:nth-of-type(4) { // name and location
            font-size: 16px;
            color: #494F66;
        }

        > p:nth-of-type(5) { // status
            position: relative;
            display: flex;
            justify-content: center;
            white-space: nowrap;

            > svg {
                position: absolute;
                left: -40px;
                width: 28px;
                top: -8px;
            }

            .new-alert,
            .false-alarm,
            .attempted-theft,
            .theft {
                padding: 5px 20px;
                font-family: Ubuntu-Medium;
                font-size: 12px;
                font-weight: 500;
                color: #FFFFFF;
                border-radius: 40px;
            }

            .new-alert { background:#3C7AF5; }
            .false-alarm { background:#62D862; }
            .attempted-theft { background:#FFAF3A; }
            .theft { background:#F55D68; }
        }
    }
}

.card-content-mobile {
    box-shadow: 1px 1px 4px 0px rgba(95, 99, 136, 0.13) !important;
    border-radius: 10px !important;

    h4, span, p { margin: 0; }

    > .MuiCardContent-root {
        padding: 12px 14px !important;
        display: flex;
        flex-direction: column;
        gap: 12px;

        header, footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        > header {
            > p {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                > svg {
                    width: 30px;
                }       
            }

            h4 {
                font-family: Ubuntu-Medium;
                font-size: 16px;
                font-weight: 500;
                color: #494F66;
            }
            span {
                padding: 4.5px 16px;
                font-family: Ubuntu-Medium;
                font-size: 12px;
                font-weight: 500;
                color: #FFFFFF;
                border-radius: 40px;
            }

            .new-alert { background:#3C7AF5; }
            .false-alarm { background:#62D862; }
            .attempted-theft { background:#FFAF3A; }
            .theft { background:#F55D68; }
        }

        > footer {
            p {
                font-family: Ubuntu;
                font-weight: 400;
            }
            > p:first-of-type {
                font-size: 14px;
                color: #6D7285;
                display: flex;
                align-items: center;
                gap: 6px;
            }
            > p:last-of-type {
                font-size: 12px;
                color: #6D7285;
            }
        }
    }
}