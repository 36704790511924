@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.edit-tag-form-content {
  display: flex;
  justify-content: space-around;

  .edit-tag-form-panel-left {
    width: 45%;

    .main-input-textarea-wrapper {
      width: 100%;
      height: 500px;
    }
  }

  .edit-tag-form-panel-right {
    width: 45%;
    .edit-tag-form-image {
      background-color: #eff7ff;
      position: relative;
      img {
        max-width: 100%;
        height: auto;
        cursor: zoom-in;
        transition: transform 0.3s ease-in-out;

        &.zoomed {
          transform: scale(3);
          cursor: zoom-out;
          position: absolute;
          top: 0;
          left: 0;
          transform-origin: top right;
        }
      }
    }
  }

  .edit-tag-form-field {
    margin-bottom: 16px;
  }

  .edit-code-tag-button {
    &.disabled {
      background-color: $btn-color-disabled;
    }
  }

  .edit-code-tag-label-error {
    color: #ff0000;
    font-size: 12px;
    font-family: Ubuntu-Regular;
    margin-top: 5px;
  }

  .subtitle {
    box-sizing: border-box;
    color: #676f8f;
    font-family: Ubuntu-Medium;
    font-size: 14px;
    letter-spacing: 0.28px;
  }

}

@media (orientation: portrait) {
  .edit-tag-form-content {
    .edit-tag-form-field {
      margin-bottom: 16px;
      width: 280px;
    }
  }
}

