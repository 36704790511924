@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.shelving{
  background-image: url("../../../../assets/images/shelving.png") !important;
}

.header-tipshelf-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85.96px;
  box-shadow: 0px 1px 5px #0022ab1a;
  background-color: white;
  position: relative;
  flex-shrink: 0;
  .left-tipshelf-container {
    display: flex;
    align-items: center;
    .icon-go-back-desktop {
      padding-left: 53px;
      color: #3c7af5;
      font-size: 20px;
      margin-top: -2px;

      cursor: pointer;
    }
    .icon-go-back-mobile {
      display: none;
    }

    .title {
      display: flex;
      font-size: 24px;
      font-family: Ubuntu-Medium;
      color: #3c7af5;
      padding-left: 89px;
    }
    .title-go-back {
      display: flex;
      font-size: 24px;
      font-family: Ubuntu-Medium;
      color: #3c7af5;
      padding-left: 13px;
    }
  }

  .middle-container {
    display: flex;
    align-items: center;

    .logo-yax {
      width: 120px;
    }

    .logo-iderotech {
      width: 120px;
      margin-left: 10px;
    }
  }

  .right-tipshelf-container {
    display: flex;
    padding-right: 38px;
    align-items: center;
    .header-button-tip{
      width: 150px;
      margin-right: 10px;
      button {
        font-size: $btn-font;
        font-family: $btn-font;
      }
    }
    .header-button {
      margin-right: 34px;
      width: $btn-width;
      button {
        font-size: $btn-font;
        font-family: $btn-font;
      }
    }
    .header-item {
      color: #3c7af5;
      font-size: 27px;
      // margin-right: 5px;
      font-size: 26px;
      cursor: pointer;
      height: 44px;
      width: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.sucursal {
        width: unset;
        height: unset;
        margin-right: 0;
        height: initial;
        width: initial;
        border-radius: 10px;
        padding: 10px;
        .icon {
          display: flex;
          align-items: flex-end;
        }
        p {
          color: #5c6176;
          font-size: 20px;
          margin: 0;
          margin-bottom: -3px;
          margin-left: 5px;
        }
      }
      &:hover {
        background: #ecf8ff;
      }
    }

    .sucursal {
    }
  }
}

@media (orientation: portrait) {
  .header-tipshelf-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 85.96px;
    box-shadow: 0px 1px 5px #0022ab1a;
    background-color: white;
    position: relative;
    flex-shrink: 0;
    .left-tipshelf-container {
      display: flex;
      align-items: start !important;
      .icon-go-back-desktop {
        padding-left: 53px;
        color: #3c7af5;
        font-size: 20px;
        margin-top: -2px;

        cursor: pointer;
      }
      .icon-go-back-mobile {
        display: none;
      }

      .title {
        display: flex;
        font-size: 24px;
        font-family: Ubuntu-Medium;
        color: #3c7af5;
        padding-left: 20px;
      }
      .title-go-back {
        display: flex;
        font-size: 24px;
        font-family: Ubuntu-Medium;
        color: #3c7af5;
        padding-left: 13px;
      }
    }

    .middle-container {
      display: flex;
      align-items: center;

      .logo-yax {
        width: 100px;
      }

      .logo-iderotech {
        width: 100px;
        margin-left: 10px;
      }
    }

    .right-tipshelf-container {
      display: flex;
      padding-right: 0px;
      align-items: center;

      .header-button-tip{
        width: 100px;
        margin-right: 10px;
        button {
          font-size: $btn-font-size;
          font-family: $btn-font;
        }
      }
      .header-button {
        margin-right: 34px;
        width: 160px;
        button {
          font-size: $btn-font-size;
          font-family: $btn-font;
        }
      }
      .header-item {
        color: #3c7af5;
        font-size: 27px;
        // margin-right: 5px;
        font-size: 26px;
        cursor: pointer;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.sucursal {
          width: unset;
          height: unset;
          margin-right: 0;
          height: initial;
          width: initial;
          border-radius: 10px;
          padding: 10px;
          .icon {
            display: flex;
            align-items: flex-end;
          }
          p {
            color: #5c6176;
            font-size: 20px;
            margin: 0;
            margin-bottom: -3px;
            margin-left: 5px;
          }
        }
        &:hover {
          background: #ecf8ff;
        }
      }

      .sucursal {
      }
    }
  }
}
