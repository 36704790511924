@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.product-summary-wrapper {
  text-align: left;
  .product-summary-title {
    display: flex;
    justify-content: space-between;
    .product-summary-title-wrapper {
      font-family: Ubuntu-Medium;
      font-size: 20px;
      letter-spacing: 0.4px;
      color: #676f8f;
      margin-bottom: 9px;

      &.edit {
        font-family: Ubuntu-Light;
        font-size: 16px;
        letter-spacing: 0.32px;
        color: #676f8f;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    .product-summary-edit-icon {
      cursor: pointer;
    }

    .product-summary-chevron {
      font-size: 8px;
      transform: rotate(180deg);
      color: #3c7af5;
      position: absolute;
      top: 22px;
      right: 13px;

      &.open {
        transform: unset;
        border-bottom: none;
      }
    }
  }

  .product-summary-total-units {
    font-family: Ubuntu-Light;
    font-size: 14px;
    letter-spacing: 0;
    color: #62d862;

    margin-bottom: 20px;

    &.edit {
      font-family: Ubuntu-Light;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #62d862;

      margin-bottom: 20px;
    }
  }

  .product-summary-pagination {
    display: flex;
    justify-content: flex-end;
  }
}

@media (orientation: portrait) {

  .product-summary-wrapper {
    .product-summary-title {
      display: flex;
      justify-content: space-between;
      position: relative;
      .product-summary-title-wrapper {
        font-family: Ubuntu-Medium;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #3c7af5;
        margin-bottom: 6px;
        &.edit {
          font-family: Ubuntu-Light;
          font-size: 12px;
          letter-spacing: 0.24px;
          color: #676f8f;
          margin-bottom: 0;
        text-transform: uppercase;

        }
      }
      .product-summary-edit-icon {
      }

      .product-summary-chevron {
        font-size: 8px;
        transform: rotate(180deg);
        color: #3c7af5;
        position: absolute;
        top: 4px;
        right: 13px;

        &.open {
          transform: unset;
          border-bottom: none;
        }
      }
    }

    .product-summary-total-units {
      font-family: Ubuntu-Light;
      font-size: 12px;
      letter-spacing: 0;
      color: #62d862;

      margin-bottom: 16px;

      &.edit {
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #62d862;

        margin-bottom: 12px;
      }
    }
  }
}
