@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';


.blue-summary-container{
    display: flex;
    background-color: #ECF8FF;
    height: 147px;
}

@media (orientation: portrait) {
    .blue-summary-container{
        height: 70px;
        justify-content: space-between;
        padding: 0 10px;
    }
}