@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/fonts';

.add-config-tag-wizard-step-three-container{
    .add-config-tag-wizard-step-three-content{
        .add-config-tag-wizard-step-three-field{
            margin-bottom: 16px;
            width:280px;
        }
        .add-config-tag-wizard-step-three-action-wrapper{

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
            background-color: #FFFFFF;

            .add-config-tag-wizard-step-three-action-button{
                width: $btn-width;
                height: $btn-height;
                border-radius: $btn-border-radius;
                border: none;

                display: flex;
                justify-content: center;
                align-items: center;

                color: #FFFFFF;
                font-size: $btn-font-size;
                font-family: $btn-font;

                background-color: $btn-color-bg-primary;
                cursor: pointer;

                &.disabled{
                    background-color: $btn-color-disabled;
                }


            }
        }
    }
}
@media (orientation: portrait) {

    .add-config-tag-wizard-step-three-container{
        .add-config-tag-wizard-step-three-content{
            .add-config-tag-wizard-step-three-field{
                margin-bottom: 16px;
                width:280px;
            }
            .add-config-tag-wizard-step-three-action-wrapper{
                margin-top: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 6px #00000029;
                .add-config-tag-wizard-step-three-action-button{
                    width: $btn-width-short;
                    height: $btn-height;
                    border-radius: $btn-border-radius;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #FFFFFF;
                    font-size: $btn-font-size;
                    font-family: $btn-font;

                    background-color: $btn-color-bg-primary;

                    &.disabled{
                        background-color: $btn-color-disabled;
                    }


                }
            }
        }
    }

}

