@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.drag-and-drop-image-container {
  .drag-and-drop-image-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .drag-and-drop-image-input-wrapper-mobile {
      display: none;
    }
    .drag-and-drop-image-input-wrapper {
      background-color: #ecf8ff;
      border: 1px dashed #858BA5;
      border-radius: 10px;
      width: 300px;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;

      box-sizing: border-box;
      padding-bottom: 27px;
      img {
        margin-bottom: 6px;
        margin-top: 33px;
      }

      .drag-and-drop-image-text-wrapper {
        text-align: center;
        margin-bottom: 2px;

      }
      .drag-and-drop-image-text {
        text-align: center;
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        letter-spacing: 0.67px;
        margin-bottom: 10px;
      }

      input[type="file"] {
        display: none;
      }
      .custom-file-upload {
        font-size: 18px;
        font-family: Ubuntu-Medium;
        color: #3c7af5;
        cursor: pointer;
      }

      .custom-file-upload-mobile {
        display: none;
      }

    }
    .drag-and-drop-image-help{
      font-family: Ubuntu-Light;
      font-size: 12px;
      color: #494F66;
      letter-spacing: 0.24px;
      margin-top: 12px;
    }

    .drag-and-drop-image-input-preview {
      margin: 10px 0;
      img {
        // background-color: #ECF8FF;
        border: 1px dashed #858BA5;
        border-radius: 10px;
        height: 190px;
        width: 190px;
        object-fit: contain;
      }

      .drag-and-drop-image-delete {
        cursor: pointer;
        margin-top: 23px;
        color: #3c7af5;
        font-size: 16px;
        font-family: Ubuntu-Medium;
        text-align: center;
        .drag-and-drop-image-icon {
        }
      }
    }
  }

  .drag-and-drop-image-error{
    color: #FC4E55;
    font-size: 12px;
    font-family: Ubuntu-Medium;
    margin-left: 6px;
    margin-top: 12px;
  }

  &.small{
    .drag-and-drop-image-wrapper {
      .drag-and-drop-image-input-wrapper {
        display: none;
      }
      .drag-and-drop-image-input-wrapper-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .drag-and-drop-bottom-label{

          margin-top: 8px;
          display: flex;
          flex-direction: column;

        justify-content: center;
        align-items: center;

          .drag-and-drop-bottom-label-add{
            color: #3C7AF5;
            font-size: 18px;
            font-family: Ubuntu-Medium;
            cursor: pointer;
          }

          .drag-and-drop-image-help{
            margin-top: 5px;
          }
        }
        input[type="file"] {
          visibility: hidden;
          height: 0;
          width: 0;
        }
        .custom-file-upload {
          .custom-file-wrapper {
            background-color: #ecf8ff;
            border: 1px dashed #858BA5;
            border-radius: 10px;
            height: 92px;
            width: 92px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          img {
            height: 46px;
            width: auto;
          }
        }

      }

      .drag-and-drop-image-input-preview {
        img {
          // background-color: #ECF8FF;
          border: 1px dashed #858BA5;
          border-radius: 10px;
          height: 90px;
          width: 90px;
          object-fit: contain;
        }

        .drag-and-drop-image-delete {
          cursor: pointer;
          margin-top: 23px;
          color: #3c7af5;
          font-size: 16px;
          font-family: Ubuntu-Medium;
          text-align: center;
          .drag-and-drop-image-icon {
          }
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .drag-and-drop-image-container {
    .drag-and-drop-image-wrapper {
      .drag-and-drop-image-input-wrapper {
        display: none;
      }
      .drag-and-drop-image-input-wrapper-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .drag-and-drop-bottom-label{

          margin-top: 8px;
          display: flex;
          flex-direction: column;

        justify-content: center;
        align-items: center;

          .drag-and-drop-bottom-label-add{
            color: #3C7AF5;
            font-size: 18px;
            font-family: Ubuntu-Medium;
          }

          .drag-and-drop-image-help{
            margin-top: 5px;
          }
        }
        input[type="file"] {
          visibility: hidden;
          height: 0;
          width: 0;
        }
        .custom-file-upload {
          .custom-file-wrapper {
            background-color: #ecf8ff;
            border: 1px dashed #858BA5;
            border-radius: 10px;
            height: 92px;
            width: 92px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            height: 46px;
            width: auto;
          }
        }

      }

      .drag-and-drop-image-input-preview {
        img {
          // background-color: #ECF8FF;
          border: 1px dashed #858BA5;
          border-radius: 10px;
          height: 90px;
          width: 90px;
          object-fit: contain;
        }

        .drag-and-drop-image-delete {
          cursor: pointer;
          margin-top: 23px;
          color: #3c7af5;
          font-size: 16px;
          font-family: Ubuntu-Medium;
          text-align: center;
          .drag-and-drop-image-icon {
          }
        }
      }
    }
  }
}
