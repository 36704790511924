@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.load-reception-order-container {
  height: 100%;
  .load-reception-order-content {
    display: flex;
    flex: 1 1;
    overflow: auto;
    width: 100%;
    justify-content: center;

    .load-reception-order-wrapper {
      position: relative;

      text-align: center;
      flex-grow: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      .load-reception-order-wizard {
        margin-top: 56px;
        width: 326px;
      }

      .load-reception-order-step .content {
        margin-top: 53px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .load-movement-items-buttons-wrapper {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        .load-movement-items-button {
          width: $btn-width;
          height: $btn-height;
          color: white;
          border-radius: $btn-border-radius;
          justify-content: center;
          display: flex;
          align-items: center;
          font-family: $btn-font;
          font-size: $btn-font-size;
          cursor: pointer;

          &.load-movement-items-clean {
            background-color: $btn-color-bg-dark;
          }
          &.load-movement-items-send {
            background-color: $btn-color-bg-primary;
            margin-left: $btn-margin;


            &.disabled{
              background-color: $grey;
              pointer-events: none;
            }
          }
        }
      }
      .load-reception-order-button {
        width: $btn-width;
        margin-top: 17px;

        &.last {
          .main-button {
            font-size: $btn-font-size;
            font-family: $btn-font;
            &.enabled {
              background-color: $btn-color-bg-primary;
              cursor: pointer;
            }
          }
        }
      }
    }

    .load-reception-order-wrapper-finish {
      width: 100%;
      height: 100%;
      display: flex;
      .load-reception-order-wrapper-finish-container {
        margin: 23px;
        background: white;
        box-shadow: 0 2px 10px #00225b1a;
        border-radius: 10px;
        padding-top: 155px;
        flex: 1;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        .load-finished-container {
          justify-content: unset;
        }
      }
    }
  }
}

@media (orientation: portrait) {

  .load-reception-order-container {
    .load-reception-order-content {
      .load-reception-order-wrapper {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        // margin-bottom: 69px;
        .load-reception-order-wizard {
          margin-top: 16px;
          width: 76%;
        }

        .load-reception-order-step .content {
          margin-top: 12px;
        }

        .load-movement-items-buttons-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          position: fixed;
          box-shadow: 0 0 6px #00000029;

          bottom: 0;
          height: 71px;
          left: 0;
          right: 0;
          background: white;
          margin-top: 0;
          align-items: center;
          .load-movement-items-button {
            width: $btn-width-short;
            height: $btn-height;
            color: white;
            border-radius: $btn-border-radius;
            justify-content: center;
            display: flex;
            align-items: center;
            font-family: $btn-font;
            font-size: $btn-font-size;

            &.load-movement-items-clean {
              width: $btn-width-short;
              background-color: $btn-color-bg-dark;
            }
            &.load-movement-items-send {
              width: $btn-width-short;
              background-color: $btn-color-bg-primary;
              margin-left: $btn-margin;
            }
          }
        }
        .load-reception-order-button {
          width: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          height: 69px;
          box-shadow: 0 0 6px #00000029;
          div {
            width: $btn-width-short;
            margin-top: 17px;
          }

          &.last div {
            .main-button {
              font-size: $btn-font-size;
              font-family: $btn-font;
              width: $btn-width;

              &.enabled {
                background-color: $btn-color-bg-primary;
                cursor: pointer;
              }
            }
          }
        }
      }

      .load-reception-order-wrapper-finish {
        width: 100%;
        height: 100%;
        display: flex;
        .load-reception-order-wrapper-finish-container {
          margin: 15px;
          box-shadow: unset;
          background: white;
          border-radius: 10px;
          padding-top: 99px;
          flex: 1;
          box-sizing: border-box;
          justify-content: center;
          display: flex;
          .load-finished-container {
            justify-content: unset;
          }
        }
      }
    }
  }
  .div-modal{
    height: 10px;
  }
}
