@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/fonts';


.error {
    color: #FC4E55;
    font-size: 12px;
    font-family: Ubuntu-Medium;
    margin-left: 6px;
    margin-top: 7px;
}

.location-selector-modal-wrapper {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 97px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .location-selector-modal-modal {
        background-color: white;
        height: 550px;
        width: 600px;
        border-radius: 10px;
        padding: 36px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .location-selector-modal-header {}

        .location-selector-modal-table {
            width: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;

            .location-selector-table-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #A9B1D1;
                padding-bottom: 7px;

                .table-header-title {
                    color: #62D862;
                    font-family: Ubuntu-Medium;
                    font-size: 12px;
                    margin: 0;
                }
            }

            .location-selector-table-rows {
                overflow: auto;
            }

            .location-row {
                display: flex;
                justify-content: space-between;

                border-bottom: 1px solid #A9B1D1;
                padding: 7px 0;


                .location-row-left {
                    display: flex;

                    .location-label {
                        margin: 0;
                        color: #494F66;
                        font-family: Ubuntu-Light;
                        font-size: 12px;
                    }

                    .location-stock {
                        margin: 0;
                        color: #494F66;
                        font-family: Ubuntu-Medium;
                        font-size: 10px;
                    }
                }

                .location-row-right {
                    text-align: -webkit-right;
                }
            }
        }

        .location-selector-buttons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 24px;

            .location-selector-erase-button {
                color: #676F8F;
                font-family: Ubuntu-Medium;
                font-size: 16px;
            }

            .location-selector-add-button {
                background-color: $btn-color-bg-primary;
                color: white;
                width: $btn-width;
                padding: 10px 0;
                font-family: $btn-font;
                font-size: $btn-font-size;
                border-radius: $btn-border-radius;
                display: flex;
                align-items: center;
                justify-content: center;

                &.disabled {
                    background-color: $grey;
                  }
            }

        }
    }
}

@media (orientation: portrait) {
    .location-selector-modal-wrapper {
        left: 0;
        .location-selector-add-button {
          width: $btn-width-short;
          border-radius: $btn-border-radius;
          font-size: $btn-font-size;
          font-family: $btn-font;
        }
    }
}