@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

@mixin auditoriaTitle{
  font-family: Ubuntu-Light;
  span {
    font-family: Ubuntu-Medium;
    color: #62d862;
  }
}
.auditoria-de-stock-container {
  height: 100%;

  .auditoria-de-stock-content {
    padding: 15px 25px 20px 55px;
    height: 100%;
    overflow: auto;
    .auditoria-de-stock-filter-panel {
      margin-bottom: 9px;
    }

    .auditoria-de-stock-reading-panel {
      margin-bottom: 44px;
    }

    .auditoria-de-stock-table {
      .auditoria-de-stock-table-header{
        margin-bottom: 35px;
        .auditoria-de-stock-table-header-count {
          @include auditoriaTitle;
          font-size: 24px;
          color: #2f2e3c;
        }
        .auditoria-de-stock-table-header-invalid-tags{
          @include auditoriaTitle;
          font-size: 18px;
          color: #555555;
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (orientation: portrait) {

  .auditoria-de-stock-container {
    .auditoria-de-stock-content {
      padding: 15px;
      .auditoria-de-stock-table{
        margin-bottom: 80px;
      }
    }
  }
}
