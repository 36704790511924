@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.head-item {
  align-items: center;
  color: #a9b1d1 !important;
  cursor: pointer;
  display: flex;
  font-family: Ubuntu-Light !important;
  font-size: 15px !important;
  margin: 100px;
  padding-left: 10px;
}

.head-container {
  padding-left: 10px !important;
}