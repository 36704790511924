@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.businessnode-list-container {
  height: 100%;
  overflow: auto;

  .outter-wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  .table-nodes-wrapper {
    height: auto;
    overflow: visible;
    max-height: calc(100% - 118px);
    padding-bottom: 10px;
    .businessnode-no-nodes-content {
      margin-top: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .businessnode-no-nodes-title {
        width: 274px;
        text-align: center;
        margin-bottom: 44px;
        color: #a9b1d1;
        font-size: 16px;
        font-family: "Ubuntu-Medium";
        letter-spacing: 0.67px;
      }
      .businessnode-create-button {
        width: $btn-width;
        height: $btn-height;
        text-align: center;
        background: $btn-color-bg-primary;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $btn-font-size;
        font-family:$btn-font-size;
        color: white;
        cursor: pointer;
      }
    }
    .businessnode-list-content {
      .businessnode-list-table {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        padding-bottom: 20px;
        .generic-table-container {
          width: 100%;
        }
        .table-pagination {
          margin-top: 30px;
        }
      }

    }
  }
}

@media (orientation: portrait) {

  .businessnode-list-container {

    .outter-wrapper {
      overflow: hidden;
    }

    .table-nodes-wrapper {
      max-height: calc(100% - 158px);
      padding: 15px;
      overflow-y: scroll;
      .businessnode-create-button {
        width: $btn-width-short;
        border-radius: $btn-border-radius;
        font-size: $btn-font-size;
        font-family: $btn-font;
      }
    }
  }
}
