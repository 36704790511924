@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.edit-config-tag-container {
  .edit-config-tag-form-content {

    .edit-config-tag-form-field {
      margin-bottom: 16px;
    }
    .main-input-textarea-wrapper{
      width: 100%;
    }
  }
}

@media (orientation: portrait) {

  .edit-config-tag-container {
    .edit-config-tag-form-content {
      .edit-config-tag-form-field {
        margin-bottom: 16px;
        width: 280px;
      }
    }
  }
}

.subtitle{
  box-sizing: border-box;
  color: #676f8f;
  font-family: Ubuntu-Medium;
  font-size: 14px;
  letter-spacing: .28px
}