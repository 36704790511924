.dialog_version {
  .btn_cancel{
    background-color: grey;
    color: white;
    font-weight: bold;
    &:hover{
      background-color: #d3d3d3;
    }
  }

  .btn_delete{
    background-color: #ff0000;
    color: white;
    font-weight: bold;
    &:hover{
      background-color: #d3d3d3;
    }
    &:disabled{
      background-color: #d3d3d3;
      color: white;
    }
  }
}