@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Roboto-Bold;
  src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: Roboto-Black;
  src: url("../fonts/Roboto-Black.ttf");
}

@font-face {
  font-family: Roboto-Light;
  src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto-Thin;
  src: url("../fonts/Roboto-Thin.ttf");
}

//Ubuntu

@font-face {
  font-family: Ubuntu;
  src: url("../fonts/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url("../fonts/Ubuntu-Bold.ttf");
}

@font-face {
  font-family: Ubuntu-Light;
  src: url("../fonts/Ubuntu-Light.ttf");
}

@font-face {
  font-family: Ubuntu-Medium;
  src: url("../fonts/Ubuntu-Medium.ttf");
}