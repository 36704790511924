@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.date-range-container {

    border: 1px solid #858BA5;
    border-radius: 6px;
    padding: 10px 18px;
    box-sizing: border-box;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative ;
    &.focused {
      border-color: #3C7AF5;
    }

    .date-range-label {
        font-family: Ubuntu-Light;
        font-size: 12px;
        margin: 0;
        color: #676F8F;
    }

    .react-daterange-picker__wrapper{
        border: none;
        height: 18px;

        .react-daterange-picker__inputGroup__input, .react-date-picker__inputGroup__divider, .react-daterange-picker__range-divider{
            font-family: Ubuntu-Medium;
            font-size: 16px;
            color: #494F66;
        }

        .react-daterange-picker__range-divider{
            margin: 0 7px;
        }
        .react-daterange-picker__inputGroup{
            flex-grow: initial;
            min-width: 0;
            padding: 0;

        }
        .react-daterange-picker__button{
            position: absolute;
            right: 16px;
            top: 17px;
            padding: 0;

            color: #3C7AF5;

            svg{
              stroke: none !important;
            }

            &:focus {
                outline: none;
                border-color: none;
            }

        }

        .react-daterange-picker__clear-button{
            display: none;
        }

        .react-daterange-picker__inputGroup__input:invalid{
            background: none;
        }


    }

    .react-daterange-picker{
        position: unset !important;
    }

    .react-daterange-picker__calendar{
        .react-calendar {
            padding: 30px 30px 35px;
            box-shadow: 0px 1px 15px #9B9FB564;
            border: none;

        }
        .react-calendar__navigation__label{
            font-family: Ubuntu-Medium;
            font-size: 12px;
        }
        .react-calendar__month-view__weekdays{
            font-size: 10px;
            font-family: Ubuntu;
            color: #707070;
            abbr{
                text-decoration: none;
            }
        }
        .react-calendar button{
            font-size: 10px;
            font-family: Ubuntu;
            color: #2F2E3C;

            &.react-calendar__tile--active{
                color: white;
                background: #707070;

            }

        }

        .react-calendar__tile--now{
            background: none;
            font-family: Ubuntu-Medium !important;
        }
    }
}



@media (orientation: portrait) {

    .date-range-container {

        border: 1px solid #D4D5E8;
        border-radius: 0px;
        padding: 13px 17px 14px;
        box-sizing: border-box;
        height: 68px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        position: relative ;
        &.focused {
            border-color: #3C7AF5;
        }

        .date-range-label {
            font-family: Ubuntu-Light;
            font-size: 12px;
            margin: 0;
            color: #676F8F;
        }

        .react-daterange-picker__wrapper{
            border: none;
            height: 18px;

            .react-daterange-picker__inputGroup__input, .react-date-picker__inputGroup__divider, .react-daterange-picker__range-divider{
                font-family: Ubuntu-Medium;
                font-size: 16px;
                color: #494F66;
            }

            .react-daterange-picker__range-divider{
                margin: 0 7px;
            }
            .react-daterange-picker__inputGroup{
                flex-grow: initial;
                min-width: 0;
                padding: 0;

            }
            .react-daterange-picker__button{
                position: absolute;
                right: 16px;
                top: 23px;
                padding: 0;
                color: #3C7AF5;

                svg{
                  stroke: none !important;
                }

                &:focus {
                    outline: none !important;
                    border-color: none !important;
                }
            }

            .react-daterange-picker__clear-button{
                display: none;
            }

            .react-daterange-picker__inputGroup__input:invalid{
                background: none;
            }


        }

        .react-daterange-picker{
            position: unset !important;
        }

        .react-daterange-picker__calendar{
            .react-calendar {
                padding: 30px 30px 35px;
                box-shadow: 0px 1px 15px #9B9FB564;
                border: none;

            }
            .react-calendar__navigation__label{
                font-family: Ubuntu-Medium;
                font-size: 12px;
            }
            .react-calendar__month-view__weekdays{
                font-size: 10px;
                font-family: Ubuntu;
                color: #707070;
                abbr{
                    text-decoration: none;
                }
            }
            .react-calendar button{
                font-size: 10px;
                font-family: Ubuntu;
                color: #2F2E3C;

                &.react-calendar__tile--active{
                    color: white;
                    background: #707070;

                }

            }

            .react-calendar__tile--now{
                background: none;
                font-family: Ubuntu-Medium !important;
            }
        }
    }

  }
