@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";
.location-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f9fb;

  .location-modal-content-wrapper {
    padding: 21px;
  }
  .location-modal-content {
    padding: 21px;
    background: white;
  }

  .prep-order-field {
    margin-bottom: 18px;
  }

  .load-movement-items-buttons-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .load-movement-items-button{
    cursor: pointer;
    font-family: $btn-font;
    font-size: $btn-font-size;
    border-radius: $btn-border-radius;
    width: $btn-width;
    height: $btn-height;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: $btn-color-bg-primary;
    &.disabled{
      background-color: $grey;
      pointer-events: none;
    }

  }
}

@media (orientation: portrait) {

  .location-modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    background: #f9f9fb;

    .location-modal-content-wrapper {
      padding: 21px;
    }
    .location-modal-content {
      padding: 21px;
      background: white;
    }
    .load-movement-items-button{
      font-family: $btn-font;
      font-size: $btn-font-size;
      border-radius: $btn-border-radius;
      width: $btn-width-short;
    }
  }
}
