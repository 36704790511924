.maintabs{
    width: 100%;
    height: 100%;
}

.MuiTabs-root {
    overflow: visible !important;
}

.celltable-connections{
    padding:9px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
    min-width: 180px;
    line-height: 1.0rem !important;
    cursor: pointer;
}

.icons{
    max-width: 80px !important;
    min-width: 80px !important;
    padding-left: 20px !important;
}


.cellwidth{
    width: 60px !important;
}