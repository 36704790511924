@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.load-request-order-container {
  height: 100%;
  .load-request-order-content {
    display: flex;
    flex: 1 1;
    overflow: auto;
    width: 100%;
    justify-content: center;

    .load-request-order-wrapper {
      text-align: center;
      flex-grow: 0;

      display: flex;
    flex-direction: column;
    align-items: center;
      .load-request-order-wizard {
        margin-top: 56px;
      width: 326px;

      }

      .load-request-order-step .content {
        margin-top: 53px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .load-request-order-button {
        width: $btn-width;
        margin-top: 17px;

        &.last {
          .main-button {
            font-size: $btn-font-size;
            font-family: $btn-font;
            &.enabled {
              background-color: $btn-color-bg-primary;
              cursor: pointer;
            }
          }
        }
      }
    }

    .load-request-order-wrapper-finish{
      width: 100%;
      height: 100%;
      display: flex;
      .load-request-order-wrapper-finish-container{
          margin: 23px;
          background: white;
          box-shadow: 0 2px 10px #00225B1A;
          border-radius: 10px;
          padding-top: 155px;
          flex: 1;
          box-sizing: border-box;
          justify-content: center;
          display: flex;
          .load-finished-container{
            justify-content: unset;
          }
      }
    }
  }
}

@media (orientation: portrait) {

  .load-request-order-container {

    .load-request-order-content{
      .load-request-order-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        // margin-bottom: 69px;
        .load-request-order-wizard{
          margin-top: 16px;
          width: 76%;
        }

        .load-request-order-step .content{
          margin-top: 12px;

        }

        .load-request-order-button {
          width: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 69px;
          box-shadow: 0 0 6px  #00000029;
          div{
            width: $btn-width-short;
            margin-top: 17px;
          }

          &.last div{
            .main-button {
              font-size: $btn-font-size;
              font-family: $btn-font;
              border-radius: $btn-border-radius;
              &.enabled {
                background-color: $btn-color-bg-primary;
                cursor: pointer;
              }
            }
          }
        }




      }

      .load-request-order-wrapper-finish{
        width: 100%;
        height: 100%;
        display: flex;
        .load-request-order-wrapper-finish-container{
            margin: 15px;
            box-shadow: unset;
            background: white;
            border-radius: 10px;
            padding-top: 99px;
            flex: 1;
            box-sizing: border-box;
            justify-content: center;
            display: flex;
            .load-finished-container{
              justify-content: unset;
            }
        }
      }

    }

  }
}


