@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.setup-locations-container{
    height: 100%;
    .setup-locations-content-wrapper{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
        .setup-locations-text{
            width: 100%;
            text-align: left;
        }
        .setup-locations-button{
            color: white;
            font-size: $btn-font-size;
            font-family: $btn-font;
            background-color: $btn-color-bg-primary;
            width: $btn-width;
            height: $btn-height;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $btn-border-radius;

        }
    }
}

@media (orientation: portrait) {

    .setup-locations-container{
        height: 100%;
        .setup-locations-content-wrapper{

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
            .setup-locations-text{
                width: 100%;
                text-align: left;
            }
            .setup-locations-button{
                color: white;
                font-size: $btn-font-size;
                font-family: $btn-font;
                background-color: $btn-color-bg-primary;
                width: $btn-width-short;
                height: $btn-height;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: $btn-border-radius;

            }
        }
    }
}