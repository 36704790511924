@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.app-add-container{
    width: 600px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 15px #0022AB1A;
    border-radius: 10px;
    padding: 30px 26px;
    .title{
        font-family: Ubuntu-Medium;
        color: #676F8F;
        font-size: 14px;
        letter-spacing: 0.28px;
    }

    .content-view-new-app {
      width: 100%;
    }

    .top{
        display: flex;

        .left{
            margin-right: 35px;
        }
        .inputs{
            margin-top: 20px;
        }
    }
    .bottom{
        .permisos{
            margin-top: 29px;
            width: 100%;

            .left{
                .title{
                    font-family: Ubuntu-Medium;
                    color: #676F8F;
                    font-size: 14px;
                    letter-spacing: 0.11px;

                }
            }

            th{
               color: #7781AC;
               font-size: 12px;
               font-family: Ubuntu-Light;
               letter-spacing: 0.24px;
               font-weight: 400;
            }

            .scope-th{
                text-align: left;
            }

            .option{
                width: 75px;
                text-align: center;

                .checkbox-container{
                    margin-left: 27px;
                }
            }
        }
    }
}
@media (orientation: portrait) {


    .app-add-container{

    padding: 21px 16px;
    box-sizing: border-box;


        .top{
            flex-direction: column;

            .left{
                margin: 0;

                .inputs{
                    margin-bottom: 20px;
                    .main-input-container{
                        margin-bottom: 15px;
                        .main-input-wrapper{
                            width: 100%;
                        }
                    }
                }
            }
        }

        .bottom{
            margin-top: 25px;
        }
    }
}