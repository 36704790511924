.maintabs{
    width: 100%;
    height: 100%;
}

.MuiTabs-root {
    overflow: visible !important;
}

.celltable-versions{
    padding:9px !important;
    max-width: 300px;
    min-width: 300px;
    line-height: 1.0rem !important;
    cursor: pointer;
}

.celltable-div{
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}


.cellwidth{
    width: 60px !important;
}