@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.form-edit-product-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 19px 64px 20px 55px;
  box-sizing: border-box;

  position: relative;
  &.disabled{
    opacity: 0.5;
  }
  .form-edit-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .form-edit-product-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

  }
  .form-edit-product-separator{
    display: none;
  }
  .form-edit-product-stock-label {
    &.with-stock {
        color: #62D862;
        font-family: Ubuntu-Medium;
        font-size: 22px;
        letter-spacing: 0.44px;
    }
    &.without-stock {
        display: none;
    }
  }
  .form-edit-product-stock-quantity {
    color: #3C7AF5;
    font-family: Ubuntu-Light;
    font-size: 18px;
    // cursor: pointer;
    display: flex;
    align-items: center;
    i{
        font-size: 12px;
        margin-top: 5px;
    }
  }

  .form-edit-product-title {
      color: #676F8F;
      font-family: Ubuntu-Medium;
      font-size: 20px;
      letter-spacing: 0.4px;
      margin-bottom: 26px;
  }
  .form-edit-input-field {
    margin-bottom: 29px;
    .main-input-wrapper {
      width: 100%;
    }
    .main-input-textarea-wrapper {
      width: 100%;
    }
    .main-button-input-container {
      width: 100%;
    }
    .main-money-input-wrapper {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (orientation: portrait) {

  .form-edit-product-container {
    width: 100%;
    background-color: #ffffff;
    padding: 19px 25px 34px 25px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 70px;
    .form-edit-product-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .form-edit-product-separator{
      display: block;
      background-color: #EDEFF5;
      width: 100%;
      height: 1px;
      margin-top: 17px;
      margin-bottom: 20px;
    }
    .form-edit-product-stock-label {
      &.with-stock {
          color: #62D862;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          letter-spacing: 0.32px;
      }
      &.without-stock {
        display: unset;
          color: #F55D68;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          letter-spacing: 0.32px;
      }
    }
    .form-edit-product-stock-quantity {
      color: #3C7AF5;
      font-family: Ubuntu-Light;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      i{
          font-size: 12px;
          margin-top: 5px;
      }
    }

    .form-edit-product-title {
        color: #676F8F;
        font-family: Ubuntu-Medium;
        font-size: 14px;
        letter-spacing: 0.28px;
        margin-bottom: 14px;
    }
    .form-edit-input-field {
      margin-bottom: 19px;
      .main-input-wrapper {
        width: 100%;
      }
      .main-input-textarea-wrapper {
        width: 100%;
      }
      .main-button-input-container {
        width: 100%;
      }
      .main-money-input-wrapper {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}