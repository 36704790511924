.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #0022ab1a;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 10px;
}

.item{
    display: flex;
    flex-direction: row;
}

.item-tags-selected{
    color: #62d862;
}

.item-sku{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.item-name{
    margin-left: 20px;
    line-clamp: 3; 
    -webkit-box-orient: vertical; 
    text-overflow: ellipsis;
}

.item-img{
    width: 53px;
    display: flex;
    align-items: center;
    cursor: pointer;
    // border: 1px solid lightcoral;

    img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.item-location {
    margin: 0;
    font-family: Ubuntu-Medium;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    display: contents;
    color: #676f8f;
}

.item-detail {
    width: 100%;
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.location-tags-count {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.location-name {
    margin-left: 20px;
    align-content: center;
    width: 60vw;
    align-self: center;
}

.tags-count {
    align-content: center;
    width: 10vw;
    text-align: end;
    color: #62d862;
    font-family: Ubuntu-Medium!important;
    font-size: 18px;
    align-self: center;
}


@media (orientation: portrait) {
  .load-relocation-order-container {

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 10px #0022ab1a;
        box-sizing: border-box;

        border-radius: 10px;
        margin-top: 15px;

        .item-detail {
          max-width: 250px;
        }

    }

    .auditoria-filter-panel-container {
      position: auto;
    }

    .item-detail {
      display: block;
      padding-left: 0px;
      font-size: 12px;

      .item-sku{
        align-items: auto;
        flex-wrap: wrap;
      }
    }

    .item-name {
        margin: 5px 0 0 0;
        flex: 1 1 100%;
    }

    .item-tags-selected {
      margin: 5px 0 0 0;
        flex: 1 1 100%;
    }

    .location-name {
        margin-left: 3px;
        align-content: center;
        width: 70vw;
    }

    .tags-count {
        align-content: center;
        margin-left: 3px;
        width: 80px;
        text-align: start;
        color: #62d862;
        font-family: Ubuntu-Medium !important;
        font-size: 12px;
    }

    .location-tags-count {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
    }
  }
}