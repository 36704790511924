@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.main-app-generic-modal-container {
  font-family: Ubuntu-Medium;
  height: fit-content;
  width: 100% !important;
  max-width: 600px;
  margin-inline: 10px;

  .main-app-generic-modal-wrapper{
    position: fixed;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .main-app-generic-modal-title {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 16px;
    font-weight: normal;
    font-family: Ubuntu-Medium;
    letter-spacing: 0.32px;
    margin: 0 !important;
  }
}

.delete-reception-tags-modal-content {
  font-family: Ubuntu-Medium;
  padding: 15px;
  overflow: auto;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title{
    font-size: 30px;
    letter-spacing: 1.25px;
    color: #494f66;
    text-align: center;
    margin-bottom: 13px;
  }

  .subtitle {
    font-family: Ubuntu-Light;
    font-size: 22px;
    letter-spacing: 0.92px;
    color: #494f66;
    text-align: center;
    padding: 0px 0;
    box-sizing: border-box;
    margin-bottom: 37px;
  }

  .delete-reception-tags-modal-title {
    margin: 20px 0;
    font-family: 'Ubuntu-Light';
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: center;
  }

  .delete-reception-tags-modal-buttons {
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
    color: #3C7AF5;
    display: flex;
    min-width: 300px;
    justify-content: space-evenly;
    align-items: center;

    .btn-cancel, .btn-continue {
      color: white !important;
      background-color: $btn-color-bg-primary;
      border-radius: $btn-border-radius;
      font-weight: bold;
      width: $btn-width;
      margin-top: 0 !important;
    }

    div {
      cursor: pointer;
    }
  }
}

@media (orientation: portrait) {

  .delete-reception-tags-modal-content {
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .title{
      font-size: 16px;
    }

    .subtitle {
      font-size: 14px;
      padding: 0 30px;
    }
  }
}