@import 'assets/styles/variables.scss';

.table-products-ficha-stock-wrapper {
  flex: 1;
  overflow: auto;
  padding: 0 55px;
  .table-products-table {
    margin-top: 10px;
    .available-balance {
      span{

        font-size: 24px;
      }

      .table-products-top-total-bold {
        color: #62D862;
        font-weight: 600;
      }

      margin-bottom: 50px;
    }
  }
  .icon-chevron-derecha {
    color: #3c7af5 !important;
  }
}

@media (max-width: $mobile-width), (max-width: $mobile-height) and (orientation: landscape) {
  .table-products-ficha-stock-wrapper {
    padding: 0 16px;
  }
}
