@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.reception-order-details-container {
  height: 100%;
  overflow: auto;

  .reception-order-details-custom-buttons {
    display: flex;
  }

  .reception-order-details-reject {
    .main-button.enabled {
      background-color: $btn-color-bg-dark;
      width: $btn-width;
    }
  }

  .reception-order-details-approve {
    margin-left: 36px;
    width: $btn-width;
    .main-button.enabled {
      background-color: $btn-color-bg-primary;
      width: $btn-width;
    }
  }

  .reception-order-details-wrapper {
    height: 100%;
    padding: 0 30px 0 66px;
    overflow-y: scroll;

    .reception-order-details-panels {
      display: flex;
    }

    .reception-order-details-panel {
      flex: 1;
      box-sizing: border-box;

      .order-detail-description-panel-container {
        background-color: white;
        padding: 20px;
      }
      .reception-order-details-panel-wrapper {
        margin-bottom: 20px;
        background-color: #ffffff;
        padding: 30px 34px;
        box-sizing: border-box;

        .item-tag-container {
          margin-bottom: 25px;
        }
      }
    }
    .reception-order-details-left {
      flex: 1;
      .reception-order-details-left-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: Ubuntu-Medium;
        color: #676f8f;
        letter-spacing: 0.48px;
        margin-left: 34px;
        height: 60px;

        .reception-order-details-title {
        }
        .pill-wrapper {
          width: 131px;
          margin-left: 15px;
        }
      }
    }
    .reception-order-details-right {
      flex: 1;
      margin-left: 34px;
      width: 40%;

      .reception-order-details-right-title {
        color: #3c7af5;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 60px;
        justify-content: flex-end;
        &.two-elements {
          justify-content: space-between;
        }
        .reception-order-details-download {
          display: flex;
          align-items: center;
        }
        .reception-order-details-download-icon {
          margin-left: 18px;
          font-size: 20px;
          margin-top: -2px;
        }
        .reception-order-details-download-text {
          font-family: Ubuntu-Medium;
        }

        .reception-order-details-init-movement {
          margin-left: 33px;
        }
      }
    }
  }
  .reception-order-details-init-movement {
    background-color: $btn-color-bg-primary;
    width: $btn-width;
    height: $btn-height;
    border-radius: $btn-border-radius;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: $btn-font;
    font-size: $btn-font-size;
    cursor: pointer;
  }
}

@media (orientation: portrait) {

  .reception-order-details-container {
    height: 100%;

    .reception-order-details-custom-buttons {
      display: flex;
    }

    .reception-order-details-reject {
      .main-button.enabled {
        background-color: $btn-color-bg-dark;
        width: $btn-width-short;
      }
    }

    .reception-order-details-approve {
      margin-left: $btn-margin;
      width: $btn-width-short;
      .main-button.enabled {
        background-color: $btn-color-bg-primary;
        width: $btn-width-short;
      }
    }

    .reception-order-details-wrapper {
      height: 100%;
      padding: 15px;
      overflow-y: scroll;

      .reception-order-details-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: unset;
        margin: 0 0 16px 0;

        .reception-order-details-left-title {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: Ubuntu-Medium;
          color: #676f8f;
          letter-spacing: 0.48px;
          margin-left: 0;

          width: 100%;
          justify-content: space-between;

          .reception-order-details-title {
          }
          .pill-wrapper {
            width: auto;
            margin-left: 15px;
          }
        }

        .reception-order-details-right-title {
          color: #3c7af5;
          font-size: 18px;
          align-items: center;
          display: none;
          .reception-order-details-download-icon {
            margin-left: 18px;
            font-size: 20px;
            margin-top: -8px;
          }
          .reception-order-details-download-text {
            font-family: Ubuntu-Medium;
          }
        }
      }

      .reception-order-details-panels {
        display: flex;
        flex-direction: column;
      }

      .reception-order-details-panel {
        flex: 1;
        box-sizing: border-box;
        padding: 17px 11px 1px 11px;

        .order-detail-description-panel-container {
          background-color: white;
          padding: 17px 11px 1px 11px;
        }

        .reception-order-details-panel-wrapper {
          margin-bottom: 20px;
          background-color: #ffffff;
          // padding: 30px 34px;
          padding: 15px;

          box-sizing: border-box;
          .item-tag-container {
            margin-bottom: 20px;
          }
        }
      }
      .reception-order-details-left {
        flex: 1;
      }
      .reception-order-details-right {
        flex: 1;
        width: 100%;
        margin: 0;

        margin-top: 20px;
      }
    }
  }

  .reception-order-details-button {
    // display: block;
    // width: $btn-width;
    margin-top: 17px;
    position: absolute;
    bottom: 0;
    height: 71px;
    background: white;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 6px #00000029;
    font-family: $btn-font;
    font-size: $btn-font-size;

    .reception-order-details-button-inner {
      width: $btn-width-short;
    }
  }
}
