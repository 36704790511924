@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.edit-device-container{
    height: 100%;
    overflow: auto;
    .edit-device-content-wrapper{
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: auto;
        .edit-device-inner{
            width: 350px;
            padding: 30px;

            form{
                background: white;
                padding: 21px;
                .edit-device-form-content{
                    .edit-device-form-field{
                        margin-bottom: 10px;

                        .main-input-container .main-input-wrapper{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .edit-device-title{
            color: #494F66;
            font-size: 16px;
            font-family: Ubuntu-Medium;
            font-weight: normal;
            margin-bottom: 15px;
        }
        .edit-device-button-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
            .edit-device-button{
                 width: $btn-width;
                height: $btn-height;
                border-radius: $btn-border-radius;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                color: #FFFFFF;
                font-size: $btn-font-size;
                font-family: $btn-font;

                background-color: $btn-color-bg-primary;
                cursor: pointer;

                &.disabled{
                    background-color: $btn-color-disabled;
                    pointer-events: none;
                }}
        }
    }
}

@media (orientation: portrait) {

    .edit-device-container{
        height: 100%;
        position: relative;
        .edit-device-content-wrapper{
            //padding: 15px;
            margin-bottom: 60px;
            .edit-device-title{
                color: #494F66;
                font-size: 16px;
                font-family: Ubuntu-Medium;
                font-weight: normal;
                margin-bottom: 15px;
            }
            .edit-device-button-wrapper{
                margin-top: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 6px #00000029;
                background-color: #FFFFFF;
                .edit-device-button{
                     width: $btn-width-short;
                    height: $btn-height;
                    border-radius: $btn-border-radius;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #FFFFFF;
                    font-size: $btn-font-size;
                    font-family: $btn-font;

                    background-color: $btn-color-bg-primary;
                    cursor: pointer;

                    &.disabled{
                        background-color: $btn-color-disabled;
                    }}
            }
        }
    }
}