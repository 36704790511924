@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.percentage-bar-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-end;
}
.percentage-bar-container {
  width: 100%;
  height: 2px;
  background-color: #858ba5;
  display: flex;
  align-items: center;
  margin-right: 22px;
}

.percentage-bar-inner {
  width: 100%;
  background-color: #62d862;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.percentage-bar-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #62d862;
}

.percentage-bar-number {
  font-family: Ubuntu-Light;
  margin: 0;
}
