@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.add-client-container{
    height: 100%;
    overflow: auto;
    .add-client-content{

        width: 100% ;
        height: 100%;
        overflow: auto;
        padding: 70px 15px 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .add-client-wrapper{
            width: 350px;
            .add-client-step-wrapper{
                margin-top: 15px;

            }
        }
    }
}

@media (orientation: portrait) {

    .add-client-container{
        .add-client-content{
            overflow: auto;

            width: 100% ;
            height: 100%;
            padding: 22px 22px;
            display: block;

            box-sizing: border-box;
            margin-bottom: 71px;
            .add-client-wrapper{
                width: 100%;
                .add-client-step-wrapper{
                    margin-top: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
