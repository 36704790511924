@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.delivered-orders-card-container {
  width: 100%;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-card-title {
  color: #494f66;
  font-family: Ubuntu-Medium;
  font-size: 21px;
  margin: 0;
  font-weight: normal;
}

.most-selled-item {
  display: flex;
  margin-bottom: 18px;
  align-items: flex-end;
}

.most-selled-item-left {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 22px;
}
.most-selled-item-left-first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 22px;
  flex: 1;
}
.most-selled-item-position {
  font-family: Ubuntu;
  font-size: 16px;
  color: #494f66;
  margin-right: 7px;
}

.most-selled-item-name {
  font-family: Ubuntu-Light;
  font-size: 16px;
  color: #494f66;
  margin-right: 22px;
}

.most-selled-item-name-first {
  font-family: Ubuntu-Light;
  font-size: 24px;
  color: #494f66;
  margin-bottom: 15px;
}

.most-selled-item-bar {
  flex: 1;
}

.most-selled-item-right {
  display: flex;
  align-items: center;
  width: 67px;
}

.most-selled-item-number {
  font-family: Ubuntu-Medium;
  font-size: 20px;
  color: #3c7af5;
}

.most-selled-item-number-first {
  font-family: Ubuntu-Light;
  font-size: 50px;
  color: #62d862;
}

.most-selled-footer {
  display: flex;
  justify-content: flex-end;
  font-family: Ubuntu-Light;
  color: #3c7af5;

  a:active {
    color: #3c7af5;
    text-decoration-line: none;
    text-decoration: none;
  }
  a:visited {
    color: #3c7af5;
    text-decoration: none;
    text-decoration-line: none;
  }
  p {
    margin: 0;
    cursor: pointer;
  }
}

@media (orientation: portrait) {

  .dashboard-card-title {
    font-size: 16px;
    margin: 0 0 10px 0;
  }

  .most-selled-item {
    display: flex;
    margin-bottom: 18px;
    align-items: flex-end;
  }

  .most-selled-item-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
    margin-right: 12px;
    flex: 1;
  }
  .most-selled-item-left-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 12px;
    flex: 1;
  }
  .most-selled-item-position {
    font-family: Ubuntu;
    font-size: 14px;
    color: #494f66;
    margin-right: 7px;
  }

  .most-selled-item-name {
    font-family: Ubuntu-Light;
    font-size: 14px;
    color: #494f66;
    margin-right: 22px;
    margin-bottom: 15px;
  }

  .most-selled-item-name-first {
    font-family: Ubuntu-Light;
    font-size: 18px;
    color: #494f66;
    margin-bottom: 15px;
  }

  .most-selled-item-bar {
    flex: 1;
  }

  .most-selled-item-right {
    display: flex;
    align-items: center;
    width: 40px;
  }

  .most-selled-item-number {
    font-family: Ubuntu-Medium;
    font-size: 20px;
    color: #3c7af5;
  }

  .most-selled-item-number-first {
    font-family: Ubuntu-Light;
    font-size: 40px;
    color: #62d862;
  }
}
