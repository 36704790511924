@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.configTag-list-container{
    height: 100%;
    overflow: auto;
    .table-configTags-outter-wrapper{
        height: 100%;
    }
    .table-configTags-wrapper{
        height: 80%;
        overflow: auto;
        padding: 15px 56px;
        .configTag-no-configTags-content{
            margin-top: 44px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;



            img{}
            .configTag-no-configTags-title{
                width: 274px;
                text-align: center;
                margin-bottom: 44px;
                color: #A9B1D1;
                font-size: 16px;
                font-family: 'Ubuntu-Medium';
                letter-spacing: 0.67px;
            }
            .configTag-create-button{
                width: $btn-width;
                height: $btn-height;
                text-align: center;
                background: $btn-color-bg-primary;
                border-radius: $btn-border-radius;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $btn-font-size;
                font-family: $btn-font;
                color: white;
                cursor: pointer;
            }

        }
        .configTag-list-content{
            .configTag-list-search-wrapper{
                width: 100%;
                box-shadow: 0 2px 10px #0022ab1a;
                border-radius: 10px;
                background: white;
                padding: 25px 32px;
                box-sizing: border-box;
                margin-top: 15px;
                margin-bottom: 31px;
                .simple-search-wrapper{
                    width: 300px;
                }
            }
            .configTag-list-table{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                width: 100%;

                .generic-table-container{
                    width: 100%;
                }
                .table-pagination{
                    margin-top: 30px;
                }
            }

        }
    }
}

@media (orientation: portrait) {

    .configTag-list-container{
        .table-configTags-wrapper{
            padding: 15px;
            .configTag-no-configTags-content{
                img{}
                .configTag-no-configTags-title{}
                .configTag-create-button{
                  width: $btn-width-short;
                  border-radius: $btn-border-radius;
                  font-size: $btn-font-size;
                  font-family: $btn-font;
                }

            }
            .configTag-list-content{
                .configTag-list-table{
                    padding-bottom: 60px;
                }

            }
        }
    }
}