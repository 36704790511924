@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.provider-description-field {
  margin-bottom: 16px;
  width: 280px;
}
@media (orientation: portrait) {

  .provider-description-field {
    margin-bottom: 16px;
    width: 280px;
  }
}
