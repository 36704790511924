.MuiTreeItem-label > li {
  padding: 0;
  max-height: 2rem;
}

.autocomplete-empty {
  & > div > .MuiInputBase-root {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.autocomplete {
  & input {
    margin-top: 4px;
    margin-left: 2px;
  }
}
