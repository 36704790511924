@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";
@import "../../../assets/fontello/css/fontello.css";


.tags-reader-container {
  //Contenedor Principal
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 680px;

  .tags-reader-content {
    //Contenendor Secundario
    width: 100%;

    .device-selector-root {
      //Raíz del lector
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tags-reader-field {
      //Contenedor campo de texto manual
      padding: 31px 30px;
      background: white;
      margin-top: 20px;
      border-radius: 10px;

      .associate-tags-title {
        //Titulo del buscador manual
        color: #676f8f;
        font-family: Ubuntu-Medium;
        font-size: 18px;
        letter-spacing: 0.36px;
        margin-bottom: 18px;
      }

      .load-movement-items-input {
        //Formulario del input de busqueda
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .main-input-container {
          //Contenedor del Input
          margin-right: 26px;
          width: 100%;

          .main-input-wrapper {
            //Paquete del Input
            width: 100%;
          }

        }

      }

      .auxiliar-container {
        padding: 4px 6px;
      }

    }

  }

  @media (orientation: portrait) {

    //Contenedor principal
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
    padding: 18px;
    border-radius: 10px;
    background: white;

    .tags-reader-content {
      //Contenedor secundario

      .device-selector-root {
        //Raíz del lector
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .tags-reader-field {
        //Contenedor campo de texto manual
        padding: 0;
        background: none;

        .associate-tags-title {

          font-size: 14px;
        }

        .load-movement-items-input {
          display: block;

          //Formulario del input de busqueda
          svg {
            display: none;
          }

        }

        .auxiliar-container {
          padding: 0;
        }

      }

    }

  }

}