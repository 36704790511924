@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.associate-tags-container {
  height: 100%;

  .associate-tags-content {
    display: flex;
    flex: 1 1;
    overflow: auto;
    width: 100%;
    justify-content: center;

    .associate-tags-wrapper {
      position: relative;

      text-align: center;
      flex-grow: 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      .associate-tags-wizard {
        margin-top: 56px;
        width: 326px;
      }

      .associate-tags-step .content {
        margin-top: 53px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .load-movement-items-buttons-wrapper {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        gap: $btn-margin;

        .load-movement-items-button {
          width: $btn-width;
          height: $btn-height;
          color: white;
          border-radius: $btn-border-radius;
          justify-content: center;
          display: flex;
          align-items: center;
          font-family: $btn-font;
          font-size: $btn-font-size;

          &.load-movement-items-clean, &.load-movement-items-cancel {
            background-color: $btn-color-bg-dark;
            cursor: pointer;
            // margin: 0 5px 0 0;
            // padding: 0 8px;
          }

          &.load-movement-items-send {
            background-color: $btn-color-bg-primary;
            cursor: pointer;
            // margin-right: 5px;
            // padding: 0 8px;
          }
        }
      }

      .associate-tags-button {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 17px;

        .main-button {
          width: $btn-width;
        }

        &.last {
          .main-button {
            font-size: $btn-font-size;
            font-family: $btn-font;

            &.enabled {
              background-color: $btn-color-bg-primary;
              cursor: pointer;
              width: $btn-width;
            }
          }
        }
      }
    }

    .error-category-modal-container {
      .main-app-generic-modal-container {
        .main-app-generic-modal-wrapper {
          .main-app-generic-modal-modal-container {
            height: auto !important;
            max-height: 95vh;

            .error-category-modal-wrapper {
              overflow-y: auto;
            }
          }
        }
      }
    }

    .associate-tags-wrapper-finish {
      width: 100%;
      height: 100%;
      display: flex;

      .associate-tags-wrapper-finish-container {
        margin: 23px;
        background: white;
        box-shadow: 0 2px 10px #00225b1a;
        border-radius: 10px;
        padding-top: 155px;
        flex: 1;
        box-sizing: border-box;
        justify-content: center;
        display: flex;

        .load-finished-container {
          justify-content: unset;
        }
      }
    }
  }
}

@media (orientation: portrait) {

  .main-app-generic-modal-modal-container{
    max-width: 80vw !important;
    .error-category-modal-text {
      max-width: 279px;
      overflow-x: auto;
    }
  }
  .associate-tags-container {
    .associate-tags-content {
      .associate-tags-wrapper {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        // margin-bottom: 69px;
        .associate-tags-wizard {
          margin-top: 16px;
          width: 76%;
        }

        .associate-tags-step .content {
          margin-top: 12px;
        }

        .load-movement-items-buttons-wrapper {
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          position: fixed;
          box-shadow: 0 0 6px #00000029;

          bottom: 0;
          height: 71px;
          left: 0;
          right: 0;
          background: white;
          margin-top: 0;
          align-items: center;
          padding: 0 10px;

          .load-movement-items-button {
            width: $btn-width-short;
            height: $btn-height;
            color: white;
            border-radius: $btn-border-radius;
            justify-content: center;
            display: flex;
            align-items: center;
            font-family: $btn-font;
            font-size: $btn-font-size;

            &.load-movement-items-clean, &.load-movement-items-cancel {
              background-color: $btn-color-bg-dark;
              cursor: pointer;
              margin: 0;
            }

            &.load-movement-items-cancel {
              width: $btn-width-short;
            }

            &.load-movement-items-send {
              background-color: $btn-color-bg-primary;
            }
          }
        }

        .associate-tags-button {
          background-color: white;
          display: flex;
          justify-content: center;
          bottom: 0;
          left: 0;
          right: 0;
          height: 69px;

          div {
            width: $btn-width;
            margin-top: 17px;
          }

          .main-button {
            width: $btn-width;
          }

          &.last {
            .main-button {
              font-size: $btn-font-size;
              font-family: $btn-font;
              margin-inline: auto;
              width: $btn-width;

              &.enabled {
                background-color: $btn-color-bg-primary;
                cursor: pointer;
              }
            }
          }
        }
      }

      .associate-tags-wrapper-finish {
        width: 100%;
        height: 100%;
        display: flex;

        .associate-tags-wrapper-finish-container {
          margin: 15px;
          box-shadow: unset;
          background: white;
          border-radius: 10px;
          padding-top: 99px;
          flex: 1;
          box-sizing: border-box;
          justify-content: center;
          display: flex;

          .load-finished-container {
            justify-content: unset;
          }
        }
      }
    }
  }
}
