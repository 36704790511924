@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/fontello/css/fontello.css';

.edit-panel-container{
    background-color: white;
    padding:  0 12px 0 12px;
    margin-bottom: 16px;
    box-sizing: border-box;
    .edit-panel-title{
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #3C7AF5;
        letter-spacing: 0.4px;
        margin-bottom: 16px;
        position: relative;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .edit-panel-icon {
            font-size: 20px;
            color: #3c7af5;
            position: absolute;
            top: unset;
            right: 0;
            cursor: pointer;
            &.open {
              transform: unset;
              border-bottom: none;
            }
          }
    }
    .edit-panel-section{
        padding-bottom: 30px;
        .edit-panel-section-title{
        text-transform: uppercase;

        font-size: 14px;
        font-family: Ubuntu-Light;
        color: #2F2E3C;
        letter-spacing: 0.28px;
        }
        .edit-panel-section-content{
            font-size: 14px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        letter-spacing: 0.28px;
        }
    }
}

@media (orientation: portrait) {


    .edit-panel-container{
        .edit-panel-title{
            font-size: 12px;
            font-family: Ubuntu-Medium;
            color: #3C7AF5;
            letter-spacing: 0.24px;
            margin-bottom: 16px;

            position: relative;
            .edit-panel-icon {
                font-size: 16px;
                color: #3c7af5;
                position: absolute;

                &.open {
                  transform: unset;
                  border-bottom: none;
                }
              }
        }
        .edit-panel-section{

            .edit-panel-section-title{
            text-transform: uppercase;
            font-size: 12px;
            font-family: Ubuntu-Light;
            color: #2F2E3C;
            letter-spacing: 0.24px;

            }
            .edit-panel-section-content{
                font-size: 12px;
                font-family: Ubuntu-Medium;
                color: #494F66;
                letter-spacing: 0.24px;
            }
        }
    }
}