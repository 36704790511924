@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.product-modal-container {
  .main-app-generic-modal-container {
    .main-app-generic-modal-modal-container {
      width: 510px;
      height: auto;
      min-height: unset;
    }
  }

  .product-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 48px;
    position: relative;
  }
  .product-modal-wrapper-close {
    color: #676f8f;
    font-size: 20px;
    position: absolute;
    right: 35px;
    top: 35px;
    cursor: pointer;
  }
  .product-modal-wrapper-icon {
    color: red;
    font-size: 60px;
    margin-top: 84px;
  }
  .product-modal-wrapper-content {
  }
  .product-modal-wrapper-button {
    width: 271px;
    background-color: $btn-color-bg-primary;
    color: white;
    border-radius: $btn-border-radius;
    font-family: $btn-font;
    font-size: $btn-font-size;
    text-align: center;
    height: $btn-height;
    display: flex;
    justify-content: center;
    align-items: center;
    .product-modal-wrapper-button-label{
      cursor: pointer;
      input[type="file"] {
        display: none;
      }
    }
  }
  .product-modal-wrapper-footer {
    font-size: 18px;
    font-family: Ubuntu-Light;
    letter-spacing: 0.75px;
    color: #494f66;
    margin-top: 36px;
    margin-bottom: 65px;
    text-align: center;
    .product-modal-wrapper-span {
      color: #3c7af5;
      font-family: Ubuntu-Medium;

      cursor: pointer;
    }
  }
}

@media (orientation: portrait) {

  .product-modal-container {
    .main-app-generic-modal-container {
      .main-app-generic-modal-modal-container {
        width: 90%;
        max-width: 335px;
        height: auto;
        min-height: unset;
      }
    }

    .product-modal-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 39px;
      position: relative;
    }
    .product-modal-wrapper-close {
      color: #676f8f;
      font-size: 13px;
      position: absolute;
      right: 12vw;
      top: 25px;
    }
    .product-modal-wrapper-icon {
      color: red;
      font-size: 48px;
      margin-top: 47px;
    }
    .product-modal-wrapper-content {
    }
    .product-modal-wrapper-button {
      width: $btn-width-short;
      background-color: $btn-color-bg-primary;
      color: white;
      border-radius: $btn-border-radius;
      font-family: $btn-font;
      font-size: $btn-font-size;
      text-align: center;
      height: $btn-height;
      display: flex;
      justify-content: center;
      align-items: center;
      .product-modal-wrapper-button-label{
        cursor: pointer;
        input[type="file"] {
          display: none;
        }
      }
    }
    .product-modal-wrapper-footer {
      font-size: 12px;
      font-family: Ubuntu-Light;
      letter-spacing: 0.5px;
      color: #494f66;
      margin-top: 36px;
      margin-bottom: 48px;
      text-align: center;
      .product-modal-wrapper-span {
        color: #3c7af5;
        font-family: Ubuntu-Medium;

        cursor: pointer;
      }
    }
  }
}
