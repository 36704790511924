@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.generic-table-row {
  box-shadow: 0 1px 5px #0022ab1a;
  width: 100%;

  .generic-table-row-container {
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 32px;
    box-sizing: border-box;
    background: white;
    border-radius: 12px;


    .generic-table-item {
      font-size: 18px;
      font-family: Ubuntu-Light;
      color: #494f66;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: left;
      flex-basis: 150px;
      flex-grow: 1;
      margin-right: 15px;

      // border: 1px solid pink;
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        margin: 0;
      }

      &.pill{
        justify-content: center;
      }

      &:first-child{
        font-family: Ubuntu-Medium;
        flex-basis: 80px;
      }
    }


    .item-table-button {
      color: #3c7af5;
      font-size: 18px;
      margin-right: 18px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      i {
        transform: rotate(90deg);
      }
    }
  }


  .generic-table-pill-container{
    width: 100%;

    .generic-table-pill{
      border-radius: 14px;
      color: white;
      font-size: 10px;
      font-family: Ubuntu-Bold;
      padding: 2px 9px;
      display: inline;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0.72px;
      text-align: center;

      p{
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        width: 100%;
        min-width: 0;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 7px;
        box-sizing: border-box;
      }

    }
  }

  .generic-table-row-mobile{
    padding: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 7px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 18px;


    .item-table-mobile{
      margin: 0;
      font-family: Ubuntu-Light;
      font-size: 12px;
      word-break: break-all;

      p{
        margin: 0;
        max-width: 83%;
      }

      &:first-child{
        font-family: Ubuntu-Medium;
        font-size: 15px;
      }

      &:nth-child(2){
        font-size: 15px;

      }
      &.pill{
        position: absolute;
        right: 16px;
        top: 14px;

        .generic-table-pill-container{
          .generic-table-pill{
            padding: 0;
          }
        }

      }


    }

  }
}
@media (orientation: portrait) {


  .app-table-item-wrapper {
    box-shadow: 0px 3px 6px #0022AB1A;
    border-radius: 20px;
  }

}