@import '../../../assets/styles/variables';
@import '../../../assets/styles/fonts';

.device-list-container{
  height: 100%;
  .outter-wrapper {
    height: 100%;
    overflow: auto;
  }
  .table-devices-wrapper {
    height: auto;
    .device-no-nodes-content {
      margin-top: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .device-no-nodes-title {
        width: 274px;
        text-align: center;
        margin-bottom: 44px;
        color: #A9B1D1;
        font-size: 16px;
        font-family: 'Ubuntu-Medium';
        letter-spacing: 0.67px;
      }
      .device-create-button {
        width: $btn-width;
        height: $btn-height;
        text-align: center;
        background: $btn-color-bg-primary;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $btn-font-size;
        font-family: $btn-font;
        color: white;
        cursor: pointer;
      }
    }
    .device-list-content {
      .device-list-table {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        .generic-table-container {
          width: 100%;
        }
        .table-pagination {
          margin-top: 30px;
        }
      }
    }
  }
  .device-no-devices-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 100px;
    i {
      font-size: 50px;
      color: #3c7af5;
      margin-bottom: 20px;
    }
    .device-no-devices-title{
      color: #A9B1D1;
      font-family: Ubuntu-Medium;
      font-size: 16px;
      letter-spacing: 0.67px;
      margin: 0;
    }
    .device-create-button{
      height: $btn-height;
      width: $btn-width-short;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: $btn-border-radius;
      font-family: $btn-font;
      font-size: $btn-font-size;
      background-color: $btn-color-bg-primary;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}

@media (orientation: portrait){

  .device-list-container{
    .table-devices-wrapper{
      max-height: calc(100% - 158px);
      padding: 15px;
      overflow-y: scroll;
      padding-bottom: 0px;
      .device-list-content{
        .device-list-table{
          padding-bottom: 60px;
        }
      }
    }
  }
}