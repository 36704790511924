@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

  .user-form-content {
    width: 330px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 26px;
    box-sizing: border-box;

    .user-form-title{
        margin-top: 10px;
        color: #676F8F;
        font-size: 14px;
        font-family: Ubuntu-Medium;
        letter-spacing: 0.28px;
        width: 100%;
        margin-bottom: 22px;
    }
    .user-form-field {
      margin-bottom: 16px;
      width: 100%;

      .main-input-container{
          .main-input-wrapper{
                width: 100%;
          }
      }

      .main-button-input-container{
          width: 100%;
      }

      &.image{
          margin-top: 50px;
      }
    }

    .user-form-submit{
        margin-top: 52px;
        .user-form-submit-button{
            background-color: $btn-color-disabled;
            height: $btn-height;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $btn-border-radius;
            width: $btn-width;

            cursor: pointer;
            color: white;
            font-family: $btn-font;
            font-size: $btn-font-size;
            &.enabled{
                background-color: $btn-color-bg-primary;
            }
        }
    }
  }
@media (orientation: portrait) {

    .user-form-content {
        width: 100%;
      .user-form-field {
        margin-bottom: 16px;
      }

      .user-form-submit{
        margin-top: 0;
        background-color: white;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        height: 69px;
        box-shadow: 0px 0px 6px #00000029;

        .user-form-submit-button{
            background-color: $btn-color-disabled;
            height: $btn-height;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $btn-width;
            border-radius: $btn-border-radius;
            font-family: $btn-font;
            font-size: $btn-font-size;

            &.edit{
                width: $btn-width-short;
            }

            &.enabled{

            }
        }
    }
    }
  }
