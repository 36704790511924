@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

.add-embalaje-container{
    height: 100%;
    overflow: auto;
    .add-embalaje-content{
        overflow: auto;
        width: 100% ;
        height: 100%;
        padding: 70px 15px 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .add-embalaje-wrapper{
            width: 350px;
            .add-embalaje-step-wrapper{
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .add-embalaje-bottom{
        .add-embalaje-wizard-step-one-field{
            width: 100%;
        }
    }
    .add-embalaje-success{
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin: 22px;
        height: 100%;

        .load-finished-general-container{
            width: 400px;
        }
    }
}
@media (orientation: portrait) {

    .add-embalaje-container{
        .add-embalaje-content{
            overflow: auto;

            width: 100% ;
            height: 100%;
            padding: 22px 22px;
            display: block;

            box-sizing: border-box;
            margin-bottom: 71px;
            .add-embalaje-wrapper{
                width: 100%;
                .add-embalaje-step-wrapper{
                    margin-top: 15px;
                }
            }
        }

        .add-embalaje-success{
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            margin: 22px;
            height: 100%;

            .load-finished-general-container{
                width: 260px;
            }
        }
    }
}
