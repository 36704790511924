@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.load-multiple-products-container {
    height: 100%;
  .load-multiple-products-content {
    display: flex;
    justify-content: center;

    overflow: auto;
    // margin: 23px;
    // background: white;
    // box-shadow: 0 2px 10px #00225B1A;
    // border-radius: 10px;
    height: 100%;
    box-sizing: border-box;
    .load-multiple-products-wrapper1 {
      // width: 409px;
      display: flex;
      flex-direction: column;
      flex-basis: 409px;
    //   justify-content: center;
      align-items: center;
      margin-top: 173px;
      .load-multiple-products-icon {
        color: #3c7af5;
        font-size: 59px;
      }
      .load-multiple-products-title {
        font-family: Ubuntu-Medium;
        font-size: 22px;
        color: #494f66;
        letter-spacing: 0.92px;
        text-align: center;
        margin-top: 19px;
      }
      .load-multiple-products-links {
        margin-top: 61px;
        .load-multiple-products-link {
          position: relative;
          width: 272px;

          margin-bottom: 37px;

          .load-multiple-products-link-number {
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            background-color: #3c7af5;
            color: white;
            text-align: center;
            left: -31px;
            top: 2px;
            font-family: Ubuntu-Medium;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .load-multiple-products-link-text {
            font-family: Ubuntu-Light;
            font-size: 18px;
            color: #676f8f;
            letter-spacing: 0.75px;

            text-align: left;
            .load-multiple-products-link-span {
              color: #3c7af5;
              cursor: pointer;
              font-family: Ubuntu-Medium;

              input[type="file"] {
                display: none;
              }
            }
          }
        }
      }
    }

    .load-multiple-products-wrapper2 {
      // width: 409px;
      display: flex;
      flex-direction: column;
      flex-basis: 357px;
      align-items: center;
      margin-top: 176px;
      .load-multiple-products-icon {
        color: #3c7af5;
        font-size: 59px;
      }
      .load-multiple-products-title {
        font-family: Ubuntu-Medium;
        font-size: 30px;
        color: #494f66;
        letter-spacing: 0.92px;
        text-align: center;
        margin-top: 19px;
      }
      .load-multiple-products-subtitle {
        font-family: Ubuntu-Light;
        font-size: 22px;
        color: #494F66;
        letter-spacing: 0.92px;
        text-align: center;
        margin-top: 15px;
      }

      .box{
        margin-top: 75px;
      }
      .loader4 {
        position: relative;
        width: 330px;
        height: 10px;

        // top: 45%;
        // top: -webkit-calc(50% - 10px);
        // top: calc(50% - 10px);
        // left: 25%;
        // left: -webkit-calc(50% - 75px);
        // left: calc(50% - 75px);

        background-color: #edeff5;
        border-radius: 10px;
      }

      .loader4:before {
        border-radius: 10px;

        content: "";
        position: absolute;
        background-color: #62d862;
        top: 0px;
        left: 0px;
        height: 10px;
        width: 0px;
        z-index: 0;
        opacity: 1;
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        -webkit-animation: loader4 3s ease-in-out infinite;
        animation: loader4 3s ease-in-out infinite;
      }

      .loader4:after {
        color: #fff;
        font-family: Lato, "Helvetica Neue";
        font-weight: 200;
        font-size: 16px;
        position: absolute;
        width: 100%;
        height: 20px;
        line-height: 20px;
        left: 0;
        top: 0;
      }

      @-webkit-keyframes loader4 {
        0% {
          width: 0px;
        }
        70% {
          width: 100%;
          opacity: 1;
        }
        90% {
          opacity: 0;
          width: 100%;
        }
        100% {
          opacity: 0;
          width: 0px;
        }
      }

      @keyframes loader4 {
        0% {
          width: 0px;
        }
        70% {
          width: 100%;
          opacity: 1;
        }
        90% {
          opacity: 0;
          width: 100%;
        }
        100% {
          opacity: 0;
          width: 0px;
        }
      }
    }
  }
  .load-multiple-products-wrapper3 {
    width: 100%;
    height: 100%;
    display: flex;

    .load-multiple-products-wrapper-finish-container{
      margin: 23px;
      background: white;
      box-shadow: 0 2px 10px #00225B1A;
      border-radius: 10px;
      padding-top: 155px;
      flex: 1;
      box-sizing: border-box;
      justify-content: center;
      display: flex;
      .load-finished-container{
        justify-content: unset;
      }
  }

  }
}

@media (orientation: portrait) {


    .load-multiple-products-container {
        height: 100%;
      .load-multiple-products-content {
        display: flex;
        justify-content: center;
        // margin: 23px;
        // background: white;
        // box-shadow: 0 2px 10px #00225B1A;
        // border-radius: 10px;
        height: 100%;
        box-sizing: border-box;
        .load-multiple-products-wrapper1 {
          // width: 409px;
          display: flex;
          flex-direction: column;
          flex-basis: 274px;
        //   justify-content: center;
          align-items: center;
          margin-top: 81px;
          .load-multiple-products-icon {
            color: #3c7af5;
            font-size: 47px;
          }
          .load-multiple-products-title {
            font-family: Ubuntu-Medium;
            font-size: 16px;
            color: #494f66;
            letter-spacing: 0.67px;
            text-align: center;
            margin-top: 12px;
          }
          .load-multiple-products-links {
            margin-top: 61px;
            .load-multiple-products-link {
              position: relative;
              width: 272px;

              margin-bottom: 33px;
              padding: 0px 20px;
              box-sizing: border-box;

                margin-top: 24px;

              .load-multiple-products-link-number {
                width: 22px;
                height: 22px;
                border-radius: 22px;
                background-color: #3c7af5;
                color: white;
                text-align: center;
                font-family: Ubuntu-Medium;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: initial;
                float: left;margin-right: 14px;
              }
              .load-multiple-products-link-text {
                font-family: Ubuntu-Light;
                font-size: 14px;
                color: #676f8f;
                letter-spacing: 0.75px;

                text-align: left;
                .load-multiple-products-link-span {
                  color: #3c7af5;
                  cursor: pointer;
                  font-family: Ubuntu-Medium;
                }
              }
            }
          }
        }

        .load-multiple-products-wrapper2 {
          // width: 409px;
          display: flex;
          flex-direction: column;
          flex-basis: 187px;
          align-items: center;
          margin-top: 85px;
          .load-multiple-products-icon {
            color: #3c7af5;
            font-size: 47px;
          }
          .load-multiple-products-title {
            font-family: Ubuntu-Medium;
            font-size: 16px;
            color: #494f66;
            letter-spacing: 0px;
            text-align: center;
            margin-top: 9px;
          }
          .load-multiple-products-subtitle {
            font-family: Ubuntu-Light;
            font-size: 14px;
            color: #494F66;
            letter-spacing: 0px;
            text-align: center;
            margin-top: 9px;
          }

          .box{
            margin-top: 49px;
          }
          .loader4 {
            position: relative;
            width: 187px;
            height: 5px;

            // top: 45%;
            // top: -webkit-calc(50% - 10px);
            // top: calc(50% - 10px);
            // left: 25%;
            // left: -webkit-calc(50% - 75px);
            // left: calc(50% - 75px);

            background-color: #edeff5;
            border-radius: 5px;
          }

          .loader4:before {
            border-radius: 10px;

            content: "";
            position: absolute;
            background-color: #62d862;
            top: 0px;
            left: 0px;
            height: 5px;
            width: 0px;
            z-index: 0;
            opacity: 1;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-animation: loader4 3s ease-in-out infinite;
            animation: loader4 3s ease-in-out infinite;
          }

          .loader4:after {
            color: #fff;
            font-family: Lato, "Helvetica Neue";
            // font-weight: 200;
            position: absolute;
            width: 100%;
            height: 5px;
            line-height: 5px;
            left: 0;
            top: 0;
          }

          @-webkit-keyframes loader4 {
            0% {
              width: 0px;
            }
            70% {
              width: 100%;
              opacity: 1;
            }
            90% {
              opacity: 0;
              width: 100%;
            }
            100% {
              opacity: 0;
              width: 0px;
            }
          }

          @keyframes loader4 {
            0% {
              width: 0px;
            }
            70% {
              width: 100%;
              opacity: 1;
            }
            90% {
              opacity: 0;
              width: 100%;
            }
            100% {
              opacity: 0;
              width: 0px;
            }
          }
        }
      }
      .load-multiple-products-wrapper3 {
        width: 100%;
        height: 100%;
        display: flex;

        .load-multiple-products-wrapper-finish-container{
          margin: 23px;
          background: white;
          box-shadow: 0 2px 10px #00225B1A;
          border-radius: 10px;
          padding-top: 99px;
          flex: 1;
          box-sizing: border-box;
          justify-content: center;
          display: flex;
          .load-finished-container{
            justify-content: unset;
          }
      }

      }
    }

}
