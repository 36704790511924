@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.main-app-generic-modal-modal-container {
  height: fit-content;
  padding: 23px;
  min-height: 210px !important;
  width: 100% !important;
  max-width: 600px;
  margin-inline: 10px;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .main-app-generic-modal-title {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 16px;
    font-weight: normal;
    font-family: Ubuntu-Medium;
    letter-spacing: 0.32px;
    margin: 0 !important;
  }
}

.delete-business-node-modal-content {
  padding: 15px;
  overflow: auto;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .delete-business-node-modal-title {
    margin: 20px 0;
    font-family: 'Ubuntu-Light';
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: center;
  }

  .delete-business-node-modal-buttons {
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
    color: #3C7AF5;
    display: flex;
    justify-content: space-around;

    div {
      cursor: pointer;
    }
  }
}

@media (orientation: portrait) {
}