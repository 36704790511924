.guard-view-mobile {
    width: 100% !important;
    max-width: none !important;
    height: 100%;
    max-height: 85vh;
    overflow: auto;
    padding: 12px 24px !important;
    background-color: #F5F7FF;

    &__sub-header {
        position: absolute;
        left: 0;
        top: 56px;
        box-sizing: border-box;
        width: 100%;
        padding: 13px 35px 13px 15px;
        background-color: #FFFFFF;
        box-shadow: 1px 1px 4px 0px rgba(95, 99, 136, 0.13);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .loader-container {
            top: -56px;
            height: 100vh;
        }
    }

    > section {
        max-height: 85vh;
        margin-top: 90px;
        
        > p {
            margin: 9px 0;
            font-family: Ubuntu-Medium;
            font-size: 14px;
            font-weight: 500;
            color: #494F66;
        }
        .infinite-scroll-component__outerdiv {
            padding-bottom: 20px;
            ::-webkit-scrollbar {
                display: none;
            }
        }
        .infinite-scroll-component {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        .MuiCircularProgress-root {
            margin: 5px auto;
        }
    }

    .not-alerts {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    
        > p {
            font-family: Ubuntu-Medium;
            font-size: 16px;
            font-weight: 500;
            color: #494F66;
        }
    }    
}