.existing-modal-container-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    .title{
        display: block;
        margin-top: 0;
        margin-bottom: 33px;
        color: #3D3935;
    }
    .description{
        font-size: 16px;
        font-family: Roboto;
        text-align: center;
        margin: 0;
    }
}
