@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

@import "../../../assets/fontello/css/fontello.css";

.email-verification-container {
  height: 100%;
}
.email-verification {
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // height: 100vh;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  // padding-top: 18vh;
  padding-top: 18%;
  box-sizing: border-box;

  .logo-container {
    width: 95px;
    height: auto;
  }
  .icon {
    font-size: 90px;
    color: #62d862;
    margin-bottom: 24px;
    margin-top: 42px;
  }
  .title {
    font-size: 35px;
    font-family: Roboto-Medium;
    color: #3c7af5;
    margin-bottom: 28px;
    text-align: center;
    margin-top: 0px;
  }
  .subtitle {
    margin-top: 0;
    font-size: 26px;
    color: #3d3935;
    font-family: Roboto-Light;
    text-align: center;
    margin-bottom: 0;
    .bold {
      font-family: Roboto-Medium;
    }
    margin-bottom: 53px;
  }
  .question-wrapper {
    margin-bottom: 21px;
    .question {
      margin-top: 0;
      padding: 0;
      font-size: 20px;
      color: #3d3935;
      font-family: Roboto-Medium;
      text-align: center;
      margin-bottom: 10px;
    }
    .answer {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      font-size: 15px;
      color: #3d3935;
      font-family: Roboto-Light;
      text-align: center;
    }
  }
  .rounded-button-wrapper {
    box-sizing: border-box;
    width: 250px;
    margin-bottom: 33px;

    .rounded-button {
      width: 100%;
    }
  }
}

@media (orientation: portrait) {

  .email-verification {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 35px;
    box-sizing: border-box;
    margin-top: 42px;

    .logo-container {
      display: none;
    }
    .icon {
      margin-top: 0px;
      margin-bottom: 7px;
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      margin-top: 39px;
      margin-bottom: 0;
      font-family: Roboto-Medium;
      color: #3c7af5;
      margin-top: 0px;
    }

    .subtitle {
      font-size: 16px;
      margin-top: 18px;
      margin-bottom: 77px;
    }

    .question-wrapper {
        margin-bottom: 0;
      .question {
        font-size: 16px;
        margin-bottom: 4px;
      }
      .answer {
        font-size: 12px;
      }
    }

    .rounded-button-wrapper {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      padding: 0;
      margin-bottom: 40px;

      .rounded-button {
        margin-top: 30px;
        width: $btn-width;
        margin-top: 12px;

      }
    }
  }
}
