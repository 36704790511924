@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.two-option-modal-container {
  .main-app-generic-modal-container {
    .main-app-generic-modal-modal-container {
      width: 596px !important;
      max-width: unset;
      height: auto;
      min-height: unset;
    }
  }
  .two-option-modal-wrapper {
    .icon-close-wrapper {
      position: absolute;
      margin-left: 540px;
      margin-top: -60px;
      .icon-close {
        font-size: 13px;
        color: #494f66;
        cursor: pointer;
      }
    }

    padding: 54px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .two-option-modal-title {
      font-family: Ubuntu-Medium;
      font-size: 30px;
      letter-spacing: 1.25px;
      color: #494f66;
      text-align: center;

      margin-bottom: 13px;
    }
    .two-option-modal-text {
      font-family: Ubuntu-Light;
      font-size: 22px;
      letter-spacing: 0.92px;
      color: #494f66;
      text-align: center;

      padding: 0px 0;
      box-sizing: border-box;
      margin-bottom: 37px;
    }
    .two-option-modal-buttons {
      display: flex;

      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .two-option-modal-button {
      font-family: $btn-font;
      font-size: $btn-font-size;
      color: $btn-text-color;
      cursor: pointer;
      width: $btn-width;
      text-align: center;
    }
    .two-option-modal-button-confirm {
      color: white;
      color: $btn-text-color;
      width: $btn-width;
      text-align: center;
      border-radius: $btn-border-radius;
      height: $btn-height;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $btn-font-size;
      font-family: $btn-font;


      &.disabled{
        color: $btn-color-disabled;
      }
    }
  }
}

@media (orientation: portrait) {
.two-option-modal-container {
  .main-app-generic-modal-container {
    .main-app-generic-modal-modal-container {
      width: 90%;
      max-width: 279px;
      height: auto;
      min-height: unset;
    }
  }
  .two-option-modal-wrapper {
    padding: 36px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .icon-close-wrapper {
      position: absolute;
      margin-left: 220px;
      margin-top: -45px;
      .icon-close {
        font-size: 13px;
        color: #494f66;
        cursor: pointer;
      }
    }

    .two-option-modal-title {
      font-family: $btn-font;
      font-size: $btn-font-size;
      letter-spacing: 0.32px;
      color: #494f66;

      margin-bottom: 20px;
    }

    .two-option-modal-text {
      font-family: Ubuntu-Light;
      font-size: 14px;
      letter-spacing: 0.28px;
      color: #676f8f;
      padding: 0px 12px;

      margin-bottom: 25px;
    }
    .two-option-modal-buttons {
      display: flex;
    }
    .two-option-modal-button {
      font-family: $btn-font;
      font-size: $btn-font-size;
      color: $btn-text-color;
      order: 2;
    }

    .two-option-modal-button-confirm {
      color: $btn-text-color;
      order: 1;
    }
  }
}
}
