@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.reception-order-step-three-outer{
  height: 100%;
    width: 100%;
    overflow: auto;
    margin: 40px 73px;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: initial;
}
.reception-order-step-three-container {
  width: 520px;
  .reception-order-step-three-location {
    margin-bottom: 20px;
  }

  .locate-movement-items-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    cursor: pointer;
    .locate-movement-items-title-icon {
      transform: rotate(270deg);
    }
    .locate-movement-items-title {
      margin: 0;
      font-size: 18px;
      font-family: Ubuntu-Medium;
      font-weight: normal;
      margin-left: 13px;
      color: #676f8f;
    }
  }

  .step-container {
    width: 100% !important;
    .step-title-wrapper .step-title {
      font-size: 16px;
    }
  }
}
.reception-order-step-three-content {
  .check-all {
    display: flex;
    align-items: center;
    color: #494f66;
    font-size: 14px;
    font-family: Ubuntu-Light;
    border-bottom: 0.5px solid #d4d5e8;
    padding: 10px 0;
    margin-bottom: 20px;
  }
  .step-title-wrapper .step-title {
    font-size: 16px;
    text-align: left;
  }
  .reception-order-step-title {
    display: flex;
    justify-content: space-between;
    .reception-order-step-title-wrapper {
      font-family: Ubuntu-Light;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #676f8f;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .reception-order-step-three-icon-wrapper {
      cursor: pointer;
    }
  }

  .reception-order-step-dest {
    font-family: Ubuntu-Medium;
    font-size: 18px;
    color: #494f66;

    margin-bottom: 41px;
  }

  .locate-movement-items-button{
    width: 255px;
    margin-top: 30px;
  }
}

@media (orientation: portrait) {

  .reception-order-step-three-outer{
    height: 100%;
      width: 100%;
      overflow: auto;
      margin: 22px 0;
      display: flex;
      justify-content: center;

  }
  .reception-order-step-three-container {
    width: 330px;

    .locate-movement-items-title-wrapper .locate-movement-items-title{
      font-size: 14px;
    }
    .step-container {
      width: 100% !important;
      .step-title-wrapper .step-title {
        font-size: 14px;
      }
    }
  }
  .reception-order-step-three-content {
    .reception-order-step-title {
      display: flex;
      justify-content: space-between;
      .reception-order-step-title-wrapper {
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #676f8f;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      .reception-order-step-edit-icon {
      }
    }

    .reception-order-step-dest {
      font-family: Ubuntu-Medium;
      font-size: 12px;
      color: #494f66;

      margin-bottom: 12px;
    }


  }
  .locate-movement-items-button{
    width: $btn-width;
    .main-button.enabled{
      width: $btn-width;
    }
  }
}
