@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/fonts';
@import '../../../../../../assets/fontello/css/fontello.css';


.load-order-finished-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:25px;
}
.load-order-finished-download-wrapper{
    display: flex;
    align-items: center;
    margin-top: 38px;
}
.laod-order-finished-icon{
    color: #3C7AF5;
    font-size: 20px;
    margin-right: 16px;
}
.load-order-finished-download-label{
    font-family: Ubuntu-Medium;
    font-size: 17px;
    color: #3C7AF5;
}


@media (orientation: portrait) {


    .load-order-finished-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:25px;
    }
    .load-order-finished-download-wrapper{
        display: flex;
        align-items: center;
        margin-top: 28px;
    }
    .laod-order-finished-icon{
        color: #3C7AF5;
        font-size: 14px;
        margin-right: 13px;
    }
    .load-order-finished-download-label{
        font-family: Ubuntu-Medium;
        font-size: 14px;
        color: #3C7AF5;
    }

}