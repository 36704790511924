.modal-alerts-container {
    position: absolute;
    z-index: 100 !important;
}

.modal-alerts-modal-container {
    padding: 0px;
    max-width: 468px;
    @media (orientation: portrait) {
        max-width: none !important;
        border-radius: 0 !important;
        background: transparent !important;
    }
}

.main-app-generic-modal-overlay {
    pointer-events: none !important;
}

.modal-alerts {
    position: relative;
    width: 100%;
    height: 100%;
    @media (orientation: portrait) {
        width: 100vw;
        height: 100vh;
        max-width: none;
        animation: slideInFromRight 0.3s ease-out forwards;
        background-color: #F5F8FE;
    }

    > img { // close icon
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        @media (orientation: portrait) {
            left: 16px;
        }
    }

    header {
        display: flex;
        align-items: center;
        gap: 28px;
        box-shadow: 0px 1.89px 3.21px 0px #2948981C;
        background-color: #EBF2FE;
        border-radius: 15px 15px 0 0;
        padding: 50px 48px 20px 48px;

        > img {
            width: 80px;
            height: 80px;
        }
        
        h4, p, span { margin: 0 }

        > div { // box content text
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        h4 {
            font-family: Ubuntu-Medium;
            font-size: 18px;
            font-weight: 500;
            color: #494F66;
        }
        p {
            font-family: Ubuntu;
            font-size: 14px;
            color: #6D7285;
            display: flex;
            align-items: center;
            gap: 10px;
            > img { opacity: 0.7; }
        }
    }

    &__items {
        box-sizing: border-box;
        padding: 36px 26px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-height: 274px;
        overflow-y: auto;
        @media (orientation: portrait) {
            max-height: calc(100vh - 160px);
        }

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #a6a6a6;
            border-radius: 10px;
        }

        h3, h4, p { margin: 0; }

        > article {
            position: relative;
            border: 1px solid #DCDDE3;
            border-radius: 10px;
            padding: 24px 25px 24px 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            transition: 0.3s;
            cursor: pointer;
            @media (orientation: portrait) {
                background-color: #FFFFFF !important;
            }

            > div:first-of-type {
                display: flex;
                gap: 15px;

                h3, h4 {
                    font-family: Ubuntu-Medium;
                    font-weight: 500;
                    color: #5F6388;
                }
                h3 { font-size: 18px; } // device name

                h4 { // device location
                    font-size: 16px;
                    display: flex;
                    gap: 8px;
                    > img { width: 10px; }
                }
            }

            > div:last-of-type {
                font-family: Ubuntu;
                font-size: 15px;
                font-weight: 400;
                color: #6D7285;
                display: flex;
                gap: 8px;

                > p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > p:first-of-type { max-width: 74px; } // sku
                > p:last-of-type { max-width: 197px; } // rfid
            }

            > img {
                position: absolute;
                top: 50%;
                right: 24px;
                transform: translateY(-50%);
            }

            &:hover {
                background-color: #F5F8FE;
            }
        }
    }

    footer {
        width: 85%;
        margin: 0 auto 36px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        @media (orientation: portrait) {
            display: none;
        }
        
        button {
            width: 136px;
            height: 40px;
            font-size: 14px;
            font-family: Ubuntu-Medium;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #3C7AF5;
            text-align: start;
            cursor: pointer;

            &:disabled {
                opacity: 0.6;
                cursor: default;
            }
        }
    }
}