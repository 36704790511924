.loader-container{
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @keyframes ldio-oktnutaa7e {
        0% { transform: translate(-50%,-50%) rotate(0deg); }
        100% { transform: translate(-50%,-50%) rotate(360deg); }
      }
      .ldio-oktnutaa7e div {
        position: absolute;
        width: 107.88px;
        height: 107.88px;
        border: 13.92px solid #62d862;
        border-top-color: transparent;
        border-radius: 50%;
      }
      .ldio-oktnutaa7e div {
        animation: ldio-oktnutaa7e 1s linear infinite;
        top: 87px;
        left: 87px
      }
      .loadingio-spinner-rolling-lktnp05eknk {
        width: 174px;
        height: 174px;
        display: inline-block;
        overflow: hidden;
        background: none;
      }
      .ldio-oktnutaa7e {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .ldio-oktnutaa7e div { box-sizing: content-box; }
      /* generated by https://loading.io/ */

      .title{
          font-size: 20px;
          font-family: Ubuntu-Medium;
          color: #FFFFFF
      }
}
