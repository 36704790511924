@import "../../../../../../../assets/styles/variables";


.reject-modal-text-area{
margin-bottom: 41px;
    .main-input-textarea-container .main-input-textarea-wrapper{
        width: 322px;
    }
}


@media (orientation: portrait) {

    .reject-modal-text-area{
        margin-bottom: 41px;
        width: 100%;
            .main-input-textarea-container .main-input-textarea-wrapper{
                width: 100%;
            }
        }
}