@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.prep-order-details-container {
  height: 100%;
  overflow: auto;

  .prep-order-details-custom-buttons {
    display: flex;
  }

  .prep-order-details-reject {
    margin-left: 5px;
    .main-button.enabled {
      font-family: $btn-font;
      font-size: $btn-font-size;
      background-color: $btn-color-bg-dark;
      width: $btn-width;
    }
  }

  .prep-order-details-approve {
    margin-left: 36px;
    width: $btn-width;
    .main-button.enabled {
      background-color: $btn-color-bg-primary;
      width: $btn-width;
    }
  }

  .prep-order-details-wrapper {
    height: 100%;
    overflow: auto;
    padding: 0 30px 0 66px;

    .prep-order-details-panels {
      display: flex;
    }

    .prep-order-details-panel {
      flex: 1;
      box-sizing: border-box;

      .order-detail-description-panel-container {
        background-color: white;
        padding: 20px;
      }
      .prep-order-details-panel-wrapper {
        margin-bottom: 20px;
        background-color: #ffffff;
        padding: 30px 34px;
        box-sizing: border-box;

        .item-tag-container {
          margin-bottom: 25px;
        }
        .reason-title {
          font-family: Ubuntu-Medium;
          font-size: 20px;
          letter-spacing: 0.4px;
          color: #676f8f;
          margin-bottom: 9px;
        }
      }
    }
    .prep-order-details-left {
      flex: 1;
      .prep-order-details-left-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: Ubuntu-Medium;
        color: #676f8f;
        letter-spacing: 0.48px;
        margin-left: 34px;
        height: 60px;

        .prep-order-details-title {
        }
        .pill-wrapper {
          width: 131px;
          margin-left: 15px;
        }
      }
    }
    .prep-order-details-right {
      flex: 1;
      margin-left: 34px;
      width: 40%;

      .prep-order-details-right-title {
        color: #3c7af5;
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 60px;
        justify-content: space-between;
        &.two-elements {
          justify-content: space-between;
        }
        .prep-order-details-download {
          display: flex;
        }
        .prep-order-details-download-icon {
          margin-left: 18px;
          font-size: 20px;
          margin-top: -8px;
        }
        .prep-order-details-download-text {
          font-family: Ubuntu-Medium;
        }

        .prep-order-details-init-movement {
          margin-left: 33px;
        }
      }
    }
    .prep-order-order-details-button{
      align-items: right;
    }
  }
  .prep-order-details-init-movement {
    background-color: $btn-color-bg-primary;
    width: $btn-width;
    height: $btn-height;
    border-radius: $btn-border-radius;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: $btn-font;
    font-size: $btn-font-size;
    cursor: pointer;
  }
}

@media (orientation: portrait) {

  .prep-order-details-container
    .prep-order-details-wrapper
    .prep-order-details-left
    .prep-order-details-left-title {
    font-size: 16px;
    font-family: Ubuntu-Medium;

    margin-left: 11px;
    justify-content: space-between;
  }

  .prep-order-details-container {
    height: 100%;

    .prep-order-details-custom-buttons {
      display: flex;
    }

    .prep-order-details-init-movement {
      width: $btn-width-short;
      font-family: $btn-font;
      font-size: $btn-font-size;
      &.verify-movement {
        width: $btn-width;
      }
    }

    .prep-order-details-reject {
      width: $btn-width-short;
      // margin-right: $btn-margin;
      font-family: $btn-font;
      font-size: $btn-font-size;
      .main-button.enabled {
        width: $btn-width-short;
        font-family: $btn-font;
        font-size: $btn-font-size;
      }
    }

    .prep-order-details-approve {
      margin-left: $btn-margin;
      width: $btn-width-short;
      font-family: $btn-font;
      font-size: $btn-font-size;
      .main-button.enabled {
        background-color: $btn-color-bg-primary;
        width: $btn-width-short;
      }
    }

    .prep-order-details-wrapper {
      height: 100%;

      padding: 15px;

      .prep-order-details-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: unset;
        margin: 0 0 16px 0;

        .prep-order-details-left {
          .prep-order-details-left-title {
            display: flex;
            align-items: center;
            font-size: $btn-font-size;
            font-family: $btn-font;
            color: #676f8f;
            letter-spacing: 0.48px;
            margin-left: 0;

            width: 100%;
            justify-content: space-between;

            .prep-order-details-title {
              font-size: 14px;
            }
            .pill-wrapper {
              width: auto;
              margin-left: 15px;
            }
          }
        }

        .prep-order-details-right-title {
          color: #3c7af5;
          font-size: 18px;
          align-items: center;
          display: none;
          .prep-order-details-download-icon {
            margin-left: 18px;
            font-size: 20px;
            margin-top: -8px;
          }
          .prep-order-details-download-text {
            font-family: Ubuntu-Medium;
          }
        }
      }

      .prep-order-details-panels {
        display: flex;
        flex-direction: column;
      }

      .prep-order-details-panel {
        flex: 1;
        box-sizing: border-box;
        padding: 17px 11px 1px 11px;

        .order-detail-description-panel-container {
          background-color: white;
          padding: 17px 11px 1px 11px;
        }

        .prep-order-details-panel-wrapper {
          margin-bottom: 20px;
          background-color: #ffffff;
          // padding: 30px 34px;
          padding: 15px;

          box-sizing: border-box;
          .item-tag-container {
            margin-bottom: 20px;
          }
        }
      }
      .prep-order-details-left {
        flex: 1;
      }
      .prep-order-details-right {
        flex: 1;
        width: 100%;
        margin: 0;

        margin-top: 20px;
      }
    }
  }

  .prep-order-details-button {
    // display: block;
    // width: $btn-width-short;
    margin-top: 17px;
    position: absolute;
    bottom: 0;
    height: 71px;
    background: white;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 6px #00000029;

    .prep-order-details-button-inner {
      width: $btn-width-short
    }
  }
}
