@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.location-detail-container {
  height: 100%;
  overflow: auto;
  .location-detail-content-wrapper {
    padding: 15px;
    overflow: auto;

    .edit-location-toggle {
      margin-top: 20px;
      padding: 0 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 14px;
        span {
          font-family: Ubuntu-Medium;
        }
      }
    }

    .location-panel {
      margin-bottom: 16px;
    }
  }
}

@media (orientation: portrait) {

}
