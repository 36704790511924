@import '../../../assets/styles/fonts';


.item-traceability {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.item-traceability-content {
  padding-top: 12px !important;
  background-color: white;
  width: 100%;

  overflow: hidden;
}

.item-traceability-orden {
  span {
    font-size: 17px !important;
    font-family: Ubuntu-Medium !important;
    color: #494F66;
    letter-spacing: 0.28px;
    text-align: left;
    margin-bottom: 6px;
  }
}

.item-traceability-sm {
  margin-top: 0px;
  margin-bottom: 0px;

  span {
    font-size: 12px !important;
    font-family: Ubuntu-Medium !important;
    color: #676f8f;
    letter-spacing: 0.28px;
    text-align: left;
  }
}

.item-traceability-md {
  span {
    font-size: 14px;
    font-family: Ubuntu-Light !important;
    color: #676f8f;
    letter-spacing: 0.28px;
    text-align: left;
    margin-bottom: 6px;
  }
}

.item-vertical-align {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.item-label {
  align-self: center;
  height: 20px !important;
  margin-top: 30px;
  margin-left: 15px;
}

.item-icon {
  align-self: center;
  margin-bottom: 50px;
}

.item-traceability-grid {
  width: 100%;
  border-bottom: 1px solid #00000029 !important;
  padding: 10px 0;
  padding-bottom: 12px !important;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}