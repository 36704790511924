@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.localization-items-outter {
  overflow: auto;
  height: 100%;

  display: flex;
  justify-content: center;
}
.localization-items-wrapper {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 680px;
  width: 100%;

  .device-selector-root{
    width: 100%;
  }
  .localization-items-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #676f8f;
    font-family: Ubuntu-Light;
    font-size: 12px;
    p {
      margin: 0;
      margin-right: 10px;
    }
    span {
      font-family: Ubuntu-Medium;
    }
  }
  .localization-items-title-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .localization-items-title-icon {
      transform: rotate(270deg);
    }
    .localization-items-title {
      margin: 0;
      font-size: 18px;
      font-family: Ubuntu-Medium;
      font-weight: normal;
      margin-left: 13px;
      color: #676f8f;
    }
  }

  .localization-items-content {
    padding: 31px 30px;
    background: white;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;

    .localization-items-similar-title {
      color: #676f8f;
      font-size: 12px;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      font-family: Ubuntu-Medium;
    }

    .localization-items-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
    }

    .localization-items-input-wrapper {
      .localization-items-input-label {
        color: #676f8f;
        font-family: Ubuntu-Medium;
        font-size: 18px;
        letter-spacing: 0.36px;
        margin-bottom: 18px;
      }
      .localization-items-input {
        display: flex;
        align-items: center;
        .main-input-container {
          margin-right: 26px;
          width: 100%;
        }
        .main-input-container .main-input-wrapper {
          width: 100%;
        }
        .main-input-container .main-input-error {
          margin-top: 0;
        }
      }
    }
    .localization-items-count {
      color: #62d862;
      font-size: 28px;
      font-family: Ubuntu-Medium;
      margin-top: 27px;
      text-align: center;
    }

    .localization-items-list {
      margin-top: 57px;
      width: 100%;
      .item-tag-container {
        margin-bottom: 30px;
      }
    }

    .localization-items-buttons-wrapper {
      display: flex;
      justify-content: center;
      .localization-items-button {
        cursor: pointer;
        font-family: $btn-font;
        font-size: $btn-font-size;
        border-radius: $btn-border-radius;
        width: $btn-width-short;
        height: $btn-height;
        display: flex;
        color: white;
        align-items: center;
        justify-content: center;
      }
      .localization-items-clean {
        background-color: $btn-color-bg-dark;
        margin-right: 0;
      }
      .localization-items-send {
        background-color: $btn-color-bg-primary;
      }
    }
  }
}

@media (orientation: portrait) {

  .localization-items-outter {
    margin-bottom: 100px;
    .localization-items-wrapper {
      margin: 22px 15px;
      display: initial;
      max-width: 90% !important;
      .localization-items-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 12px;
        p {
          margin: 0;
          margin-right: 10px;
        }
        span {
          font-family: Ubuntu-Medium;
        }

        &.similars {
          justify-content: flex-start;
        }
      }
      .localization-items-title-wrapper {
        .localization-items-title {
          font-size: 14px;
        }
      }
      .localization-items-content {
        margin-top: 20px;
        padding: 24px 12px 24px 24px;

        width: unset;

        .localization-items-similar-title {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
        .localization-items-input-wrapper {
          .localization-items-input-label {
            font-size: 14px;
            letter-spacing: 0.28px;
          }

          .localization-items-input {
            .main-input-container {
              margin-right: 13px;
            }
          }
        }

        .localization-items-count {
          text-align: center;
          margin-top: 14px;
        }
        .localization-items-list {
          margin-top: 46px;
        }

        .localization-items-buttons-wrapper {
          position: absolute;
          bottom: 0;
          background-color: white;
          height: 71px;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          padding: 0 35px;
          box-shadow: 0 0 6px #00000029;
          display: flex;
          justify-content: center;

          .localization-items-button {
          }
          .localization-items-clean {
            margin-right: 0;
          }
        }
      }
    }
  }
}
