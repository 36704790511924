@import 'assets/styles/fonts';
@import 'assets/styles/variables';

.image{
  width: 33px;
  height: 33px;
  object-fit: cover;
  border: 0.5px solid #C8CAD1;
  border-radius: 4px;
}

.main-list-collapsable{
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 4px 0px #5F638821;
  margin-bottom: 5px;
  cursor: pointer;
}

.main-list {
  background-color: #FFFFFF;
  box-shadow: 1px 1px 4px 0px #5F638821;
}

.detail {
  h4, p { margin: 0 }

  h4 {
    font-family: Ubuntu-Medium;
    font-size: 12px;
    color: $btn-color-bg-dark;
  }

  p {
    margin-top: 5px;
    font-family: Ubuntu;
    font-size: 10px;
    color: #676F8F;
  }
}

.button-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px 14px 24px;

  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    svg {
      width: 20px;
      color: $btn-text-color;
    }
  }
}

.epc-count{
  margin: 0;
  padding-right: 5px;
  font-family: Ubuntu-Medium;
  font-size: 12px;
  color: $btn-color-bg-dark;

  span {
    font-size: 14px;
    color: $green;
  }
}

.ListItemProductTag_list-epcs {
  list-style: none;
  margin: 0;
  padding: 0;

  h3, p, h5 {
    margin: 0;
  }

  > li {
    padding: 10px 24px;
    border-bottom: 1px solid #DBDCE0;

    &:first-of-type {
      border-top: 1px solid #DBDCE0;
    }

    > h3 {
      font-family: Ubuntu-Medium;
      font-size: 11px;
      color: $btn-color-bg-dark;
    }

    > div {
      display: flex;
      gap: 10px;
      margin-top: 5px;

      > span {
        display: flex;
        align-items: center;
        gap: 5px;

        p {
          font-family: Ubuntu-Medium;
          font-size: 10px;
          color: $btn-color-bg-dark;
        }

        h5 {
          font-family: Ubuntu;
          font-weight: 400;
          font-size: 10px;
          color: #6D7285;
        }
      }
    }
  }
}

.text{
  padding: 10px;
  font-family: Ubuntu-Light !important;
  font-size: 15px !important;
  color: #2f2e3c !important;
  width: 200px;
}

.text-end{
  width: 50px;
  margin-right: 0px;
  margin-left: 15px;
  font-family: Ubuntu-Light !important;
  font-size: 12px !important;
  color: #2f2e3c !important;
  text-align: end;
}

.isNotCollapse {
  background-color: red;
}