@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.stock-reading-table-container {
  .stock-reading-table-mobile {
    display: none;
  }
  .stock-reading-table-header {
    display: flex;
    padding-left: 32px;

    margin-bottom: 25px;
    align-items: center;
    justify-content: flex-end;
    .stock-reading-table-header-item {
      font-family: Ubuntu-Light;
      color: #a9b1d1;
      font-size: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .stock-reading-table-header-icon-span {
        font-size: 6px;
        font-size: 12px;
        margin-left: 8px;

        &.active {
          color: #3c7af5;
        }
        &.down {
          transform: rotate(180deg);
        }
      }
    }
    .stock-reading-table-header-product {
      flex-basis: 97px;
      flex-grow: 1;
    }
    .stock-reading-table-header-sku {
      flex-basis: 173px;
      flex-grow: 1;
    }

    .stock-reading-table-header-tags {
      flex-basis: 206px;
      flex-grow: 1;
      justify-content: flex-end;
      margin-right: 60px;
      padding-right: 14px;
    }
    .stock-reading-table-header-button {
      margin-right: 18px;
      width: 22px;
    }
  }

  .stock-reading-table-content-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .stock-reading-table-no-items {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: #494f66;
      font-size: 30px;
      font-family: Ubuntu-Medium;
      font-weight: 400;
    }
  }
}

@media (orientation: portrait) {

  .stock-reading-table-container {
    .stock-reading-table-header {
      display: none;
    }
    .tag-container {
      .left-container {
        .tag {
          max-width: 60vw;
          .tag-number {
            width: auto;
            .tag-info {
              max-width: 60vw;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .tag-rfid {
            div {
              max-width: 60vw;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

        }
      }
    }
  }
}
