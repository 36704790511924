@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

.add-location-form-container{
    background-color: white;
    padding: 24px;
    border-radius: 6px;
    .add-location-form-content{
        .add-location-form-field{
            margin-bottom: 13px;
            .main-input-container .main-input-wrapper{
                width: 100%;
            }
        }
    }
    .add-location-add-button{
        color: #3C7AF5;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: normal;

            margin-top: 40px;
            margin-bottom: 20px;
            svg{
                margin-right: 6px;
            }
    }
}

@media (orientation: portrait) {

    .add-location-form-container{
        .add-location-form-content{
            margin-bottom: 81px;
        }
    }
}