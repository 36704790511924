@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.relocation-order-details-container {
  height: 100%;

  .relocation-order-details-custom-buttons{
      display: flex;
  }

  .relocation-order-details-reject{
      .main-button.enabled{
          background-color: $btn-color-bg-dark;
            width: $btn-width;

      }
  }

  .relocation-order-details-approve{
      margin-left: 36px;
      width: $btn-width;
      .main-button.enabled{
        background-color: $btn-color-bg-primary;
          width: $btn-width;

    }
  }

  .relocation-order-details-wrapper {
    height: 100%;

    padding: 0 30px 0 66px;

    .relocation-order-details-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;

      .relocation-order-details-left-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: Ubuntu-Medium;
        color: #676f8f;
        letter-spacing: 0.48px;
        margin-left: 34px;


        .relocation-order-details-title {
        }
        .pill-wrapper {
          width: 131px;
          margin-left: 15px;
        }
      }

      .relocation-order-details-right-title {
          color: #3C7AF5;
          font-size: 18px;
          display: flex;
          align-items: center;
          cursor: pointer;
        .relocation-order-details-download-icon {
            margin-left: 18px;
            font-size: 20px;
            margin-top: -8px;
        }
        .relocation-order-details-download-text {
            font-family: Ubuntu-Medium;
        }
      }
      .relocation-order-details-button{
        align-items: right;
        margin-right: 34px;
      }
    }

    .relocation-order-details-panels {
      display: flex;
    }

    .relocation-order-details-panel {
      flex: 1;
      background-color: #ffffff;

      padding: 30px 34px;
      box-sizing: border-box;
    }
    .relocation-order-details-left {
      flex: 1;

    }
    .relocation-order-details-right {
      flex: 1;
      margin-left: 34px;
      width: 40%;
    }
  }

}

@media (orientation: portrait) {

  .relocation-order-details-container .relocation-order-details-wrapper .relocation-order-details-title-wrapper .relocation-order-details-right-title{
    font-size: 14px;
    display: 'flex';
    align-items: 'center';
    width: '100%';
    justify-content: 'flex-start';
    margin-top: '24px';
  }
  .relocation-order-details-container {
    height: 100%;

    .relocation-order-details-custom-buttons{
        display: flex;
    }

    .relocation-order-details-reject{
        .main-button.enabled{
            background-color: $btn-color-bg-dark;
              width: $btn-width-short;

        }
    }

    .relocation-order-details-approve{
        margin-left: $btn-margin;
        width: $btn-width-short;
        .main-button.enabled{
          background-color: $btn-color-bg-primary;
            width: $btn-width-short;

      }
    }

    .relocation-order-details-wrapper {
      height: 100%;

      padding: 15px;

      .relocation-order-details-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: unset;
        margin: 0 0 16px 0;
        flex-direction: column;

        .relocation-order-details-left-title {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: Ubuntu-Medium;
          color: #676f8f;
          letter-spacing: 0.48px;
          margin-left: 0;

          width: 100%;
          justify-content: space-between;


          .relocation-order-details-title {
          }
          .pill-wrapper {
            width: auto;
            margin-left: 15px;
          }
        }

        .relocation-order-details-right-title {
            color: #3C7AF5;
            font-size: 14px;
            align-items: center;
            font-family: Ubuntu-Medium;
            width: 100%;
            margin-top: 20px;


            .relocation-order-details-rec-order-icon {
              margin-left: 18px;
              font-size: 20px;
              margin-top: -8px;
               display: none;

            }
            .relocation-order-details-rec-order-text {
                font-family: Ubuntu-Medium;
              display: none;
              font-size: 14px !important;

            }

          .relocation-order-details-download-icon {
              margin-left: 18px;
              font-size: 20px;
              margin-top: -8px;
            display: none;

          }
          .relocation-order-details-download-text {
              font-family: Ubuntu-Medium;
            display: none;

          }
        }
      }

      .relocation-order-details-panels {
        display: flex;
        flex-direction: column;
      }

      .relocation-order-details-panel {
        flex: 1;
        background-color: #ffffff;

        padding: 17px 11px 1px 11px;
        box-sizing: border-box;
      }
      .relocation-order-details-left {
        flex: 1;
      }
      .relocation-order-details-right {
        flex: 1;
        width: 100%;
        margin: 0;

        margin-top: 20px;
      }
    }
  }

  .relocation-order-details-button {
    // display: block;
    // width: $btn-width-short;
    margin-top: 17px;
    position: absolute;
    bottom: 0;
    height: 71px;
    background: white;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 6px #00000029;
    font-family: $btn-font;
    font-size: $btn-font-size;

    .relocation-order-details-button-inner {
      width: $btn-width-short;
    }
  }

  .relocation-order-details-container .relocation-order-details-wrapper .relocation-order-details-title-wrapper .relocation-order-details-right-title{
    font-size: 14px;
  }

}
