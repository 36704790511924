@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.category-table-item-container{
    display: flex;
    height: 72px;
    border-radius: 0;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .category-table-item-name{
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #494F66;
        flex-basis: 269px;
        flex-grow: 1;
        margin-left: 31px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .category-table-item-button{
        color: #3C7AF5;
        font-size: 19px;
        margin-right: 5px;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            cursor: pointer;
            background: #ECF8FF 0% 0% no-repeat padding-box;
            opacity: 1;
        }

        &.button-delete{
            font-size: 21px;
            margin-right: 18px;
        }
    }
}


@media (orientation: portrait) {

    .category-table-item-container{
        display: flex;
        height: 61px;
        border-radius: 0;
        justify-content: space-between;
        background-color: white;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        .category-table-item-name{
            font-size: 16px;
            font-family: Ubuntu-Medium;
            color: #494F66;
            flex-basis: 269px;
            flex-grow: 1;
            margin-left: 23px;
        }

        .category-table-item-button{
            color: #3C7AF5;
            font-size: 16px;
            margin-right: 4px;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover{
                cursor: pointer;
                background: #ECF8FF 0% 0% no-repeat padding-box;
                opacity: 1;
            }

            &.button-delete{
                font-size: 21px;
                margin-right: 18px;
            }
        }
    }
}