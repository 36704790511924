@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

@import "../../../assets/fontello/css/fontello.css";

.orders-container {
  height: 100%;

  .orders-contet-wrapper {
    height: 100%;
    padding: 10px 28px 0 61px;
    overflow: auto;

    .orders-blue-summary-wrapper {
      margin-bottom: 18px;
    }

    .order-summary-button-wapper {
      margin-bottom: 18px;
    }
  }

@media (orientation: portrait) {
  
    .orders-contet-wrapper {
      max-height: calc(100% - 116px);
      padding: 14px;
      overflow: auto;

      .order-summary-button-wapper {
        width: 100%;
      }
    }
  }
}
