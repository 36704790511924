@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.reprintTag-container {
  height: 100%;
  overflow: auto;
  .color-btn{
    color: #2196f3 !important;
  }
  .reprintTag-content {
    height: 100vh;
    overflow: auto;
    padding: 15px 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .reprintTag-wrapper-search {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-shadow: 0 2px 10px rgba(0, 34, 171, 0.1019607843);
      border-radius: 10px;
      background: white;
      padding: 25px 32px;
      box-sizing: border-box;
      margin-top: 15px;
      margin-bottom: 31px;
    }
    .reprintTag-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .wizard-container {
        width: 350px;
      }
      .reprintTag-step-wrapper {
        margin-top: 15px;
        width: 100%;
      }
    }
  }
  .reprintTag-detail-container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;

    .reprintTag-detail-panel-2-container {
      display: flex;
      justify-content: center;
      width: 45%;
    }

    .reprintTag-detail-panel-1,
    .reprintTag-detail-panel-2 {
      background-color: white;
      padding: 20px;
      width: 50%;
      .reprintTag-detail-section-title1 {
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #676f8f;
        letter-spacing: 0.4px;
        height: 47px;
      }
      .reprintTag-detail-section-title2 {
        text-transform: uppercase;
        font-size: 14px;
        font-family: Ubuntu-Light;
        color: #2f2e3c;
        letter-spacing: 0.28px;
      }
      .reprintTag-section-content {
        font-size: 12px;
        font-family: Ubuntu-Medium;
        color: #494f66;
        letter-spacing: 0.24px;
        padding-bottom: 30px;
      }
    }
    .reprintTag-detail-panel-2 {
      width: 50%;
    }
  }
  .with-table {
    display: flex;
    justify-content: space-between;
    .reprintTag-detail-panel-1-table {
      width: 80%!important;
      padding-right: 30px;

      .generic-table-header:first-child,
      .generic-table-item:first-child {
        flex-basis: 0px;
        max-width: 30px;
      }
      .generic-table-header:nth-child(3),
      .generic-table-item:nth-child(3){
        max-width: 150px;
        p {
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .generic-table-header:nth-child(6),
      .generic-table-item:nth-child(6){
        max-width: 150px;
      }
      .generic-table-header:nth-child(7),
      .generic-table-item:nth-child(7) {
        flex-basis: 0px;
        min-width: 145px;
      }

    }
    .reprintTag-detail-panel-2-container {
      width: 300px;
      .reprintTag-detail-panel-2 {
        width: 100%;
      }
    }
    .MuiTableCell-root {
      font-family: Ubuntu-Light;
      color: #a9b1d1;
      font-size: 15px;
    }
    .MuiTableCell-body {
      color: inherit;
      font-size: 18px;
    }
    .Mui-checked {
      color: #62D862;
    }
  }

  .reprintTag-action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;
    .reprintTag-action-button {
      width: $btn-width;
      height: $btn-height;
      border-color: transparent;
      border-radius: $btn-border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;

      color: #ffffff;
      font-size: $btn-font-size;
      font-family: $btn-font;

      background-color: $btn-color-bg-primary;
      cursor: pointer;

      &.disabled {
        background-color: $btn-color-disabled;
      }
    }
  }
}
@media (orientation: portrait) {
  .reprintTag-container {

    .table-reprintTag-wrapper {
      height: 90vh;
      overflow: auto;
    }

    .import-file-wrapper{
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .reprintTag-content {
      overflow: auto;
      width: 100%;
      height: 100%;
      padding: 0px 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;

      box-sizing: border-box;
      margin-bottom: 71px;



      .reprintTag-wrapper {
        width: 330px;
        .wizard-container {
          width: 100%;
        }
        .reprintTag-step-wrapper {
          margin-top: 15px;
        }
      }
      .reprintTag-detail-container {
        display: block;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;

        .reprintTag-detail-panel-2-container {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 50px;
        }

        .reprintTag-detail-panel-1,
        .reprintTag-detail-panel-2 {
          width: calc(100% - 40px);
          .main-dropdown-option-wrapper {
            background-color: aquamarine;
            // height: 110px;
            height: 17vh;
          }
        }

        .reprintTag-detail-panel-1-table{
          width: 100% !important;
        }

        .reprintTag-detail-panel-1:first-of-type {
          margin-bottom: 15px;
        }
      }
    }
  }
}
