//Colors
$blue: #477CEE;
$lightBlue: #5E93FC;
$grey: #858BA5;
$green: #62D862;
$btn-color-bg-primary: #477CEE;
$btn-color-disabled: #A9B1D1;
$btn-color-bg-dark: #494F66;
$btn-black: #494F66;
$btn-text-color: #3C7AF5;

//Breakpoint;
$mobile-width: 430px;
$mobile-height: 932px;
// $mobile-height: 0;

$desktop-width: 1440px;
$desktop-height: 932px;

// buttons
$btn-border-radius: 22px;
$btn-font: Ubuntu-Bold;
$btn-font-size: 14px;
$btn-width: 191px;
$btn-width-short: 131px;
$btn-margin: 10px;
$btn-height: 36px;

