@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";


.load-products-buttons-wrapper{
    .load-products-button{
        margin-bottom: 24px;
    }
}

@media (orientation: portrait) {

    .load-products-buttons-wrapper{
      padding-top: 10%;
      padding-bottom: 40px;
      overflow-y: auto;

        .load-products-button{
            margin-bottom: 16px;
        }
    }
}