@import '../../../assets/styles/variables';
@import '../../../assets/styles/fonts';

@import '../../../assets/fontello/css/fontello.css';

.reset-password-container{
    height: 100%;
}
.reset-password{
    width: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;

    // height: 100vh;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    // padding-top: 15vh;
    padding-top: 15%;
    box-sizing: border-box;

    form{
        width: 390px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo-container {
        width: 95px;
        height: auto;
    }
    .title{
        font-size: 35px;
        font-family: Roboto-Medium;
        color: #3C7AF5;
        margin-bottom: 28px;
        margin-top: 42px;
        text-align: center;
    }
    .subtitle{
        margin-top: 0;
        font-size: 24px;
        color: #3D3935;
        font-family: Roboto;
        text-align: center;
        margin-bottom: 0;
    }
    .second-subtitle{
        margin-top: 10px;
        font-size: 18px;
        color: #3D3935;
        font-family: Roboto;
        text-align: center;
        margin-bottom: 0;
    }
    .form-container{
        .inputs-wrapper{
            width: 370px;
        }
        .remember-row{
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
            padding-left: 39px;

            button{
                background: transparent;
                border: none;
                color: #477CEE;
                font-size: 14px;
                font-family: Roboto;
            }
        }

    }
    .rounded-button-wrapper{
        padding-left: 34px;
        box-sizing: border-box;
        width: 370px;

        .rounded-button{
            margin-top: 45px;
            width: 100%;
        }
    }

    .form-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 63px;
        width: 100%;
        padding-left: 34px;
        box-sizing: border-box;
        .footer-legend{
            font-size: 20px;
            font-family: Roboto;
            color: #3D3935;
            margin-bottom: 5px;
        }
    }
}

@media (orientation: portrait) {

    .reset-password{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 35px;
        box-sizing: border-box;

        form{
            width: 370px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 35px;
            box-sizing: border-box;
            margin-top: 42px;
        }
        .logo-container {
            display: none;
        }
        .title{
            width: 100%;
            text-align: center;
            font-size: 24px;
            margin-top: 39px;
            margin-bottom: 0;
            font-family: Roboto-Medium;
            color: #3C7AF5;
            display: none;
        }

        .subtitle{
            font-size: 18px;
        }
        .second-subtitle{
            font-size: 14px;
            margin-top: 14px;
        }
        .form-container{
            width: 100%;
            margin-top: 19px;
            .inputs-wrapper{
                width: 100%;
            }
            .remember-row{
                display: flex;
                justify-content: space-between;
                margin-top: 25px;
                padding: 0;
                button{
                    background: transparent;
                    border: none;
                    color: #477CEE;
                    font-size: 14px;
                    font-family: Roboto;
                }
            }

        }
        .rounded-button-wrapper{
            box-sizing: border-box;
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            padding: 0;
            .rounded-button{
                margin-top: 37px;
                width: $btn-width;
            }
        }

        .form-footer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-top: 30px;
            padding: 0;

            .footer-legend{
                font-size: 14px;
                margin-top: 0;
                font-family: Roboto;
                color: #3D3935;
                margin-bottom: 5px;
            }
        }
    }
}