@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

.client-contact-field{
    margin-bottom: 16px;
    width:280px;
}

@media (orientation: portrait) {

    .client-contact-field{
        margin-bottom: 16px;
        width:280px;
    }

}

