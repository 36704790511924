@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.two-option-filter-container{
  .two-option-filter-label{

    font-family: Ubuntu-Medium;
    color: #494F66;
    font-size: 16px;
    margin-bottom: 13px;
  }
  .two-option-filter-options-wrapper{
    display: flex;
    flex-direction: row;

    .two-option-filter-option{
      margin-right: 44px;
      .label{
        font-size: 14px;
        font-family: Ubuntu-light;
        color: #676F8F;
        letter-spacing: 0.28px;
      }

    }
  }
}

@media (orientation: portrait) {
    .dropdown-filter-container {
        width: 100%;
        background-color: white;
        position: unset;
      margin-bottom: 14px;

        border: 1px solid #D4D5E8;
        .dropdown-filter-input {
          border: unset;
          border-radius: 6px;
          height: 66px;
          padding: 12px 17px 0 17px;
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
          &.focused {
          border: unset;

          }

          display: flex;
          flex-direction: column;

          .dropdown-filter-chevron{
              font-size: 8px;
              transform: rotate(180deg);
              color: #3C7AF5;
              position: absolute;
              top: 28px;
              right: 17px;

              &.open{
                  transform: unset;
              }

          }
          .dropdown-filter-label-inner{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 90%;
          }
          .dropdown-filter-label {
              color: #676F8F;
              font-family: Ubuntu-Light;
              font-size: 12px;
              margin-bottom: 10px;

          }

          .dropdown-filter-placeholder {
              color: #494F66;
              font-family: Ubuntu-Medium;
              font-size: 16px;


          }
          .dropdown-filter-selection {
              flex: 1;
              display: flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 95%;
              font-family: Ubuntu-Medium;
              font-size: 16px;
            .dropdown-filter-selected-item {
              background: none;
              height: 22px;
              padding: 4px 7px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 2px;

              font-family: Ubuntu-Medium;
              font-size: 16px;
              color: #494F66;
              letter-spacing: 0.24px;
              margin-right: 7px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              span{
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
              }
              i{
              }
            }
          }
        }

        .dropdown-filter-option-container{
          box-shadow: unset;
          padding: 0 18px 40px 0;

          padding-bottom: 0;

          .dropdown-filter-option-wrapper {

              max-height: unset;

              overflow: auto;

              /* Track */
              &::-webkit-scrollbar-track {
                  background: #EDEFF5;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #62D862;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }

                &::-webkit-scrollbar {
                  width: 5px;
                  border-radius: 10px;
                }
              .dropdown-filter-option {
                  font-family: Ubuntu-Light;
                  color: #494F66;
                  font-size: 14px;
                  letter-spacing: 0.12px;
                  height: 51px;
                  padding-left: 16px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  &:hover{
                      background-color: #FAFAFC;

                  }

                  .checkbox-container{
                      width: 97%;
                  }
                  .label{
                      margin-left: 19px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      width: 100%;
                      text-align: left;
                      cursor: pointer;
                  font-family: Ubuntu-Light;
                  font-size: 14px;

                  }

                  .unchecked{
                      width: 21px;
                  }
                  .button{
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 100%;
                  }

              }
            }

        }

      }
}
