@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.item-container {
  .item-title {
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
  }
  .item-sku {
    color: #676f8f;
    font-size: 16px;
    font-family: Ubuntu-Light;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
  }
  .item-quantity {
    color: #62d862;
    font-size: 14px;
    font-family: Ubuntu-Medium;
    margin-bottom: 7px;
  }
}

@media (orientation: portrait) {

  .item-container {
    .item-title {
      color: #494f66;
      font-family: Ubuntu-Medium;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .item-sku {
      color: #676f8f;
      font-size: 10px;
      font-family: Ubuntu-Light;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .item-quantity {
      color: #62d862;
      font-size: 10px;
      font-family: Ubuntu-Medium;
      margin-bottom: 7px;
    }
  }
}
