.filter-alerts-mobile {
    > button {
        position: relative;
        background: transparent;
        border: none;

        > span {
            position: absolute;
            top: -12px;
            right: -12px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            font-family: Ubuntu-Medium;
            font-size: 9px;
            font-weight: 700;
            color: #FFFFFF;
            background: #3C7AF5;
            display: grid;
            place-content: center;
        }
    }
}