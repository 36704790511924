@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/fonts';

.product-added-item-container{
    display: flex;
    flex-direction: column;
    .product-added-item-wrapper{
        display: flex;
        justify-content: space-between;
    }
    .product-summary-container {
        flex: 1;
        .product-summary-divider{
            display: none;
        }
    }
    .product-added-item-right-col{
        display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        .product-added-item-stock{
            color: #62D862;
            font-size: 16px;
            font-family: Ubuntu-Medium;
            text-align: right;
            margin-bottom: 16px;
        }
        .product-added-item-number-input{

        }

        .product-added-item-delete{
            cursor: pointer;
        }
    }

    .product-added-item-divider{
        width: 100%;
        height: 0.5px;
        background-color: #D4D5E8;
        margin-bottom: 20px;
    }

    &:last-child{
        .product-added-item-divider{
            display: none;
        }
    }
}

@media (orientation: portrait) {


    .product-added-item-container{
        display: flex;
        justify-content: space-between;
        .product-summary-container {

            .product-summary-divider{
                display: none;
            }
            .product-summary-code{
                margin-bottom: 5px;
                font-size: 10px;

            }
            .product-summary-name{
                font-size: 12px;
                margin-bottom: 2px;
            }
            .product-summary-brand{
                font-size: 10px;
                margin-bottom: 2px;
            }

        }
        .product-added-item-right-col{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -15px;
            margin-left: 2px;

            .product-added-item-stock{
                color: #62D862;
                font-size: 12px;
                font-family: Ubuntu-Medium;
                text-align: right;
                margin-bottom: 16px;
            }
            .product-added-item-number-input{

            }

            .product-added-item-delete{
                margin-top: -5px;
            }
        }
    }

}