
.prep-order-table-item-mobile-container{
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 15px #0022AB1A;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 15px;
    height: 117px;
    padding: 15px;

    &.destination{
        height: 137px;
    }

    .prep-order-table-item-mobile-klass{
        font-family: Ubuntu-Medium;
        font-size: 15px;
        letter-spacing: 0.3px;
        color: #2F2E3C;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 7px;

    }

    .prep-order-table-item-mobile-code{
        font-family: Ubuntu-Light;
        font-size: 15px;
        letter-spacing: 0.3px;
        color: #2F2E3C;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 9px;

    }
    .prep-order-table-item-mobile-date{
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #2F2E3C;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 7px;

    }
    .prep-order-table-item-mobile-bottom{
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .prep-order-table-item-mobile-owner{
            font-family: Ubuntu-Light;
            font-size: 12px;
            letter-spacing: 0.24px;
            color: #2F2E3C;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
        
    }
}