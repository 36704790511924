@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";



.table-container{
    .table-mobile{
        display: none;
    }
    .table-header{
        display: flex;
        padding-left: 13px;

        margin-bottom: 25px;
        align-items: center;
        justify-content: flex-end;
        .table-header-item{

            font-family: Ubuntu-Light;
            color: #A9B1D1;
            font-size: 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .table-header-icon-span{
                font-size: 6px;
                font-size: 12px;
                margin-left: 8px;

                &.active{
                    color: #3C7AF5;
                }
                &.down{
                    transform: rotate(180deg);

                }
            }
        }
        .table-header-image{
            width: 53px;
            margin-left: 14px;
        }
        .table-header-checkbox{

        }
        .table-header-name{
            flex-basis: 269px;
            flex-grow: 1;
            margin-left: 31px;

        // border: 1px solid lightcoral;


        }
        .table-header-brand{

        flex-basis: 185px;
        flex-grow: 1;

        // border: 1px solid lightcoral;


        }
        .table-header-code{

        flex-basis: 238px;
        flex-grow: 1;

        // border: 1px solid lightcoral;


        }
        .table-header-category{

        width: 325px;
        text-align: center;
        justify-content: center;

        // border: 1px solid lightcoral;


        }
        .table-header-stock{

        flex-basis: 61px;
        flex-grow: 1;
        margin-right: 30px;
        justify-content: flex-end;

        // border: 1px solid lightcoral;

        }
        .table-header-button{

            margin-right: 18px;
            width: 22px;
        }
    }

    .table-content-item{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0
        }
    }
}


@media (orientation: portrait) {

    .table-container{
    .table-mobile{
        display: unset;
    }
    .table-desktop{
        display: none;
    }

}
}