.modal-new-alert-container {
    position: absolute;
    z-index: 100 !important;
}

.modal-new-alert-modal-container {
    padding: 0px;
    max-width: 468px;
    @media (orientation: portrait) {
        max-width: none !important;
        border-radius: 0 !important;
        background: transparent !important;
    }
}

.main-app-generic-modal-overlay {
    pointer-events: none !important;
}

.modal-new-alert-info {
    position: relative;
    width: 100%;
    height: 100%;
    @media (orientation: portrait) {
        width: 100vw;
        height: 100vh;
        max-width: none;
        animation: slideInFromRight 0.3s ease-out forwards;
    }

    > img { // close icon
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        @media (orientation: portrait) {
            left: 16px;
        }
    }

    header {
        display: flex;
        align-items: center;
        gap: 28px;
        box-shadow: 0px 1.89px 3.21px 0px #2948981C;
        background-color: #EBF2FE;
        border-radius: 15px 15px 0 0;
        padding: 50px 48px 20px 48px;
        @media (orientation: portrait) {
            border-radius: 0;
        }

        > img {
            width: 80px;
            height: 80px;
        }
        
        h4, p, span { margin: 0 }

        > div { // box content text
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        h4 {
            font-family: Ubuntu-Medium;
            font-size: 18px;
            font-weight: 500;
            color: #494F66;
        }
        p {
            font-family: Ubuntu;
            font-size: 14px;
            color: #6D7285;
            display: flex;
            align-items: center;
            gap: 10px;
            > img { opacity: 0.7; }
        }
    }

    &__detail {
        padding: 29px 48px 70px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (orientation: portrait) {
            height: 100%;
            padding: 39px 15px;
            background-color: #F5F8FE;
        }

        h5, h3 { margin: 0 }

        > article {
            display: flex;
            align-items: center;
            gap: 17px;

            > picture {
                position: relative;
                width: 47px;
                height: 47px;
                display: grid;
                place-items: center;
                background-color: #F5F8FE;
                border-radius: 4px;
                @media (orientation: portrait) {
                    background-color: #FFFFFF;
                }
                img {
                    width: 24px;
                }
            }    

            > div {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            h5 {
                font-family: Ubuntu;
                font-size: 15px;
                font-weight: 400;
                color: #6D7285;
            }
            h3 {
                font-family: Ubuntu-Medium;
                font-size: 18px;
                font-weight: 500;
                color: #494F66;
            }
        }
    }

    footer {
        box-sizing: border-box;
        width: 85%;
        margin: 0 auto 36px;
        display: flex;
        justify-content: space-between;
        @media (orientation: portrait) {
            position: absolute;
            bottom: 0;
            height: 77px;
            margin: 0;
            width: 100%;
            padding: 18px 15px 24px;
            background-color: #FFFFFF;
        }
        
        button {
            width: 136px;
            height: 40px;
            font-size: 14px;
            font-family: Ubuntu-Medium;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #3C7AF5;
            text-align: start;
            cursor: pointer;

            &:disabled {
                opacity: 0.6;
                cursor: default;
            }
        }
        // confirm button
        button:last-of-type {
            text-align: center;
            color: #FFFFFF;
            border-radius: 40px;
            background-color: #3C7AF5;
        }
    }
}

.modal-new-alert-not-process {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 55px 48px;
    text-align: center;

    h3, p { margin: 0; }

    h3 {
        font-family: Ubuntu-Medium;
        font-size: 19px;
        font-weight: 500;
        color: #494F66;
    }

    p {
        margin: 25px 0 50px;
        font-family: Ubuntu;
        font-size: 17px;
        font-weight: 400;
        color: #494F66;
    }

    div:last-of-type {
        width: 85%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        
        button {
            width: 136px;
            height: 40px;
            font-size: 16px;
            font-family: Ubuntu-Medium;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #3C7AF5;
            text-align: start;
            cursor: pointer;

            &:disabled {
                opacity: 0.6;
                cursor: default;
            }
        }
    }
}