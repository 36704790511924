@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

.device-detail-container{
    height: 100%;
    overflow: auto;
    .device-detail-content-wrapper{
        padding: 15px;
        overflow: auto;

        .location-panel{
            margin-bottom: 16px;
        }
    }
}

@media (orientation: portrait) {

}