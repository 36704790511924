.pagination-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    > p {
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: 500;
        color: #585D6F;
        
        > input {
            box-sizing: border-box;
            width: 40px;
            font-family: Ubuntu-Medium;
            font-weight: 700;
            background-color: #FFFFFF;
            color: #585D6F;
            padding: 8px 0;
            text-align: center;
            border: 1px solid #DCDDE3;
            border-radius: 4px;
            margin: 0 4px;
        }

        > strong {
            margin-left: 4px;
        }
    }

    > nav {
        margin-left: 12px;
        width: 76px;
        height: 35px;
        background-color: #FFFFFF;
        border-radius: 4px;
        border: 0.87px solid #3C7AF5;
        display: flex;

        > button {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;
            cursor: pointer;

            &:first-of-type > img {
                transform: rotate(180deg);
            }

            &:disabled {
                opacity: 0.5;
            }
        }

    }
}