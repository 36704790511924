@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

.client-list-container {
  height: 100%;

  .outter-wrapper {
    height: 100%;
    overflow-y: scroll;
  }
 .table-client-wrapper {
    height: auto;


    .client-no-client-content {
      margin-top: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .client-no-client-title {
        width: 274px;
        text-align: center;
        margin-bottom: 44px;
        color: #a9b1d1;
        font-size: 16px;
        font-family: "Ubuntu-Medium";
        letter-spacing: 0.67px;
      }

      .client-create-button {
        width: $btn-width;
        height: $btn-height;
        text-align: center;
        background: $btn-color-bg-primary;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $btn-font-size;
        font-family: $btn-font;
        color: white;
        cursor: pointer;
      }
    }

    .client-list-content {
      .client-list-table {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        padding-bottom: 10px;

        .generic-table-container {
          width: 100%;
        }

        .table-pagination {
          margin-top: 30px;
        }
      }
    }
  }
  .client-table-mobile-panel {
    display: none;

    &.opened {
      display: block;
    }
  }
}

@media (orientation: portrait) {

  .client-list-container {
    .outter-wrapper {
      overflow: revert;
    }
      overflow: auto;

    .outter-wrapper{
      overflow: hidden;
    }

    .table-client-wrapper {
      max-height: calc(100% - 158px);
      padding: 15px;
      overflow-y: scroll;
      .client-no-client-content {
        img {
        }
        .client-no-client-title {
        }
        .client-create-button {
          width: $btn-width-short;
          border-radius: $btn-border-radius;
          font-size: $btn-font-size;
          font-family: $btn-font;
        }
      }

    }

    .client-table-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;

      .client-table-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;

        .client-table-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .client-table-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.client-table-active-filter {
          color: #3c7af5;
        }

        .client-table-top-icon {
          font-size: 16px;
          margin-top: 4px;
          margin-left: 3px;


          &.client-table-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }
}
