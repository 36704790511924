@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';


.main-notification-container{
    position: absolute;
    top: 0;
    text-align: center;
    left: 97px;
    right: 0;
    box-shadow: 0 2px 10px #0022AB1A;
    background-color: #EFF7EF;
    z-index: 10000;
    &.error {
        background-color: #FDEDEF;
    }
    .main-notification-content-wrapper{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 65px;
        padding: 0 15px;
        box-sizing: border-box;

        .main-notification-icon{
            font-size: 30px;
            color: #62D862;
            margin-right: 7px;

            rect{
                fill: white
            }
        }

        .main-notification-label{
            font-size: 16px;
            color: #292A31;
            font-family: Ubuntu-Medium;

        }

        .main-notification-icon-close{
            width: 50px;
            right: 0;
            position: absolute;
            color: black;
            cursor: pointer;   
        }
    }
}
@media (orientation: portrait) {
    .main-notification-container{
        box-shadow: 0 1px 4px #00000029;
        // border-bottom: 2px solid #3C7AF5;
        left: 0;
        z-index: 9999;

        .main-notification-content-wrapper{
            height: 60px;
            max-width: 92%;
            padding-right: 23px;

            .main-notification-icon{
                font-size: 20px;
            }

            .main-notification-label{
                font-size: 12px;

            }
        }
    }
}