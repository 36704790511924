.alert-detail-card {
    display: block;
    width: 1290px;
    max-width: 95%;
    overflow: hidden !important;
    margin: 0 auto;
    margin-top: 25px;
    box-shadow: 0px 1.89px 3.21px 0px #2948981C !important;
    border-radius: 15px !important;
    @media (orientation: portrait) {
        margin: 0 auto;
    }

    &__content {
        display: flex;
        gap: 17px;
        padding: 25px 36px 25px 43px !important;
        @media (orientation: portrait) {
            flex-direction: column;
            padding: 2px !important;

            > div:last-of-type {
                margin-bottom: 21px;
            }
        }

        > div { // item
            width: 290px;
            display: flex;
            gap: 17px;
            @media (orientation: portrait) {
                margin-left: 18px;
            }

            > div { // box text
                display: flex;
                flex-direction: column;
                gap: 9px;

                p, h3 {
                    margin: 0;
                    font-family: Ubuntu;
                }
                p {
                    font-size: 15px;
                    color: #6D7285;
                }
                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #494F66;
                    max-width: 256px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .without-comment {
                    color: #A9B1D1;
                }
            }
            picture {
                width: 47px;
                height: 47px;
                background-color: #F5F8FE;
                display: grid;
                place-content: center;
                border-radius: 5px;
                
                img {
                    width: fit-content;
                    height: fit-content;
                }
            }
        }

        .main-app-tooltip > .tooltip-text {
            top: -75px;
            right: -130px;
            width: 343px;
            height: fit-content;
            padding: 17px 20px;
            border-radius: 15px;
            word-wrap: break-word;
            white-space: normal;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: #494F66;

            &::after {
                content: '';
                position: absolute;
                bottom: -22px;
                left: 50%; 
                transform: translateX(-50%);
                border-width: 12px;
                border-style: solid;
                border-color: #FFFFFF transparent transparent transparent;
                filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.1607843137));
            }
        }
    }

    &__status-desk,
    &__status-mobile {
        text-align: center;
        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 600;

        .ALARM, .THEFT, .FALSE_ALARM, .ATTEMPTED_THEFT {
            background: transparent !important;
            border: 2px solid;
        }

        .ALARM {
            color: #3c7af5 !important;
            border-color: #3c7af5;
        }
        
        .THEFT {
            color: #f55d68 !important;
            border-color: #f55d68;
        }
        
        .FALSE_ALARM {
            color: #62d862 !important;
            border-color: #62d862;
        }

        .ATTEMPTED_THEFT {
            color: #ffaf3a !important;
            border-color: #ffaf3a;
        }
    }

    &__status-desk {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        .MuiChip-root {
            height: 24px;
            min-width: 100px;
        }
        .MuiChip-label {
            padding: 5px 20px !important;
        }
    }

    &__status-mobile {
        display: flex;
        justify-content: flex-end;
        border-radius: 15px 15px 0px 0px;
        border: 2px 0px 0px 0px;
        padding: 16px 20px;
        background: #EBF2FE;
        .MuiChip-root {
            min-width: 114px;
            height: 24px;
            font-size: 12px;
        }
    }
}