@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.main-input-textarea-container{
    position: relative;
    .main-input-textarea-overlay{
        width: 100%;
        background: #EDEFF5;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .main-input-textarea-wrapper{
        width: 280px;
        height: 120px;
        border: 1px solid #858BA5;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        background: transparent;
        border-radius: 4px;
        box-sizing: border-box;

        &.focused{
            border: 1px solid #3C7AF5;
        }
        &.disabled{
            border: none;
        }
        &.error{
            border: 1px solid #F55D68;
        }

        .main-input-textarea-label{
            font-family: Ubuntu-Light;
            font-size: 12px;
            color: #A9B1D1;
            margin-top: 10px;
            margin-left: 16px;

            text-align: left;

            &.focused{
                color: #3C7AF5;
            }
            &.error{
                color: #F55D68;
            }
            .main-input-required-label{
                color: #3C7AF5;
            }


            .main-input-textarea-required-label{
                color: #3C7AF5;
            }
        }
        textarea{
            flex: 1;
            border: none;
            background: transparent;
            margin: 5px 13px 0;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            color: #494F66;
            resize: none;

            &:focus{
                border: none;
                outline-width: 0;
            }

        }

        .main-input-textarea-help{
            font-family: Roboto-Light;
            font-size: 10px;
            color: #C7C7C7;
            letter-spacing: 0.2px;
            margin-bottom: 19px;
           margin-right: 13px;
            text-align: right;
        }


    }
    .main-input-textarea-error{
        color: #FC4E55;
        font-size: 12px;
        font-family: Ubuntu-Medium;
        margin-left: 6px;
        margin-top: 7px;
    }
    .main-input-textarea-label-placeholder{
        position: absolute;
        top: 17px;
        color: #A9B1D1;

        font-family: Ubuntu-Light;
        font-size: 16px;
        margin-left: 17px;
        .main-input-textarea-required-label{
            color: #3C7AF5;
        }
    }

}
@media (orientation: portrait) {}