@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';

.checkbox-container{
    .label{
        color: #292A31;
        font-size: 16px;
        font-family: Roboto;
        margin-left: 7px;
        text-transform: none;
    }

    .unchecked{
        width: 18px;
        height: 19px;
        border: 1px solid #D9D9D9;
        border-radius: 5px;

        margin-top: 3px;
    }

    .button{
        border: none;
        background-color: transparent;
        padding: 0;
        justify-content: center;
        display: flex;
        align-items: center;

        &:focus{
            border: none;
            outline-width: 0;
        }
    }
    .icon{
        font-size: 20px;
        color: #62D862;
        margin: 0;


        &::before{
            margin:0
        }
    }
    .icon-disabled{
        font-size: 20px;
        color: #EDEFF5;
        margin: 0;


        &::before{
            margin:0
        }
    }
}
