@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?25042989');
  src: url('../font/fontello.eot?25042989#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?25042989') format('woff2'),
       url('../font/fontello.woff?25042989') format('woff'),
       url('../font/fontello.ttf?25042989') format('truetype'),
       url('../font/fontello.svg?25042989#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?25042989#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-iconos_menu:before { content: '\e800'; } /* '' */
.icon-iconos_mas2:before { content: '\e801'; } /* '' */
.icon-iconos_descargar:before { content: '\e802'; } /* '' */
.icon-vinculartag-2:before { content: '\e803'; } /* '' */
.icon-iconos_movimientos:before { content: '\e80e'; } /* '' */
.icon-iconos_producto:before { content: '\e80f'; } /* '' */
.icon-iconos_succes:before { content: '\e810'; } /* '' */
.icon-iconos_varios_productos:before { content: '\e811'; } /* '' */
.icon-iconos_alerta:before { content: '\e812'; } /* '' */
.icon-iconos_buscar:before { content: '\e813'; } /* '' */
.icon-iconos_buscar-documento:before { content: '\e814'; } /* '' */
.icon-iconos_categorìas:before { content: '\e815'; } /* '' */
.icon-iconos_close:before { content: '\e816'; } /* '' */
.icon-iconos_close2:before { content: '\e817'; } /* '' */
.icon-iconos_dashboard:before { content: '\e818'; } /* '' */
.icon-iconos_documento:before { content: '\e819'; } /* '' */
.icon-iconos_editar:before { content: '\e81a'; } /* '' */
.icon-iconos_eliminar:before { content: '\e81b'; } /* '' */
.icon-iconos_filtros:before { content: '\e81c'; } /* '' */
.icon-iconos_flecha-ordenar:before { content: '\e81d'; } /* '' */
.icon-iconos_imagen:before { content: '\e81e'; } /* '' */
.icon-iconos_mas:before { content: '\e81f'; } /* '' */
.icon-iconos_menos:before { content: '\e820'; } /* '' */
.icon-iconos_menu-hamburguesa:before { content: '\e821'; } /* '' */
.icon-reenviar-email:before { content: '\e822'; } /* '' */
.icon-ojo:before { content: '\e823'; } /* '' */
.icon-mail:before { content: '\e824'; } /* '' */
.icon-candado:before { content: '\e825'; } /* '' */
.icon-check-square:before { content: '\e827'; } /* '' */
.icon-check-circle:before { content: '\e828'; } /* '' */
.icon-avatar:before { content: '\e82a'; } /* '' */
.icon-back:before { content: '\e82b'; } /* '' */
.icon-cruz:before { content: '\e82c'; } /* '' */
.icon-check:before { content: '\e82d'; } /* '' */
.icon-chevron-down:before { content: '\e82e'; } /* '' */
.icon-chevron-derecha:before { content: '\e82f'; } /* '' */
.icon-chevron-izquierda:before { content: '\e830'; } /* '' */
.icon-chevron:before { content: '\e831'; } /* '' */
.icon-contok:before { content: '\e832'; } /* '' */
.icon-shelving:before { content: '\e833'; } /* '' */