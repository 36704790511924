.alert-product-detail-card {
    width: 1290px;
    max-width: 95%;
    overflow: hidden;
    margin: 15px auto 0;
    border-radius: 15px !important;
    box-shadow: 0px 1.89px 3.21px 0px #2948981C !important;

    @media (orientation: portrait) {
        margin-top: 10px;
    }

    header {
        padding: 25px 36px 25px 48px;
        background-color: #EBF2FE;
        border: 3px solid #FFFFFF;
        border-radius: 12px 12px 0px 0px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (orientation: portrait) {
            padding: 17px 20px 21px 20px;
        }

        > h5 {
            margin: 0;
            font-family: Ubuntu-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #494F66;
            @media (orientation: portrait) {
                font-size: 15px;
            }
        }
        > button {
            width: 160px;
            height: 40px;
            border-radius: 40px;
            border: none;
            background-color: #3C7AF5;
            color: #FFFFFF;
            font-family: Ubuntu-Medium;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
            @media (orientation: portrait) {
                display: none;   
            }
        }
    }

    &__content {
        display: flex;
        padding: 45px 196px 60px 43px !important;
        align-items: center;
        gap: 45px;
        @media (orientation: portrait) {
            flex-direction: column;
            padding: 20px !important;
            align-items: normal;
            gap: 25px;
        }
        
        section {
            height: 150px;
            @media (orientation: portrait) {
                height: fit-content;
            }
        }

        &--info {
            display: flex;
            gap: 46px;
            max-width: 60%;
            @media (orientation: portrait) {
                max-width: none;
                gap: 15px;
            }

            > picture {
                display: block;
                width: 160px;
                height: 150px;
                border: 1px solid #DBDCE0;
                border-radius: 5px;
                @media (orientation: portrait) {
                    width: clamp(93px, 20vw, 150px);
                    height: clamp(90px, 20vh, 150px);
                }
                
                > span {
                    width: 100% !important;
                    height:  100% !important;
    
                    > img {
                        object-fit: contain;
                        border-radius: 5px;
                    }
                }
            }

            > div {
                width: 436px;
                max-height: 150px;
                @media (orientation: portrait) {
                    width: fit-content;
                    max-width: 70%;
                    padding-top: 10px;
                }
                
                h3, h4, p {
                    margin: 0;
                }
                h3, h4 {
                    font-family: Ubuntu-Medium;
                    font-weight: 500 !important;
                }
                h3 {
                    font-size: 21px;
                    color: #494F66;
                    @media (orientation: portrait) {
                        font-size: 19px;
                    }
                }
                h4 {
                    margin-top: 8px;
                    font-size: 16px;
                    color: #3C7AF5;
                }
                p {
                    margin-top: 20px;
                    font-family: Ubuntu;
                    font-size: 16px;
                    color: #6D7285;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                }
    
                .tooltip-text {
                    top: -170px;
                    right: -200px;
                    width: 450px;
                    height: fit-content;
                    padding: 30px;
                    border-radius: 15px;
                    word-wrap: break-word;
                    white-space: normal;
                    font-family: Ubuntu-Medium;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                    color: #494F66;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -22px;
                        left: 50%; 
                        transform: translateX(-50%);
                        border-width: 12px;
                        border-style: solid;
                        border-color: #FFFFFF transparent transparent transparent;
                        filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.1607843137));
                    }
                }
            }    
        }

        &--properties {
            margin-left: 114px;
            @media (orientation: portrait) {
                margin-left: 0;
            }

            h3, p {
                margin: 0;
            }
            &__prop {
                display: flex;
                align-items: start;
                gap: 17px;

                picture {
                    width: 29px;
                    height: 29px;
                    border-radius: 50%;
                    background: #EBF2FE;
                    display: grid;
                    place-content: center;
                }
                
                > div {
                    > p {
                        font-family: Ubuntu-Medium;
                        font-size: 14px;
                        color: #5F6388;
                    }
                    > h3 {
                        margin-top: 9px;
                        font-family: Ubuntu-Bold;
                        font-size: 14px;
                        color: #363852;
                    }
                }
            }
        }
    }
}