@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.edit-provider-container {
  height: 100%;
  overflow: auto;
  .edit-provider-content-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: auto;
    .edit-provider-inner {
      width: 350px;
      // padding: 15px;

      .edit-provider-toggle {
        margin-top: 20px;
        padding: 0 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          color: #676f8f;
          font-family: Ubuntu-Light;
          font-size: 14px;
          span {
            font-family: Ubuntu-Medium;
          }
        }
      }
      form {
        padding: 21px;
        background-color: white;
        margin: 20px 0;
        border-radius: 10px;
        .edit-provider-form {
          .provider-description-field {
            width: 100%;
            .main-input-container .main-input-wrapper {
              width: 100%;
            }
          }

          .provider-contact-field {
            width: 100%;
            .main-input-container .main-input-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
    .edit-provider-title {
      color: #494f66;
      font-size: 16px;
      font-family: Ubuntu-Medium;
      font-weight: normal;
      margin-bottom: 15px;
    }
    .edit-provider-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
      background-color: white;
      .edit-provider-button {
        width: $btn-width;
        height: $btn-height;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: #ffffff;
        font-size: $btn-font-size;
        font-family: $btn-font;

        background-color: $btn-color-bg-primary;
        cursor: pointer;

        &.disabled {
          background-color: $btn-color-disabled;
        }
      }
    }
  }
}

@media (orientation: portrait) {

  .edit-provider-container {
    height: 100%;
    position: relative;
    .edit-provider-content-wrapper {
      .edit-provider-inner {
        .edit-provider-toggle {
          margin-top: 20px;
          padding: 0 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            color: #676f8f;
            font-family: Ubuntu-Light;
            font-size: 12px;
            span {
              font-family: Ubuntu-Medium;
            }
          }
        }
        form {
          padding: 0;
          padding-bottom: 100px;
          background-color: transparent;
        }
      }
      // padding: 15px;

      .edit-provider-form {
        background: white;
        padding: 25px;
        border-radius: 10px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 100px;
        }

        .provider-description-field {
          width: 100%;
          .main-input-container .main-input-wrapper {
            width: 100%;
          }
        }

        .provider-contact-field {
          width: 100%;
          .main-input-container .main-input-wrapper {
            width: 100%;
          }
        }
      }
      .edit-provider-title {
        color: #494f66;
        font-size: 16px;
        font-family: Ubuntu-Medium;
        font-weight: normal;
        margin-bottom: 15px;
      }
      .edit-provider-button-wrapper {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #00000029;

        .edit-provider-button {
          width: $btn-width-short;
          height: $btn-height;
          border-radius: $btn-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;

          color: #ffffff;
          font-size: $btn-font-size;
          font-family: $btn-font;

          background-color: $btn-color-bg-primary;
          cursor: pointer;

          &.disabled {
            background-color: $btn-color-disabled;
          }
        }
      }
    }
  }
}
