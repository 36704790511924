@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.edit-user-container {
  height: 100%;
  overflow: auto;

  .edit-user-content-wrapper {
    overflow: auto;
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .edit-business-button {
      cursor: pointer;
    }

    .toggle-panel-container {
      width: 100%;
    }

    .disable-user-container {
      display: flex;
      align-items: center;
      width: 330px;
      justify-content: space-between;

      .disable-user-label {
        font-family: Ubuntu-Light;
        font-size: 12px;
        color: #676f8f;

        .disable-user-label-span {
          font-family: Ubuntu-Medium;
        }
      }
    }
  }
}

@media (orientation: portrait) {


  .edit-user-container {
    height: 100%;
    overflow: auto;

    .edit-user-content-wrapper {
      overflow: auto;
      display: flex;
      justify-content: center;
      margin: 20px 0;

      .edit-business-button {
        cursor: pointer;
      }

      .toggle-panel-container {
        width: 100%;
      }

      .disable-user-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .disable-user-label {
          font-family: Ubuntu-Light;
          font-size: 12px;
          color: #676f8f;

          .disable-user-label-span {
            font-family: Ubuntu-Medium;
          }
        }
      }
    }

  }
}