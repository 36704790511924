.notification-alert {
      box-sizing: border-box;
      position: absolute;
      z-index: 1000;
      top: 0;
      height: 65px;
      background: #EBF2FE;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 60px;
      box-shadow: 0px 1px 3px 0px #00000029;
      @media (orientation: portrait) {
         width: 100% !important;
         left: 0 !important;
         bottom: 0 !important;
         top: auto;
         height: 96px;
         flex-direction: column;
         justify-content: flex-start;
         align-items: start;
         padding: 0 30px;
      }

   > div {
      display: flex;
      align-items: center;
      gap: 14px;
   > p {
      font-family: Ubuntu;
      font-size: 16px;
      color: #292A31;
   }
   }
   
   > button {
      text-transform: uppercase;
      background: transparent;
      border: none;
      font-family: Ubuntu-Medium;
      font-size: 16px;
      font-weight: 500;
      color: #3C7AF5;
      cursor: pointer;
      @media (orientation: portrait) {
         margin-left: 25px;
      }
   }
}
