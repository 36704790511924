@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.role-form-content {
  width: 600px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 26px;
  box-sizing: border-box;
  box-shadow: 0px 1px 15px #0022ab1a;

  .role-form-title {
    margin: 0;
    color: #676f8f;
    font-size: 14px;
    font-family: Ubuntu-Medium;
    letter-spacing: 0.28px;
    width: 100%;
    margin-bottom: 22px;
  }
  .role-form-field {
    margin-bottom: 16px;
    width: 100%;
  }

  .permisos {
    width: 100%;

    .left {
      .title {
        font-family: Ubuntu-Medium;
        color: #676f8f;
        font-size: 14px;
        letter-spacing: 0.11px;
      }
    }

    th {
      color: #7781ac;
      font-size: 12px;
      font-family: Ubuntu-Light;
      letter-spacing: 0.24px;
      font-weight: 400;
    }

    .scope-th {
      text-align: left;
    }

    .option {
      width: 75px;
      text-align: center;

      .checkbox-container {
        margin-left: 27px;
      }
    }
  }

  .permisos-mobile{
    display: none;
  }
  .role-form-submit {
    margin-top: 52px;
    .role-form-submit-button {
      background-color: $btn-color-disabled;
      height: $btn-height;
      padding: 0 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $btn-border-radius;
      width: $btn-width;

      cursor: pointer;
      color: white;
      font-family: $btn-font;
      font-size: $btn-font-size;
      &.enabled {
        background-color: $btn-color-bg-primary;
      }
    }
  }
}
@media (orientation: portrait) {

  .MuiPaper-rounded{
    border-top: 1px solid #D4D5E8 !important;
  }

  .role-form-content {
    width: 330px !important;
    padding-bottom: 60px !important;
    .role-form-field {
      margin-bottom: 16px;
      .main-input-container {
        .main-input-wrapper {
          width: 100%;
        }

      }
      .main-input-textarea-container .main-input-textarea-wrapper{
        width: 100%;

      }
    }

    .permisos{
      display: none;
      .role-form-field {
        width: 100%;
      }
    }

    .permisos-mobile{
      display: initial;
      width: 100%;
    }

    .role-form-submit {
      margin-top: 0;
      background-color: white;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 69px;
      box-shadow: 0px 0px 6px #00000029;

      .role-form-submit-button {
        background-color: $btn-color-disabled;
        height: $btn-height;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $btn-width-short;
        border-radius: $btn-border-radius;
        font-family: $btn-font;
        font-size: $btn-font-size;

        &.enabled {
        }
      }
    }
  }
}
