@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.orders-request-container {
  height: 100%;
  // overflow: auto;

  // padding: 0 55px;

  .orders-request-table-no-products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
    .orders-request-table-no-products-icon {
      font-size: 50px;
      color: #3c7af5;
      margin-bottom: 20px;
    }
    .orders-request-table-no-products-title {
      font-family: Ubuntu-Medium;
      font-size: 30px;
      color: #494f66;
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
    }
    .orders-request-table-no-products-subtitle {
      font-size: 22px;
      font-family: Ubuntu-Light;
      color: #676f8f;
      margin: 0;
      padding: 0;
    }
  }

  .orders-request-table-filter-container {
    .orders-request-table-filter-wrapper {
      width: 100%;
      box-shadow: 0 2px 10px #0022ab1a;
      border-radius: 10px;
      background: white;
      padding: 25px 32px;
      box-sizing: border-box;
      display: flex;

      .orders-request-table-filters-button {
        font-size: 20px;
        font-family: Ubuntu-Medium;
        color: #3c7af5;
        margin-left: 30px;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        .orders-request-table-filter-icon {
          color: #3c7af5;
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }

    margin-top: 15px;
    margin-bottom: 31px;
  }

  .orders-request-table-table {
    .orders-request-table-top-table {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      align-items: center;
      .orders-request-table-top-total {
        font-family: Ubuntu-Light;
        font-size: 16px;
        color: #494f66;
        text-transform: capitalize;
        .orders-request-table-top-total-bold {
          font-family: Ubuntu-Medium;
        }
      }

      .orders-request-table-top-sort {
        color: grey;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;

        &.orders-request-table-active-filter {
          color: #3c7af5;
        }
        .orders-request-table-top-icon {
          font-size: 11px;
          margin-top: 4px;
          margin-left: 3px;
          &.orders-request-table-chevron-down {
            font-size: 6px;
            margin-top: 4px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .report-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .report-button {
    width: 100px;
    margin-top: 30px;
  }

  .pagination-container {
    margin-top: 26px;
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-end;
  }
  .orders-request-content-container {
    // height: 100%;
    flex: 1;
    overflow: auto;
    padding: 10px 28px 0 61px;
  }
}

@media (orientation: portrait) {

  .orders-request-container {
    .orders-request-content-container {
      padding: 0;
      flex: 1;
      overflow: auto;

      padding: 0 0;
      margin-bottom: 60px;
      padding-bottom: 20px;
    }

    .table-load-products-content {
      .table-load-products-title {
        color: #858ba5;
        font-family: Ubuntu-Medium;
        font-size: 16px;
        width: 280px;
        text-align: center;
        letter-spacing: 0.67px;
        margin-top: 56px;
        margin-bottom: 43px;
      }
    }

    .orders-request-table-filter-container {
      margin-top: 0;
      margin-bottom: 12px;

      .orders-request-table-filter-wrapper {
        width: 100%;
        box-shadow: 0 2px 10px #0022ab1a;
        border-radius: 10px;
        background: white;
        padding: 0 32px;
        box-sizing: border-box;
        display: flex;

        .orders-request-table-filters-button {
          font-size: 20px;
          font-family: Ubuntu-Medium;
          color: #3c7af5;
          margin-left: 30px;
          background: none;
          border: none;
          display: flex;
          align-items: center;
          .orders-request-table-filter-icon {
            color: #3c7af5;
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }
    }

    .orders-request-table-mobile-panel {
      display: none;
      &.opened {
        display: block;
      }
    }

    .orders-request-table-table {
      padding: 0 16px;
      .orders-request-table-top-table {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        padding: 0 0;
        align-items: center;
        .orders-request-table-top-total {
          font-family: Ubuntu-Light;
          font-size: 16px;
          color: #494f66;
          text-transform: none;
          .orders-request-table-top-total-bold {
            font-family: Ubuntu-Medium;
          }
        }

        .orders-request-table-top-sort {
          color: grey;
          font-family: Ubuntu-Medium;
          font-size: 16px;
          display: flex;
          align-items: center;

          &.orders-request-table-active-filter {
            color: #3c7af5;
          }
          .orders-request-table-top-icon {
            font-size: 16px;
          }
        }
      }
    }

    .orders-request-table-no-products-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      text-align: center;
      .orders-request-table-no-products-icon {
        font-size: 33px;
        color: #3c7af5;
        margin-bottom: 20px;
      }
      .orders-request-table-no-products-title {
        font-family: Ubuntu-Medium;
        font-size: 16px;
        color: #494f66;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        width: 250px;
      }
    }

    .report-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .report-button {
      width: 100px;
      margin-top: 30px;
    }
  }
}
