@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/fonts';

.add-embalaje-wizard-step-one-container{
    width: 760px;
    .step-container{
        width: 100%;
    }
    .add-embalaje-wizard-step-one-content{
        .add-embalaje-top{
            display: flex;

            .add-embalaje-right{
                display: flex;
                justify-content: center;
                flex: 1;
            }
            .add-embalaje-palet-options{
                display: flex;
                justify-content: center;
                flex: 1;
                gap: 45px;
                height: 48px;
                align-items: center;
                .checkbox-container{
                    .button{
                        display: flex;
                        align-items: center;

                        .label{
                            color: #A9B1D1;
                            font-family: Ubuntu-light;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .add-embalaje-bottom{
            .add-embalaje-wizard-step-one-field{
                .product-added-list-wrapper{
                    margin-top: 20px;
                    .product-summary-units  {
                        visibility: hidden;
                    }
                }
            }
        }
        .add-embalaje-wizard-step-one-field{
            margin-bottom: 16px;
            width:280px;
        }

        .add-embalaje-wizard-image-label{
            color: #A9B1D1;
            margin-top: 30px;
            margin-bottom: 15px;
        }
        .add-embalaje-wizard-step-one-action-wrapper{

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 26px;
            background-color: #FFFFFF;

            .add-embalaje-wizard-step-one-action-button{
                width: $btn-width;
                height: $btn-height;
                border-radius: $btn-border-radius;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

                color: #FFFFFF;
                font-size: $btn-font-size;
                font-family: $btn-font;

                background-color: $btn-color-bg-primary;
                cursor: pointer;

                &.disabled{
                    background-color: $btn-color-disabled;
                }


            }
        }
    }
}
@media (orientation: portrait) {

    .add-embalaje-wizard-step-one-container{
        width: 100%;
        .add-embalaje-wizard-step-one-content{
            .add-embalaje-top{
                width: 100%;
            }
            .add-embalaje-left{
                width: 100%;
            }
            .add-embalaje-wizard-step-one-field{
                margin-bottom: 16px;
                width:100%;

                .main-input-container{
                    .main-input-wrapper{
                        width: 100%;
                    }
                }
            }
            .add-embalaje-wizard-step-one-action-wrapper{
                margin-top: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 6px #00000029;
                .add-embalaje-wizard-step-one-action-button{
                    width: $btn-width;
                    height: $btn-height;
                    border-radius: $btn-border-radius;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #FFFFFF;
                    font-size: $btn-font-size;
                    font-family: $btn-font;

                    background-color: $btn-color-bg-primary;

                    &.disabled{
                        background-color: $btn-color-disabled;
                    }


                }
            }
        }
    }

}

