@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.simple-search-panel {
  width: 100%;
  box-shadow: 0 2px 10px #0022ab1a;
  border-radius: 10px;
  background: white;
  padding: 25px 32px;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 18px;
  .simple-search-wrapper {
    width: 300px;
  }
}

@media (orientation: portrait) {

  .simple-search-panel {
    box-shadow: none;
    background: none;
    padding: 0;
    margin-top: 0;

    .simple-search-wrapper {
      width: 100%;
    }
  }
}
