@import "../../../../../../../../assets/styles/variables";
@import "../../../../../../../../assets/styles/fonts";

@import "../../../../../../../../assets/fontello/css/fontello.css";


.request-order-step-one-container{
    width: 330px;
    .step-container{
        width: 100% !important;

        .step-title-wrapper .step-title{
            font-size: 16px;
        }

    }
}


@media (orientation: portrait) {

    .request-order-step-two-container{
        width: 330px;
        .step-container{
            width: 100% !important;

            .step-title-wrapper .step-title{
                font-size: 14px;
            }

        }
    }
}