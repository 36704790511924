@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.dashboard-table {
  .dashboard-table-header {
    display: flex;
    padding-left: 13px;

    margin-bottom: 25px;
    align-items: center;
    // justify-content: flex-end;
    .dashboard-table-header-item {
      font-family: Ubuntu-Light;
      color: #a9b1d1;
      font-size: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .dashboard-table-header-icon-span {
        font-size: 6px;
        font-size: 12px;
        margin-left: 8px;

        &.active {
          color: #3c7af5;
        }
        &.down {
          transform: rotate(180deg);
        }
      }
      &.table-header-type {
        flex-basis: 250px;
        flex-grow: 1;
        margin-left: 10px;
      }

      &.table-header-code {
        flex-basis: 320px;
        flex-grow: 1;
        margin-left: 10px;
      }
      &.table-header-date {
        flex-basis: 153px;
        flex-grow: 1;
        // margin-left: 31px;
      }
      &.table-header-owner {
        flex-basis: 226px;
        flex-grow: 1;
        // margin-left: 31px;
      }
      &.table-header-state {
        flex-basis: 125px;
        flex-grow: 1;
        // margin-left: 31px;
      }
    }

    .table-header-action {
      margin-right: 18px;
      width: 22px;
    }
  }
}
