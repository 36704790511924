@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";
.reading-panel-outer {
  .reading-panel-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #0022ab1a;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 10px;

    .reading-panel-top{
      width: 55%;
    }
    .reading-panel-bottom{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
    }
    .reading-panel-left {
      width: 55%;
      .reading-panel-left-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .reading-panel-label {
          color: #676f8f;
          font-family: Ubuntu-Medium;
          font-size: 18px;
          letter-spacing: 0.36px;
        }
        .reading-panel-toggle {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #676f8f;
          font-family: Ubuntu-Light;
          font-size: 14px;
          p {
            margin: 0;
            margin-right: 10px;
          }
          span {
            font-family: Ubuntu-Medium;
          }
        }
      }
      .reading-panel-input {
        display: flex;
        align-items: center;
        .main-input-container {
          width: 100%;
        }
        .main-input-container .main-input-wrapper {
          width: 100%;
        }
        .main-input-container .main-input-error {
          margin-top: 0;
        }
      }
    }
    .reading-panel-center {
      .reading-panel-count {
        font-family: Ubuntu-Medium;
        color: #a9b1d1;
        font-size: 70px;
        margin: 0;

        p{
          margin: 0;
        }
        span {
          color: #62d862;
        }
      }
    }
    .reading-panel-right {
      .reading-panel-reset {
        background: $btn-color-bg-dark;
        border-color: transparent;

        height: $btn-height;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: $btn-border-radius;
        padding: 0 35px;
        font-size: $btn-font-size;
        font-family: $btn-font;
        cursor: pointer;
      }
    }
  }
}
@media (orientation: portrait) {

  .reading-panel-outer {


    .device-selector-root{
      display: flex;
      flex-direction: column;
      align-items: flex-end;

    }
    .reading-panel-toggle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #676f8f;
      font-family: Ubuntu-Light;
      font-size: 14px;
      margin-bottom: 15px;
      margin-right: 4px;
      p {
        margin: 0;
        margin-right: 10px;
      }
      span {
        font-family: Ubuntu-Medium;
      }
    }
    .reading-panel-container {
      flex-direction: column;
      height: auto;
      margin-top: 32px;

      .reading-panel-bottom{
        flex-direction: column;
        margin-top: 0;
      }
      .reading-panel-left {
        width: 100%;
        .reading-panel-left-top {
          .reading-panel-label {
            font-size: 14px;
            letter-spacing: 0.28px;
          }
          .reading-panel-toggle {
            display: none;
            p {
            }
            span {
            }
          }
        }
        .reading-panel-input {
          .main-input-container {
          }
          .main-input-container .main-input-wrapper {
          }
          .main-input-container .main-input-error {
          }
        }
      }
      .reading-panel-center {
        .reading-panel-count {
          font-size: 44px;
          margin: 0;
          margin-top: 10px;
          span {
          }
        }
      }
      .reading-panel-right {
        background-color: red;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #00000029;
        height: 71px;
        .reading-panel-reset {
          width: $btn-width-short;
        }
      }
    }
  }
}
