@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';


.sign-in{
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form{
        width: 370px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logo-container {
        width: 160px;
    }
    .title{
        font-size: 30px;
        font-family: Roboto-Medium;
        color: #3C7AF5;
    }
    .form-container{
        #user{
        }
        .inputs-wrapper{
            width: 370px;
        }
        .remember-row{
            display: flex;
            justify-content: space-between;
            margin-top: 25px;

            button{
                background: transparent;
                border: none;
                color: #477CEE;
                font-size: 14px;
                font-family: Roboto;
                cursor: pointer;
            }
        }

    }
    .rounded-button{
        margin-top: 45px;
        width: $btn-width;
    }
    .form-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 63px;
        .footer-legend{
            font-size: 20px;
            font-family: Roboto;
            color: #3D3935;
            margin-bottom: 5px;
        }
    }
}

@media (orientation: portrait) {
    .sign-in{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 35px;
        box-sizing: border-box;

        form{
            width: 370px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 35px;
            box-sizing: border-box;
        }
        .logo-container {
            display: none;
        }
        .title{
            width: 100%;
            text-align: center;
            font-size: 24px;
            margin-top: 39px;
            margin-bottom: 0;
            font-family: Roboto-Medium;
            color: #3C7AF5;
        }
        .form-container{
            width: 100%;
            .inputs-wrapper{
                width: 100%;
            }
            .remember-row{
                display: flex;
                justify-content: space-between;
                margin-top: 25px;

                button{
                    background: transparent;
                    border: none;
                    color: #477CEE;
                    font-size: 14px;
                    font-family: Roboto;
                }
            }

        }
        .rounded-button{
            margin-top: 30px;
            width: $btn-width-short;
        }
        .form-footer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-top: 30px;

            .footer-legend{
                font-size: 14px;
                margin-top: 0;
                font-family: Roboto;
                color: #3D3935;
                margin-bottom: 5px;

            }
        }
    }
}