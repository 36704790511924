@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.main-input-container{
    position: relative;
    background: white;

    .main-input-overlay{
        width: 100%;
        background: #EDEFF5;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .main-input-wrapper{
        width: 280px;
        height: 56px;
        border: 1px solid #858BA5;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        background: transparent;
        border-radius: 4px;
        box-sizing: border-box;

        &.focused{
            border: 1px solid #3C7AF5;
        }
        &.disabled{
            border: none;
        }
        &.error{
            border: 1px solid #F55D68;
        }
        .main-input-label{
            font-family: Ubuntu-Light;
            font-size: 12px;
            color: #A9B1D1;
            margin-top: 5px;
            margin-left: 16px;

            text-align: left;
            &.focused{
                color: #3C7AF5;
            }
            &.error{
                color: #F55D68;
            }
            .main-input-required-label{
                color: #3C7AF5;
            }
        }
        input{
            flex: 1;
            border: none;
            background: transparent;
            margin-left: 13px;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            color: #494F66;
            margin-right: 10px;
            &:focus{
                border: none;
                outline-width: 0;
            }
        }
    }

    .main-input-error{
        color: #FC4E55;
        font-size: 12px;
        font-family: Ubuntu-Medium;
        margin-left: 6px;
        margin-top: 7px;
    }
    .main-input-label-placeholder{
        position: absolute;
        top: 19px;
        color: #A9B1D1;
        font-family: Ubuntu-Light;
        font-size: 16px;
        margin-left: 17px;
        .main-input-required-label{
            color: #3C7AF5;
        }
    }
}

@supports (-webkit-appearance: none) {

  input[type="date"]{
    overflow: visible;
    margin-right: 10px;
    padding-bottom: 10px;
  }

  .main-input-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(37%) sepia(99%) saturate(2640%) hue-rotate(210deg) brightness(103%) contrast(92%);
    transform: translateY(-20%);
    width: 20px;
    height: 20px;
  }
  .main-input-wrapper.disabled input[type="date"]::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(68%) sepia(6%) saturate(593%) hue-rotate(190deg) brightness(93%) contrast(90%);
  }
}

@media (orientation: portrait) {}
