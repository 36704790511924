@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';

.account-dropdown-container{
    position: relative;
    width: 100%;
    .desktop-dropdown{
        padding: 25px;
        box-shadow: 0 0 24px #00000038;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        z-index: 20;
        top: 0;
        background: white;
        top: 48px;
        .overlay{
            background-color: red;
        }
        }
    .mobile-modal{
        display: none;
    }
    .container{
        box-shadow: 1px 0 10px #00000010;
        border-radius: 10px;
        height: 53px;
        display: flex;
        justify-content: center;
        background-color: #FFFFFF;
        z-index: 21;
        position: relative;
        .content-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon{
                font-size: 25px;
                margin-right: 10px;
                color: #5E93FC;
            }
            .email{
                font-size: 20px;
                font-family: Roboto;
                color: #3D3935;
                padding: 0;
                margin: 0;
            }
        }
        .chevron{
            position: absolute;
            right: 25px;
            display: flex;
            align-items: center;
            height: 53px;
            cursor: pointer;
            font-size: 7px;
            color: #707070;
        }

        .chevron-up{
            position: absolute;
            right: 20px;
            display: flex;
            align-items: center;
            height: 53px;
            cursor: pointer;
            font-size: 10px;
            color: #707070;
        }

    }


}

@media (orientation: portrait) {
    .account-dropdown-container{
        position: initial;
        .container{
            position: initial;
            .content-wrapper{
                .icon{
                    font-size: 20px;
                    color: #8D8D8D;
                }
                .email{
                    font-size: 16px;
                }
            }

            .chevron{
                display: none;
            }

            .chevron-up{
                display: none;
            }
        }
        .mobile-modal{
            display: initial;

            .modal-wrapper-container{
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: space-between;
                .cancel-button{
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        .desktop-dropdown{
            display: none;
        }
    }

}
