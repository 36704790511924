@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.filter-panel-container {
  width: 100%;
  box-shadow: 0 2px 10px #0022ab1a;
  border-radius: 10px;
  background: white;
  padding: 25px 32px;
  box-sizing: border-box;
  .filter-panel-wrapper {
    display: flex;

    .filter-panels-button {
      font-size: 20px;
      font-family: Ubuntu-Medium;
      color: #3c7af5;
      margin-left: 30px;
      background: none;
      border: none;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f1faff;
        cursor: pointer;
      }

      &:focus {
        border: none;
        outline-width: 0;
      }
      .filter-panel-icon {
        color: #3c7af5;
        font-size: 14px;
        margin-right: 5px;

        &.icon-iconos_menos {
          font-size: 3px;
          margin-right: 19px;
          margin-left: 2px;
        }
      }
    }
  }
  .filter-panels-pills {
    display: flex;
    border-top: 1px solid #efefef;
    margin-top: 26px;
    padding-top: 26px;
    cursor: pointer;
    flex-wrap: wrap;
  }
  .filter-panel-pills-delete-all {
    color: #494f66;
    font-size: 16px;
    font-family: Ubuntu-Medium;
    display: flex;
    align-items: center;
    height: 40px;

  }

  .filter-panels-pill {
    background: #ecf8ff;
    height: 40px;
    padding: 4px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-bottom: 14px;

    font-family: Ubuntu-Light;
    font-size: 16px;
    color: #494f66;
    letter-spacing: 0.32px;
    margin-right: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    i {
      color: #3c7af5;
      font-size: 9px;
      margin-left: 12px;
      margin-top: 2px;
    }
  }

  margin-top: 15px;
  margin-bottom: 31px;

  .filter-panel-opened {
    .filter-panel-filter-wrapper {
      display: flex;
      margin-top: 40px;
      .filter-panel-filter {
        width: 280px;
        margin-right: 20px;


      }
    }
    .filter-panel-filter-buttons {
      text-align: right;
      margin-top: 20px;

      .filter-panel-filter-apply {
        background-color: $btn-color-bg-primary;
        font-size: $btn-font-size;
        font-family: $btn-font;
        color: white;
        width: $btn-width;
        border: none;
        height: $btn-height;
        border-radius: $btn-border-radius;

        cursor: pointer;
        &.disabled {
          background-color: #a9b1d1;
          cursor: unset;
        }

        &:focus {
          border: none;
          outline-width: 0;
        }
      }

      .filter-panel-filter-delete {
        background-color: transparent;
        font-size: 14px;
        font-family: Ubuntu-Medium;
        color: #a9b1d1;
        border: none;
        height: $btn-height;
        margin-left: 33px;
        cursor: pointer;

        &:focus {
          border: none;
          outline-width: 0;
        }
      }
    }
  }
}

@media (orientation: portrait) {

  .filter-panel-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 26;
    margin: 0;
    border-radius: 0;
    background-color: #fafafc;
    overflow: auto;

    .filter-panels-pills-title{
      color: #494F66;
      font-size: 12px;
      font-family: Ubuntu-Medium;
      margin: 20px 0 10px 0;
    }
    .filter-panels-pills {
      display: flex;
      border-top: none;
      margin-top: 0;
      padding-top: 0;
      cursor: pointer;
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
    .filter-panel-pills-delete-all {
      color: #494f66;
      font-size: 16px;
      font-family: Ubuntu-Medium;
      display: flex;
      align-items: center;
    }

    .filter-panels-pill {
      background: #ecf8ff;
      height: 25px;
      padding: 4px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;

      font-family: Ubuntu-Light;
      font-size: 12px;
      color: #494f66;
      letter-spacing: 0.32px;
      margin-right: 12px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      i {
        color: #3c7af5;
        font-size: 6px;
        margin-left: 12px;
        margin-top: 0;
      }
    }


    .filter-panel-mobile-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      p{
        margin: 0;
      }

      .filter-panel-mobile-title-text {
        color: #3c7af5;
        font-size: 20px;
        font-family: Ubuntu-Medium;
      }

      .filter-panel-mobile-close {
        color: #3c7af5;
      }
    }
    .filter-panel-filter-wrapper {
      display: flex;
      margin-top: 40px;
      flex-direction: column;
    }

    .filter-panel-filter-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      text-align: unset;
      display: flex;
      height: 71px;
      box-shadow: 0px 0px 6px #00000029;
      background-color: white;
      .filter-panel-filter-apply {
        width: $btn-width-short !important;
        border-radius: $btn-border-radius !important;
        font-size: $btn-font-size !important;
        font-family: $btn-font !important;
      }
    }


    .filter-panel-opened {
      margin-top: 30px;
      .filter-panel-filter-wrapper {
        display: flex;
        margin-top: 40px;
        .filter-panel-filter {
          width: 100%;
          margin-right: 20px;

          .date-range-container{
            margin-bottom: 14px;
          }

          &:last-child{
            .dropdown-filter-container{
              margin-bottom: 75px;
            }
          }
        }
      }

      .filter-panel-filter {
        width: 100%;
        margin-right: 20px;
      }
    }


  }


}
