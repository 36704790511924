@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.auth-mobile-container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  .header-container {
    background: transparent linear-gradient(240deg, #62D062 0%, #60CA6A 8%, #5BBA82 27%, #52A1A9 52%, #477EDE 87%, #467AE6 91%, #467AE6 100%) 0% 0% no-repeat padding-box;
    height: 220px;
    width: 100%;
  }
  .main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 11;
    .placeholder {
      height: 165px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content-container {
      background-color: white;
      border-radius: 65px;
      width: 100%;
      flex: 1;
    }
  }
}

.auth-mobile-container-logo {
  .header-container {
    height: 220px;
  }
  .main {
    .placeholder {
      height: 165px;
      width: 100%;
      flex-basis: 165px;
      flex-shrink: 0;
      .logo{
        width: 81px;
        height: 86px;
      }
    }
  }
}

.auth-mobile-container-header {
  .header-container {
    height: 151px;
  }
  .main {
    .placeholder {
      height: 85px;

      .logo{
        width: 81px;
        height: 86px;
      }
      .title {
        color: #ffffff;
        font-family: Roboto-Medium;
        font-size: 22px;
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        .icon {
          position: absolute;
          left: 35px;
          color: white;
          font-size: 18px;
        }
      }
    }
  }
}
