@import "~assets/styles/variables";


.guard-item-detail {
    background-color: #F5F7FF !important;

    @media (orientation: portrait) {
        position: absolute; 
        top: 0;
        z-index: 110;
        overflow: scroll;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        max-height: 91.8vh;
        padding-bottom: 90px;
        gap: 10px;

        .main-app-generic-modal-modal-container {
            background: transparent;
            max-width: none !important;
            max-height: none !important;
            width: 100vw !important;
            height: 100vh;
            margin: 0;
            padding: 0 !important;
            border-radius: 0;
        }
    }

    .main-app-generic-modal-modal-container {
        max-width: 484px;
        max-height: 90%;
        overflow-y: hidden;
        padding: 32px;
    }

    .alert-process-mobile {
        position: fixed;
        bottom: 8vh;
        width: 100%;
        padding: 18px 0;
        display: flex;
        justify-content: center;
        background-color: #FFFFFF;
        border-bottom: 1px solid #A9B1D1;

        > button {
            min-width: 158px;
            height: 35px;
            padding: 10px 24px;
            border: none;
            border-radius: 34px;
            background-color: #3C7AF5;
            color: #FFF;
            font-family: Ubuntu-Medium;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}

.height-max-viewport {
    max-height: 100vh !important;
}

.header-tittle {
    display: flex;
    font-size: 20px;
    font-family: Ubuntu-Medium;
    color: #FFFFFF;
    padding-left: 1em;

    @media (orientation: portrait) {
        padding-left: 0;
    }
}