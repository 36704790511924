.empty-arc-tags {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;
    > img {
        width: 200px;
    }
    > p {
        max-width: 260px;
        text-align: center;
        font-size: 15px;
    }
}