@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';
@import '../../../../../assets/fontello/css/fontello.css';


.tag-association--finished-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:25px;
}
.tag-association--finished-download-wrapper{
    display: flex;
    align-items: center;
    margin-top: 38px;
}
.tag-association-finished-icon{
    color: #3C7AF5;
    font-size: 20px;
    margin-right: 16px;
}
.tag-association--finished-download-label{
    font-family: Ubuntu-Medium;
    font-size: 17px;
    color: #3C7AF5;
}


@media (orientation: portrait) {


    .tag-association--finished-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:25px;
    }
    .tag-association--finished-download-wrapper{
        display: flex;
        align-items: center;
        margin-top: 28px;
    }
    .tag-association-finished-icon{
        color: #3C7AF5;
        font-size: 14px;
        margin-right: 13px;
    }
    .tag-association--finished-download-label{
        font-family: Ubuntu-Medium;
        font-size: 14px;
        color: #3C7AF5;
    }

}