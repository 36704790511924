@import '../../../../../../assets/styles/fonts';
@import '../../../../../../assets/styles/variables';

.verify-movement-items-outter{
    overflow: auto;
    height: 100%;

}
.verify-movement-items-wrapper{
    margin: 40px 73px;

    .verify-movement-items-header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .verify-movement-items-button-confirm-inner{
            background-color: $btn-color-bg-primary;
            width: $btn-width;
            height: $btn-height;
            color: white;
            border-radius: $btn-border-radius;
            font-family: $btn-font;
            font-size: $btn-font-size;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
        }
    }
    .verify-movement-items-title-wrapper{
        display: flex;
        align-items: center;
        cursor: pointer;
        .verify-movement-items-title-icon{
            transform: rotate(270deg);
        }
        .verify-movement-items-title{
            margin: 0;
            font-size: 18px;
            font-family: Ubuntu-Medium;
            font-weight: normal;
            margin-left: 13px;
            color: #676F8F;
        }
    }

    .verify-movement-items-content{
        padding: 31px 43px;
        background: white;
        margin-top: 21px;
        border-radius: 10px;
        .verify-movement-items-input-wrapper{
            .verify-movement-items-input-label{
                color: #676F8F;
                font-family: Ubuntu-Medium;
                font-size: 18px;
                letter-spacing: 0.36px;
                margin-bottom: 18px;
            }
            .verify-movement-items-input{
                display: flex;
                align-items: center;
                .main-input-container{
                    margin-right: 26px;
                    max-width: 684px;
                    width: 100%;
                }
                .main-input-container .main-input-wrapper{
                    width: 100%;

                }
                .main-input-container .main-input-error{
                    margin-top: 0;
                }
            }
        }
        .verify-movement-items-count{
            color: #62D862;
            font-size: 28px;
            font-family: Ubuntu-Medium;
            margin-top: 27px;

        }

        .verify-movement-items-list{
            margin-top: 0;
            width: 100%;
            max-width: 684px;

        }

        .verify-movement-items-button-wrapper{
            display: flex;
            margin-top: 55px;
            .verify-movement-items-button-inner{
                cursor: pointer;
                font-family: $btn-font;
                font-size: $btn-font-size;
                border-radius: $btn-border-radius;
                width: $btn-width;
                height: $btn-height;
                display: flex;
                color: white;
                align-items: center;
                justify-content: center;
            }
            .verify-movement-items-clean{
                background-color: $btn-color-bg-dark;
                margin-right: 68px;

            }
            .verify-movement-items-send{
                background-color: $btn-color-bg-primary;
            }
        }
    }

}

@media (orientation: portrait) {


    .verify-movement-items-outter{
        overflow: auto;
        height: 100%;

    }
    .verify-movement-items-wrapper{
        margin: 22px 15px;


        .verify-movement-items-header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .verify-movement-items-button-wrapper{
                position: absolute;
                bottom: 0;
                background-color: white;
                height: 71px;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 6px #00000029;
            }
            .verify-movement-items-button-confirm-inner{
                background-color: $btn-color-bg-primary;
                width: $btn-width-short;
                height: $btn-height;
                color: white;
                border-radius: $btn-border-radius;
                font-family: $btn-font;
                font-size: $btn-font-size;
                justify-content: center;
                align-items: center;
                display: flex;
                cursor: pointer;
            }
        }
        .verify-movement-items-title-wrapper{
            display: flex;
            align-items: center;
            .verify-movement-items-title-icon{
                transform: rotate(270deg);
            }
            .verify-movement-items-title{
                margin: 0;
                font-size: 14px;
                font-family: Ubuntu-Medium;
                font-weight: normal;
                margin-left: 13px;
                color: #676F8F;
            }
        }

        .verify-movement-items-content{
            padding: 23px;
            background: white;
            margin-top: 21px;
            border-radius: 10px;
            .verify-movement-items-input-wrapper{
                .verify-movement-items-input-label{
                    color: #676F8F;
                    font-family: Ubuntu-Medium;
                    font-size: 18px;
                    letter-spacing: 0.36px;
                    margin-bottom: 18px;
                }
                .verify-movement-items-input{
                    display: flex;
                    align-items: center;
                    .main-input-container{
                        margin-right: 26px;
                        max-width: 684px;
                        width: 100%;
                    }
                    .main-input-container .main-input-wrapper{
                        width: 100%;

                    }
                    .main-input-container .main-input-error{
                        margin-top: 0;
                    }
                }
            }
            .verify-movement-items-count{
                color: #62D862;
                font-size: 28px;
                font-family: Ubuntu-Medium;
                margin-top: 27px;

            }

            .verify-movement-items-list{
                margin-top: 0;
                width: 100%;
                max-width: 684px;

            }

            .verify-movement-items-button-wrapper{
                position: absolute;
                bottom: 0;
                background-color: white;
                height: 71px;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                padding: 0 35px;
                box-shadow: 0 0 6px #00000029;

                .verify-movement-items-button-inner{

                }
                .verify-movement-items-clean{
                    margin-right: 28px;
                }
            }
        }

    }
}