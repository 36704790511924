@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

.embalaje-detail-container{
    height: 100%;

    .embalaje-detail-content-wrapper{
        height: 100%;
        overflow: auto;
        display: flex;
        justify-content: space-between;
        gap: 25px;
        padding: 35px 50px;
        .panel{
            background-color: white;
            flex: 1;
            padding: 21px 26px;

            .panel-title{
                color: #676F8F;
                font-size: 20px;
                font-family: Ubuntu-Medium;
                letter-spacing: 0.4px;
                margin-bottom: 24px;
            }

            .panel-group{
                margin-bottom: 20px;
                .panel-key{
                    color: #494F66;
                    font-size: 14px;
                    font-family: Ubuntu-Light;
                    letter-spacing: 0.28px;
                }
                .panel-value{
                    color: #494F66;
                    font-size: 16px;
                    font-family: Ubuntu-Medium;
                    letter-spacing: 0.12px;
                }
            }

            .relation{
                margin-bottom: 20px;
                .relation-name{
                    color: #494F66;
                    font-size: 14px;
                    font-family: Ubuntu-Medium;
                    letter-spacing: 0.28px;
                    overflow-wrap: anywhere;
                    span{
                        color: #62D862;
                        font-size: 14px;
                        font-family: Ubuntu-Medium;
                        letter-spacing: 0.28px;
                    }
                }
                .relation-brand{
                    color: #494F66;
                    font-size: 16px;
                    font-family: Ubuntu-Light;
                    letter-spacing: 0.12px;
                }
                .relation-sku{
                    color: #494F66;
                    font-size: 16px;
                    font-family: Ubuntu-Light;
                    letter-spacing: 0.12px;
                }
            }
        }
    }
}

@media (orientation: portrait) {

    .embalaje-detail-container{
        height: 100%;

        .embalaje-detail-content-wrapper{
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0px;
            padding: 15px;
            .panel{
                background-color: white;
                flex: 1;
                padding: 21px 26px;
            }

            .panel-title{
                color: #2f2e3c;
                font-family: Ubuntu-Medium;
                font-size: 15px !important;
                letter-spacing: .3px;
                margin-bottom: 7px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}