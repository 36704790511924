@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.product-added-list-wrapper {
  text-align: left;
  .product-added-list-title {
    display: flex;
    justify-content: space-between;
    .product-added-list-title-wrapper {
      font-family: Ubuntu-Light;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #676F8F;
      margin-bottom: 21px;
      text-transform: uppercase;
    }
  }

  .product-added-list-pagination{
    display: flex;
    justify-content: flex-end;
  }
}

@media (orientation: portrait) {

  .product-added-list-wrapper {
    .product-added-list-title {
      display: flex;
      justify-content: space-between;
      position: relative;
      .product-added-list-title-wrapper {
        font-family: Ubuntu-Light;
        font-size: 12px;
        letter-spacing: 0.24px;
        color: #676F8F;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
    }

    .product-added-list-pagination{
      display: none;
    }
  }
}
