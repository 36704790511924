@import "../../../../../../assets/styles/fonts";
@import "../../../../../../assets/styles/variables";

.app-table-item-wrapper {
  box-shadow: 0 1px 5px #0022ab1a;
  border-radius: 12px;
  width: 100%;
  background: white;

  .app-table-item-container {
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 32px;
    box-sizing: border-box;

    .app-table-item {
      font-size: 18px;
      font-family: Ubuntu-Light;
      color: #494f66;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      height: 100%;
      box-sizing: border-box;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
      }
    }

    .app-table-item-logo {
      font-family: Ubuntu-Medium;
      font-size: 20px;

      flex-basis: 152px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;


      p {
        display: flex;
        align-items: center;
      }
      img {
        height: 40px;
      }
    }

    .app-table-item-name {
      font-size: 19px;
      flex-basis: 181px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;
      font-family: Ubuntu-Medium;
      color: #494F66;

    }

    .app-table-item-client_id {
      font-family: Ubuntu-Light;
      font-size: 19px;

      flex-basis: 225px;
      flex-grow: 1;
      margin-right: 60px;
      display: flex;
      align-items: center;
      // border: 1px solid lightcoral;
      padding-right: 14px;

      box-sizing: border-box;

      div{
          display: flex;
          width: 80%;

      }
      p{
          margin: 0;
      }
      .icon{
          margin-left: 10px;
      }
    }
    .app-table-item-client_secret {
        font-family: Ubuntu-Light;
        font-size: 19px;

        flex-basis: 389px;
        flex-grow: 1;
        margin-right: 60px;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;
        padding-right: 14px;
        p{
          margin: 0;
        }
        .icon{
            margin-left: 10px;
        }
    }

    .app-table-item-status {
    font-family: Ubuntu-Medium;
    font-size: 19px;

    flex-basis: 168px;
    flex-grow: 1;
    justify-content: center;
    margin-right: 60px;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;


    }

    .app-table-item-scopes {
        font-family: Ubuntu-Medium;
        font-size: 19px;

        flex-basis: 64px;
        flex-grow: 1;
        justify-content: center;
        margin-right: 60px;
        display: flex;
        align-items: center;
        // border: 1px solid lightcoral;
        padding-right: 14px;
        }

    .app-table-item-button {
      color: #3c7af5;
      font-size: 18px;
      margin-right: 18px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      i {
        transform: rotate(90deg);
      }
    }
  }

  .app-table-tags-separator {
    border-bottom: 1px solid #d4d5e8;
    margin-bottom: 10px;
  }
  .app-table-list {
    padding: 0 110px 35px 32px;

    background-color: white;
    display: none;
    .tag-container {
      margin-bottom: 10px;
    }

    &.opened {
      display: block;
    }
  }

  .app-table-pill-container{
    width: 100%;

    .app-table-pill{
      border-radius: 14px;
      color: white;
      font-size: 10px;
      font-family: Ubuntu-Bold;
      padding: 2px 9px;
      display: inline;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0.72px;
      text-align: center;

      p{
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        width: 100%;
        min-width: 0;
        background-color: #3C7AF5;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 7px;
        box-sizing: border-box;
      }

      &.int{
        p{
            background-color: #3C7AF5;
        }
      }

      &.not{
        p{
            background-color: #F55D68;
        }
      }

      &.pen{
        p{
            background-color: #FFAF3A;
        }
      }

    }
  }

  .app-table-item-container-mobile{
    padding: 14px;
    .app-table-item-row-mobile{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      p{
        margin: 0;
      }

      .app-table-item-col{
        display: flex;
        flex-direction: column;

        .title{
          color: #7781AC;
          font-size: 12px;
          font-family: Ubuntu-Light;
        }

        .value{
          color: #2F2E3C;
          font-size: 12px;
          font-family: Ubuntu-Light;

        }
      }

      .app-logo{
        margin-bottom: 15px;
        height: 80px;
        img{
          height: 80px;

        }
      }
      .app-name{
        color: #2F2E3C;
        font-family: Ubuntu-Medium;
        font-size: 15px;
        letter-spacing: 0.3px;
        margin-bottom: 10px;
      }

      .app-client_id{
        margin-bottom: 10px;

      }

      .app-copy{
        img{
          height: 15px;

        }
      }
    }

  }
}
@media (orientation: portrait) {


  .app-table-item-wrapper {
    box-shadow: 0px 3px 6px #0022AB1A;
    border-radius: 20px;
  }

}