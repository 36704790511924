@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';


.button{
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: #5E93FC;
    text-transform: uppercase;
    font-family: Roboto-Medium;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.07px;
    cursor: pointer;

}