@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.auditoria-de-stock-content{

  .formControl {
    width: 100%;
  }

  .dropdown-filter-container{
    padding-right: 10px;
    text-overflow: ellipsis;
  }

  .combo_categories {
    margin-right: 10px;
  }

  .not-zones {
    font-size: 14px;
    font-family: Ubuntu-Light;
  }

  .auditoria-filter-categories-products{
    .MuiInputBase-input {
      border: 1px solid #D4D5E8 !important;
      border-radius: 4px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiOutlinedInput-input {
      padding: 17px;
    }
    .MuiInputLabel-outlined {
      transform: translate(17px, 27px) scale(1);
    }
    .MuiSelect-outlined {
      padding: 25px 0 11px 17px;
    }
    .MuiFormLabel-filled {
      transform: translate(17px, 0px) scale(0.75) !important;
    }
    .MuiInputLabel-shrink {
      transform: translate(14px, 0px) scale(0.75);
    }

    .MuiOutlinedInput-root {
      margin-left: 10px;
      gap: 4px;
      padding: 0px;
    }

    .custom-chip {
      background-color: white;
      color: $lightBlue;
      border: 1px solid $lightBlue;
      font-family: 'Ubuntu-Medium';
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      .MuiChip-deleteIcon {
        background-color: $lightBlue;
        color: white;
        border-radius: 50%;
      }
    }

    .categories-tree {
      width: 30vw;
      .MuiContainer-root {
        padding: 0px;
      }
      .MuiInputBase-root {
        margin-left: 0px;
      }

    }

    .MuiAutocomplete-input {
      height: 36px !important;
      font-family: 'Ubuntu-Light';
    }

    .products-autocomplete {

      .css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding-top: 0px;
      }
    }

  }
}

.css-17qvps1-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #62D862 !important;
}

.auditoria-filter-panel-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #0022ab1a;
  box-sizing: border-box;
  padding: 32px;
  border-radius: 10px;

  .auditoria-filter-panel-inputs {
    display: flex;
    align-items: center;
    .dropdown-filter-container {
      width: 280px;
    }
    .dropdown-filter-option-container {
      padding: 0px;
    }
  }

  .auditoria-filter-categories-products {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 15px;

    .dropdown-filter-container {
      width: 280px;
    }

    .formControl {
      width: 280px;
      position: 'relative',
    }
  }

  .auditoria-filter-panel-buttons {
    display: flex;
    align-items: center;

    .auditoria-filter-panel-filter-apply {
      background: #3c7af5;
      border-color: transparent;

      height: $btn-height;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: $btn-border-radius;
      padding: 0 35px;
      font-size: $btn-font-size;
      font-family: $btn-font;
      cursor: pointer;

      &:focus {
        outline-width: 0;
      }
      &.disabled{
        background-color: #F9F9FB;
      }
    }

    .auditoria-filter-panel-filter-delete {
      background: transparent;
      border-color: transparent;
      height: $btn-height;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: $btn-color-bg-dark;
      border-radius: $btn-border-radius;
      margin-left: $btn-margin;
      padding: 0 35px;
      font-size: $btn-font-size;
      font-family: $btn-font;
      cursor: pointer;
      &:focus {
        outline-width: 0;
      }
    }
  }
}
@media (orientation: landscape) and (max-width: 1400px) {
  .dropdown-filter-container{
    width: 240px !important;
  }
}

@media (orientation: landscape) and (max-width: 1278px) {
  .dropdown-filter-container{
    width: 200px !important;
  }
  .auditoria-filter-panel-filter-apply, .auditoria-filter-panel-filter-delete {
      padding: 0 10px !important;
  }
}

@media (orientation: landscape) and (max-width: 1059px) {
  .dropdown-filter-container{
    width: 150px !important;
  }
}

.auditoria-filter-panel-filtered-products {
  width: 100% !important;
  margin-top: 15px;
  .auditoria-filter-panel-filter-delete-all {
    background: $btn-color-bg-primary;
    border-color: transparent;
    height: $btn-height;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: $btn-border-radius;
    padding: 0 35px;
    font-size: $btn-font-size;
    font-family: $btn-font;
    cursor: pointer;
    margin-top: 15px;
  }
  .auditoria-filter-panel-filtered-products-list {
    width: 100% !important;
    clear: both;
    margin-top: 15px;
    span{
      padding-right: 10px;
      font-family: Ubuntu-Medium;
      color: #494F66;
      font-size: 18px;
      background: transparent;
    }
  }
}

@media (orientation: portrait) {

  .auditoria-filter-panel-container-out {

    .dropdown-filter-container{
      padding-right: 0px;
    }

    .not-zones{
      margin-bottom: 15px;
      margin-left: 0px;
    }

    .auditoria-filter-panel-filtered-products {
      font-family: Ubuntu-Light;
      font-size: 15px;
      margin-top: -15px;
      display: block;
      overflow-x: auto;
      .combo-categories {
        .MuiInputBase-root {
          margin-left: 0px;
        }
        .MuiContainer-root {
          padding: 0px;
        }
        .MuiOutlinedInput-root {
          padding-right: 39px;
          width: 100%;
          padding-left: 0px;
        }
      }
      .products-autocomplete {
          width: 100%;

          .MuiOutlinedInput-root {
            margin-left: 0px;
          }
        }

      .auditoria-filter-categories-products {
        display: block;

        .combo-categories {
          margin-bottom: 10px;
        }

        .formControl {
          width: 100%;
          background-color: white;
        }
        .select-categories-filter-wrapper {
          margin-top: 0;
          margin-bottom: 15px;
          width: 100%;
        }
      }
    }

  }

  .auditoria-filter-panel-container {
    position: absolute;
    top: 58px;
    bottom: 0;
    left: 0;
    background-color: #fafafc;
    border-radius: 0;
    align-items: flex-start;
    padding: 0;
    padding-bottom: 100px;
    .auditoria-filter-panel-inputs {
      flex-direction: column;
      width: 100%;
      padding: 19px 27px;
      overflow: scroll;
      height: 100%;

      .dropdown-filter-container {
        width: 100%;
        margin-right: 0;
      }
    }
    .auditoria-filter-panel-buttons {
      position: absolute;
      bottom: 0;
      background-color: white;
      box-shadow: 0px 0px 6px #00000029;
      height: 71px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      .auditoria-filter-panel-filter-apply {
      }

      .auditoria-filter-panel-filter-delete {
      }
    }
  }

  .auditoria-filter-panel-summary {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    padding: 0 17px 10px 17px;
    // height: 74px;
    justify-content: center;
    margin-bottom: 19px;

    .auditoria-filter-panel-summary-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .auditoria-filter-panel-summary-filters {
        font-family: Ubuntu-Light;
        font-size: 15px;
        color: #2f2e3c;
        display: flex;
        align-items: center;
        .separator {
          width: 1px;
          height: 20px;
          background-color: #2f2e3c;
          margin: 0 4px;
          &:last-child {
            display: none;
            font-size: 16px;
          }
        }
      }
      .auditoria-filter-panel-summary-button {
        i {
          color: #3c7af5;
          cursor: pointer;
        }
      }
      .auditoria-filter-panel-summary-stock {
        font-size: 12px;
        font-family: Ubuntu-Medium;
        color: #494f66;
      }
    }

    .auditoria-filter-categories-products {
      display: block;
      .dropdown-filter-container{
        max-width: none;
        width: 100%;
        border: none;
        .dropdown-filter-input {
          border: 1px solid #D4D5E8 !important;
        }
      }
      .select-categories-filter-wrapper {
        width: 100%;
      }
      .select-products-filter-wrapper {
        width: 100%;
      }
    }
  }
}
