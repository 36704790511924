@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

@import "../../../assets/fontello/css/fontello.css";

.dashboard-container {
  height: 100%;
}

.dashboard-wrapper {
  overflow: auto;
  padding: 0;
}

.dashboard-wrapper-inner {
  padding: 0 39px 39px;
}
.dashboard-charts-first-row {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
}

.dashboard-charts-second-row {
  display: flex;
  height: 323px;
}

.dashboard-charts-most-selled {
  flex: 1;
  margin-right: 10px;
}

.dashboard-charts-order-states {
  flex: 1;
  margin-left: 10px;
}

.dashboard-charts-delivered {
  width: 25%;
  margin-right: 10px;
}

.dashboard-charts-monthly-sales {
  flex: 1;
  margin-left: 10px;
}

.dashboard-table-wrapper {
  background-color: white;
  padding: 39px 39px;

  .dashboard-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .dashboard-table-title {
    color: #494f66;
    font-family: Ubuntu-Medium;
    font-size: 21px;
    margin: 0;
    font-weight: normal;
  }
}
@media (orientation: portrait) {
  .dashboard-wrapper {
    overflow: auto;
    padding: 0;
  }
  .dashboard-wrapper-inner {
    padding: 0 25px 125px;
  }
  .dashboard-charts-first-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .dashboard-charts-second-row {
    display: flex;
    flex-direction: column;
    min-height: 323px;
    height: unset;
  }

  .dashboard-charts-most-selled {
    flex: 1;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .dashboard-charts-order-states {
    flex: 1;
    margin-left: 0;
  }

  .dashboard-charts-delivered {
    flex: 1;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .dashboard-charts-monthly-sales {
    flex: 1;
    margin-left: 0;
  }

  .dashboard-table-wrapper{
    margin-bottom: 150px;
  }
}
