@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

@import "../../../assets/fontello/css/fontello.css";

.categories-container {
  height: 100%;
  .categories-wrapper {
    padding: 30px 37px 0 64px;
    overflow: auto;

    .table-categories-filter-container {
      .table-categories-filter-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .table-categories-search-wrapper {
          margin-left: 2px;

          .search-container {
            width: 389px;
          }
        }
        .table-categories-add-button {
          width: $btn-width;
          //   margin-right: -28px;
          button {
            font-size: $btn-font-size;
          }
        }
      }
    }

    .table-categories-table {
      margin-top: 46px;
      .table-categories-top-table {
        margin-bottom: 40px;
        .table-categories-top-total {
          font-family: Ubuntu-Light;
          font-size: 19px;
          color: #494f66;

          margin-left: 2px;

          .table-categories-top-total-bold {
            font-family: Ubuntu-Medium;
          }
        }
      }
    }

    .table-categories-pagination {
      .pagination-container {
        margin-top: 41px;
        margin-bottom: 40px;
        justify-content: flex-end;
      }
    }
  }
}

@media (orientation: portrait) {

  .categories-container {
    .categories-wrapper {
      padding: 0;
      flex: 1;
      overflow: auto;
      height: 100%;

      .table-categories-filter-container {
        .simple-search-panel {
          margin-bottom: 18px;
        }
      }

      .table-categories-table {
        margin-top: 0px;
        margin-bottom: 50px;
        max-height: calc(100% - 56px - 22px - 18px);
        height: 100%;
        padding: 15px;
        overflow-x: hidden;
        overflow-y: scroll;

        .table-categories-top-table {
          margin-bottom: 15px;

          .table-categories-top-total {
            font-size: 16px;
            text-transform: uppercase;
            margin-left: 18px;
            .table-categories-top-total-bold {
            }
          }
        }
      }
    }
  }
}
