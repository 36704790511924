
@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/variables";

.device-selector-root{

    display: flex;

    align-items: center;
    justify-content: space-between;
    .input-wrapper{
      width: 280px;
      img { // icon dropdown select
        position: absolute;
        top: 20%;
        right: 15px;
      }
    }
    .toggle-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #676f8f;
        font-family: Ubuntu-Light;
        font-size: 14px;
        padding-left: 31px;
        p {
          margin: 0;
          margin-right: 10px;
        }
        span {
          font-family: Ubuntu-Medium;
        }
      }
}

@media (orientation: portrait) {

    .device-selector-root{

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .input-wrapper{
            width: 100%;
            order: 2;
        }
        .toggle-wrapper {
            display: flex;
            order: 1;
            margin-bottom: 25px;
            align-items: center;
            justify-content: flex-start;
            color: #676f8f;
            font-family: Ubuntu-Light;
            font-size: 14px;
            padding-left: 31px;
            p {
              margin: 0;
              margin-right: 10px;
            }
            span {
              font-family: Ubuntu-Medium;
            }
          }
    }
}