@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.pagination-container{
    display: flex;
    .pagination-numbers{
        display: flex;
    }
    .pagination-item{
        font-family: Ubuntu-Light;
        color: #494F66;
        font-size: 16px;
    }
    .pagination-number{
        font-family: Ubuntu-Light;
        color: #494F66;
        font-size: 16px;
        margin-right: 17px;
        cursor: pointer;

        &.active{
            color: #62D862;
            font-family: Ubuntu-Bold;
            cursor: unset;
        }

        &:last-child{
            margin-right: 7px;
        }
    }
    .pagination-dots{
        letter-spacing: 1.57px;

        &.izq{
            margin-right: 7px;
        }
    }
    .pagination-icon{
        margin-left: 9px;
        color:#3C7AF5;
        cursor: pointer;
        font-size: 17px;
    }
}