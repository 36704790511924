.filter-alerts-desktop {
    margin-bottom: 30px;
    padding: 20px 30px;
    border-radius: 15px !important;
    box-shadow: 1px 1px 4px 0px rgba(95, 99, 136, 0.13) !important;
    display: flex;
    align-items: center;
    gap: 22px;

    &__small {
        display: flex;
        gap: 8px;
        span {
            right: -8px !important;
        }
        > h5 {
            margin: 0;
            font-family: Ubuntu-Medium;
            font-size: 16px;
            font-weight: 500;
            color: #3C7AF5;
        }
    }

    .main-app-generic-modal-modal-container {
        max-height: 90%;
    }
    
    .multi-select-wrapper {
        width: 25%;
        max-width: 280px;
    }

    &__input {
        .MuiSelect-root {
            position: relative;
        }
        img {
            position: absolute;
            top: 6px;
            right: 19px;
            pointer-events: none;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 10px 17px !important;

        .MuiCheckbox-root {
            padding: 0;
            width: 20px;
            height: 20px;
        
            i {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        
            .icon{
                font-size: 20px;
                color: #62D862;
                margin: 0;
        
                &::before{
                    margin:0
                }
            }
        
            .MuiTouchRipple-root {
                width: 18px;
                height: 19px;
                border: 1px solid #D9D9D9;
                border-radius: 5px;
            }
        }        

        .checkbox-container {
            width: 20px;
            height: 20px;
            .unchecked { margin: 0; }
        }

        .MuiListItemText-root {
            margin: 0;

            > span {
                margin: 0;
                font-family: Ubuntu;
                font-size: 14px;
                font-weight: 400;
                color: #494F66;
            }
        }
    }

    .modal-filters {
        animation: none !important;
        border-radius: 10px;

        .multi-select-wrapper, .react-datepicker-wrapper {
            box-sizing: border-box;
            width: 100%;
            max-width: none;
            height: 66px !important;
            background-color: #FFFFFF;
        }

        .custom-date-input > .MuiInputBase-root,
        .custom-date-input-complete > .MuiInputBase-root {
            height: 66px !important;
        }
    }
}