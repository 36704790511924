@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.location-summary-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  padding: 17px;
  box-sizing: border-box;
  .location-summary-container-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    .location-summary-left {
      .path {
        font-size: 15px;
        letter-spacing: 0.3px;
        font-family: Ubuntu-Light;
        margin-bottom: 17px;
        color: #2f2e3c;
        width: 380px;
        overflow: auto;
        text-align: left;
      }
      .items {
        font-family: Ubuntu-Medium;
        font-size: 12px;
        color: #494f66;
        text-transform: uppercase;
      }
    }
  }
  .location-summary-left {
    .path {
      font-size: 15px;
      letter-spacing: 0.3px;
      font-family: Ubuntu-Light;
      margin-bottom: 17px;
      color: #2f2e3c;
    }
    .items {
      font-family: Ubuntu-Medium;
      font-size: 12px;
      color: #494f66;
      text-transform: uppercase;
    }
  }
}

@media (orientation: portrait) {

  .location-summary-container {
    .location-summary-container-child {
      .location-summary-left {
        .path {
          width: 170px;
          padding: 0px 0px 10px;
        }
      }
    }
  }
}