@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.add-category-container {
  height: 100%;

  .add-category-wrapper {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100%;

    .add-category-content {

        width: 391px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 69px;
        margin-bottom: 26px;
      .add-category-icon {
          color: #858BA5;
          font-size: 122px;
          margin-bottom: 13px;
      }
      .add-category-subtitle {
          font-size: 22px;
          font-family: Ubuntu-Medium;
          color: #A9B1D1;
          letter-spacing: 0.92px;

          margin: 0;
          text-align: center;
          margin-bottom: 44px;
      }
      form {
          display: flex;
          flex-direction: column;
          align-items: center;
        .add-category-input {

        }

        .add-category-input-help {
            font-size: 12px;
            font-family: Ubuntu-Medium;
            color: #858BA5;
            margin: 0;
            margin-top: 5px;
            margin-left: 6px;

          }
        .add-category-button {
            margin-top: 42px;
            width: $btn-width;
            button{
                font-size: $btn-font-size;
            }
        }
      }
    }
  }
}

@media (orientation: portrait) {

  .add-category-container {
    height: 100%;

    .add-category-wrapper {

        .add-category-content {
            width: 274px;
            margin-top: 81px;
            .add-category-icon {
                font-size: 66px;
                margin-bottom: 3px;
            }

            .add-category-subtitle {
                font-size: 16px;
                letter-spacing: 0.67px;
                margin-bottom: 54px;
            }
            .add-category form {
              .add-category-input {
                .add-category-input-helper {
                    font-size: 6px;
                }
              }

              .add-category-button {
                width: $btn-width-short;
                border-radius: $btn-border-radius;
                font-size: $btn-font-size;
                font-family: $btn-font;
              }
            }
          }
    }
  }
}
