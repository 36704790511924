@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

@import '../../../../assets/fontello/css/fontello.css';

.auth-desktop-container{
    width: 100%;
    // height: 100vh;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    .img-container{
        width: 50%;
        .image{
            // height: 100vh;
            height: 100%;
            min-height: 100%;
            max-height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 100% 0;
        }
    }
    
    .content-container{
        // height: 100vh;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
    }
}