@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

.edit-code-tag-container {
  height: 100%;
  overflow: auto;
  .edit-code-tag-content-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: auto;
    .edit-code-tag-inner {
      width: 60%;
      padding: 30px;

      form {
        background: white;
        padding: 21px;
        .edit-code-tag-form-content {
          .edit-code-tag-form-field {
            margin-bottom: 10px;

            .main-input-container .main-input-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
    .edit-code-tag-title {
      color: #494f66;
      font-size: 16px;
      font-family: Ubuntu-Medium;
      font-weight: normal;
      margin-bottom: 15px;
    }
    .edit-code-tag-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
      .edit-code-tag-button {
        width: $btn-width;
        height: $btn-height;
        border-radius: $btn-border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: #ffffff;
        font-size: $btn-font-size;
        font-family: $btn-font;

        background-color: $btn-color-bg-primary;
        cursor: pointer;

        &.disabled {
          background-color: $btn-color-disabled;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .edit-code-tag-container {
    height: 100%;
    position: relative;
    .edit-code-tag-content-wrapper {
      padding: 15px;
      margin-bottom: 60px;
      .edit-code-tag-title {
        color: #494f66;
        font-size: 16px;
        font-family: Ubuntu-Medium;
        font-weight: normal;
        margin-bottom: 15px;
      }
      .edit-code-tag-button-wrapper {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #00000029;
        background-color: #ffffff;
        .edit-code-tag-button {
          width: $btn-width-short;
          height: $btn-height;
          border-radius: $btn-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;

          color: #ffffff;
          font-size: $btn-font-size;
          font-family: $btn-font;

          background-color: $btn-color-bg-primary;
          cursor: pointer;

          &.disabled {
            background-color: $btn-color-disabled;
          }
        }
      }
    }
  }
}
