@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/fonts";

@import "../../../../assets/fontello/css/fontello.css";

.table-stock-container {
  .table-stock-mobile {
    display: none;
  }
  .table-stock-header {
    display: flex;
    padding-left: 13px;

    margin-bottom: 25px;
    align-items: center;
    justify-content: flex-end;
    .table-stock-header-item {
      font-family: Ubuntu-Light;
      color: #a9b1d1;
      font-size: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .table-stock-header-icon-span {
        font-size: 6px;
        font-size: 12px;
        margin-left: 8px;

        &.active {
          color: #3c7af5;
        }
        &.down {
          transform: rotate(180deg);
        }
      }
    }
    .table-stock-header-date {
      flex-basis: 97px;
      flex-grow: 1;
    }
    .table-stock-header-type {
      flex-basis: 173px;
      flex-grow: 1;
    }

    .table-stock-header-order {
      flex-basis: 206px;
      flex-grow: 1;
    }
    .table-stock-header-reason {
      flex-basis: 188px;
      flex-grow: 1;
    }
    .table-stock-header-in {
      flex-basis: 158px;
      flex-grow: 1;
    }
    .table-stock-header-out {
      flex-basis: 147px;
      flex-grow: 1;
    }
    .table-stock-header-total {
      flex-basis: 90px;
      flex-grow: 1;
    }
    .table-stock-header-button {
      margin-right: 18px;
      width: 22px;
    }
  }

  .table-stock-content-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (orientation: portrait) {

  .table-stock-container {
    .table-stock-mobile {
      display: unset;
    }
    .table-stock-desktop {
      display: none;
    }
  }
}
