@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

@import "../../../../../../assets/fontello/css/fontello.css";

.product-step-one-container{
    .prod-step-one-field{
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px
        }

        .remove-product-category{
          color: $grey;
          display: block;
          margin: 8px 0px;
          text-align: right;
          cursor: pointer;

          &:hover{
            color: $blue;
          }
        }
    }

}