@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

.table-item-container {
  display: flex;
  height: 72px;
  border-radius: 12px;
  box-shadow: 0 1px 5px #0022ab1a;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 13px;
  box-sizing: border-box;
  background: white;

  .table-item {
    font-size: 18px;
    font-family: Ubuntu-Light;
    color: #494f66;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    height: 100%;
    box-sizing: border-box;

    div {
      overflow: hidden;
    }
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }

  .table-item-date {
    flex-basis: 97px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-type {
    font-family: Ubuntu-Medium;
    font-size: 19px;
    flex-basis: 173px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-order {
    flex-basis: 206px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-reason {
    flex-basis: 188px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-in {
    font-family: Ubuntu-Medium;
    font-size: 19px;
    color: green;
    flex-basis: 158px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-out {
    font-family: Ubuntu-Medium;
    font-size: 19px;
    color: red;
    flex-basis: 147px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-total {
    font-family: Ubuntu-Medium;
    font-size: 19px;
    flex-basis: 90px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
    padding-right: 14px;
  }

  .table-item-button {
    color: #3c7af5;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 8px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    // border: 1px solid lightcoral;
  }
}
