@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/fonts";

.add-config-tag-wizard-step-four-container {
  .add-config-tag-wizard-step-four-content {
    .add-config-tag-wizard-step-four-field {
      margin-bottom: 16px;
      width: 280px;
    }

    .add-config-tag-confirmation-main-title{
        color: #676F8F;
        font-size: 16px;
        font-family: Ubuntu-Medium;
        letter-spacing: 0.28px;
        font-weight: normal;

                }
    .config-tag-confirmation-wrapper {

      .config-tag-confirmation-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        .config-tag-confirmation-title {
            color: #3C7AF5;
            font-size: 14px;
            font-family: Ubuntu-Medium;
            letter-spacing: 0.28px;
            font-weight: normal;
            margin: 0;
        }
      }
      .config-tag-confirmation-content {
        .config-tag-confirmation-key{
          color: #2F2E3C;
            font-size: 12px;
            font-family: Ubuntu-Light;
            letter-spacing: 0.24px;
            font-weight: normal;
            margin-top: 15px;
        }
        .config-tag-confirmation-item {
            color: #494F66;
            font-size: 12px;
            font-family: Ubuntu-Medium;
        }
        margin-bottom: 20px;
      }
    }

    .add-config-tag-wizard-step-four-action-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
      background-color: #ffffff;

      .add-config-tag-wizard-step-four-action-button {
        width: $btn-width;
        height: $btn-height;
        border-radius: $btn-border-radius;
        border: none;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #ffffff;
        font-size: $btn-font-size;
        font-family: $btn-font;

        background-color: $btn-color-bg-primary;
        cursor: pointer;

        &.disabled {
          background-color: $btn-color-disabled;
        }
      }
    }
  }
}
@media (orientation: portrait) {

  .add-config-tag-wizard-step-four-container {

    .add-config-tag-wizard-step-four-content {
        .config-tag-confirmation-wrapper {
            .config-tag-confirmation-main-title{

            }
          .config-tag-confirmation-header {
            .config-tag-confirmation-title {
            }
          }
          .config-tag-confirmation-content {
            .config-tag-confirmation-item {
            }
          }
        }

      .add-config-tag-wizard-step-four-field {
        margin-bottom: 16px;
        width: 280px;
      }
      .add-config-tag-wizard-step-four-action-wrapper {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #00000029;
        .add-config-tag-wizard-step-four-action-button {
          width: $btn-width-short;
          height: $btn-height;
          border-radius: $btn-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;

          color: #ffffff;
          font-size: $btn-font-size;
          font-family: $btn-font;

          background-color: $btn-color-bg-primary;

          &.disabled {
            background-color: $btn-color-disabled;
          }
        }
      }
    }
  }
}
