@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/fontello/css/fontello.css";

.donut-chart-container {
  display: flex;
  justify-content: space-between;
}
.inner-legend {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inner-legend-title {
  font-size: 15px;
  font-family: Ubuntu-Light;
  color: #494f66;
}

.inner-legend-number {
  font-size: 60px;
  font-family: Ubuntu-Light;
  color: #62d862;
}
.donut-chart-legend {
  //   position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  right: 0;
}
.donut-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.donut-legend-item-first {
  display: flex;
  align-items: flex-end;
  margin-bottom: 22px;
}
.donut-legend-left {
  display: flex;
  align-items: center;
}
.donut-legend-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 3px solid #62d862;
  margin-right: 12px;
}

.donut-legend-name {
  color: #858ba5;
  font-family: Ubuntu;
  font-size: 19px;
  width: 130px;
}

.donut-legend-name-first {
  color: #858ba5;
  font-family: Ubuntu-Bold;
  font-size: 19px;
  width: 130px;
}

.donut-legend-number {
  color: #858ba5;
  font-family: Ubuntu-Medium;
  font-size: 16px;
  letter-spacing: 0.32px;
}

.donut-legend-number-first {
  color: #858ba5;
  font-family: Ubuntu-Light;
  font-size: 44px;
  letter-spacing: 0.32px;
  line-height: 38px;
}

.highcharts-credits {
  display: none;
}
@media (orientation: portrait) {

  .donut-chart-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .inner-legend {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .inner-legend-title {
    font-size: 15px;
    font-family: Ubuntu-Light;
    color: #494f66;
  }

  .inner-legend-number {
    font-size: 60px;
    font-family: Ubuntu-Light;
    color: #62d862;
  }
  .donut-chart-legend {
    //   position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    right: 0;
  }
  .donut-legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
  }
  .donut-legend-item-first {
    display: flex;
    align-items: flex-end;
    margin-bottom: 22px;
  }
  .donut-legend-left {
    display: flex;
    align-items: center;
  }
  .donut-legend-circle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 3px solid #62d862;
    margin-right: 12px;
  }

  .donut-legend-name {
    color: #858ba5;
    font-family: Ubuntu;
    font-size: 16px;
    width: 130px;
  }

  .donut-legend-name-first {
    color: #858ba5;
    font-family: Ubuntu-Bold;
    font-size: 16px;
    width: 130px;
  }

  .donut-legend-number {
    color: #858ba5;
    font-family: Ubuntu-Medium;
    font-size: 14px;
    letter-spacing: 0.32px;
  }

  .donut-legend-number-first {
    color: #858ba5;
    font-family: Ubuntu-Light;
    font-size: 40px;
    letter-spacing: 0.32px;
    line-height: 38px;
  }
}
