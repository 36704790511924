@import '../../../assets/styles/fonts';

.card{
  width: 130px;
  height: 130px;
  margin: 3px !important;
}

.dialog{
    min-width: 500px;
}

.grid-item{
  margin-top: 15px;
  padding-top: 20px !important;
  margin-left: 30px;
  margin-bottom: 20px;
  background-color: #fafafc !important;
}

@keyframes incrementframes {
  from {color: #494f66 }
  to {color: #62d862 }
}

.increment {
  animation-name: incrementframes !important;
  animation-duration: 1s !important;
}

@keyframes decrementframes {
  from { color: #494f66;}
  to {color: #FF6161; }
}

.decrement{
  animation-name: decrementframes !important;
  animation-duration: 2s !important;
}


.increment2{
  //background:'-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #b9f005), color-stop(1, #98bf45))';
  color: #FF6161;
  color: #7BFFBB;
  color: #98bf45;
  color: #b9f005;
  background: 'webkit-gradient(linear, left top, left bottom, color-stop(0.05, #FF6161), color-stop(1, #7BFFBB))' !important; 
}

.decrement{
  background:'-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #FF6161), color-stop(1, #7BFFBB))' !important; 
}

.image-estante{
  height: 100%;
  width: 45px;
  padding: 8px;
  border-radius: 15px;
  object-fit: contain;

}

.tittle{
  color: #494f66 !important;
  margin-left: 10px;
  font-family: Ubuntu-Medium !important;
  font-size: 15px !important;
  margin-top: -1px !important;
}

.message{
  color: #494f66 !important;
  margin-left: 10px;
  font-family: Ubuntu-Medium !important;
  font-size: 20px !important;
  margin-top: 10px !important;
}


.txtcantidad{
  color: #494f66;
  font-family: Ubuntu-Medium !important;
  font-size: 25px !important;
  margin-top: -32px !important;
  margin-left: 80px !important;
}

.prod{
  color: #494f66;
  font-family: Ubuntu-Medium !important;
  font-size: 25px !important;
}

.image-estante-dialog{
  height: 100% !important;
  border-radius: 15px;
  width: 70px !important;
  object-fit: contain;
}

.tittle-dialog{
  color: #494f66;
  font-family: Ubuntu-Medium !important;
  font-size: 24px !important;  
}

.icon-close{
  transform: scale(1.8);
}