.association-product-item {
    display: flex;
    height: 100px;
    img {
        height: 50px;
        width: 50px;
        margin-right: 30px;
    }

    .association-product-data {
        color: #494f66;
        font-family: Ubuntu-Medium;
        font-size: 20px;

        .association-product-sku{
            font-size: 15px;
            font-family: 'Ubuntu-Light';
        }


    }
}