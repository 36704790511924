@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/fonts";

@import "../../../../../assets/fontello/css/fontello.css";

.monthly-sales-card-container {
  width: 100%;
  height: 100%;
}

.dashboard-card-title {
  color: #494f66;
  font-family: Ubuntu-Medium;
  font-size: 21px;
  margin: 0;
  font-weight: normal;
}

@media (orientation: portrait) {

  .dashboard-card-title {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
}
