@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/variables";

.app-edit-container{
    display: flex;
    gap: 5%;

    .left{
        background-color: white;
        border-radius: 10px;
        flex: 1;
        min-width: 0;
    }

    .right{
        flex: 1;
        min-width: 0;

    }

    .group{
        padding: 21px 51px;
        &.logo{

            background-color: white;
            border-radius: 10px;
        }

        &.void{
            padding: 0;
        }

        &.credentials{
            margin-top: 64px;
        }

        .title{
            color: #676F8F;
            font-size: 20px;
            font-family: Ubuntu-Medium;
            letter-spacing: 0.4px;
            margin-bottom: 26px;
        }

        .permisos{
            margin-top: 29px;
            width: 100%;

            .left{
                .title{
                    font-family: Ubuntu-Medium;
                    color: #676F8F;
                    font-size: 14px;
                    letter-spacing: 0.11px;

                }
            }

            th{
               color: #7781AC;
               font-size: 14px;
               font-family: Ubuntu-Light;
               letter-spacing: 0.28px;
               font-weight: 400;
            }

            .scope-th{
                text-align: left;
            }

            .title-table{
                color: #494F66;
                font-family: Ubuntu-Medium;
                font-size: 16px;
                letter-spacing: 0.12px;
            }
            .option{
                width: 75px;
                text-align: center;

                .checkbox-container{
                    margin-left: 27px;
                }
            }
        }

        .enable-toggle{
            display: flex;
            justify-content: flex-end;
            margin-top: 34px;

            .toggle-label{
                margin: 0;
                color: #676F8F;
                font-family: Ubuntu-Light;
                font-size: 18px;
                margin-right: 14px;
            }
        }

    }
}

@media (orientation: portrait) {

    .app-edit-container{
        flex-direction: column;

        .left{
            order: 1;
        }
        .right{
            order: 0;
        }

        .group{
            padding: 21px 51px;


            &.credentials{
                padding: 16px;
            }
        }
    }
}