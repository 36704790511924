@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/fonts';

@import '../../../../../assets/fontello/css/fontello.css';

.password-group{

    .validation{
        margin-top: 10px;
        margin-left: 39px;

        .safe-password{
            font-size: 15px;
            font-family: Roboto-Light;
            color: #62D862;
        }
        .validation-title{
            color: #292A31;
            font-size: 15px;
            font-family: Roboto-Bold;
            padding: 0;
            margin: 0;
        }

        .validation-item{
            color: #8D8D8D;
            font-size: 15px;
            font-family: Roboto-Light;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;

            .icon{
                font-size: 10px;
                margin-right: 3px;
            }
            &.valid{
                color: #62D862;
            }
        }
    }
}

@media (orientation: portrait) {
    .password-group{

        .validation{
            margin-top: 10px;
            margin-left: 34px;

            .safe-password{
                font-size: 12px;
                font-family: Roboto-Light;
                color: #62D862;
            }
            .validation-title{
                font-size: 15px;
                font-family: Roboto-Medium;
            }

            .validation-item{
                color: #8D8D8D;
                font-size: 14px;
                font-family: Roboto;

                &.valid{
                    color: #62D862;
                }
            }
        }
    }
}