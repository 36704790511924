@import "../../../assets/styles/variables";
@import '../../../assets/styles/fonts';

.content {
  border-radius: 12px;
  box-shadow: 0 1px 5px #0022ab1a;
  background-color: white;
  margin-top: 10px;
}

.list-content {
  color: #494f66;
  font-family: Ubuntu-Medium;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.path {
  padding-right: 5px;
  color: #676f8f;
  font-family: Ubuntu-Light;
  font-size: 5px;
  overflow: auto;
}

.head {
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
}

.tag {
  color: #62d862;
  font-family: Ubuntu-Medium !important;
  font-size: 14px;
  margin-bottom: 7px;
}

.row-icon {
  color: #3c7af5;
  text-align: end;
}

@media (orientation: portrait) {

  .head {
    max-height: calc(100% - 100px);
  }
}